import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import * as R from 'ramda';
import { axios } from '../../../utils';
import { ICrewAssignment, ICrewVehicle, IDriver } from '../../../interfaces';
import AppContext from '../../../context';

const useStyles = makeStyles({
  root: {
    padding: 32,
  },
  topRow: {
    display: 'flex',
  },
  text: {
    color: '#37011F',
    marginRight: 40,
  },
  label: {
    color: '#666666',
  },
  table: {
    marginTop: 32,
    width: 800,
    borderCollapse: 'collapse',
    border: '1px solid #37001F',
  },
  cell: {
    padding: '4px 8px',
    color: '#37001F',
    fontSize: 16,
    fontWeight: 300,
    border: '1px solid #37001F',
    textAlign: 'center',
    '&:first-child': {
      textAlign: 'left',
    },
  },
  headerCell: {
    fontWeight: 400,
  },
  emptyCell: {
    width: 37,
    border: '1px solid #37001F',
  },
});

export default function AssignPrint() {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useContext(AppContext);
  const { date, depot } = Object.fromEntries(new URLSearchParams(location.search).entries());
  const [vehicles, setVehicles] = useState<ICrewVehicle[] | null>(null);
  const [drivers, setDrivers] = useState<IDriver[] | null>(null);
  const [table, setTable] = useState<ICrewAssignment[]>([]);
  useEffect(() => {
    (async () => {
      if (date && depot) {
        const agency = user?.agency || localStorage.getItem('agency');
        const [
          { data: vehiclesData },
          { data: driversData },
          { data },
        ] = await Promise.all([
          axios.get(`/${agency}/vehicles`),
          axios.get(`/${agency}/drivers`),
          axios.get(`/${agency}/crew/assignment`, {
            params: { date, depot },
          }),
        ]);
        setVehicles(vehiclesData);
        setDrivers(driversData);
        setTable(data);
      }
    })();
  }, [date, depot, user]);
  if (!vehicles || !drivers) return null;
  return (
    <div className={classes.root}>
      <div className={classes.topRow}>
        <Typography className={classes.text}>
          <span className={classes.label}>
            {t('data.date')}
            {': '}
          </span>
          {moment(date).format('DD.MM.YYYY')}
        </Typography>
        <Typography className={classes.text}>
          <span className={classes.label}>
            {t('data.depot')}
            {': '}
          </span>
          {depot}
        </Typography>
      </div>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classNames(classes.cell, classes.headerCell)}>
              {t('common.route')}
            </th>
            <th className={classNames(classes.cell, classes.headerCell)}>
              {t('data.startTime')}
            </th>
            <th className={classNames(classes.cell, classes.headerCell)}>
              {t('data.vehicleID')}
            </th>
            <th
              className={classNames(classes.cell, classes.headerCell)}
              colSpan={2}
            >
              {t('data.firstShift')}
            </th>
            <th
              className={classNames(classes.cell, classes.headerCell)}
              colSpan={2}
            >
              {t('data.secondShift')}
            </th>
          </tr>
        </thead>
        <tbody>
          {table.map((row) => (
            <tr key={row.task}>
              <td className={classes.cell}>
                {row.task}
              </td>
              <td className={classes.cell}>
                {moment(row.start, 'HH:mm').format('H:mm')}
              </td>
              <td className={classes.cell}>
                {vehicles.find(R.propEq('id', row.vehicle))?.number}
              </td>
              <td className={classes.cell}>
                {drivers.find(R.propEq('id', row.driverFirst))?.name}
              </td>
              <td className={classes.emptyCell} />
              <td className={classes.cell}>
                {drivers.find(R.propEq('id', row.driverSecond))?.name}
              </td>
              <td className={classes.emptyCell} />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
