import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Button, IconButton, MenuItem, TextField,
} from '@material-ui/core';
import * as R from 'ramda';
import { IDataPattern, IDataRoute } from '../../interfaces';
import { axios } from '../../utils';
import { ProviderContext } from '../../context';
import VehicleIcon from '../VehicleIcon';
import { ReactComponent as Clear } from '../../assets/clear.svg';
import VersionInfo from '../VersionInfo';
import NewHeader from '../NewHeader';
import ButtonList from '../ButtonList';
import DeleteDialog from '../DeleteDialog';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
  },
  main: {
    display: 'flex',
  },
  left: {
    flexGrow: 1,
  },
  right: {
    width: 340,
    marginLeft: 24,
    flexShrink: 0,
  },
  routeName: {
    marginLeft: 16,
  },
  editRoute: {
    border: '1px solid #707070',
    borderRadius: 3,
    padding: 24,
  },
  routeNameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  routeNameField: {
    width: 200,
  },
  closeRoute: {
    margin: '-16px -12px 0 0',
  },
  transportModeName: {
    marginLeft: 16,
  },
  buttons: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
  },
  saveRoute: {
    marginLeft: 'auto',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    '&  svg': {
      margin: '-4px 0',
    },
  },
  description: {
    marginTop: 24,
  },
});

export default function Routes() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: { agency }, version } = useContext(ProviderContext);
  const [routes, setRoutes] = useState<IDataRoute[]>([]);
  const [patterns, setPatterns] = useState<IDataPattern[]>([]);
  const [selectedRoute, setSelectedRoute] = useState<number | 'new' | null>(null);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [transportMode, setTransportMode] = useState(3);
  const [descriptionTo, setDescriptionTo] = useState('');
  const [descriptionFrom, setDescriptionFrom] = useState('');
  const closeRoute = () => {
    setSelectedRoute(null);
    setName('');
    setNameError(false);
    setTransportMode(3);
  };
  useEffect(() => {
    (async () => {
      const [
        { data },
        { data: patternsData },
      ] = await Promise.all([
        axios.get(`/${agency}/data/routes`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/patterns`, { params: { providerVersion: version!.id } }),
      ]);
      setRoutes(data);
      setPatterns(patternsData);
    })();
  }, [agency, version]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const readOnly = !version?.hasUserLock;
  const canDelete = typeof selectedRoute !== 'number' || !patterns.some(R.propEq('route', selectedRoute));
  return (
    <div className={classes.root}>
      <VersionInfo />
      <div className={classes.main}>
        <div className={classes.left}>
          <NewHeader
            headerText={t('data.routes')}
            newText={t('data.newRoute')}
            onClick={() => {
              setSelectedRoute('new');
              setName('');
              setNameError(false);
              setTransportMode(3);
            }}
            disabled={readOnly}
          />
          <ButtonList
            elements={routes.map((route) => ({
              id: route.id,
              name: (
                <>
                  <VehicleIcon type={route.type} />
                  <span className={classes.routeName}>
                    {route.name || route.id}
                  </span>
                </>
              ),
              onClick: () => {
                setSelectedRoute(route.id);
                setName(route.name);
                setNameError(false);
                setTransportMode(route.type);
                setDescriptionTo(route.descriptionTo);
                setDescriptionFrom(route.descriptionFrom);
              },
            }))}
            selected={selectedRoute}
          />
        </div>
        <div className={classes.right}>
          {selectedRoute && (
            <div className={classes.editRoute}>
              <div className={classes.routeNameContainer}>
                <TextField
                  label={t('data.routeName')}
                  value={name}
                  onChange={({ target }) => {
                    setName(target.value);
                    setNameError(false);
                  }}
                  variant="outlined"
                  size="small"
                  className={classes.routeNameField}
                  error={nameError}
                  helperText={nameError ? t('data.routeExists', { name }) : ''}
                  id="route-name"
                />
                <IconButton
                  className={classes.closeRoute}
                  onClick={closeRoute}
                >
                  <Clear />
                </IconButton>
              </div>
              <TextField
                label={t('data.transportMode')}
                value={transportMode}
                onChange={({ target }) => setTransportMode(Number(target.value))}
                variant="outlined"
                size="small"
                fullWidth
                select
              >
                <MenuItem value={3}>
                  <div className={classes.menuItem}>
                    <VehicleIcon type={3} />
                    <span className={classes.transportModeName}>
                      {t('data.bus')}
                    </span>
                  </div>
                </MenuItem>
                <MenuItem value={0}>
                  <div className={classes.menuItem}>
                    <VehicleIcon type={0} />
                    <span className={classes.transportModeName}>
                      {t('data.tram')}
                    </span>
                  </div>
                </MenuItem>
                <MenuItem value={2}>
                  <div className={classes.menuItem}>
                    <VehicleIcon type={2} />
                    <span className={classes.transportModeName}>
                      {t('data.train')}
                    </span>
                  </div>
                </MenuItem>
                <MenuItem value={800}>
                  <div className={classes.menuItem}>
                    <VehicleIcon type={800} />
                    <span className={classes.transportModeName}>
                      {t('data.trolleybus')}
                    </span>
                  </div>
                </MenuItem>
                <MenuItem value={1200}>
                  <div className={classes.menuItem}>
                    <VehicleIcon type={1200} />
                    <span className={classes.transportModeName}>
                      {t('data.ferry')}
                    </span>
                  </div>
                </MenuItem>
              </TextField>
              <TextField
                label={t('data.routeDescriptionTo')}
                value={descriptionTo}
                onChange={({ target }) => setDescriptionTo(target.value)}
                multiline
                rows={6}
                variant="outlined"
                size="small"
                fullWidth
                className={classes.description}
              />
              <TextField
                label={t('data.routeDescriptionFrom')}
                value={descriptionFrom}
                onChange={({ target }) => setDescriptionFrom(target.value)}
                multiline
                rows={6}
                variant="outlined"
                size="small"
                fullWidth
                className={classes.description}
              />
              <div className={classes.buttons}>
                {selectedRoute !== 'new' && (
                  <Button
                    color="primary"
                    onClick={() => {
                      setDeleteDialog(true);
                      setDeleteName(routes.find(R.propEq('id', selectedRoute))!.name);
                    }}
                    disabled={readOnly}
                  >
                    {t('data.deleteRoute')}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.saveRoute}
                  onClick={async () => {
                    if (routes.some((x) => x.name === name && x.id !== selectedRoute)) {
                      setNameError(true);
                      return;
                    }
                    if (selectedRoute === 'new') {
                      const { data } = await axios.post(`/${agency}/data/route`, {
                        name,
                        type: transportMode,
                        descriptionTo,
                        descriptionFrom,
                      });
                      setRoutes(R.append(data));
                    } else {
                      await axios.put(`/${agency}/data/route/${selectedRoute}`, {
                        name,
                        type: transportMode,
                        descriptionTo,
                        descriptionFrom,
                      });
                      setRoutes(R.map((x) => (
                        x.id === selectedRoute ? ({
                          name,
                          type: transportMode,
                          id: x.id,
                          descriptionTo,
                          descriptionFrom,
                        }) : x
                      )));
                    }
                    closeRoute();
                  }}
                  disabled={readOnly}
                >
                  {t('data.save')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={canDelete ? t(
          'data.deleteRouteConfirm',
          { name: deleteName },
        ) : t('data.routeDeleteError')}
        onDelete={async () => {
          await axios.delete(`/${agency}/data/route/${selectedRoute}`);
          closeRoute();
          setRoutes(R.filter<IDataRoute>((x) => x.id !== selectedRoute));
          setDeleteDialog(false);
        }}
        canDelete={canDelete}
      />
    </div>
  );
}
