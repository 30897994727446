import React, {
  useContext, useEffect, useRef, useState, Fragment,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Button, Dialog, IconButton, InputAdornment, TextField, Typography, useMediaQuery, Tabs, Tab,
} from '@material-ui/core';
import {
  Map, Marker, Polyline, ScaleControl, TileLayer, Tooltip,
} from 'react-leaflet';
import leaflet from 'leaflet';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';
import { Base64 } from 'js-base64';
import classNames from 'classnames';
import moment from 'moment';
import Nav from './Nav';
import { axios, useGetLink } from '../utils';
import { ReactComponent as CardIcon } from '../assets/card.svg';
import { ReactComponent as ChevronBig } from '../assets/chevron-big.svg';
import { ReactComponent as Delete } from '../assets/delete.svg';
import AppContext from '../context';
import Footer from './Footer';
import markerPrimary from '../assets/marker-primary.svg';
import markerDark from '../assets/marker.svg';
import { ReactComponent as Search } from '../assets/search.svg';
import { ReactComponent as BackArrow } from '../assets/back-arrow.svg';
import { ReactComponent as Star } from '../assets/star.svg';
import { ReactComponent as MarkerOutlined } from '../assets/marker-outlined.svg';
import { ReactComponent as Phone } from '../assets/phone.svg';
import { ReactComponent as Website } from '../assets/website.svg';
import { ReactComponent as Download } from '../assets/download.svg';
import { ReactComponent as MapIcon } from '../assets/map.svg';
import { ReactComponent as Accessible } from '../assets/accessible.svg';
import { ReactComponent as Elevator } from '../assets/elevator.svg';
import { ReactComponent as Escalator } from '../assets/escalator.svg';
import AztecCode from '../common/AztecCode';
import DraggableCard from './DraggableCard';

interface IPeriod {
  from: string;
  to: string;
}

interface IPath {
  name: string;
  start: string;
  end: string;
  points: {
    lat: number;
    lon: number;
  }[];
}

interface IAttraction {
  site: {
    name: string;
    description: string;
  };
  name: string;
  description: string;
  accessible: boolean;
  elevator: boolean;
  escalator: boolean;
  address: string;
  website: string;
  phone: string;
  image: string | null;
  discount: {
    type: 'FREE_ENTRY' | 'PERCENTAGE' | 'PLN';
    entries: number;
    savings: number;
    price: number;
  };
  rating: {
    stars: number | null;
    ratings: number;
  };
  openingHours: {
    hours: IPeriod[];
    days: string[];
    periods: IPeriod[];
    excluded: string[];
    remarks: string;
  }[];
  openSoon: IPeriod[];
  resources: {
    name: string;
    file: string;
  }[];
  paths: IPath[];
  locations: {
    lat: number;
    lon: number;
    name: string;
  }[];
}

export interface ICard {
  id: string;
  holder: string;
  image: string | null;
  packages: {
    name: string;
    price: number | null;
    expired: boolean;
    validFrom: string;
    validTill: string;
    attractions: IAttraction[];
  }[];
}

export interface IAvailableCard {
  packageName: string;
  price: number | null;
  image: string | null;
  website: string;
  attractions: IAttraction[];
}

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flexGrow: 1,
    minHeight: 400,
    display: 'flex',
    height: 'calc(100vh - 43px)',
    '@media (max-width: 800px)': {
      height: 'initial',
    },
  },
  left: {
    width: 424,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    '@media (max-width: 800px)': {
      width: '100%',
    },
  },
  map: {
    flexGrow: 1,
    '@media (max-width: 800px)': {
      height: 200,
    },
  },
  addCard: {
    padding: '40px 64px',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
  },
  header: {
    fontSize: 26,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    color: '#37001F',
  },
  icon: {
    marginRight: 4,
  },
  text: {
    marginBottom: 16,
    color: '#37001F',
  },
  name: {
    fontWeight: 400,
  },
  textField: {
    marginBottom: 24,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottom: {
    flexGrow: 1,
    backgroundColor: '#A1145C',
    display: 'flex',
    flexDirection: 'column',
  },
  cardInfo: {
    padding: '24px 32px',
  },
  deleteCard: {
    marginLeft: -12,
  },
  chevron: {
    transform: 'rotate(90deg)',
    transition: '0.4s',
    width: 19,
    height: 19,
  },
  chevronFlipped: {
    transform: 'rotate(-90deg)',
    transition: '0.4s',
    width: 19,
    height: 19,
  },
  dialog: {
    padding: 24,
  },
  dialogText: {
    color: '#37001F',
    marginBottom: 16,
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: -8,
  },
  benefits: {
    margin: 16,
  },
  benefit: {
    width: '100%',
    height: 80,
    marginBottom: 8,
    display: 'flex',
    backgroundColor: 'white',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
    transition: '0.4s',
    zIndex: 1,
  },
  benefitActive: {
    transform: 'translateX(10px)',
  },
  imagePreview: {
    width: 105,
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexShrink: 0,
  },
  benefitRight: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    width: 'calc(100% - 105px)',
  },
  benefitTextContainer: {
    width: 'calc(100% - 60px)',
  },
  benefitText: {
    fontSize: 14,
    color: '#37001F',
    marginRight: 8,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  benefitButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  goButton: {
    minWidth: 0,
    padding: '2px 18px',
  },
  detailsButton: {
    marginTop: 'auto',
    marginRight: -8,
    padding: 4,
    width: 27,
  },
  expandedBenefit: {
    width: 424,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    position: 'relative',
  },
  benefitImage: {
    width: '100%',
    height: 250,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexShrink: 0,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 8,
  },
  expandedDetails: {
    margin: 24,
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  expandedName: {
    fontSize: 20,
    fontWeight: 400,
    color: '#37001F',
    marginRight: 16,
  },
  address: {
    fontWeight: 400,
    marginBottom: 16,
    color: '#37001F',
  },
  expandable: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'fit-content',
    marginBottom: 8,
  },
  subheader: {
    fontWeight: 400,
    color: '#37001F',
  },
  description: {
    marginTop: 16,
    '& p': {
      margin: 0,
    },
  },
  search: {
    width: '100%',
    marginBottom: 16,
    marginTop: 8,
    '& > div': {
      background: 'white',
    },
    '& fieldset': {
      border: 'none',
    },
    '& legend': {
      display: 'none',
    },
  },
  cardsBottom: {
    padding: '16px 48px',
    '@media (max-width: 800px)': {
      padding: '16px',
    },
  },
  cardDetails: {
    width: 424,
    overflowY: 'auto',
    padding: '16px 36px',
  },
  backButton: {
    margin: 8,
    alignSelf: 'flex-start',
  },
  bottomCard: {
    margin: '24px auto 8px',
  },
  detailsAztec: {
    display: 'block',
    margin: '0 auto',
  },
  detailsClose: {
    marginTop: 40,
    marginLeft: 'auto',
    display: 'block',
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 16,
  },
  expandedAttractionName: {
    fontSize: 17,
    color: '#37001F',
  },
  expandedEntries: {
    fontSize: 14,
    fontWeight: 400,
    color: '#37001F',
    marginBottom: 8,
  },
  youSaveFree: {
    fontSize: 12,
    fontWeight: 300,
    marginLeft: 16,
  },
  youSave: {
    marginLeft: 16,
  },
  expandedText: {
    fontSize: 14,
    color: '#37001F',
    fontWeight: 300,
    '& > p': {
      margin: 0,
    },
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  stars: {
    margin: '-3px 8px 0',
    display: 'flex',
  },
  star: {
    margin: '0 2px',
  },
  openNow: {
    color: '#188D15',
  },
  closedNow: {
    color: 'red',
  },
  hours: {
    margin: '10px 0',
  },
  hoursElement: {
    marginBottom: 10,
  },
  period: {
    fontSize: 16,
    fontWeight: 400,
    color: '#37001F',
    marginBottom: 4,
  },
  hoursHours: {
    marginLeft: 16,
  },
  expandButton: {
    marginLeft: 8,
  },
  resourcesRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
  },
  resourcesIcon: {
    marginRight: 12,
  },
  resource: {
    margin: '16px 0',
    fontWeight: 400,
    color: '#A1145C',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  resourceIcon: {
    marginLeft: 12,
    marginTop: -2,
  },
  starGray: {
    filter: 'grayscale(1)',
  },
  tooltip: {
    boxShadow: 'none',
  },
  tooltipText: {
    fontSize: 18,
    padding: '0 4px',
  },
  closedContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  closedElement: {
    fontSize: 14,
    color: '#37001F',
    width: '50%',
  },
  accessibility: {
    display: 'flex',
    marginBottom: 10,
  },
  accessibilityIcon: {
    marginRight: 8,
  },
  searchLabel: {
    color: 'white !important',
    top: -8,
    fontWeight: 300,
  },
  tabs: {
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
  },
  tabIndicator: {
    backgroundColor: 'white',
    height: 4,
  },
  tab: {
    fontSize: 16,
    fontWeight: 300,
    color: 'white',
    textTransform: 'none',
  },
});

const splitCardNumber = (id: string) => id.match(/.{4}/g)?.join(' ');

export default function Card() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { showSnackbar, name } = useContext(AppContext);
  const getLink = useGetLink();
  const isMobile = useMediaQuery('(max-width: 800px)');
  const history = useHistory();
  const mapRef = useRef<any>();
  const [mapViewport, setMapViewport] = useState<{
    center: [number, number],
    zoom: number,
  } | null>(null);
  const [ready, setReady] = useState(false);
  const [cards, setCards] = useState<ICard[]>([]);
  const [availableCards, setAvailableCards] = useState<IAvailableCard[]>([]);
  const [nearestCards, setNearestCards] = useState<IAvailableCard[]>([]);
  const [cardList, setCardList] = useState<(ICard | IAvailableCard)[]>([]);
  const [openCard, setOpenCard] = useState<ICard | IAvailableCard | null>(null);
  const [openPackageIndex, setOpenPackageIndex] = useState(0);
  const openAttractions = (() => {
    if (openCard) {
      if ('packages' in openCard) {
        return openCard.packages[openPackageIndex].attractions;
      }
      return openCard.attractions;
    }
    return null;
  })();
  const [code, setCode] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [expandedBenefitId, setExpandedBenefitId] = useState<number | null>(null);
  const [hoursExpanded, setHoursExpanded] = useState(false);
  const [resourcesExpanded, setResourcesExpanded] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [search, setSearch] = useState('');
  const [cardDetailsOpen, setCardDetailsOpen] = useState(false);
  const [selectedPath, setSelectedPath] = useState<IPath | null>(null);
  const expandedBenefit = (openAttractions && expandedBenefitId !== null)
    ? openAttractions[expandedBenefitId]
    : null;
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        '/user/cards/v2',
        { params: { lang: i18n.language } },
      );
      setCards(data);
      setReady(true);
    })();
  }, [i18n.language]);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(async ({ coords }) => {
      const { data } = await axios.get(
        '/user/card/nearest',
        { params: { lang: i18n.language, lat: coords.latitude, lon: coords.longitude } },
      );
      setNearestCards(data);
    });
  }, [i18n.language]);
  useEffect(() => {
    if (availableCards.length > 0) {
      setCardList(availableCards);
    } else {
      setCardList([...nearestCards, ...cards]);
    }
  }, [availableCards, nearestCards, cards]);
  useEffect(() => {
    if (mapRef.current) {
      if (openAttractions && openAttractions.length > 1) {
        mapRef.current.leafletElement.fitBounds(
          new leaflet.LatLngBounds(openAttractions.map(
            ({ locations: [{ lat, lon }] }) => [lat, lon],
          )),
          {
            padding: [32, 32],
          },
        );
      } else {
        mapRef.current.leafletElement.fitBounds(
          new leaflet.LatLngBounds([
            [56.464660, 14.408800],
            [53.570577, 19.443904],
          ]),
        );
      }
    }
  }, [ready, openAttractions]);
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.leafletElement.invalidateSize();
    }
    if (expandedBenefit) {
      setMapViewport({
        center: [expandedBenefit.locations[0].lat, expandedBenefit.locations[0].lon],
        zoom: 15,
      });
    }
  }, [expandedBenefit, cardDetailsOpen]);
  useEffect(() => {
    if (mapRef.current && selectedPath && selectedPath.points.length > 1) {
      mapRef.current.leafletElement.fitBounds(
        new leaflet.LatLngBounds(selectedPath.points.map(({ lat, lon }) => [lat, lon])),
        {
          padding: [32, 32],
        },
      );
    }
  }, [selectedPath]);
  const goToPlace = (benefit: IAttraction) => () => {
    const searchData = {
      from: {
        type: 'your-location',
      },
      to: {
        ...benefit.locations[0],
        name: benefit.name,
        type: 'search-result',
      },
    };
    history.push(getLink(
      `/trip-planning/${Base64.encodeURI(JSON.stringify(searchData))}`,
    ));
  };
  if (!ready) return null;
  return (
    <div className={classes.root}>
      <Nav />
      <div className={classes.main}>
        {!(isMobile && (expandedBenefit || cardDetailsOpen)) && (
          <div className={classes.left}>
            {!addCard && !openCard && (
              <>
                <div className={classes.headerContainer}>
                  <Typography className={classes.header}>
                    <CardIcon className={classes.icon} />
                    {t('touristCard.cards')}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setAddCard(true)}
                  >
                    {`+ ${t('touristCard.card')}`}
                  </Button>
                </div>
                <div className={classes.bottom}>
                  <div className={classes.cardsBottom}>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      label={t('touristCard.search')}
                      value={search}
                      onChange={async ({ target: { value } }) => {
                        setSearch(value);
                        if (value) {
                          const { data } = await axios.get('/user/card/search', {
                            params: { lang: i18n.language, query: value },
                          });
                          setAvailableCards(data);
                        } else {
                          setAvailableCards([]);
                        }
                      }}
                      className={classes.search}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ className: classes.searchLabel }}
                    />
                    <div>
                      {cardList.map((card, i) => (
                        <DraggableCard
                          key={'id' in card ? card.id : card.packageName}
                          card={card}
                          onClick={() => {
                            setOpenPackageIndex(0);
                            setOpenCard(card);
                          }}
                          labels
                          moveCard={(dragIndex, hoverIndex) => {
                            setCardList(R.move(dragIndex, hoverIndex));
                          }}
                          index={i}
                        />
                      ))}
                    </div>
                  </div>
                  <Footer darkBg narrow />
                </div>
              </>
            )}
            {!addCard && openCard && openAttractions && (
              <>
                <div className={classes.backContainer}>
                  <IconButton
                    color="primary"
                    onClick={() => setOpenCard(null)}
                    className={classes.backButton}
                  >
                    <BackArrow />
                  </IconButton>
                  {!('id' in openCard) && (
                    <Button
                      variant="contained"
                      color="primary"
                      component="a"
                      target="_blank"
                      rel="noreferrer noopener"
                      href={openCard.website}
                    >
                      {t('touristCard.buyCard')}
                    </Button>
                  )}
                </div>
                <div className={classes.bottom}>
                  <div className={classes.bottomCard}>
                    <DraggableCard
                      card={openCard}
                      onClick={() => {
                        setCardDetailsOpen(true);
                        setExpandedBenefitId(null);
                      }}
                      moveCard={() => {}}
                      index={0}
                    />
                  </div>
                  {'packages' in openCard && openCard.packages.length > 1 && (
                    <Tabs
                      value={openPackageIndex}
                      onChange={(event, value) => setOpenPackageIndex(value)}
                      variant="scrollable"
                      className={classes.tabs}
                      TabIndicatorProps={{ className: classes.tabIndicator }}
                    >
                      {openCard.packages.map((pkg, i) => (
                        <Tab
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          value={i}
                          label={pkg.name}
                          className={classes.tab}
                        />
                      ))}
                    </Tabs>
                  )}
                  <div className={classes.benefits}>
                    {openAttractions.map((benefit, i) => (
                      <div
                        key={benefit.name}
                        className={classNames(classes.benefit, {
                          [classes.benefitActive]: benefit === expandedBenefit,
                        })}
                      >
                        <div
                          className={classes.imagePreview}
                          style={{
                            backgroundImage: `url('${benefit.image}')`,
                          }}
                        />
                        <div className={classes.benefitRight}>
                          <div className={classes.benefitTextContainer}>
                            <Typography className={classes.benefitText}>
                              {benefit.site.name}
                            </Typography>
                            <Typography className={classes.benefitText}>
                              {benefit.name}
                            </Typography>
                            <Typography className={classes.benefitText}>
                              {benefit.discount.entries}
                              {' '}
                              {t(
                                benefit.discount.type === 'FREE_ENTRY'
                                  ? 'touristCard.freeEntry'
                                  : 'touristCard.discountedEntry',
                                { count: benefit.discount.entries },
                              )}
                            </Typography>
                          </div>
                          <div className={classes.benefitButtons}>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.goButton}
                              onClick={goToPlace(benefit)}
                            >
                              {t('touristCard.go')}
                            </Button>
                            <IconButton
                              color="primary"
                              className={classes.detailsButton}
                              onClick={() => {
                                setExpandedBenefitId(i);
                                setCardDetailsOpen(false);
                                setHoursExpanded(false);
                                setResourcesExpanded(false);
                                setSelectedPath(null);
                              }}
                              data-testid="attraction-details"
                            >
                              <ChevronBig />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Footer darkBg narrow />
                </div>
              </>
            )}
            {addCard && (
              <>
                <div className={classes.addCard}>
                  <Typography className={classes.header}>
                    <CardIcon className={classes.icon} />
                    {t('touristCard.addCard')}
                  </Typography>
                  <Typography className={classes.text}>
                    {t('touristCard.cardHolder')}
                    {': '}
                    <span className={classes.name}>
                      {name}
                    </span>
                  </Typography>
                  <TextField
                    value={code}
                    onChange={({ target }) => setCode(target.value)}
                    label={t('touristCard.cardNumber')}
                    fullWidth
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    id="card-number"
                  />
                  <div className={classes.buttons}>
                    <Button
                      color="primary"
                      onClick={() => setAddCard(false)}
                    >
                      {t('touristCard.cancel')}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        try {
                          await axios.put(`/user/card?id=${code}`);
                          const { data } = await axios.get('/user/cards/v2', { params: { lang: i18n.language } });
                          setCards(data);
                          setAddCard(false);
                          showSnackbar(t('touristCard.cardSaved'));
                        } catch {
                          showSnackbar(t('touristCard.failedToSave'));
                        }
                      }}
                    >
                      {t('touristCard.save')}
                    </Button>
                  </div>
                </div>
                <Footer narrow />
              </>
            )}
          </div>
        )}
        {cardDetailsOpen && openCard && 'id' in openCard && (
          <div className={classes.cardDetails}>
            <Typography className={classes.header}>
              <CardIcon className={classes.icon} />
              {openCard.packages[0].name}
            </Typography>
            <div className={classes.cardInfo}>
              <Typography>
                {name}
                <br />
                {t('touristCard.card')}
                {' # '}
                {splitCardNumber(openCard.id)}
                <br />
                {t('touristCard.valid')}
                {': '}
                {(openCard.packages[0].validFrom && openCard.packages[0].validTill) ? (
                  <>
                    {moment(openCard.packages[0].validFrom).format('DD.MM.Y')}
                    {` ${t('touristCard.validTo')} `}
                    {moment(openCard.packages[0].validTill).format('DD.MM.Y')}
                  </>
                ) : t('touristCard.fromActivation')}
                <br />
                {`${t('touristCard.cardPrice')}: `}
                {openCard.packages[0].price ? `${openCard.packages[0].price} PLN` : t('touristCard.free')}
              </Typography>
              <Button
                color="primary"
                className={classes.deleteCard}
                onClick={() => setDeleteDialog(true)}
              >
                {t('touristCard.delete')}
              </Button>
            </div>
            <AztecCode
              text={openCard.id}
              scale={6}
              className={classes.detailsAztec}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.detailsClose}
              onClick={() => setCardDetailsOpen(false)}
            >
              {t('common.close')}
            </Button>
          </div>
        )}
        {expandedBenefit && (
          <div className={classes.expandedBenefit}>
            <div
              className={classes.benefitImage}
              style={{
                backgroundImage: `url('${expandedBenefit.image}')`,
              }}
            />
            <IconButton
              color="primary"
              className={classes.closeButton}
              onClick={() => setExpandedBenefitId(null)}
            >
              <Delete />
            </IconButton>
            <div className={classes.expandedDetails}>
              <div className={classes.nameContainer}>
                <div>
                  <Typography className={classes.expandedName}>
                    {expandedBenefit.site.name}
                  </Typography>
                  <Typography className={classes.expandedAttractionName}>
                    {expandedBenefit.name}
                  </Typography>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToPlace(expandedBenefit)}
                >
                  {t('touristCard.go')}
                </Button>
              </div>
              <Typography className={classes.expandedEntries}>
                {expandedBenefit.discount.type === 'FREE_ENTRY'
                  ? `${expandedBenefit.discount.entries} ${t('touristCard.freeEntry', { count: expandedBenefit.discount.entries })}`
                  : `${t('touristCard.admission')} ${expandedBenefit.discount.price} PLN`}
                <span
                  className={
                    expandedBenefit.discount.type === 'FREE_ENTRY' ? classes.youSaveFree : classes.youSave
                  }
                >
                  {`${t('touristCard.youSave')} ${expandedBenefit.discount.savings} PLN`}
                </span>
              </Typography>
              {expandedBenefit.rating.stars && (
                <div className={classes.rating}>
                  <Typography className={classes.expandedText}>
                    {expandedBenefit.rating.stars.toFixed(1)}
                  </Typography>
                  <div className={classes.stars}>
                    {R.times((n) => (
                      <Star
                        key={n}
                        className={classNames(classes.star, {
                          [classes.starGray]: (
                            (n + 1) > Math.round(expandedBenefit.rating.stars || 0)
                          ),
                        })}
                      />
                    ), 5)}
                  </div>
                  <Typography className={classes.expandedText}>
                    {`(${expandedBenefit.rating.ratings})`}
                  </Typography>
                </div>
              )}
              <div className={classes.expandable}>
                <Typography className={classes.expandedText}>
                  {(() => {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const { from, to } of expandedBenefit.openSoon) {
                      if (moment().isBetween(moment(from), moment(to))) {
                        return (
                          <>
                            <span className={classes.openNow}>
                              {t('touristCard.openNow')}
                              .
                            </span>
                            {` ${t('touristCard.closes')} ${moment(to).format('HH:mm')}`}
                          </>
                        );
                      }
                      if (moment().isBefore(moment(from))) {
                        return (
                          <>
                            <span className={classes.closedNow}>
                              {t('touristCard.closedNow')}
                              .
                            </span>
                            {` ${t('touristCard.opens')} ${moment(from).format('HH:mm')}`}
                          </>
                        );
                      }
                    }
                    return t('touristCard.closed');
                  })()}
                </Typography>
                <IconButton
                  color="primary"
                  size="small"
                  className={classes.expandButton}
                  onClick={() => setHoursExpanded(R.not)}
                  data-testid="expand-hours"
                >
                  <ChevronBig
                    className={hoursExpanded ? classes.chevronFlipped : classes.chevron}
                  />
                </IconButton>
              </div>
              {hoursExpanded && (
                <div className={classes.hours}>
                  {Object.values(
                    R.groupBy((x) => JSON.stringify(x.periods), expandedBenefit.openingHours),
                  ).map((hoursGroup, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={i}>
                      <div className={classes.hoursElement}>
                        {hoursGroup[0].periods.map((period, j) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Typography className={classes.period} key={j}>
                            {moment(period.from).format('MMMM D, Y')}
                            {' - '}
                            {moment(period.to).format('MMMM D, Y')}
                          </Typography>
                        ))}
                        {hoursGroup.map((hours) => (
                          <>
                            <Typography className={classes.expandedText}>
                              {(() => {
                                const week = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];
                                const weekend = ['SATURDAY', 'SUNDAY'];
                                if ([...week, ...weekend].every((x) => hours.days.includes(x))) {
                                  return t('touristCard.mondaySunday');
                                }
                                if (week.every((x) => hours.days.includes(x))) {
                                  return t('touristCard.mondayFriday');
                                }
                                if (weekend.every((x) => hours.days.includes(x))) {
                                  return t('touristCard.saturdaySunday');
                                }
                                return hours.days.map((x) => t(`touristCard.${x}`)).join(', ');
                              })()}
                              <span className={classes.hoursHours}>
                                {hours.hours.map(({ from, to }) => (
                                  `${moment(from, 'HH:mm').format('HH:mm')} - ${moment(to, 'HH:mm').format('HH:mm')}`
                                )).join(` ${t('touristCard.and')} `)}
                              </span>
                            </Typography>
                          </>
                        ))}
                      </div>
                      {hoursGroup[0].excluded.length > 0 && (
                        <div className={classes.hoursElement}>
                          <Typography className={classes.period}>
                            {t('touristCard.closed')}
                          </Typography>
                          <div className={classes.closedContainer}>
                            {hoursGroup[0].excluded.map((date) => (
                              <Typography className={classes.closedElement}>
                                {moment(date).format('MMM D, Y')}
                              </Typography>
                            ))}
                          </div>
                        </div>
                      )}
                      <div className={classes.hoursElement}>
                        <div
                          className={classes.expandedText}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: hoursGroup[0].remarks }}
                        />
                      </div>
                    </Fragment>
                  ))}
                </div>
              )}
              <div className={classes.expandable}>
                <Typography className={classes.subheader}>
                  {t('touristCard.visitorResources')}
                </Typography>
                <IconButton
                  color="primary"
                  size="small"
                  className={classes.expandButton}
                  onClick={() => setResourcesExpanded(R.not)}
                  data-testid="expand-resources"
                >
                  <ChevronBig
                    className={resourcesExpanded ? classes.chevronFlipped : classes.chevron}
                  />
                </IconButton>
              </div>
              {resourcesExpanded && (
                <>
                  <div className={classes.resourcesRow}>
                    <MarkerOutlined className={classes.resourcesIcon} />
                    <Typography className={classes.expandedText}>
                      {expandedBenefit.address}
                    </Typography>
                  </div>
                  <div className={classes.resourcesRow}>
                    <Phone className={classes.resourcesIcon} />
                    <Typography className={classes.expandedText}>
                      {expandedBenefit.phone}
                    </Typography>
                  </div>
                  {expandedBenefit.website && (
                    <div className={classes.resourcesRow}>
                      <Website className={classes.resourcesIcon} />
                      <Typography
                        className={classes.expandedText}
                        component="a"
                        target="_blank"
                        rel="noreferrer noopener"
                        href={expandedBenefit.website}
                      >
                        {expandedBenefit.website
                          .replace(/^https?:\/\//, '')
                          .replace(/\/$/, '')}
                      </Typography>
                    </div>
                  )}
                  {expandedBenefit.resources.map((resource) => (
                    <Typography
                      key={resource.file}
                      className={classes.resource}
                      component="a"
                      href={resource.file}
                      target="_blank"
                      rel="noopener noreferer"
                    >
                      {resource.name}
                      <Download className={classes.resourceIcon} />
                    </Typography>
                  ))}
                  {expandedBenefit.paths.map((path) => (
                    <Typography
                      key={path.name}
                      className={classes.resource}
                      onClick={() => setSelectedPath(path)}
                    >
                      {path.name}
                      <MapIcon className={classes.resourceIcon} />
                    </Typography>
                  ))}
                </>
              )}
              <div className={classes.accessibility}>
                {expandedBenefit.accessible && (
                  <Accessible className={classes.accessibilityIcon} />
                )}
                {expandedBenefit.elevator && (
                  <Elevator className={classes.accessibilityIcon} />
                )}
                {expandedBenefit.escalator && (
                  <Escalator className={classes.accessibilityIcon} />
                )}
              </div>
              <div
                className={classes.description}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: expandedBenefit.site.description }}
              />
              <div
                className={classes.description}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: expandedBenefit.description }}
              />
            </div>
          </div>
        )}
        {!isMobile && (
          <Map
            className={classes.map}
            minZoom={3}
            maxZoom={18}
            ref={mapRef}
            center={mapViewport ? mapViewport.center : undefined}
            zoom={mapViewport ? mapViewport.zoom : undefined}
            onViewportChanged={({ center, zoom }) => {
              if (center && zoom) {
                setMapViewport({ center, zoom });
              }
            }}
            zoomControl={!isMobile}
            // @ts-ignore
            fullscreenControl={{ position: 'topright' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ScaleControl position="bottomleft" imperial={false} />
            {openAttractions && openAttractions.map((benefit) => (
              <Marker
                key={benefit.name}
                position={[benefit.locations[0].lat, benefit.locations[0].lon]}
                icon={leaflet.icon({
                  iconUrl: markerPrimary,
                  iconSize: benefit === expandedBenefit ? [26, 32] : [17, 21],
                })}
              />
            ))}
            {openCard && selectedPath && (
              <>
                <Marker
                  position={[selectedPath.points[0].lat, selectedPath.points[0].lon]}
                  icon={leaflet.icon({
                    iconUrl: markerDark,
                    iconSize: [19, 24],
                  })}
                >
                  <Tooltip
                    opacity={1}
                    className={classes.tooltip}
                  >
                    <Typography className={classes.tooltipText}>
                      {selectedPath.start}
                    </Typography>
                  </Tooltip>
                </Marker>
                <Marker
                  position={[R.last(selectedPath.points)!.lat, R.last(selectedPath.points)!.lon]}
                  icon={leaflet.icon({
                    iconUrl: markerPrimary,
                    iconSize: [19, 24],
                  })}
                >
                  <Tooltip
                    opacity={1}
                    className={classes.tooltip}
                  >
                    <Typography className={classes.tooltipText}>
                      {selectedPath.end}
                    </Typography>
                  </Tooltip>
                </Marker>
                <Polyline
                  positions={selectedPath.points.map(({ lat, lon }) => ({ lat, lng: lon }))}
                  color="#37001F"
                  weight={12}
                  dashArray="1 20"
                />
                <Polyline
                  positions={selectedPath.points.map(({ lat, lon }) => ({ lat, lng: lon }))}
                  color="#E4E4E4"
                  weight={8}
                  dashArray="1 20"
                />
              </>
            )}
          </Map>
        )}
      </div>
      <Dialog
        open={deleteDialog}
        PaperProps={{ className: classes.dialog }}
        onClose={() => setDeleteDialog(false)}
      >
        <Typography className={classes.dialogText}>
          {t('touristCard.deleteConfirm')}
        </Typography>
        <div className={classes.dialogButtons}>
          <Button
            variant="text"
            color="primary"
            onClick={() => setDeleteDialog(false)}
          >
            {t('touristCard.cancel')}
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={async () => {
              if (!openCard || !('id' in openCard)) return;
              await axios.delete(`/user/card?id=${openCard.id}`);
              setOpenCard(null);
              setCards(cards.filter((x) => x.id !== openCard.id));
              setDeleteDialog(false);
              setExpandedBenefitId(null);
            }}
          >
            {t('touristCard.delete')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
