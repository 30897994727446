import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  useHistory, useParams, Switch, Redirect, Route,
} from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import { useGetLink } from '../utils';
import PackageUsage from './PackageUsage';
import VisitorAttendance from './VisitorAttendance';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tabs: {
    margin: '0 -32px',
    backgroundColor: '#F5F5F5',
    paddingTop: 16,
  },
  tab: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 300,
    width: 400,
  },
});

export default function Statistics() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const getLink = useGetLink();
  const { tab } = useParams<{ tab: string; }>();
  if (!tab) {
    return (
      <Redirect from="/:language/card/statistics" exact to={getLink('/card/statistics/package-usage')} />
    );
  }
  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={(event, value) => history.push(getLink(`/card/statistics/${value}`))}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.tabs}
      >
        <Tab
          className={classes.tab}
          value="package-usage"
          label={t('card.packageUsage')}
        />
        <Tab
          className={classes.tab}
          value="visitor-attendance"
          label={t('card.visitorAttendance')}
        />
      </Tabs>
      <Switch>
        <Route path="/:language/card/statistics/package-usage" component={PackageUsage} />
        <Route path="/:language/card/statistics/visitor-attendance" component={VisitorAttendance} />
      </Switch>
    </div>
  );
}
