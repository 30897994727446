import React, { Component, createRef, useContext } from 'react';
import { createPortal } from 'react-dom';
import {
  Button, Dialog, TextField, Typography, createStyles, WithStyles, withStyles,
} from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  Map, ScaleControl, TileLayer,
} from 'react-leaflet';
import L, {
  Circle, CircleMarker, LeafletMouseEvent, Marker,
} from 'leaflet';
import 'leaflet.path.drag';
import Control from 'react-leaflet-control';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import { isPointWithinRadius } from 'geolib';
import {
  IDataVersion, IProvider, IRoute, IStopWithRoutes,
} from '../../interfaces';
import { axios } from '../../utils';
import { ReactComponent as Clear } from '../../assets/clear.svg';
import Radius from './Radius';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import markerIcon from '../../assets/marker.svg';
import VehicleIcon from '../VehicleIcon';
import { IUser, ProviderContext } from '../../context';
import VersionInfo from '../VersionInfo';

const leaflet = L;

type Data = (IStopWithRoutes & { provider: string }) | { lat: number; lon: number; };

const styles = createStyles({
  root: {
    display: 'flex',
    marginTop: 32,
    width: '100%',
  },
  left: {
    width: 400,
    marginRight: 32,
  },
  select: {
    width: '100%',
  },
  providers: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 32,
    margin: -8,
  },
  provider: {
    backgroundColor: '#F5F5F5',
    borderRadius: 15,
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    margin: 8,
  },
  providerName: {
    fontSize: 20,
    fontWeight: 400,
  },
  providerClear: {
    padding: 0,
    minWidth: 0,
    borderRadius: '50%',
    marginLeft: 8,
  },
  right: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  map: {
    width: '100%',
    height: 523,
    zIndex: 1,
  },
  markerIconContainer: {
    margin: '-50px 0 0 -50px !important',
    width: '100px !important',
    height: '100px !important',
  },
  markerIcon: {
    width: 100,
    height: 100,
    borderRadius: '50%',
    backgroundColor: 'rgba(200, 39, 139, 0.45)',
  },
  stopSearch: {
    width: 300,
    backgroundColor: 'white',
  },
  searchInput: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '&$inputFocused': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
  },
  inputFocused: {},
  marker: {
    width: 20,
    height: 25,
    top: -25,
    left: -10,
  },
  popup: {
    backgroundColor: 'white',
    borderRadius: 3,
    '& > :first-child': {
      boxShadow: 'none',
      padding: 0,
      '& > div': {
        margin: '16px 24px',
      },
    },
    '& > :last-child': {
      display: 'none',
    },
  },
  name: {
    backgroundColor: '#37001F',
    color: 'white',
    fontSize: 24,
    margin: '0 0 0 -24px !important',
    padding: '4px 16px 4px 24px',
    borderRadius: '0 15px 15px 0',
    display: 'inline-block',
  },
  routeGroup: {
    margin: '8px 0',
    display: 'flex',
  },
  icon: {
    margin: '6px 8px 6px 0',
  },
  routes: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: -4,
  },
  route: {
    margin: 4,
    padding: '4px 8px',
    backgroundColor: '#F5F5F5',
    borderRadius: 12,
    fontSize: 20,
    fontWeight: 400,
    color: '#37001F',
    fontFamily: '"Roboto"',
  },
  inRadius: {
    fontSize: 13,
    color: '#666666',
    fontWeight: 400,
    margin: '8px 0 0 !important',
  },
  nearbyName: {
    color: '#37001F',
    fontSize: 16,
    fontWeight: 400,
    margin: '4px 0 !important',
  },
  dialog: {
    padding: 24,
    width: 400,
  },
  restoreLocation: {
    margin: '8px 0 0 auto',
    display: 'flex',
  },
  deleteStop: {
    margin: '8px 0 -12px auto',
    display: 'flex',
  },
  underMap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

type Props = WithStyles<typeof styles> & WithTranslation & {
  providers: IProvider[];
  user: IUser;
  version?: IDataVersion;
};

interface State {
  selectedProviders: string[];
  inputValue: string;
  routes: Record<string, IRoute[]>;
  originalStops: Record<string, IStopWithRoutes[]>;
  stops: Record<string, IStopWithRoutes[]>;
  radius: number;
  editMode: boolean;
  searchValue: IStopWithRoutes & { provider: string } | null;
  popupData: Data | null;
  dialogOpen: boolean;
  stopName: string;
  draggedStop: string | null;
}

const isStop = (x: Data): x is IStopWithRoutes => R.has('routes', x);

const isCircleMarker = (x: Marker | CircleMarker): x is CircleMarker => (
  R.has('setStyle', Reflect.getPrototypeOf(x))
);

const initialState = {
  selectedProviders: [],
  inputValue: '',
  routes: {},
  originalStops: {},
  stops: { new: [] },
  radius: 100,
  editMode: false,
  searchValue: null,
  popupData: null,
  dialogOpen: false,
  stopName: '',
  draggedStop: null,
};

// eslint-disable-next-line react/prefer-stateless-function
class ImpactArea extends Component<Props, State> {
  mapRef = createRef<any>();

  stopMarkers: Record<string, Marker | CircleMarker> = {};

  impactMarkers: Record<string, Circle> = {};

  popupContainer: HTMLDivElement | null = null;

  lastClick: LeafletMouseEvent | null = null;

  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const {
      selectedProviders, routes, originalStops,
    } = this.state;
    const { mapRef } = this;
    if (
      prevState.originalStops !== originalStops
      && selectedProviders.every((x) => routes[x] && originalStops[x]) && mapRef.current
    ) {
      const map = mapRef.current.leafletElement;
      const coords: [number, number][] = R.chain(
        (provider) => originalStops[provider],
        selectedProviders,
      ).map(
        (stop) => [stop.lat, stop.lon],
      );
      map.fitBounds(
        new leaflet.LatLngBounds(
          coords.length > 0 ? coords : [
            [56.464660, 14.408800],
            [53.570577, 19.443904],
          ],
        ),
        {
          padding: [32, 32],
        },
      );
      R.difference(R.keys(originalStops), R.keys(prevState.originalStops)).forEach((provider) => {
        originalStops[provider].forEach((stop) => (
          this.addStop({ ...stop, provider })
        ));
      });
    }
    const { version } = this.props;
    if (version !== prevProps.version) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(initialState);
    }
  }

  getSelectedStops = (original: boolean = false) => {
    const { stops, originalStops, selectedProviders } = this.state;
    return R.chain(
      (provider) => (
        ((original ? originalStops : stops)[provider] || []).map((x) => ({ ...x, provider }))
      ),
      [...selectedProviders, 'new'],
    );
  };

  markerClickHandler = (event: LeafletMouseEvent) => {
    this.lastClick = event;
    this.setState((currentState) => {
      const { provider, id } = event.target;
      const stop = currentState.stops[provider].find(R.whereEq({ id }));
      if (!stop) {
        return null;
      }
      const data = { ...stop, provider };
      return (currentState.draggedStop ? null : ({
        dialogOpen: true,
        popupData: data,
        stopName: data.name,
      }));
    });
  };

  addPopup = (marker: Marker | CircleMarker, data: Data) => {
    const { classes } = this.props;
    const { editMode } = this.state;
    const makePopup = () => {
      const div = document.createElement('div');
      this.popupContainer = div;
      this.setState({ popupData: data });
      return div;
    };
    if (editMode && isStop(data)) {
      // @ts-ignore
      marker.dragging.enable();
      return marker
        .unbindPopup()
        .on('click', this.markerClickHandler);
    }
    return marker.bindPopup(makePopup, {
      className: classes.popup,
      minWidth: 400,
      maxWidth: 400,
    });
  };

  addStop = (data: Data) => {
    const { classes } = this.props;
    const { mapRef, stopMarkers, impactMarkers } = this;
    const { radius } = this.state;
    const marker: any = isStop(data) ? new leaflet.CircleMarker([data.lat, data.lon], {
      radius: 8,
      fillColor: data.provider === 'new' ? '#FF0000' : '#FFA20D',
      fillOpacity: 1,
      weight: 3,
      color: '#1A359B',
      pane: 'markerPane',
    }) : new leaflet.Marker([data.lat, data.lon], {
      icon: leaflet.icon({
        iconUrl: markerIcon,
        className: classes.marker,
        popupAnchor: [0, -12],
      }),
    });
    marker.addTo(mapRef.current.leafletElement);
    this.addPopup(marker, data);
    const impactMarker = new leaflet.Circle([data.lat, data.lon], {
      radius,
      fillColor: '#034EA2',
      fillOpacity: 0.45,
      stroke: false,
    }).addTo(mapRef.current.leafletElement);
    const key = isStop(data) ? `${data.provider}-${data.id}` : 'marker';
    impactMarkers[key] = impactMarker;
    if (isStop(data)) {
      marker.on('drag', (event: any) => {
        impactMarker.setLatLng(event.target.getLatLng());
      });
      marker.on('dragstart', () => {
        this.setState({ draggedStop: key });
      });
      marker.on('dragend', (event: any) => {
        setTimeout(() => {
          marker.setStyle({
            fillColor: '#FF0000',
          });
          const { lat, lng: lon } = event.target.getLatLng();
          this.setState((currentState) => ({
            draggedStop: null,
            stops: R.over(
              R.lensProp(data.provider),
              R.map((x: any) => (x.id === data.id ? { ...x, lat, lon } : x)),
              currentState.stops,
            ),
          }));
        }, 1);
      });
    } else {
      marker.openPopup();
    }
    marker.on('popupopen', () => {
      impactMarker.setStyle({
        fillColor: '#CA1F8C',
      }).bringToFront();
    });
    marker.on('popupclose', () => {
      impactMarker.setStyle({
        fillColor: '#034EA2',
      });
      if (!isStop(data)) {
        marker.remove();
        impactMarker.remove();
      }
    });
    stopMarkers[key] = marker;
    if (isStop(data)) {
      marker.provider = data.provider;
      marker.id = data.id;
    }
    return marker;
  };

  hasStopMoved = (stop: Data): boolean => {
    if (!isStop(stop)) {
      return false;
    }
    const { originalStops } = this.state;
    const providerStops = originalStops[stop.provider];
    if (!providerStops) {
      return false;
    }
    const originalStop = providerStops.find(R.whereEq({ id: stop.id }));
    return originalStop !== undefined
      && (stop.lat !== originalStop.lat || stop.lon !== originalStop.lon);
  };

  render() {
    const {
      classes, t, providers, version, user,
    } = this.props;
    const {
      selectedProviders, inputValue, radius, editMode, searchValue,
      popupData, routes, stops, dialogOpen, stopName, originalStops, draggedStop,
    } = this.state;
    const { mapRef, stopMarkers, impactMarkers } = this;
    const selectedStops = this.getSelectedStops();
    const nearbyStops = popupData ? selectedStops.filter((x) => (
      (!isStop(popupData) || x.id !== popupData.id) && isPointWithinRadius(
        { latitude: x.lat, longitude: x.lon },
        { latitude: popupData.lat, longitude: popupData.lon },
        radius,
      )
    )) : null;
    const renderRoutes = (currentStop: IStopWithRoutes & { provider: string }) => R.map(
      ([type, typeRoutes]) => (
        <div className={classes.routeGroup} key={type}>
          <div className={classes.icon}>
            <VehicleIcon type={Number(type)} />
          </div>
          <div className={classes.routes}>
            {typeRoutes.map((route) => (
              <div className={classes.route} key={`${type}-${route.id}`}>
                {route.name}
              </div>
            ))}
          </div>
        </div>
      ),
      R.toPairs(R.groupBy(
        (x) => String(x.type),
        R.map(
          (id) => routes[currentStop.provider].find(R.whereEq({ id })),
          currentStop.routes,
        ) as IRoute[],
      )),
    );
    // @ts-ignore
    // @ts-ignore
    return (
      <div className={classes.root}>
        <div className={classes.left}>
          <VersionInfo />
          <CustomAutocomplete
            className={classes.select}
            options={providers.filter((x) => !selectedProviders.includes(x.id))}
            value={null}
            onChange={async (event, provider) => {
              this.setState({ inputValue: '' });
              if (provider !== null && !selectedProviders.includes(provider.id)) {
                this.setState(R.over(R.lensProp('selectedProviders'), R.append(provider.id)));
                const options = (version && provider.id === user.agency)
                  ? { params: { providerVersion: version.id } }
                  : {};
                const [
                  { data: providerRoutes },
                  { data: providerStops },
                ] = await Promise.all([
                  axios.get(`/${provider.id}/routes`, options),
                  axios.get(`/${provider.id}/stops`, options),
                ]);
                this.setState(R.over(
                  R.lensProp('routes'),
                  R.set(R.lensProp(provider.id), providerRoutes),
                ));
                const setNewStops = R.set(R.lensProp(provider.id), providerStops);
                this.setState(R.over(R.lensProp('stops'), setNewStops));
                this.setState(R.over(R.lensProp('originalStops'), setNewStops));
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, value) => {
              this.setState({ inputValue: value });
            }}
            label={t('stats.findDataSource')}
            getOptionLabel={R.prop('name')}
          />
          <div className={classes.providers}>
            {selectedProviders.map((provider) => (
              <div className={classes.provider} key={provider}>
                <Typography className={classes.providerName}>
                  {providers.find(R.propEq('id', provider))!.name}
                </Typography>
                <Button
                  color="primary"
                  className={classes.providerClear}
                  onClick={() => {
                    this.setState({
                      selectedProviders: R.filter((x) => x !== provider, selectedProviders),
                      stops: R.dissoc(provider, stops),
                      originalStops: R.dissoc(provider, originalStops),
                    });
                    stops[provider].forEach((stop) => {
                      stopMarkers[`${provider}-${stop.id}`].remove();
                      impactMarkers[`${provider}-${stop.id}`].remove();
                    });
                  }}
                >
                  <Clear />
                </Button>
              </div>
            ))}
          </div>
        </div>
        {selectedProviders.length > 0 && (
          <div className={classes.right}>
            <Map
              className={classes.map}
              ref={mapRef}
              minZoom={3}
              maxZoom={18}
              preferCanvas
              onclick={(event) => {
                if (editMode) {
                  this.setState((currentState) => {
                    if (currentState.draggedStop
                      || (this.lastClick && this.lastClick.originalEvent === event.originalEvent)) {
                      return null;
                    }
                    const newStop = {
                      id: String(Math.random()),
                      name: '',
                      lat: event.latlng.lat,
                      lon: event.latlng.lng,
                      routes: [],
                      provider: 'new',
                    };
                    this.addStop(newStop).fire('click');
                    return R.over(
                      R.lensPath(['stops', 'new']),
                      R.append(newStop),
                      currentState,
                    );
                  });
                } else {
                  this.addStop({ lat: event.latlng.lat, lon: event.latlng.lng });
                }
              }}
              dragging={!(editMode && draggedStop)}
              // @ts-ignore
              fullscreenControl
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <ScaleControl position="topright" imperial={false} />
              <Control position="bottomleft">
                <CustomAutocomplete
                  className={classes.stopSearch}
                  options={selectedStops}
                  value={searchValue}
                  onChange={(event, value) => {
                    this.setState({ searchValue: value });
                    if (value) {
                      const marker = stopMarkers[`${value.provider}-${value.id}`];
                      if (editMode) {
                        mapRef.current.leafletElement.panTo(marker.getLatLng());
                        marker.fire('click');
                      } else {
                        marker.openPopup();
                      }
                    }
                  }}
                  label={t('impact.stop')}
                  TextFieldProps={{
                    variant: 'filled',
                    InputProps: {
                      classes: {
                        root: classes.searchInput,
                        focused: classes.inputFocused,
                      },
                    },
                  }}
                  getOptionLabel={R.prop('name')}
                />
              </Control>
              <Control position="bottomright">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (popupData) {
                      stopMarkers[isStop(popupData) ? `${popupData.provider}-${popupData.id}` : 'marker'].closePopup();
                    }
                    this.setState({
                      editMode: !editMode,
                      dialogOpen: false,
                    }, () => {
                      selectedStops.forEach((stop) => {
                        const marker = stopMarkers[`${stop.provider}-${stop.id}`];
                        this.addPopup(marker, stop);
                        // @ts-ignore
                        marker.dragging[editMode ? 'disable' : 'enable']();
                      });
                    });
                  }}
                >
                  {t(editMode ? 'impact.finishEditing' : 'impact.edit')}
                </Button>
              </Control>
            </Map>
            <div className={classes.underMap}>
              <Button
                color="primary"
                onClick={() => {
                  R.values(stopMarkers).forEach((marker) => marker.remove());
                  R.values(impactMarkers).forEach((marker) => marker.remove());
                  selectedProviders.forEach((provider) => {
                    originalStops[provider].forEach((stop) => (
                      this.addStop({ ...stop, provider })
                    ));
                  });
                }}
              >
                {t('impact.restoreCurrentState')}
              </Button>
              <Radius
                value={radius}
                onChange={(newRadius) => {
                  this.setState({ radius: newRadius });
                  R.values(this.impactMarkers).forEach((marker) => {
                    marker.setRadius(newRadius);
                  });
                  selectedStops.forEach((stop) => {
                    this.addPopup(stopMarkers[`${stop.provider}-${stop.id}`], stop);
                  });
                }}
              />
            </div>
          </div>
        )}
        {editMode ? (
          <>
            {popupData && isStop(popupData) && (
              <Dialog
                onClose={() => {
                  const newData = {
                    ...popupData,
                    name: stopName,
                  };
                  this.setState({
                    dialogOpen: false,
                    stops: R.over(
                      R.lensProp(popupData.provider),
                      R.map((x: IStopWithRoutes) => (
                        x.id === popupData.id ? { ...x, name: stopName } : x
                      )),
                      stops,
                    ),
                    popupData: newData,
                  }, () => {
                    this.addPopup(stopMarkers[`${newData.provider}-${newData.id}`], newData);
                  });
                }}
                open={dialogOpen}
                classes={{ paper: classes.dialog }}
              >
                <TextField
                  variant="outlined"
                  value={stopName}
                  onChange={({ target }) => this.setState({ stopName: target.value })}
                  fullWidth
                  label={t('impact.stopName')}
                  size="small"
                />
                {this.hasStopMoved(popupData) && (
                  <Button
                    color="primary"
                    className={classes.restoreLocation}
                    onClick={() => {
                      const originalStop = originalStops[popupData.provider]
                        .find(R.whereEq({ id: popupData.id }));
                      if (originalStop) {
                        const { lat, lon } = originalStop;
                        const key = `${popupData.provider}-${popupData.id}`;
                        const stopMarker = stopMarkers[key];
                        stopMarker.setLatLng([lat, lon]);
                        if (isCircleMarker(stopMarker)) {
                          stopMarker.setStyle({ fillColor: '#FFA20D' });
                        }
                        impactMarkers[key].setLatLng([lat, lon]);
                        this.setState({
                          stops: R.over(
                            R.lensProp(popupData.provider),
                            R.map((x: IStopWithRoutes) => (
                              x.id === popupData.id ? { ...x, lat, lon } : x
                            )),
                            stops,
                          ),
                        });
                      }
                    }}
                  >
                    {t('impact.restoreLocation')}
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.deleteStop}
                  onClick={() => {
                    this.setState({
                      dialogOpen: false,
                      stops: R.over(
                        R.lensProp(popupData.provider),
                        R.filter((x: IStopWithRoutes) => x.id !== popupData.id),
                        stops,
                      ),
                    }, () => {
                      stopMarkers[`${popupData.provider}-${popupData.id}`].remove();
                      impactMarkers[`${popupData.provider}-${popupData.id}`].remove();
                    });
                  }}
                >
                  {t('impact.deleteStop')}
                </Button>
              </Dialog>
            )}
          </>
        ) : (
          <>
            {this.popupContainer && popupData && nearbyStops && createPortal(
              <>
                {isStop(popupData) && (
                  <>
                    <Typography className={classes.name}>
                      {popupData.name}
                    </Typography>
                    {renderRoutes(popupData)}
                  </>
                )}
                {(nearbyStops.length > 0 || !isStop(popupData)) && (
                  <>
                    <Typography className={classes.inRadius}>
                      {t(isStop(popupData) ? 'impact.inRadius' : 'impact.inMarkerRadius', { radius })}
                      :
                    </Typography>
                    {nearbyStops.length === 0 && (
                      <Typography className={classes.nearbyName}>
                        {t('impact.noStops')}
                      </Typography>
                    )}
                    {nearbyStops.map((nearbyStop) => (
                      <div key={nearbyStop.id}>
                        <Typography className={classes.nearbyName}>
                          {nearbyStop.name}
                        </Typography>
                        {renderRoutes(nearbyStop)}
                      </div>
                    ))}
                  </>
                )}
              </>,
              this.popupContainer,
            )}
          </>
        )}
      </div>
    );
  }
}

const ConnectedImpactArea = withTranslation()(withStyles(styles)(ImpactArea));

export default () => {
  const { providers, version, user } = useContext(ProviderContext);
  return <ConnectedImpactArea providers={providers} version={version} user={user} />;
};
