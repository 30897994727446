import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Map, Marker, Polyline, Popup, ScaleControl, TileLayer,
} from 'react-leaflet';
import {
  Typography, useMediaQuery, Button, IconButton, TextField, MenuItem, InputAdornment, Tabs, Tab,
} from '@material-ui/core';
import classNames from 'classnames';
import * as R from 'ramda';
import leaflet from 'leaflet';
import debounce from 'debounce-promise';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import natsort from 'natsort';
import pdfMake from 'pdfmake/build/pdfmake';
import Nav from './Nav';
import Footer from './Footer';
import { ReactComponent as Timetable } from '../assets/timetable.svg';
import { ReactComponent as BusWhite } from '../assets/bus-white.svg';
import { ReactComponent as TrolleybusWhite } from '../assets/trolleybus-white.svg';
import { ReactComponent as TramWhite } from '../assets/tram-white.svg';
import { ReactComponent as TrainWhite } from '../assets/train-white.svg';
import { ReactComponent as FerryWhite } from '../assets/ferry-white.svg';
import bus, { ReactComponent as Bus } from '../assets/bus.svg';
import trolleybus, { ReactComponent as Trolleybus } from '../assets/trolleybus.svg';
import tram, { ReactComponent as Tram } from '../assets/tram.svg';
import train, { ReactComponent as Train } from '../assets/train.svg';
import ferry, { ReactComponent as Ferry } from '../assets/ferry.svg';
import { ReactComponent as DirectionArrow } from '../assets/direction-arrow.svg';
import { ReactComponent as Swap } from '../assets/swap.svg';
import { ReactComponent as Print } from '../assets/print.svg';
import { ReactComponent as Chevron } from '../assets/chevron-big.svg';
import { ReactComponent as BackArrow } from '../assets/back-arrow.svg';
import CustomAutocomplete from '../common/CustomAutocomplete';
import {
  IRouteDetails, IRouteSocket, IStop, IStopArrivals, IStopTimetable, ITimetableSearch,
} from '../interfaces';
import { axios } from '../utils';
import VehicleIcon from '../provider/VehicleIcon';
import tramDelayed from '../assets/tram-delayed.svg';
import trainDelayed from '../assets/train-delayed.svg';
import trolleybusDelayed from '../assets/trolleybus-delayed.svg';
import busDelayed from '../assets/bus-delayed.svg';
import robotoLight from '../assets/Roboto-Light.ttf';
import robotoLightItalic from '../assets/Roboto-LightItalic.ttf';
import robotoRegular from '../assets/Roboto-Regular.ttf';
import robotoItalic from '../assets/Roboto-Italic.ttf';

pdfMake.fonts = {
  Roboto: {
    normal: window.location.origin + robotoLight,
    bold: window.location.origin + robotoRegular,
    italics: window.location.origin + robotoLightItalic,
    bolditalics: window.location.origin + robotoItalic,
  },
};

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    minHeight: 'calc(100vh - 43px)',
  },
  left: {
    width: 424,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    flexShrink: 0,
    '@media (max-width: 800px)': {
      width: '100%',
    },
  },
  map: {
    flexGrow: 1,
  },
  bottom: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#A1145C',
    paddingTop: 10,
  },
  top: {
    margin: 36,
  },
  header: {
    fontSize: 28,
    fontWeight: 400,
    marginBottom: 16,
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    marginRight: 4,
  },
  search: {
    margin: '0 16px 16px 16px',
    width: 'calc(100% - 32px)',
  },
  meansContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 90,
  },
  means: {
    backgroundColor: '#F5F5F5',
    borderRadius: 16,
    minWidth: 60,
    padding: '3px 16px',
    margin: '0 4px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
  },
  meansSelected: {
    backgroundColor: '#A1145C',
    '&:hover': {
      backgroundColor: '#700e40',
    },
  },
  optionTop: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
  },
  optionVehicle: {
    margin: '5px 8px 5px 16px',
    display: 'flex',
  },
  optionName: {
    color: '#37001F',
  },
  optionAddress: {
    fontSize: 12,
    color: '#37001F',
  },
  directionContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 4,
  },
  directionArrow: {
    margin: '2px 12px 0 20px',
    flexShrink: 0,
  },
  optionDirection: {
    fontSize: 12,
    color: '#37001F',
  },
  optionRoute: {
    borderRadius: 7,
    fontSize: 14,
    fontWeight: 400,
    color: '#37001F',
    backgroundColor: '#F5F5F5',
    lineHeight: 1,
    padding: '2px 8px',
    margin: '1px 2px 1px 0',
    display: 'inline-block',
  },
  tile: {
    margin: '5px 16px',
    backgroundColor: 'white',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
    padding: '12px 0',
  },
  optionNameBig: {
    fontSize: 23,
    fontWeight: 400,
  },
  optionTextBig: {
    fontSize: 14,
  },
  optionTopBig: {
    alignItems: 'flex-start',
  },
  right: {
    flexGrow: 1,
    backgroundColor: '#E4E4E4',
    padding: 20,
    display: 'flex',
    alignItems: 'flex-start',
  },
  departures: {
    width: 390,
    backgroundColor: 'white',
  },
  departuresHeader: {
    padding: '13px 23px',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    marginBottom: 25,
  },
  departuresHeaderText: {
    fontSize: 23,
    fontWeight: 400,
  },
  table: {
    marginBottom: 70,
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableCell: {
    fontSize: 16,
    color: '#37001F',
    fontWeight: 300,
    padding: '4px 16px',
    '&:first-child': {
      textAlign: 'center',
    },
    '&:nth-child(2)': {
      textAlign: 'left',
    },
    '&:last-child': {
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
  },
  tableHeader: {
    fontSize: 14,
    color: '#666666',
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-child(odd)': {
      backgroundColor: '#F5F5F5',
    },
  },
  message: {
    fontWeight: 400,
    color: '#37001F',
    margin: '0 16px 50px 16px',
  },
  optionAgency: {
    fontSize: 14,
    fontWeight: 300,
  },
  switchDirection: {
    padding: 6,
    marginLeft: 12,
  },
  agencyBig: {
    fontSize: 16,
  },
  stopMarker: {
    width: 10,
    height: 10,
    backgroundColor: '#E4E4E4',
    borderRadius: '50%',
    border: '2px solid #37001F',
  },
  intermediateStopMarker: {
    width: 8,
    height: 8,
    backgroundColor: '#316FB9',
    borderRadius: '50%',
  },
  myLocationMarker: {
    width: 20,
    height: 20,
    backgroundColor: '#A1145C',
    borderRadius: '50%',
    border: '4px solid #37011F',
  },
  popup: {
    backgroundColor: 'white',
    borderRadius: 4,
    '& > :first-child': {
      boxShadow: 'none',
      padding: 0,
      '& > div': {
        margin: 6,
        '& p': {
          margin: 0,
        },
      },
    },
    '& > :last-child': {
      display: 'none',
    },
  },
  stopPopup: {
    '& > :first-child': {
      '& > div': {
        margin: '16px 24px',
      },
    },
  },
  popupStopName: {
    fontSize: 18,
    fontWeight: 400,
  },
  showDepartures: {
    marginTop: 12,
    marginLeft: 'auto',
    display: 'flex',
  },
  timetable: {
    flexGrow: 1,
    maxWidth: 600,
    backgroundColor: 'white',
    marginLeft: 16,
  },
  timetableTop: {
    padding: '13px 23px 6px',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    position: 'relative',
  },
  timetableHeaderRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  timetableHeader: {
    fontSize: 23,
    fontWeight: 400,
  },
  timetableRoutes: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: -4,
    paddingBottom: 8,
  },
  timetableRoute: {
    backgroundColor: '#F5F5F5',
    borderRadius: 16,
    minWidth: 0,
    padding: '2px 16px',
    margin: 4,
    fontSize: 14,
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
  },
  timetableRouteSelected: {
    backgroundColor: '#A1145C',
    color: 'white',
    '&:hover': {
      backgroundColor: '#700e40',
    },
  },
  timetableOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  directionWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
  },
  directionLabel: {
    fontSize: 14,
  },
  direction: {
    marginLeft: 4,
  },
  directionSelect: {
    fontSize: 14,
    fontWeight: 300,
  },
  selectRoot: {
    paddingLeft: 8,
  },
  selectIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: 2,
    transform: 'rotate(90deg)',
  },
  selectNoBorder: {
    border: 'none',
  },
  dateInput: {
    width: 110,
    fontSize: 14,
    fontWeight: 300,
    padding: '10px 3px 10px 0',
  },
  dateNativeInput: {
    padding: 0,
  },
  hourRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 16px',
    '&:nth-child(odd)': {
      backgroundColor: '#F5F5F5',
    },
  },
  hour: {
    fontSize: 20,
    fontWeight: 400,
    marginRight: 16,
  },
  minute: {
    fontSize: 14,
    marginRight: 12,
  },
  mobileHeaderContainer: {
    display: 'flex',
    padding: '0 20px 12px',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    alignItems: 'baseline',
  },
  mobileStopIcon: {
    flexShrink: 0,
    display: 'flex',
  },
  mobileStopHeader: {
    fontSize: 23,
    fontWeight: 400,
    lineHeight: '1',
    margin: '0 8px',
  },
  mobileStopTown: {
    fontSize: 14,
    alignSelf: 'flex-end',
  },
  tabs: {
    marginBottom: 8,
  },
  tab: {
    textTransform: 'none',
    fontWeight: 300,
    fontSize: 16,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  departuresContentWrapper: {
    margin: '0 16px',
  },
  mobileRouteContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    zIndex: 1000,
  },
  mobileMapContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  goBack: {
    marginBottom: 5,
    marginRight: -12,
  },
  vehicleMarker: {
    transition: '250ms',
  },
  popupHeader: {
    fontSize: 12,
    color: '#37001F',
    marginBottom: 4,
  },
  popupText: {
    fontSize: 11,
    color: '#37001F',
    marginBottom: 4,
  },
  bottomHeader: {
    fontSize: 26,
    fontWeight: 400,
    color: 'white',
    padding: '10px 32px',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    margin: '5px 16px',
  },
  nearestTile: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  nearestButton: {
    width: 32,
    height: 32,
    padding: 2,
    marginRight: 6,
    marginBottom: -8,
  },
  optionContainer: {
    marginRight: 8,
  },
});

const sorter = natsort();

export default function Timetables() {
  const classes = useStyles();
  const { t } = useTranslation();
  const mapRef = useRef<any>();
  const isMobile = useMediaQuery('(max-width: 800px)');
  const [selectedMeans, setSelectedMeans] = useState({
    bus: true,
    trolleybus: true,
    tram: true,
    train: true,
    ferry: true,
  });
  const [myLocation, setMyLocation] = useState<{ lat: number; lon: number; } | null>(null);
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.leafletElement.fitBounds(
        new leaflet.LatLngBounds([
          [56.464660, 14.408800],
          [53.570577, 19.443904],
        ]),
      );
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        setMyLocation({ lat: coords.latitude, lon: coords.longitude });
        mapRef.current.leafletElement.setView([coords.latitude, coords.longitude], 15);
      });
    }
  }, []);
  const [searchResults, setSearchResults] = useState<ITimetableSearch[]>([]);
  const onInputChange = useCallback(debounce(async (event, query) => {
    if (query) {
      const { data } = await axios.get('/public/find-routes-stops', {
        params: {
          query,
          vehicles: Object.entries(selectedMeans)
            .filter(R.prop('1'))
            .flatMap(([type]) => {
              switch (type) {
                case 'bus':
                  return [3, 700];
                case 'trolleybus':
                  return [800];
                case 'tram':
                  return [0, 900];
                case 'train':
                  return [2, 106, 109];
                case 'ferry':
                  return [4];
                default:
                  return [];
              }
            })
            .join(','),
          limit: 4,
          ...(myLocation || {}),
        },
      });
      setSearchResults(data);
    } else {
      setSearchResults([]);
    }
  }, 200), [selectedMeans, myLocation]);
  const [selectedItem, setSelectedItem] = useState<ITimetableSearch | null>(null);
  const [departures, setDepartures] = useState<IStopArrivals | null>(null);
  const [timetables, setTimetables] = useState<IStopTimetable[] | null>(null);
  const [selectedRoute, setSelectedRoute] = useState<string | null>(null);
  const [selectedDirection, setSelectedDirection] = useState('all');
  const [date, setDate] = useState(moment());
  const [routeDetails, setRouteDetails] = useState<IRouteDetails | null>(null);
  const [routeRTData, setRouteRTData] = useState<IRouteSocket | null>(null);
  const [oppositeDirection, setOppositeDirection] = useState(false);
  const [nearestStops, setNearestStops] = useState<ITimetableSearch[]>([]);
  useEffect(() => {
    (async () => {
      if (myLocation) {
        const { data } = await axios.get('/public/find-nearest-stops', {
          params: {
            limit: 4,
            ...myLocation,
          },
        });
        setNearestStops(data.map((x: any) => ({ type: 'stop', ...x })));
      }
    })();
  }, [myLocation]);
  const renderOption = (option: ITimetableSearch, big?: boolean) => (
    <div className={classes.optionContainer}>
      <div
        className={classNames(classes.optionTop, {
          [classes.optionTopBig]: big,
        })}
      >
        <div className={classes.optionVehicle}>
          <VehicleIcon type={option.vehicles[0]} />
        </div>
        <div>
          <Typography
            className={classNames(classes.optionName, {
              [classes.optionNameBig]: big,
            })}
          >
            {option.type === 'stop' ? option.name : `${t('timetables.route')} ${option.name}, `}
            {option.type === 'route' && (
              <span
                className={classNames(classes.optionAgency, {
                  [classes.agencyBig]: big,
                })}
              >
                {option.agency.name}
              </span>
            )}
          </Typography>
          {option.type === 'stop' && (
            <Typography
              className={classNames(classes.optionAddress, {
                [classes.optionTextBig]: big,
              })}
            >
              {option.agency.name}
            </Typography>
          )}
        </div>
        {big && option.type === 'route' && (
          <IconButton
            className={classes.switchDirection}
            onClick={() => setOppositeDirection(R.not)}
          >
            <Swap />
          </IconButton>
        )}
      </div>
      {option.type === 'stop' && option.routes && (
        <>
          <div className={classes.directionContainer}>
            <DirectionArrow className={classes.directionArrow} />
            <Typography
              className={classNames(classes.optionDirection, {
                [classes.optionTextBig]: big,
              })}
            >
              {R.intersperse<React.ReactNode>(', ', option.routes.map((route) => (
                <>
                  <span className={classes.optionRoute}>
                    {route.name}
                  </span>
                  {route.headsigns.join(', ')}
                </>
              )))}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
  const stopsOnMap = routeDetails ? routeDetails.stops[oppositeDirection ? 'opposite' : 'default'] : [];
  useEffect(() => {
    if (stopsOnMap.length >= 2) {
      mapRef.current.leafletElement.fitBounds(
        new leaflet.LatLngBounds(stopsOnMap.map(({ lat, lon }) => [lat, lon])),
      );
    }
  }, [stopsOnMap]);
  const directionTrips = routeDetails ? routeDetails.trips
    .filter((x) => x.direction === (oppositeDirection ? 1 : 0)) : null;
  const shapesOnMap = (() => {
    if (routeDetails && directionTrips) {
      const shapeIds = directionTrips.map(R.prop('shapeId')).filter((x) => x !== null);
      if (shapeIds.length > 0) {
        return routeDetails.shapes
          .filter((x) => shapeIds.includes(x.id))
          .map(({ shape }) => (
            shape.map(({ lat, lon }) => ({ lat, lng: lon }))
          ));
      }
      return directionTrips.map(({ stops }) => (
        stops.map(({ id }) => {
          const { lat, lon } = stopsOnMap.find((x) => x.id === id) as IStop;
          return { lat, lng: lon };
        })
      ));
    }
    return [];
  })();
  const vehiclesOnMap = (routeRTData && directionTrips) ? routeRTData.vehicles
    .filter((vehicle) => directionTrips.some((trip) => trip.pattern === vehicle.pattern)) : [];
  useEffect(() => {
    setSelectedDirection('all');
    if (selectedItem?.type === 'stop') {
      setRouteDetails(null);
      setRouteRTData(null);
      const { agency: { id: agencyId }, id: stopId } = selectedItem;
      const getDepartures = async () => {
        const { data } = await axios.get(`/public/${agencyId}/stop/${stopId}/arrivals`);
        setDepartures(data);
      };
      getDepartures();
      const intervalId = setInterval(getDepartures, 30 * 1000);
      return () => clearInterval(intervalId);
    }
    if (selectedItem?.type === 'route') {
      setDepartures(null);
      setTimetables(null);
      const { agency: { id: agencyId }, id: routeId } = selectedItem;
      const stringDate = moment().format('YYYY-MM-DD');
      axios.get(`/public/${agencyId}/route/${routeId}/trips?date=${stringDate}`)
        .then(({ data }) => {
          setRouteDetails(data);
        });
      const getRTData = async () => {
        const { data } = await axios.get(`/public/${agencyId}/route/${routeId}/rtdata`);
        setRouteRTData(data);
      };
      getRTData();
      const intervalId = setInterval(getRTData, 30 * 1000);
      return () => clearInterval(intervalId);
    }
    if (!selectedItem) {
      setRouteDetails(null);
      setRouteRTData(null);
      setDepartures(null);
      setTimetables(null);
    }
    return () => {};
  }, [selectedItem]);
  useEffect(() => {
    (async () => {
      if (selectedItem?.type === 'stop') {
        const stringDate = date.format('YYYY-MM-DD');
        const { agency: { id: agencyId }, id: stopId } = selectedItem;
        const { data }: { data: IStopTimetable[] } = (
          await axios.get(`/public/${agencyId}/stop/${stopId}/timetable?date=${stringDate}`)
        );
        const sorted = data.sort((a, b) => sorter(a.route, b.route));
        setTimetables(sorted);
        if (sorted.length > 0) {
          setSelectedRoute(sorted[0].route);
        }
      }
    })();
  }, [selectedItem, date]);
  const selectedTimetable = (timetables && selectedRoute)
    ? timetables.find((x) => x.route === selectedRoute)
    : null;
  const selectedTimes = (() => {
    if (!selectedTimetable) return null;
    if (selectedDirection === 'all') {
      return selectedTimetable.destinations.flatMap(R.prop('times'));
    }
    return selectedTimetable.destinations
      .find(({ headsign }) => headsign === selectedDirection)!.times;
  })();
  const departuresContent = (departures && timetables) ? (
    <>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classNames(classes.tableCell, classes.tableHeader)}>
              {t('timetables.route')}
            </th>
            <th className={classNames(classes.tableCell, classes.tableHeader)}>
              {t('timetables.direction')}
            </th>
            <th className={classNames(classes.tableCell, classes.tableHeader)}>
              {t('timetables.departsIn')}
            </th>
          </tr>
        </thead>
        <tbody>
          {departures.arrivals.map((x) => (
            <tr
              key={x.route + x.time + x.headsign}
              className={classes.tableRow}
              onClick={() => setSelectedItem({
                type: 'route',
                id: x.routeId,
                name: x.route,
                vehicles: selectedItem!.vehicles,
                agency: selectedItem!.agency,
              })}
            >
              <td className={classes.tableCell}>
                {x.route}
              </td>
              <td className={classes.tableCell}>
                {x.headsign}
              </td>
              <td className={classes.tableCell}>
                {(() => {
                  if (x.arrivalIn === undefined) {
                    return x.time;
                  }
                  if (x.arrivalIn < 1) {
                    return t('timetables.now');
                  }
                  return `${x.arrivalIn} min`;
                })()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Typography className={classes.message}>
        {departures.message}
      </Typography>
    </>
  ) : null;
  const [stopTab, setStopTab] = useState<'departures' | 'timetable'>('departures');
  const timetableHeader = (departures && timetables) ? (
    <>
      <div className={classes.timetableRoutes}>
        {timetables.map(({ route }) => (
          <Button
            variant="contained"
            className={classNames(classes.timetableRoute, {
              [classes.timetableRouteSelected]: selectedRoute === route,
            })}
            key={route}
            onClick={() => {
              setSelectedRoute(route);
              setSelectedDirection('all');
            }}
          >
            {route}
          </Button>
        ))}
      </div>
      <div className={classes.timetableOptions}>
        <div className={classes.directionWrapper}>
          <Typography className={classes.directionLabel}>
            {t('timetables.direction')}
            :
          </Typography>
          <TextField
            value={selectedDirection}
            onChange={({ target }) => setSelectedDirection(target.value)}
            variant="outlined"
            size="small"
            className={classes.direction}
            select
            SelectProps={{
              className: classes.directionSelect,
              classes: { root: classes.selectRoot },
              // eslint-disable-next-line react/prop-types
              IconComponent: ({ className, ...rest }) => (
                <div {...rest} className={classNames(className, classes.selectIcon)}>
                  <Chevron />
                </div>
              ),
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.selectNoBorder,
              },
            }}
          >
            <MenuItem value="all">
              {t('timetables.all')}
            </MenuItem>
            {selectedTimetable && selectedTimetable.destinations.map(({ headsign }) => (
              <MenuItem value={headsign} key={headsign}>
                {headsign}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <DatePicker
          value={date}
          onChange={(value) => {
            if (value) {
              setDate(value);
              setSelectedDirection('all');
            }
          }}
          inputVariant="outlined"
          TextFieldComponent={(TextFieldProps) => (
            // @ts-ignore
            <TextField
              {...TextFieldProps}
              size="small"
              InputProps={{
                classes: {
                  notchedOutline: classes.selectNoBorder,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <div className={classes.selectIcon}>
                      <Chevron />
                    </div>
                  </InputAdornment>
                ),
                className: classes.dateInput,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                className: classes.dateNativeInput,
              }}
            />
          )}
          cancelLabel={t('common.cancel')}
          format="MMM. D, Y"
        />
      </div>
    </>
  ) : null;
  const timetableArray = (selectedTimetable && selectedTimes)
    ? R.sortBy(R.prop('0'), Object.entries(R.groupBy(
      (x) => x.substring(0, 2),
      selectedTimes,
    ))).map(([hour, times]) => ({
      hour,
      times: R.sortBy(R.identity, times.map((time) => time.substring(3))),
    }))
    : null;
  const timetableContent = (selectedTimetable && selectedTimes && timetableArray) ? (
    <div>
      {timetableArray.map(({ hour, times }) => (
        <div className={classes.hourRow} key={hour}>
          <Typography className={classes.hour}>
            {hour}
          </Typography>
          {times.map((time) => (
            <Typography className={classes.minute} key={time}>
              {time}
            </Typography>
          ))}
        </div>
      ))}
    </div>
  ) : null;
  const getVehicleIcon = (delay: number = 0) => {
    const delayed = delay >= 180;
    switch (selectedItem?.vehicles[0]) {
      case 0:
      case 900:
        return delayed ? tramDelayed : tram;
      case 2:
      case 101:
      case 102:
      case 106:
      case 109:
        return delayed ? trainDelayed : train;
      case 800:
        return delayed ? trolleybusDelayed : trolleybus;
      case 1200:
        return ferry;
      case 3:
      case 700:
      case 702:
      default:
        return delayed ? busDelayed : bus;
    }
  };
  const openPdf = async () => {
    if (!selectedItem || !selectedRoute || !timetableArray) return;
    const direction = selectedDirection === 'all' ? t('timetables.all') : selectedDirection;
    pdfMake.createPdf({
      content: [
        {
          table: {
            body: [
              [
                {
                  margin: [-4, -3],
                  border: [true, true, true, true],
                  borderColor: ['#DEDEDE', '#DEDEDE', '#DEDEDE', '#DEDEDE'],
                  table: {
                    body: [
                      [
                        {
                          border: [false, false, false, false],
                          columns: [
                            {
                              svg: (
                                await axios.get(`${window.location.origin}/${getVehicleIcon()}`)
                              ).data,
                              width: 16,
                              height: 16,
                            },
                            {
                              text: selectedItem.name,
                              fontSize: 15,
                              bold: true,
                            },
                          ],
                          columnGap: 6,
                          margin: [8, 8, 8, 4],
                        },
                      ],
                      [
                        {
                          border: [false, false, false, false],
                          columns: [
                            {
                              width: 'auto',
                              stack: [
                                {
                                  canvas: [
                                    {
                                      type: 'rect',
                                      x: 0,
                                      y: 0,
                                      w: 19 + 5 * selectedRoute.length,
                                      h: 19,
                                      r: 10,
                                      color: '#A1145C',
                                    },
                                  ],
                                },
                                {
                                  color: 'white',
                                  fontSize: 9,
                                  bold: true,
                                  text: selectedRoute,
                                  absolutePosition: { x: 66, y: 84 },
                                },
                              ],
                            },
                            {
                              fontSize: 9,
                              text: `${t('timetables.direction')}: ${direction}`,
                              width: 'auto',
                              margin: [0, 4],
                            },
                            {
                              fontSize: 9,
                              text: date.format('MMM. D, Y'),
                              margin: [10, 4, 0, 4],
                              width: 'auto',
                            },
                          ],
                          columnGap: 6,
                          margin: [8, 0, 8, 5],
                        },
                      ],
                      [
                        {
                          border: [false, false, false, false],
                          margin: [-6, -3],
                          table: {
                            widths: ['*'],
                            body: [
                              ...timetableArray.map(({ hour, times }, i) => (
                                [
                                  {
                                    margin: [5, 0],
                                    border: [false, false, false, false],
                                    columns: [
                                      {
                                        text: hour,
                                        fontSize: 13,
                                        bold: true,
                                        width: 16,
                                      },
                                      ...times.map((time) => ({
                                        text: time,
                                        fontSize: 9,
                                        margin: [0, 3, 0, 0],
                                        width: 'auto',
                                      })),
                                    ],
                                    columnGap: 6,
                                    fillColor: i % 2 === 0 ? '#F5F5F5' : 'white',
                                  },
                                ]
                              )),
                            ],
                          },
                        },
                      ],
                    ],
                  },
                },
              ],
            ],
          },
        },
      ],
      defaultStyle: {
        font: 'Roboto',
      },
      pageMargins: 43,
    }).open();
  };
  const map = (
    <Map
      className={classes.map}
      minZoom={3}
      maxZoom={18}
      ref={mapRef}
      zoomControl={!isMobile}
      tap={false}
      // @ts-ignore
      fullscreenControl={{ position: 'topright' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ScaleControl position="bottomleft" imperial={false} />
      {myLocation && (
        <Marker
          position={[myLocation.lat, myLocation.lon]}
          icon={leaflet.divIcon({
            className: classes.myLocationMarker,
            iconSize: [20, 20],
          })}
        />
      )}
      {stopsOnMap.map((stop, i) => {
        const isFirstOrLast = i === 0 || i === stopsOnMap.length - 1;
        return (
          <Marker
            key={stop.lat}
            position={[stop.lat, stop.lon]}
            icon={leaflet.divIcon({
              className: isFirstOrLast ? classes.stopMarker : classes.intermediateStopMarker,
              iconSize: isFirstOrLast ? [10, 10] : [8, 8],
            })}
          >
            <Popup
              className={classNames(classes.popup, classes.stopPopup)}
              closeButton={false}
              maxWidth={350}
            >
              <Typography className={classes.popupStopName}>
                {stop.name}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.showDepartures}
                onClick={() => {
                  setSelectedItem({
                    type: 'stop',
                    id: stop.id,
                    name: stop.name,
                    vehicles: selectedItem!.vehicles,
                    agency: selectedItem!.agency,
                    lat: stop.lat,
                    lon: stop.lon,
                  });
                  setRouteDetails(null);
                  setRouteRTData(null);
                }}
              >
                {t('timetables.showDepartures')}
              </Button>
            </Popup>
          </Marker>
        );
      })}
      {shapesOnMap.map((shape, i) => (
        <Polyline
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          positions={shape}
          color="#3571B7"
          weight={3}
        />
      ))}
      {vehiclesOnMap.map((vehicle) => (
        <Marker
          key={vehicle.code}
          position={[vehicle.lat, vehicle.lon]}
          icon={leaflet.icon({
            className: classes.vehicleMarker,
            iconUrl: getVehicleIcon(vehicle.delay),
            iconSize: [24, 24],
          })}
          zIndexOffset={100}
        >
          <Popup
            className={classes.popup}
            closeButton={false}
            maxWidth={250}
          >
            {vehicle.headsign && (
              <Typography className={classes.popupHeader}>
                {`${t('timetables.direction')}: ${vehicle.headsign}`}
              </Typography>
            )}
            {vehicle.nextStop && (
              <>
                <Typography className={classes.popupText}>
                  {`${t('timetables.nextStop')}:`}
                </Typography>
                <Typography className={classes.popupText}>
                  {vehicle.nextStop}
                </Typography>
              </>
            )}
            <Typography className={classes.popupText}>
              {t('map.delay')}
              {': '}
              {vehicle.delay >= 60 && `${Math.floor(vehicle.delay / 60)} ${t('map.minutes')} `}
              {vehicle.delay % 60}
              {' '}
              {t('map.seconds')}
            </Typography>
          </Popup>
        </Marker>
      ))}
    </Map>
  );
  return (
    <div className={classes.root}>
      <Nav />
      <div className={classes.main}>
        {!(isMobile && selectedItem?.type === 'route') && (
          <div className={classes.left}>
            <div className={classes.top}>
              <Typography className={classes.header}>
                <Timetable className={classes.headerIcon} />
                {t('timetables.timetables')}
              </Typography>
              <CustomAutocomplete
                popupIcon={<div />}
                noAdornment
                label={t('timetables.search')}
                options={R.uniq([
                  ...searchResults,
                  ...(selectedItem ? [selectedItem] : []),
                ])}
                getOptionSelected={R.equals}
                className={classes.search}
                onInputChange={onInputChange}
                getOptionLabel={R.prop('name')}
                renderOption={(option) => renderOption(option)}
                filterOptions={R.filter<ITimetableSearch>(
                  (option) => searchResults.includes(option),
                )}
                value={selectedItem}
                onChange={async (event, value) => setSelectedItem(value)}
              />
              <div className={classes.meansContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.means, {
                    [classes.meansSelected]: selectedMeans.bus,
                  })}
                  onClick={() => setSelectedMeans(R.over(R.lensProp('bus'), R.not))}
                >
                  {selectedMeans.bus ? <BusWhite /> : <Bus />}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.means, {
                    [classes.meansSelected]: selectedMeans.trolleybus,
                  })}
                  onClick={() => setSelectedMeans(R.over(R.lensProp('trolleybus'), R.not))}
                >
                  {selectedMeans.trolleybus ? <TrolleybusWhite /> : <Trolleybus />}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.means, {
                    [classes.meansSelected]: selectedMeans.tram,
                  })}
                  onClick={() => setSelectedMeans(R.over(R.lensProp('tram'), R.not))}
                >
                  {selectedMeans.tram ? <TramWhite /> : <Tram />}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.means, {
                    [classes.meansSelected]: selectedMeans.train,
                  })}
                  onClick={() => setSelectedMeans(R.over(R.lensProp('train'), R.not))}
                >
                  {selectedMeans.train ? <TrainWhite /> : <Train />}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.means, {
                    [classes.meansSelected]: selectedMeans.ferry,
                  })}
                  onClick={() => setSelectedMeans(R.over(R.lensProp('ferry'), R.not))}
                >
                  {selectedMeans.ferry ? <FerryWhite /> : <Ferry />}
                </Button>
              </div>
            </div>
            <div className={classes.bottom}>
              {selectedItem && (
                <div className={classes.tile}>
                  {!isMobile && renderOption(selectedItem, true)}
                  {(isMobile && selectedItem.type === 'stop') && (
                    <>
                      <div className={classes.mobileHeaderContainer}>
                        <div className={classes.mobileStopIcon}>
                          <VehicleIcon type={selectedItem.vehicles[0]} />
                        </div>
                        <Typography className={classes.mobileStopHeader}>
                          {selectedItem.name}
                        </Typography>
                        <Typography className={classes.mobileStopTown}>
                          Gdańsk
                        </Typography>
                      </div>
                      <Tabs
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        value={stopTab}
                        onChange={(event, value) => setStopTab(value)}
                        className={classes.tabs}
                      >
                        <Tab
                          value="departures"
                          label={t('timetables.departures')}
                          className={classes.tab}
                        />
                        <Tab
                          value="timetable"
                          label={t('timetables.timetable')}
                          className={classes.tab}
                        />
                      </Tabs>
                      {stopTab === 'departures' && departuresContent}
                      {stopTab === 'timetable' && (
                        <>
                          <div className={classes.timetableTop}>
                            {timetableHeader}
                          </div>
                          {timetableContent}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
              {!selectedItem && nearestStops.length > 0 && (
                <>
                  <Typography className={classes.bottomHeader}>
                    {t('timetables.nearestStops')}
                    :
                  </Typography>
                  {nearestStops.map((stop) => (
                    <div className={classNames(classes.tile, classes.nearestTile)} key={stop.id}>
                      {renderOption(stop)}
                      <IconButton
                        onClick={() => setSelectedItem(stop)}
                        className={classes.nearestButton}
                        data-testid="nearest-stop"
                      >
                        <Chevron />
                      </IconButton>
                    </div>
                  ))}
                </>
              )}
              <Footer darkBg narrow />
            </div>
          </div>
        )}
        {!isMobile && ((departures && timetables && selectedItem) ? (
          <div className={classes.right}>
            <div className={classes.departures}>
              <div className={classes.departuresHeader}>
                <Typography className={classes.departuresHeaderText}>
                  {t('timetables.departures')}
                </Typography>
              </div>
              <div className={classes.departuresContentWrapper}>
                {departuresContent}
              </div>
            </div>
            <div className={classes.timetable}>
              <div className={classes.timetableTop}>
                <div className={classes.timetableHeaderRow}>
                  <Typography className={classes.timetableHeader}>
                    {t('timetables.timetable')}
                  </Typography>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={openPdf}
                    data-testid="print"
                  >
                    <Print />
                  </IconButton>
                </div>
                {timetableHeader}
              </div>
              {timetableContent}
            </div>
          </div>
        ) : map)}
        {(isMobile && selectedItem?.type === 'route') && (
          <div className={classes.mobileMapContainer}>
            <div className={classes.mobileRouteContainer}>
              <IconButton
                onClick={() => setSelectedItem(null)}
                className={classes.goBack}
              >
                <BackArrow />
              </IconButton>
              {renderOption(selectedItem, true)}
            </div>
            {map}
          </div>
        )}
      </div>
    </div>
  );
}
