import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Dialog, TextField, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import AppContext, { ProviderContext } from '../../context';
import { IDataChange, IDataVersion } from '../../interfaces';
import { axios } from '../../utils';
import VersionInfo from '../VersionInfo';
import CustomAutocomplete from '../../common/CustomAutocomplete';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 16,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  copyVersion: {
    width: 300,
    marginRight: 16,
  },
  toDraft: {
    marginRight: 16,
  },
  releaseName: {
    width: 300,
    marginRight: 16,
  },
  changes: {
    padding: '4px 0',
    margin: '16px -16px',
    backgroundColor: '#F5F5F5',
  },
  name: {
    fontWeight: 700,
  },
  noChanges: {
    padding: '8px 16px',
  },
  versionContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 37,
  },
  takeoverButton: {
    marginLeft: 32,
    marginBottom: 16,
  },
  dialog: {
    padding: 24,
  },
  dialogText: {
    color: '#37001F',
    marginBottom: 16,
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: -8,
  },
});

export default function Manage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppContext);
  const {
    user: { agency, role }, versions, setVersions, version,
  } = useContext(ProviderContext);
  const [copyVersion, setCopyVersion] = useState<IDataVersion | null>(null);
  const [name, setName] = useState('');
  const [changes, setChanges] = useState<IDataChange[] | null>(null);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/${agency}/data/compare`);
      setChanges(data);
    })();
  }, [agency, versions]);
  const [takeoverDialog, setTakeoverDialog] = useState(false);
  const readOnly = role === 'AGENCY_EMPL';
  const updateVersions = async () => {
    const { data } = await axios.get(`/${agency}/versions`);
    setVersions(data);
  };
  if (!changes || !versions || !version) return null;
  return (
    <div className={classes.root}>
      <div className={classes.versionContainer}>
        <VersionInfo />
        {version.locked !== undefined && !version.hasUserLock && (
          <Button
            color="primary"
            className={classes.takeoverButton}
            onClick={() => setTakeoverDialog(true)}
            disabled={readOnly}
          >
            {t('data.takeOverEditing')}
          </Button>
        )}
      </div>
      <Typography className={classes.header}>
        {t('data.copy')}
      </Typography>
      <div className={classes.row}>
        <CustomAutocomplete
          options={versions.filter((x) => x.type !== 'draft')}
          value={copyVersion}
          onChange={(event, value) => setCopyVersion(value)}
          getOptionLabel={R.prop('name')}
          className={classes.copyVersion}
          label={t('data.copyDataFrom')}
        />
        <Typography className={classes.toDraft}>
          {t('data.toDraft')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            if (!copyVersion) return;
            await axios.put(`/${agency}/mgmt/draft`, { from: copyVersion.id });
            await updateVersions();
            setCopyVersion(null);
            showSnackbar(t('data.copiedToDraft'));
          }}
          disabled={readOnly || !copyVersion}
        >
          {t('data.copy')}
        </Button>
      </div>
      <Typography className={classes.header}>
        {t('data.publish')}
      </Typography>
      <div className={classes.row}>
        <TextField
          value={name}
          onChange={({ target }) => setName(target.value)}
          label={t('data.releaseName')}
          className={classes.releaseName}
          variant="outlined"
          size="small"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            if (!name) return;
            await axios.post(`/${agency}/mgmt/version`, { name });
            await updateVersions();
            setName('');
            showSnackbar(t('data.draftPublished'));
          }}
          disabled={readOnly || !name}
        >
          {t('data.publishDraft')}
        </Button>
      </div>
      <Typography>
        {t('data.changesSummary')}
        :
      </Typography>
      <div className={classes.changes}>
        {changes.length === 0 ? (
          <Typography className={classes.noChanges}>
            {t('data.noChanges')}
          </Typography>
        ) : (
          <ul>
            {changes.map((change, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Typography component="li" key={i}>
                {change.action === 'ADDED' && t('data.added')}
                {change.action === 'DELETED' && t('data.deleted')}
                {change.action === 'CHANGED' && t('data.edited')}
                {change.action === 'RENAMED' && t('data.renamed')}
                {' '}
                {change.entity === 'route' && t('data.route')}
                {change.entity === 'pattern' && t('data.pattern')}
                {change.entity === 'stop' && t('data.changeStop')}
                {change.entity === 'trip' && change.type === 'SINGLE' && t('data.changeTrip')}
                {change.entity === 'trip' && change.type === 'BLOCK' && t('data.changeTripBlock')}
                {change.entity === 'shape' && t('data.changeShape')}
                {change.entity === 'calendar' && t('data.changeCalendar')}
                {' '}
                {(() => {
                  if (change.action === 'RENAMED') {
                    return (
                      <>
                        <span className={classes.name}>
                          {change.from}
                        </span>
                        {' '}
                        {t('data.renamedTo')}
                        {' '}
                        <span className={classes.name}>
                          {change.to}
                        </span>
                      </>
                    );
                  }
                  if (change.entity === 'trip') {
                    return (
                      <>
                        <span className={classes.name}>
                          {change.type === 'SINGLE'
                            ? `${change.departure}, ${change.calendar}`
                            : `${change.from}-${change.to}, ${change.calendar}`}
                        </span>
                        {' '}
                        {t('data.changeForRoute')}
                        {' '}
                        {change.route}
                        {', '}
                        {t('data.changeForPattern')}
                        {' '}
                        {change.pattern}
                      </>
                    );
                  }
                  return (
                    <span className={classes.name}>
                      {change.name}
                    </span>
                  );
                })()}
                {change.entity === 'shape' && (
                  <>
                    {' '}
                    {t('data.forStops')}
                    {' '}
                    <span className={classes.name}>
                      {change.stopFrom}
                    </span>
                    {' - '}
                    <span className={classes.name}>
                      {change.stopTo}
                    </span>
                  </>
                )}
              </Typography>
            ))}
          </ul>
        )}
      </div>
      <Dialog
        open={takeoverDialog}
        PaperProps={{ className: classes.dialog }}
        onClose={() => setTakeoverDialog(false)}
      >
        <Typography className={classes.dialogText}>
          {t('data.takeoverConfirm', { author: version.author })}
        </Typography>
        <div className={classes.dialogButtons}>
          <Button
            variant="text"
            color="primary"
            onClick={() => setTakeoverDialog(false)}
          >
            {t('data.cancel')}
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={async () => {
              await axios.put(`/${agency}/mgmt/takeover`);
              await updateVersions();
              setTakeoverDialog(false);
            }}
          >
            {t('data.proceed')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
