import React, { useState } from 'react';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { DatePickerProps, DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Calendar } from '../assets/calendar.svg';

interface Props extends DatePickerProps {}

const useStyles = makeStyles({
  input: {
    paddingRight: 10,
  },
});

export default function CustomDatePicker(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [id] = useState(Math.round(Math.random() * 10000));
  return (
    <DatePicker
      inputVariant="outlined"
      TextFieldComponent={(TextFieldProps) => (
        <TextField
          {...TextFieldProps}
          size="small"
          id={`date-picker-${id}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Calendar />
              </InputAdornment>
            ),
            className: classes.input,
          }}
        />
      )}
      cancelLabel={t('common.cancel')}
      format="DD.MM.Y"
      {...props}
    />
  );
}
