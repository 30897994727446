import {
  Redirect, Route, RouteComponentProps, RouteProps,
} from 'react-router-dom';
import React, { useContext } from 'react';
import AppContext from '../context';
import { useGetLink } from '../utils';

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

export default function LoggedInRoute({ component: Component, ...props }: Props) {
  const { user } = useContext(AppContext);
  const getLink = useGetLink();
  const isLoggedIn = user && (['ADMIN', 'AGENCY_ADMIN', 'AGENCY_EMPL'].includes(user.role || '')
    || ['CARD_OFFERS_MANAGER', 'SITE_MANAGER', 'CARD_SELLER'].includes(user.cardRole || ''));
  return (
    <Route
      {...props}
      render={(componentProps) => (
        isLoggedIn ? (
          <Component {...componentProps} />
        ) : (
          <Redirect to={getLink('/auth/login')} />
        )
      )}
    />
  );
}
