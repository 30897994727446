import { createContext } from 'react';
import {
  ICardRole, IDataVersion, IProvider, IRole,
} from './interfaces';

export interface IUser {
  role: IRole;
  agency: string | null;
  cardRole: ICardRole;
  cardOrganization: number | null;
  cardSite: number | null;
}

export interface IValue {
  name?: string;
  user?: IUser;
  interconnectNumber?: string;
  snackbarOpen: boolean;
  snackbarMessage?: string;
}

interface IValueWithFunctions extends IValue {
  updateContext: (update: (oldValue: IValue) => IValue) => void;
  showSnackbar: (content: string) => void;
}

const AppContext = createContext<IValueWithFunctions>({
  snackbarOpen: false,
  updateContext: () => {},
  showSnackbar: () => {},
});

export default AppContext;

export interface IProviderValue {
  name: string;
  user: IUser;
  providers: IProvider[];
  setProviders: (providers: IProvider[]) => void;
  version?: IDataVersion;
  versions?: IDataVersion[];
  setVersions: (versions: IDataVersion[]) => void;
}

const defaultUser = {
  role: null,
  agency: null,
  cardRole: null,
  cardOrganization: null,
  cardSite: null,
};

export const ProviderContext = createContext<IProviderValue>({
  name: '',
  user: defaultUser,
  providers: [],
  setProviders: () => {},
  setVersions: () => {},
});

export interface ICardValue {
  name: string;
  user: IUser;
}

export const CardContext = createContext<ICardValue>({
  name: '',
  user: defaultUser,
});
