import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox, FormControlLabel, InputAdornment, TextField,
} from '@material-ui/core';
import * as R from 'ramda';
import AppContext, { ProviderContext } from '../context';
import { ReactComponent as Search } from '../assets/search.svg';
import NewHeader from './NewHeader';
import ButtonList from './ButtonList';
import SelectLocation, { coordsValid } from '../common/SelectLocation';
import CustomAutocomplete from '../common/CustomAutocomplete';
import timeZones from './data/time-zones';
import { IProvider } from '../interfaces';
import DeleteDialog from './DeleteDialog';
import { axios } from '../utils';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    display: 'flex',
  },
  left: {
    flexGrow: 1,
    marginRight: 24,
  },
  search: {
    width: 500,
    marginBottom: 24,
  },
  right: {
    width: 600,
    flexShrink: 0,
    marginTop: 64,
  },
  field: {
    marginBottom: 16,
  },
  fieldRow: {
    display: 'flex',
    margin: '0 -12px 24px',
  },
  shortField: {
    width: '50%',
    margin: '0 12px',
  },
  saveRow: {
    display: 'flex',
    alignItems: 'center',
  },
  discard: {
    marginRight: 16,
    marginLeft: 'auto',
  },
  tripPlanning: {
    marginBottom: 16,
  },
});

function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

function sortByName<T extends { name: string }>(list: T[]): T[] {
  return R.sort((a, b) => a.name.localeCompare(b.name, 'pl'), list);
}

type DataMgmt = 'manual' | 'gtfs' | 'netex' | 'other';

export default function Organizations() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { providers, setProviders, user } = useContext(ProviderContext);
  const { updateContext } = useContext(AppContext);
  const [search, setSearch] = useState('');
  const [selectedOrg, setSelectedOrg] = useState<string | null>(null);
  const [fullName, setFullName] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [website, setWebsite] = useState('');
  const [websiteError, setWebsiteError] = useState(false);
  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [dataMgmt, setDataMgmt] = useState<DataMgmt>('manual');
  const [importUrl, setImportUrl] = useState('');
  const [importUrlError, setImportUrlError] = useState(false);
  const [tripPlanning, setTripPlanning] = useState(true);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const loadData = (org: IProvider | null) => {
    if (org) {
      setFullName(org.fullName);
      setName(org.name);
      setWebsite(org.website);
      setLat(String(org.lat));
      setLon(String(org.lon));
      setTimeZone(org.timeZone);
      setDataMgmt(org.dataSource === 'manual' ? 'manual' : org.dataImport.type);
      setImportUrl(org.dataSource === 'manual' ? '' : org.dataImport.url || '');
      setTripPlanning(org.tripPlanning);
    } else {
      setFullName('');
      setName('');
      setWebsite('');
      setLat('');
      setLon('');
      setTimeZone('Europe/Warsaw');
      setDataMgmt('manual');
      setImportUrl('');
      setTripPlanning(true);
    }
    setNameError(false);
  };
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <NewHeader
          headerText={t('orgs.orgs')}
          newText={t('orgs.newOrg')}
          onClick={() => {
            setSelectedOrg('new');
            loadData(null);
          }}
        />
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          label={t('orgs.search')}
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
          className={classes.search}
          variant="outlined"
          size="small"
        />
        <ButtonList
          elements={(
            sortByName(providers.filter((provider) => (
              provider.name.toLowerCase().includes(search.toLowerCase())
            )).map((provider) => ({
              id: provider.id,
              name: provider.name,
              onClick: () => {
                setSelectedOrg(provider.id);
                loadData(provider);
              },
            })))
          )}
          selected={selectedOrg}
          variant="small"
        />
      </div>
      <div className={classes.right}>
        {selectedOrg && (
          <>
            <TextField
              label={t('orgs.fullName')}
              value={fullName}
              onChange={({ target }) => setFullName(target.value)}
              variant="outlined"
              size="small"
              fullWidth
              className={classes.field}
            />
            <TextField
              label={t('orgs.shortName')}
              value={name}
              onChange={({ target }) => {
                setName(target.value);
                setNameError(false);
              }}
              variant="outlined"
              size="small"
              fullWidth
              className={classes.field}
              error={nameError}
              helperText={nameError ? t('orgs.alreadyExists', { name }) : ''}
            />
            <TextField
              label={t('orgs.website')}
              value={website}
              onChange={({ target }) => {
                setWebsite(target.value);
                setWebsiteError(false);
              }}
              variant="outlined"
              size="small"
              fullWidth
              className={classes.field}
              error={websiteError}
              helperText={websiteError ? t('orgs.invalidUrl') : ''}
            />
            <SelectLocation
              lat={lat}
              setLat={setLat}
              lon={lon}
              setLon={setLon}
            />
            <div className={classes.fieldRow}>
              <div className={classes.shortField}>
                <CustomAutocomplete
                  options={timeZones}
                  value={timeZone}
                  onChange={(event, value) => value && setTimeZone(value)}
                  label={t('orgs.timeZone')}
                  disableClearable
                />
              </div>
              <div className={classes.shortField}>
                <CustomAutocomplete
                  options={['manual', 'gtfs', 'netex', 'other'] as DataMgmt[]}
                  getOptionLabel={(option) => t(`orgs.${option}`)}
                  getOptionDisabled={(option) => {
                    if (selectedOrg === 'new') {
                      return false;
                    }
                    return (dataMgmt === 'manual') !== (option === 'manual');
                  }}
                  value={dataMgmt}
                  onChange={(event, value) => {
                    if (value) {
                      setDataMgmt(value);
                      if (!(value === 'gtfs' || value === 'netex')) {
                        setImportUrl('');
                      }
                    }
                  }}
                  label={t('orgs.dataMgmt')}
                  disableClearable
                />
              </div>
            </div>
            <TextField
              label={t('orgs.importUrl')}
              value={importUrl}
              onChange={({ target }) => {
                setImportUrl(target.value);
                setImportUrlError(false);
              }}
              variant="outlined"
              size="small"
              fullWidth
              className={classes.field}
              disabled={!(dataMgmt === 'gtfs' || dataMgmt === 'netex')}
              error={importUrlError}
              helperText={importUrlError ? t('orgs.invalidUrl') : ''}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={tripPlanning}
                  onChange={({ target }) => setTripPlanning(target.checked)}
                  color="primary"
                />
              )}
              label={t('orgs.tripPlanning')}
              className={classes.tripPlanning}
            />
            <div className={classes.saveRow}>
              {selectedOrg !== 'new' && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    setDeleteDialog(true);
                    setDeleteName(providers.find(R.propEq('id', selectedOrg))!.name);
                  }}
                >
                  {t('orgs.delete')}
                </Button>
              )}
              <Button
                variant="text"
                color="primary"
                className={classes.discard}
                onClick={() => {
                  loadData(
                    selectedOrg !== 'new'
                      ? providers.find(R.propEq('id', selectedOrg)) || null
                      : null,
                  );
                }}
              >
                {t('orgs.discard')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={(
                  !name || !fullName || !coordsValid(lat, lon)
                  || ((dataMgmt === 'gtfs' || dataMgmt === 'netex') && !importUrl)
                )}
                onClick={async () => {
                  let error = false;
                  if (providers.some((provider) => (
                    provider.id !== selectedOrg && provider.name === name
                  ))) {
                    setNameError(true);
                    error = true;
                  }
                  if (website && !isValidHttpUrl(website)) {
                    setWebsiteError(true);
                    error = true;
                  }
                  if (importUrl && !isValidHttpUrl(importUrl)) {
                    setImportUrlError(true);
                    error = true;
                  }
                  if (error) {
                    return;
                  }
                  const data = {
                    name,
                    fullName,
                    website,
                    lat: Number(lat),
                    lon: Number(lon),
                    timeZone,
                    tripPlanning,
                    ...(dataMgmt === 'manual' ? {
                      dataSource: 'manual',
                    } : {
                      dataSource: 'auto',
                      dataImport: {
                        type: dataMgmt,
                        url: importUrl,
                      },
                    }),
                  };
                  if (selectedOrg === 'new') {
                    const { data: newProvider } = await axios.post('/provider', data);
                    setProviders(sortByName([...providers, newProvider]));
                  } else {
                    await axios.put(`/provider/${selectedOrg}`, data);
                    setProviders(sortByName(providers.map((provider) => (
                      provider.id === selectedOrg ? {
                        id: selectedOrg,
                        ...data,
                      } as IProvider : provider
                    ))));
                  }
                  setSelectedOrg(null);
                }}
              >
                {t('orgs.save')}
              </Button>
            </div>
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={t('orgs.confirmDelete', { name: deleteName })}
        onDelete={async () => {
          await axios.delete(`/provider/${selectedOrg}`);
          setProviders(providers.filter((x) => x.id !== selectedOrg));
          if (user.agency === selectedOrg) {
            localStorage.removeItem('agency');
            localStorage.removeItem('version');
            updateContext(R.set(R.lensPath(['user', 'agency']), null));
          }
          setSelectedOrg(null);
          setDeleteDialog(false);
        }}
        canDelete
      />
    </div>
  );
}
