import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { Button, Typography } from '@material-ui/core';
import classNames from 'classnames';
import * as R from 'ramda';
import { ProviderContext } from '../../../context';
import CustomDatePicker from '../../../common/CustomDatePicker';
import CustomAutocomplete from '../../../common/CustomAutocomplete';
import { axios, useGetLink } from '../../../utils';
import { ICrewAssignment, ICrewVehicle, IDriver } from '../../../interfaces';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
  },
  topRow: {
    width: 800,
    marginTop: 32,
    display: 'flex',
    alignItems: 'center',
  },
  depot: {
    width: 300,
    marginLeft: 16,
  },
  printableVersion: {
    marginLeft: 'auto',
  },
  datePicker: {
    width: 140,
  },
  copyRow: {
    marginTop: 32,
    padding: '24px 32px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    width: 800,
  },
  copyText: {
    color: '#37001F',
  },
  copyDate: {
    width: 190,
    marginLeft: 16,
    backgroundColor: 'white',
  },
  copyButton: {
    marginLeft: 24,
  },
  table: {
    marginTop: 32,
    width: 800,
    borderCollapse: 'collapse',
  },
  cell: {
    padding: '4px 8px',
    color: '#37001F',
    fontSize: 16,
    fontWeight: 300,
    textAlign: 'center',
    '&:first-child': {
      textAlign: 'left',
    },
  },
  headerCell: {
    fontWeight: 400,
  },
  tableRow: {
    '&:nth-child(even)': {
      backgroundColor: '#F5F5F5',
    },
  },
  vehicleID: {
    width: 140,
    backgroundColor: 'white',
  },
  shift: {
    width: 220,
    backgroundColor: 'white',
  },
  saveRow: {
    width: 800,
    marginTop: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: 24,
  },
});

export default function Assign() {
  const classes = useStyles();
  const { t } = useTranslation();
  const getLink = useGetLink();
  const { user: { agency } } = useContext(ProviderContext);
  const [depots, setDepots] = useState<string[] | null>(null);
  const [vehicles, setVehicles] = useState<ICrewVehicle[] | null>(null);
  const [drivers, setDrivers] = useState<IDriver[] | null>(null);
  useEffect(() => {
    (async () => {
      const [
        { data: depotsData },
        { data: vehiclesData },
        { data: driversData },
      ] = await Promise.all([
        await axios.get(`/${agency}/depots`),
        await axios.get(`/${agency}/vehicles`),
        await axios.get(`/${agency}/drivers`),
      ]);
      setDepots(depotsData);
      setVehicles(vehiclesData);
      setDrivers(driversData);
    })();
  }, [agency]);
  const [date, setDate] = useState<Moment | null>(null);
  const [depot, setDepot] = useState<string | null>(null);
  const [assignedDates, setAssignedDates] = useState<string[] | null>(null);
  useEffect(() => {
    (async () => {
      if (depot) {
        const { data } = await axios.get(`/${agency}/crew/assigned`, { params: { depot } });
        setAssignedDates(data);
      }
    })();
  }, [depot, agency]);
  const [copyDate, setCopyDate] = useState<string | null>(null);
  const [table, setTable] = useState<ICrewAssignment[]>([]);
  const [initTable, setInitTable] = useState<ICrewAssignment[]>([]);
  useEffect(() => {
    (async () => {
      if (date && depot) {
        const { data } = await axios.get(`/${agency}/crew/assignment`, {
          params: {
            date: date.format('YYYY-MM-DD'),
            depot,
          },
        });
        setTable(data);
        setInitTable(data);
      }
    })();
  }, [date, depot, agency]);
  if (!depots || !vehicles || !drivers) return null;
  return (
    <div className={classes.root}>
      <div className={classes.topRow}>
        <CustomDatePicker
          value={date}
          onChange={setDate}
          label={t('data.date')}
          className={classes.datePicker}
        />
        <CustomAutocomplete
          value={depot}
          onChange={(event, value) => setDepot(value)}
          options={depots}
          className={classes.depot}
          label={t('data.depot')}
        />
        <Button
          variant="contained"
          color="primary"
          component="a"
          href={getLink(`/provider/data/crew/print?date=${date?.format('YYYY-MM-DD')}&depot=${depot}`)}
          target="_blank"
          className={classes.printableVersion}
          disabled={!(date && depot)}
        >
          {t('data.printableVersion')}
        </Button>
      </div>
      {(date && depot && assignedDates) && (
        <>
          <div className={classes.copyRow}>
            <Typography className={classes.copyText}>
              {t('data.copyCrew')}
            </Typography>
            <CustomAutocomplete
              value={copyDate}
              onChange={(event, value) => setCopyDate(value)}
              options={assignedDates}
              getOptionLabel={(x) => moment(x).format('DD.MM.Y')}
              className={classes.copyDate}
              label={t('data.date')}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.copyButton}
              disabled={!copyDate}
              onClick={async () => {
                const { data } = await axios.get(`/${agency}/crew/assignment`, {
                  params: {
                    date: copyDate,
                    depot,
                  },
                });
                setTable(table.map((x) => {
                  const copyRow = data.find(R.propEq('task', x.task));
                  if (copyRow) {
                    return {
                      ...copyRow,
                      start: x.start,
                    };
                  }
                  return x;
                }));
              }}
            >
              {t('data.copy')}
            </Button>
          </div>
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classNames(classes.cell, classes.headerCell)}>
                  {t('common.route')}
                </th>
                <th className={classNames(classes.cell, classes.headerCell)}>
                  {t('data.startTime')}
                </th>
                <th className={classNames(classes.cell, classes.headerCell)}>
                  {t('data.vehicleID')}
                </th>
                <th className={classNames(classes.cell, classes.headerCell)}>
                  {t('data.firstShift')}
                </th>
                <th className={classNames(classes.cell, classes.headerCell)}>
                  {t('data.secondShift')}
                </th>
              </tr>
            </thead>
            <tbody>
              {table.map((row) => {
                const updateTable = (prop: string) => (event: any, value: any) => setTable(
                  R.map((x) => (
                    x.task === row.task ? { ...x, [prop]: value ? value.id : null } : x
                  )),
                );
                return (
                  <tr className={classes.tableRow} key={row.task}>
                    <td className={classes.cell}>
                      {row.task}
                    </td>
                    <td className={classes.cell}>
                      {moment(row.start, 'HH:mm').format('H:mm')}
                    </td>
                    <td className={classes.cell}>
                      <CustomAutocomplete
                        // @ts-ignore
                        value={vehicles.find(R.propEq('id', row.vehicle)) || null}
                        onChange={updateTable('vehicle')}
                        options={vehicles}
                        className={classes.vehicleID}
                        disableClearable
                        getOptionLabel={R.prop('number')}
                      />
                    </td>
                    <td className={classes.cell}>
                      <CustomAutocomplete
                        // @ts-ignore
                        value={drivers.find(R.propEq('id', row.driverFirst)) || null}
                        onChange={updateTable('driverFirst')}
                        options={drivers}
                        className={classes.shift}
                        disableClearable
                        getOptionLabel={R.prop('name')}
                      />
                    </td>
                    <td className={classes.cell}>
                      <CustomAutocomplete
                        // @ts-ignore
                        value={drivers.find(R.propEq('id', row.driverSecond)) || null}
                        onChange={updateTable('driverSecond')}
                        options={drivers}
                        className={classes.shift}
                        disableClearable
                        getOptionLabel={R.prop('name')}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={classes.saveRow}>
            <Button
              color="primary"
              onClick={() => setTable(initTable)}
            >
              {t('data.discardChanges')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.saveButton}
              disabled={R.equals(table, initTable) || table.some((x) => (
                x.vehicle === undefined
              ))}
              onClick={async () => {
                await axios.put(`/${agency}/crew/assignment`, {
                  depot,
                  date: date.format('YYYY-MM-DD'),
                  assignments: table.map((x) => ({
                    task: x.task,
                    vehicle: x.vehicle || null,
                    driverFirst: x.driverFirst || null,
                    driverSecond: x.driverSecond || null,
                  })),
                });
                setInitTable(table);
              }}
            >
              {t('data.save')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
