import React, { useContext, useState } from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';
import * as R from 'ramda';
import { IProvider } from '../interfaces';
import { axios } from '../utils';
import CustomAutocomplete from '../common/CustomAutocomplete';
import { ProviderContext } from '../context';
import VersionInfo from './VersionInfo';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
  },
  select: {
    width: 300,
    marginBottom: 24,
  },
  button: {
    width: 237,
    height: 56,
    fontSize: 22,
    fontWeight: 400,
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 10px)',
    top: 'calc(50% - 10px)',
  },
});

type ExportType = 'netex' | 'gtfs' | 'kml' | 'geojson';

export default function Export() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { providers, version, user } = useContext(ProviderContext);
  const [selectedProvider, setSelectedProvider] = useState<IProvider | null>(null);
  const [type, setType] = useState<ExportType | null>(null);
  const [loading, setLoading] = useState(false);
  return (
    <div className={classes.root}>
      <VersionInfo />
      <CustomAutocomplete
        options={providers}
        value={selectedProvider}
        onChange={(event, value) => setSelectedProvider(value)}
        label={t('export.dataSource')}
        className={classes.select}
        getOptionLabel={R.prop('name')}
      />
      <CustomAutocomplete
        options={['netex', 'gtfs', 'kml', 'geojson'] as ExportType[]}
        getOptionLabel={(option) => {
          switch (option) {
            case 'netex': return 'NeTEx';
            case 'gtfs': return 'GTFS';
            case 'kml': return 'KML';
            case 'geojson': return 'GeoJSON';
            default: return '';
          }
        }}
        value={type}
        onChange={(event, value) => setType(value)}
        label={t('export.dataFormat')}
        className={classes.select}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          (async () => {
            if (!selectedProvider) return;
            setLoading(true);
            try {
              const { data } = await axios.get(`/${selectedProvider.id}/transportData`, {
                params: {
                  format: type,
                  ...((selectedProvider.id === user.agency && version) ? {
                    providerVersion: version.id,
                  } : {}),
                },
                responseType: 'arraybuffer',
              });
              fileDownload(
                data,
                `${selectedProvider.id}-${type}.zip`,
              );
              // eslint-disable-next-line no-empty
            } catch {}
            setLoading(false);
          })();
        }}
        className={classes.button}
        disabled={!selectedProvider || !type || loading}
      >
        {t('export.downloadFile')}
        {loading && (
          <CircularProgress
            size={20}
            color="primary"
            className={classes.loader}
          />
        )}
      </Button>
    </div>
  );
}
