import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import classNames from 'classnames';

interface Props {
  headerText: string;
  newText: string;
  onClick: () => void;
  disabled?: boolean;
  small?: boolean;
  bigMargin?: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    maxWidth: 520,
    justifyContent: 'space-between',
  },
  bigMargin: {
    marginBottom: 40,
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
  },
  small: {
    fontSize: 18,
    fontWeight: 400,
  },
  plus: {
    fontSize: 24,
    lineHeight: 1.5,
    marginRight: 16,
  },
});

export default function NewHeader(props: Props) {
  const {
    headerText, newText, onClick, disabled, small, bigMargin,
  } = props;
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.root, {
        [classes.bigMargin]: bigMargin,
      })}
    >
      <Typography
        className={classNames(classes.header, {
          [classes.small]: small,
        })}
      >
        {headerText}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={disabled}
      >
        <span className={classes.plus}>
          +
        </span>
        {newText}
      </Button>
    </div>
  );
}
