import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Slider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

interface Props {
  value: number;
  onChange: (newValue: number) => void;
}

const useStyles = makeStyles({
  radiusContainer: {
    marginLeft: 'auto',
    display: 'flex',
    marginTop: 16,
    zIndex: 2,
  },
  impactArea: {
    fontSize: 20,
    color: '#37001F',
    fontWeight: 400,
    marginRight: 32,
  },
  slider: {
    width: 300,
    color: '#E4E4E4',
  },
  rail: {
    opacity: 1,
  },
  thumb: {
    color: '#a1145c',
  },
  mark: {
    width: 8,
    height: 8,
    margin: '-3px 0 0 -6px',
    borderRadius: '50%',
  },
  markActive: {
    backgroundColor: '#E4E4E4',
  },
  markLabelActive: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export default function Radius({ value, onChange }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [internalValue, setInternalValue] = useState(value);
  const debouncedOnChange = useRef(debounce(onChange, 50));
  useEffect(() => {
    debouncedOnChange.current = debounce(onChange, 50);
  }, [onChange]);
  return (
    <div className={classes.radiusContainer}>
      <Typography className={classes.impactArea}>
        {t('impact.impactArea')}
      </Typography>
      <Slider
        className={classes.slider}
        marks={[
          { value: 100, label: '100m' },
          { value: 500, label: '500m' },
          { value: 1000, label: '1km' },
          { value: 2000, label: '2km' },
        ]}
        step={50}
        value={internalValue}
        onChange={(event, newValue) => {
          if (typeof newValue === 'number') {
            setInternalValue(newValue);
            debouncedOnChange.current(newValue);
          }
        }}
        min={50}
        max={2000}
        getAriaValueText={(x) => `${x}m`}
        valueLabelDisplay="auto"
        classes={{
          rail: classes.rail,
          thumb: classes.thumb,
          mark: classes.mark,
          markActive: classes.markActive,
          markLabelActive: classes.markLabelActive,
        }}
      />
    </div>
  );
}
