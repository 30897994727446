import React, {
  useContext, useRef, useState,
} from 'react';
import {
  Button, CircularProgress, IconButton, makeStyles, MenuItem, TextField, Typography,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { ProviderContext } from '../context';
import CustomAutocomplete from '../common/CustomAutocomplete';
import { IProvider } from '../interfaces';
import { axios } from '../utils';
import { ReactComponent as ChevronBig } from '../assets/chevron-big.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 32,
  },
  providerSelect: {
    width: 300,
    marginBottom: 24,
  },
  select: {
    width: 120,
    marginBottom: 24,
  },
  fileInput: {
    display: 'none',
  },
  name: {
    fontWeight: 400,
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 10px)',
    top: 'calc(50% - 10px)',
  },
  selectRow: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  progressBar: {
    width: 260,
    height: 6,
    borderRadius: 10,
    backgroundColor: '#EFEFEF',
    position: 'relative',
    marginTop: 14,
  },
  barFilled: {
    height: '100%',
    backgroundColor: '#A1145C',
    borderRadius: 10,
    transition: theme.transitions.create('width'),
  },
  progressTooltipContainer: {
    position: 'absolute',
    top: -8,
    transform: 'translate(-50%, -100%)',
    transition: theme.transitions.create('left'),
  },
  progressTooltip: {
    padding: '0 8px',
    backgroundColor: '#A1145C',
    borderRadius: 3,
    fontWeight: 400,
    color: 'white',
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '50%',
      bottom: 0,
      transform: 'translate(-50%, 50%) rotate(45deg)',
      width: 8,
      height: 8,
      backgroundColor: '#A1145C',
      zIndex: -1,
    },
  },
  selectButton: {
    marginRight: 40,
    flexShrink: 0,
  },
  importInfo: {
    color: '#A1145C',
    marginRight: 8,
  },
  chevron: {
    transform: 'rotate(-90deg)',
    transition: '0.4s',
    width: 19,
    height: 19,
  },
  chevronFlipped: {
    transform: 'rotate(90deg)',
    transition: '0.4s',
    width: 19,
    height: 19,
  },
  errorRow: {
    display: 'flex',
    alignItems: 'center',
  },
  importResult: {
    marginTop: 8,
  },
}));

type IFileType = 'gtfs' | 'netex' | '';

export default function Import() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [fileType, setFileType] = useState<IFileType>('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { user, providers } = useContext(ProviderContext);
  const [selectedProvider, setSelectedProvider] = useState<IProvider | null>(
    user.role === 'AGENCY_ADMIN' ? providers.find(R.whereEq({ id: user.agency })) || null : null,
  );
  const [importedFile, setImportedFile] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);
  const [queued, setQueued] = useState(false);
  const [errorExpanded, setErrorExpanded] = useState(false);
  if (!providers) {
    return null;
  }
  return (
    <div className={classes.root}>
      {user.role === 'ADMIN' && (
        <CustomAutocomplete
          options={providers.filter((x) => x.dataSource === 'auto')}
          value={selectedProvider}
          onChange={(event, value) => setSelectedProvider(value)}
          label={t('export.dataSource')}
          className={classes.providerSelect}
          getOptionLabel={R.prop('name')}
        />
      )}
      <TextField
        select
        label={t('import.fileType')}
        value={fileType}
        onChange={({ target }) => (
          setFileType(target.value as IFileType)
        )}
        className={classes.select}
        variant="outlined"
        size="small"
        id="import-file-type"
      >
        <MenuItem value="gtfs">
          GTFS
        </MenuItem>
        <MenuItem value="netex">
          NeTEx
        </MenuItem>
      </TextField>
      <input
        type="file"
        accept=".zip"
        className={classes.fileInput}
        ref={inputRef}
        onChange={async ({ target }) => {
          if (target.files && target.files[0] && selectedProvider) {
            const form = new FormData();
            form.append('file', target.files[0]);
            form.append('type', fileType);
            try {
              setLoading(true);
              setError(null);
              setImportedFile(null);
              const { data: { job } } = await axios.post(`/${selectedProvider.id}/import`, form);
              const { name } = target.files[0];
              let interval: NodeJS.Timeout;
              let timeout: NodeJS.Timeout;
              let delay = 500;
              let currentProgress: number | null = null;
              const checkStatus = async () => {
                const { data } = await axios.get('/import/status', { params: { job } });
                if (data.status === 'IN_PROGRESS') {
                  setQueued(false);
                  if (currentProgress === null || data.existingProgress > currentProgress) {
                    setProgress(data.existingProgress);
                    currentProgress = data.existingProgress;
                    clearTimeout(timeout);
                    delay = 500;
                    const updateProgress = () => {
                      setProgress((x) => {
                        if (x === null || x < data.nextProgress) {
                          delay *= 1.2;
                          timeout = setTimeout(updateProgress, delay);
                          return (x || 0) + 1;
                        }
                        return x;
                      });
                    };
                    timeout = setTimeout(updateProgress, delay);
                  }
                } else if (data.status === 'QUEUED') {
                  setQueued(true);
                } else if (data.status === 'DONE' && target.files) {
                  setQueued(false);
                  setProgress(100);
                  clearInterval(interval);
                  clearTimeout(timeout);
                  setTimeout(() => {
                    setImportedFile(name);
                    setProgress(null);
                  }, 2000);
                } else if (data.status === 'FAILED') {
                  setQueued(false);
                  setError(data.errorDetails);
                  setProgress(null);
                  clearInterval(interval);
                  clearTimeout(timeout);
                }
              };
              checkStatus();
              interval = setInterval(checkStatus, 5000);
              setQueued(true);
            } catch (e) {
              setError(e?.response?.data?.message || 'Error');
            }
            // eslint-disable-next-line no-param-reassign
            target.value = '';
            setLoading(false);
          }
        }}
      />
      <div className={classes.selectRow}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => inputRef.current && inputRef.current.click()}
          disabled={!selectedProvider || !fileType || loading || queued || progress !== null}
          className={classes.selectButton}
        >
          {t('import.selectFile')}
          {loading && (
            <CircularProgress
              size={20}
              color="primary"
              className={classes.loader}
            />
          )}
        </Button>
        <div className={classes.importResult}>
          {progress !== null && (
            <div className={classes.progressBar}>
              <div
                className={classes.barFilled}
                style={{ width: `${progress}%` }}
              />
              <div
                className={classes.progressTooltipContainer}
                style={{ left: `${progress}%` }}
              >
                <Typography className={classes.progressTooltip}>
                  {`${progress}%`}
                </Typography>
              </div>
            </div>
          )}
          {queued && (
            <Typography className={classes.importInfo}>
              {t('import.importQueued')}
            </Typography>
          )}
          {importedFile && (
            <Typography className={classes.importInfo}>
              <Trans
                i18nKey="import.fileImported"
                components={[<span className={classes.name} />]}
                values={{ name: importedFile }}
              />
            </Typography>
          )}
          {error && (
            <div>
              <div className={classes.errorRow}>
                <Typography className={classes.importInfo}>
                  {t('import.importFailed')}
                </Typography>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => setErrorExpanded((x) => !x)}
                >
                  <ChevronBig
                    className={errorExpanded ? classes.chevron : classes.chevronFlipped}
                  />
                </IconButton>
              </div>
              {errorExpanded && (
                <Typography>
                  {error}
                </Typography>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
