import { useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as R from 'ramda';
import { auth0, axios, useGetLink } from '../utils';
import AppContext from '../context';

interface Props extends RouteComponentProps {}

export default function Callback({ history }: Props) {
  const getLink = useGetLink();
  const { updateContext } = useContext(AppContext);
  useEffect(() => {
    auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        const { accessToken } = authResult;
        auth0.client.userInfo(accessToken, (err2, user) => {
          if (user.email_verified) {
            (async () => {
              localStorage.setItem('accessToken', accessToken);
              const [
                { data },
                { data: { interconnectNumber } },
              ] = await Promise.all([
                axios.get('/users/me'),
                axios.get('/user/account'),
              ]);
              const agency = localStorage.getItem('agency');
              updateContext(R.mergeLeft({
                user: {
                  ...data,
                  agency: data.agency || agency,
                },
                name: user.name,
                interconnectNumber,
              }));
              if (['ADMIN', 'AGENCY_ADMIN', 'AGENCY_EMPL'].includes(data.role)) {
                history.push(getLink('/provider'));
              } else if (['CARD_OFFERS_MANAGER', 'SITE_MANAGER', 'CARD_SELLER'].includes(data.cardRole)) {
                history.push(getLink('/card'));
              } else {
                history.push(getLink('/'));
              }
            })();
          } else {
            history.push(getLink('/auth/login'), {
              notVerified: true,
              accessToken,
            });
          }
        });
      } else {
        // Handle failure
      }
    });
  }, [getLink, history, updateContext]);
  return null;
}
