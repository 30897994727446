import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

export default function NumberField({ inputProps, ...props }: TextFieldProps) {
  return (
    <TextField
      {...props}
      inputProps={{
        ...inputProps,
        onKeyPress: (event) => {
          if (!event.key.match(/(\d|-|\.)/)) {
            event.preventDefault();
          }
        },
      }}
    />
  );
}
