import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  IconButton, InputAdornment, TextField, Checkbox, FormControlLabel, Button, Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import * as R from 'ramda';
import NewHeader from '../provider/NewHeader';
import { ReactComponent as Search } from '../assets/search.svg';
import { ReactComponent as ChevronPrimary } from '../assets/chevron-primary.svg';
import { ReactComponent as Clear } from '../assets/clear.svg';
import { ReactComponent as Checkmark } from '../assets/checkmark.svg';
import SelectLocation from '../common/SelectLocation';
import DeleteDialog from '../provider/DeleteDialog';
import { axios } from '../utils';
import { IProvider, IStop } from '../interfaces';
import CustomAutocomplete from '../common/CustomAutocomplete';

const useStyles = makeStyles({
  root: {
    marginTop: 24,
    display: 'flex',
  },
  search: {
    width: 400,
    marginTop: 16,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 24,
    backgroundColor: '#F5F5F5',
    border: '1px solid #37001F',
  },
  cell: {
    padding: '6px 12px',
    fontSize: 16,
    color: '#37001F',
    fontWeight: 300,
    border: '1px solid #37001F',
    borderWidth: '0 1px',
    '&:first-child': {
      textAlign: 'right',
    },
    '&:last-child': {
      textAlign: 'center',
    },
  },
  headerCell: {
    fontWeight: 400,
    textAlign: 'left',
    border: '1px solid #37001F',
  },
  headerCellContent: {
    display: 'flex',
    alignItems: 'center',
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-child(odd)': {
      backgroundColor: 'white',
    },
  },
  sortButton: {
    marginRight: -4,
    marginLeft: 4,
  },
  chevronFlipped: {
    transform: 'rotate(180deg)',
  },
  left: {
    flexGrow: 1,
  },
  right: {
    width: 620,
    flexShrink: 0,
    marginLeft: 24,
  },
  fieldRow: {
    display: 'flex',
    marginBottom: 24,
  },
  beaconID: {
    width: 160,
    marginRight: 24,
  },
  name: {
    width: 300,
  },
  field: {
    marginBottom: 24,
  },
  saveRow: {
    display: 'flex',
    alignItems: 'center',
  },
  poiContainer: {
    marginBottom: 16,
  },
  discard: {
    marginLeft: 'auto',
    marginRight: 24,
  },
  dataSource: {
    width: 300,
    marginRight: 16,
  },
  stop: {
    width: 300,
  },
  add: {
    display: 'flex',
    marginLeft: 'auto',
    marginBottom: 16,
  },
  stopRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    backgroundColor: '#F5F5F5',
    borderRadius: 15,
  },
  stopName: {
    fontSize: 20,
    color: '#37001F',
    marginLeft: 16,
  },
  assignedTo: {
    fontWeight: 400,
    color: '#37001F',
    margin: '-8px 0 16px',
  },
  organization: {
    width: 300,
    marginRight: 16,
  },
  site: {
    width: 300,
  },
});

interface IStationaryBeacon {
  id: number;
  name: string;
  site?: number;
  lat: number;
  lon: number;
  type: 'stop' | 'info';
  welcomePL?: string;
  welcomeEN?: string;
  stops?: {
    agency: string;
    stopId: string;
  }[];
}

interface ISite {
  id: number;
  name: string;
  organization: string;
}

export default function Stationary() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [beacons, setBeacons] = useState<IStationaryBeacon[] | null>(null);
  const [providers, setProviders] = useState<IProvider[] | null>(null);
  const [sites, setSites] = useState<ISite[] | null>(null);
  const [providerStops, setProviderStops] = useState<Record<string, IStop[]>>({});
  useEffect(() => {
    (async () => {
      const [
        { data },
        { data: providersData },
        { data: sitesData },
      ] = await Promise.all([
        axios.get('/beacons?category=stationary'),
        axios.get('/providers'),
        axios.get('/card/sites'),
      ]);
      setBeacons(data);
      setProviders(providersData);
      setSites(sitesData);
      const stopProviders: string[] = R.uniq(data.flatMap((x: IStationaryBeacon) => (
        x.stops?.map(R.prop('agency')) || []
      )));
      const responses = await Promise.all(stopProviders.map((x) => axios.get(`/${x}/stops`)));
      setProviderStops(R.fromPairs(
        stopProviders.map((provider, i) => [provider, responses[i].data]),
      ));
    })();
  }, []);
  const [search, setSearch] = useState('');
  const [sortColumn, setSortColumn] = useState<keyof IStationaryBeacon>('id');
  const [sortAsc, setSortAsc] = useState(false);
  const [selectedBeacon, setSelectedBeacon] = useState<number | 'new' | null>(null);
  const [poi, setPoi] = useState(false);
  const [id, setId] = useState('');
  const [idError, setIdError] = useState(false);
  const [name, setName] = useState('');
  const [organization, setOrganization] = useState<string | null>(null);
  const [site, setSite] = useState<ISite | null>(null);
  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');
  const [welcomeMessagePL, setWelcomeMessagePL] = useState('');
  const [welcomeMessageEN, setWelcomeMessageEN] = useState('');
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [stop, setStop] = useState<IStop | null>(null);
  const [stops, setStops] = useState<{ agency: string; stopId: string; }[]>([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  if (!beacons || !providers || !sites) return null;
  const loadData = (beacon: IStationaryBeacon | null) => {
    if (beacon) {
      setSelectedBeacon(beacon.id);
      setPoi(beacon.type === 'info');
      setId(String(beacon.id));
      setName(beacon.name);
      setLat(String(beacon.lat));
      setLon(String(beacon.lon));
      setWelcomeMessagePL(beacon.welcomePL || '');
      setWelcomeMessageEN(beacon.welcomeEN || '');
      setStops(beacon.stops || []);
    } else {
      setSelectedBeacon('new');
      setPoi(false);
      setId('');
      setName('');
      setLat('');
      setLon('');
      setWelcomeMessagePL('');
      setWelcomeMessageEN('');
      setStops([]);
    }
    if (beacon?.site) {
      const siteObj = sites.find(R.propEq('id', beacon.site)) as ISite;
      setOrganization(siteObj.organization);
      setSite(siteObj);
    } else {
      setOrganization(null);
      setSite(null);
    }
    setIdError(false);
  };
  const columnNames = {
    id: 'beacon.beaconID',
    name: 'beacon.name',
    lat: 'common.latitude',
    lon: 'common.longitude',
    poi: 'beacon.poi',
  };
  const tableData = beacons
    .filter((row) => Object.values(row).some((cell) => (
      String(cell).toLowerCase().includes(search.toLowerCase())
    )))
    .sort((sortAsc ? R.ascend : R.descend)((x) => x[sortColumn]));
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <NewHeader
          headerText={t('beacon.stationaryBeacons')}
          newText={t('beacon.newBeacon')}
          onClick={() => loadData(null)}
        />
        <TextField
          label={t('beacon.stationarySearch')}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          className={classes.search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
        />
        <table className={classes.table}>
          <thead>
            <tr>
              {Object.entries(columnNames).map(([property, columnName]) => (
                <th
                  key={property}
                  className={classNames(classes.cell, classes.headerCell)}
                >
                  <div className={classes.headerCellContent}>
                    {t(columnName)}
                    <IconButton
                      color="primary"
                      size="small"
                      className={classes.sortButton}
                      onClick={() => (
                        sortColumn === property
                          ? setSortAsc(!sortAsc)
                          : setSortColumn(property as keyof IStationaryBeacon)
                      )}
                    >
                      <ChevronPrimary
                        className={(sortColumn === property && sortAsc) ? classes.chevronFlipped : ''}
                      />
                    </IconButton>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row) => (
              <tr
                className={classes.tableRow}
                key={row.id}
                onClick={() => loadData(row)}
              >
                {Object.keys(columnNames).map((property) => (
                  <td className={classes.cell} key={property}>
                    {property === 'poi' ? (
                      <>
                        {row.type === 'info' && (
                          <Checkmark />
                        )}
                      </>
                    ) : row[property as keyof IStationaryBeacon]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.right}>
        {selectedBeacon !== null && (
          <>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={poi}
                  onChange={((event, checked) => setPoi(checked))}
                  color="primary"
                />
              )}
              label={t('beacon.locatedAtPoint')}
              className={classes.poiContainer}
            />
            <div className={classes.fieldRow}>
              <TextField
                value={id}
                onChange={({ target }) => {
                  setId(target.value);
                  setIdError(false);
                }}
                label={t('beacon.beaconID')}
                variant="outlined"
                size="small"
                className={classes.beaconID}
                error={idError}
                helperText={idError ? t('beacon.idError', { from: 0, to: 32767 }) : ''}
              />
              <TextField
                value={name}
                onChange={({ target }) => setName(target.value)}
                label={t('beacon.beaconName')}
                variant="outlined"
                size="small"
                className={classes.name}
              />
            </div>
            {poi ? (
              <>
                <Typography className={classes.assignedTo}>
                  {t('beacon.assignedTo')}
                </Typography>
                <div className={classes.fieldRow}>
                  <CustomAutocomplete
                    options={R.uniq(sites.map(R.prop('organization')))}
                    value={organization}
                    onChange={(event, value) => {
                      setOrganization(value);
                      setSite(null);
                    }}
                    label={t('beacon.organization')}
                    className={classes.organization}
                  />
                  <CustomAutocomplete
                    options={sites.filter(R.whereEq({ organization }))}
                    value={site}
                    onChange={(event, value) => setSite(value)}
                    label={t('beacon.site')}
                    className={classes.site}
                    getOptionLabel={R.prop('name')}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={classes.fieldRow}>
                  <CustomAutocomplete
                    options={providers}
                    value={provider}
                    onChange={async (event, value) => {
                      setProvider(value);
                      setStop(null);
                      if (value && !providerStops[value.id]) {
                        const { data } = await axios.get(`/${value.id}/stops`);
                        setProviderStops(R.set(
                          R.lensProp(value.id),
                          data,
                        ));
                      }
                    }}
                    label={t('beacon.dataSource')}
                    getOptionLabel={R.prop('name')}
                    className={classes.dataSource}
                  />
                  <CustomAutocomplete
                    options={(provider && providerStops[provider?.id]) || []}
                    value={stop}
                    onChange={(event, value) => setStop(value)}
                    label={t('beacon.stop')}
                    getOptionLabel={R.prop('name')}
                    className={classes.stop}
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.add}
                  disabled={!stop}
                  onClick={() => {
                    if (!provider || !stop) return;
                    setStops(R.append({ agency: provider.id, stopId: stop.id }));
                    setProvider(null);
                    setStop(null);
                  }}
                >
                  {t('beacon.add')}
                </Button>
                {stops.map(({ agency, stopId }) => {
                  const agencyName = providers.find(R.propEq('id', agency))!.name;
                  const stopName = providerStops[agency].find(R.propEq('id', stopId))!.name;
                  return (
                    <div className={classes.stopRow} key={stopName}>
                      <Typography className={classes.stopName}>
                        {`${agencyName}, ${stopName}`}
                      </Typography>
                      <IconButton
                        color="primary"
                        onClick={() => setStops(stops.filter(
                          (x) => !(x.agency === agency && x.stopId === stopId),
                        ))}
                      >
                        <Clear />
                      </IconButton>
                    </div>
                  );
                })}
              </>
            )}
            <SelectLocation
              lat={lat}
              setLat={setLat}
              lon={lon}
              setLon={setLon}
            />
            {poi && (
              <>
                <TextField
                  value={welcomeMessagePL}
                  onChange={({ target }) => setWelcomeMessagePL(target.value)}
                  label={t('beacon.welcomeMessagePL')}
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.field}
                />
                <TextField
                  value={welcomeMessageEN}
                  onChange={({ target }) => setWelcomeMessageEN(target.value)}
                  label={t('beacon.welcomeMessageEN')}
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.field}
                />
              </>
            )}
            <div className={classes.saveRow}>
              {selectedBeacon !== 'new' && (
                <Button
                  color="primary"
                  onClick={() => setDeleteDialog(true)}
                >
                  {t('beacon.deleteBeacon')}
                </Button>
              )}
              <Button
                color="primary"
                onClick={() => loadData(beacons.find(R.propEq('id', selectedBeacon)) || null)}
                className={classes.discard}
              >
                {t('common.discardChanges')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={(
                  !id || !name || !lat || !lon
                  || (poi ? (!welcomeMessagePL || !welcomeMessageEN) : (stops.length === 0))
                )}
                onClick={async () => {
                  const numberId = Number(id);
                  if (Number.isNaN(numberId) || numberId < 0 || numberId > 32767) {
                    setIdError(true);
                    return;
                  }
                  const data: IStationaryBeacon = {
                    id: numberId,
                    name,
                    type: poi ? 'info' : 'stop',
                    lat: Number(lat),
                    lon: Number(lon),
                    ...(poi ? {
                      welcomePL: welcomeMessagePL,
                      welcomeEN: welcomeMessageEN,
                      site: site?.id,
                    } : { stops }),
                  };
                  await axios.put('/beacon', data);
                  if (selectedBeacon === 'new') {
                    setBeacons([...beacons, data]);
                  } else {
                    setBeacons(beacons.map((x) => (x.id === numberId ? data : x)));
                  }
                  setSelectedBeacon(null);
                }}
              >
                {t('common.save')}
              </Button>
            </div>
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={t('beacon.deleteBeaconConfirm')}
        onDelete={async () => {
          await axios.delete('/beacon', { params: { id: selectedBeacon } });
          setDeleteDialog(false);
          setSelectedBeacon(null);
          setBeacons(beacons.filter((x) => x.id !== selectedBeacon));
        }}
        canDelete
      />
    </div>
  );
}
