import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button, TextField, Typography, FormHelperText, FormControlLabel, Checkbox, Link as MuiLink,
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { validate as isValidEmail } from 'email-validator';
import { Link, useLocation, useHistory } from 'react-router-dom';
import CustomAutocomplete from '../CustomAutocomplete';
import { useGetLink, axios, auth0 } from '../../utils';
import AppContext from '../../context';

const useStyles = makeStyles({
  root: {
    margin: '32px 64px',
    '@media (max-width: 1200px)': {
      margin: '32px 16px',
    },
  },
  header: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 24,
  },
  shortField: {
    display: 'flex',
    width: '100%',
    maxWidth: 380,
    marginBottom: 24,
  },
  longField: {
    display: 'flex',
    width: '100%',
    maxWidth: 770,
    marginBottom: 24,
  },
  submitButton: {
    display: 'block',
    width: '100%',
    maxWidth: 380,
  },
  checkboxes: {
    display: 'flex',
    marginBottom: 24,
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  captchaError: {
    marginLeft: 16,
  },
  agreement: {
    margin: '14px 0 0 80px',
    maxWidth: 280,
    '@media (max-width: 1200px)': {
      margin: '24px 0 0 12px',
    },
  },
  agreementLabel: {
    fontSize: 14,
    fontWeight: 300,
    color: '#37001F',
  },
  sent: {
    marginTop: 24,
  },
  checkboxContainer: {
    alignItems: 'flex-start',
  },
  checkbox: {
    marginTop: -8,
  },
});

export default function Contact() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const getLink = useGetLink();
  const { user, showSnackbar } = useContext(AppContext);
  const [app, setApp] = useState<string | null>(null);
  const [appError, setAppError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [subject, setSubject] = useState('');
  const [subjectError, setSubjectError] = useState(false);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const captcha = useRef<any>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [agree, setAgree] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  const [role, setRole] = useState<string | null>(null);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const urlRole = new URLSearchParams(location.search).get('role');
    if (urlRole) {
      setRole(urlRole);
      history.push(location.pathname);
    }
  }, [location, history]);
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      auth0.client.userInfo(accessToken, (err, auth0User) => {
        if (auth0User && auth0User.email) {
          setEmail(auth0User.email);
        }
      });
    }
  }, [user]);
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {t('support.needHelp')}
        <br />
        {t('support.pleaseDescribe')}
      </Typography>
      <CustomAutocomplete
        label={t('support.issueConcerns')}
        options={[
          'passengerPortal',
          'mobileApps',
          'touristCard',
          'providerPortal',
          'serviceApps',
          'cardManagementPortal',
          'inspectorApp',
          'otherApp',
        ]}
        getOptionLabel={(option) => t(`support.${option}`)}
        value={app || undefined}
        onChange={(event, value) => {
          setApp(value);
          setAppError(false);
        }}
        className={classes.shortField}
        disableClearable
        TextFieldProps={{
          error: appError,
          helperText: appError ? t('support.pleaseFill') : '',
        }}
      />
      <TextField
        label={t('support.yourEmail')}
        value={email}
        onChange={({ target }) => {
          setEmail(target.value);
          setEmailError(false);
        }}
        className={classes.shortField}
        variant="outlined"
        size="small"
        error={emailError}
        helperText={emailError ? t('auth.invalidEmail') : ''}
        id="your-email"
      />
      <TextField
        label={t('support.subject')}
        value={subject}
        onChange={({ target }) => {
          setSubject(target.value);
          setSubjectError(false);
        }}
        className={classes.longField}
        variant="outlined"
        size="small"
        error={subjectError}
        helperText={subjectError ? t('support.pleaseFill') : ''}
        id="subject"
      />
      <TextField
        label={t('support.description')}
        value={description}
        onChange={({ target }) => {
          setDescription(target.value);
          setDescriptionError(false);
        }}
        className={classes.longField}
        variant="outlined"
        size="small"
        multiline
        rows={8}
        rowsMax={20}
        error={descriptionError}
        helperText={descriptionError ? t('support.pleaseFill') : ''}
        id="description"
      />
      <div className={classes.checkboxes}>
        <div>
          <ReCAPTCHA
            ref={captcha}
            onChange={(token) => {
              setCaptchaToken(token);
              if (token) {
                setCaptchaError(false);
              }
            }}
            sitekey={process.env.RECAPTCHA_KEY as string}
          />
          {captchaError && (
            <FormHelperText error className={classes.captchaError}>
              {t('support.pleaseFill')}
            </FormHelperText>
          )}
        </div>
        <div className={classes.agreement}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={agree}
                onChange={({ target }) => {
                  setAgree(target.checked);
                  setAgreeError(false);
                }}
                color="primary"
                id="agreement"
                className={classes.checkbox}
              />
            )}
            label={(
              <span className={classes.agreementLabel}>
                <Trans
                  i18nKey="support.agreement"
                  components={[
                    <MuiLink component={Link} to={getLink('/terms-and-conditions')} />,
                    <MuiLink component={Link} to={getLink('/privacy-policy')} />,
                  ]}
                />
              </span>
            )}
            className={classes.checkboxContainer}
          />
          {agreeError && (
            <FormHelperText error>
              {t('support.pleaseFill')}
            </FormHelperText>
          )}
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        className={classes.submitButton}
        onClick={async () => {
          let error = false;
          if (!app) {
            setAppError(true);
            error = true;
          }
          if (!isValidEmail(email)) {
            setEmailError(true);
            error = true;
          }
          if (!subject) {
            setSubjectError(true);
            error = true;
          }
          if (!description) {
            setDescriptionError(true);
            error = true;
          }
          if (!captchaToken) {
            setCaptchaError(true);
            error = true;
          }
          if (!agree) {
            setAgreeError(true);
            error = true;
          }
          if (!error) {
            await axios.post('/public/support', {
              app,
              role: role || user?.role,
              email,
              subject,
              description,
              captchaToken,
              lang: i18n.language,
            });
            setApp(null);
            setEmail('');
            setSubject('');
            setDescription('');
            setCaptchaToken(null);
            captcha.current.reset();
            setAgree(false);
            showSnackbar(t('support.sent'));
          }
        }}
      >
        {t('support.submit')}
      </Button>
    </div>
  );
}
