import React, { useState } from 'react';
import {
  InputAdornment, makeStyles, TextField, TextFieldProps, IconButton,
} from '@material-ui/core';
import * as R from 'ramda';
import { ReactComponent as Eye } from '../assets/eye.svg';
import { ReactComponent as EyeActive } from '../assets/eye-active.svg';

const useStyles = makeStyles({
  button: {
    margin: '0 -12px',
  },
});

export default function PasswordField({ InputProps, ...props }: TextFieldProps) {
  const [visible, setVisible] = useState(false);
  const classes = useStyles();
  return (
    <TextField
      {...props}
      type={visible ? 'text' : 'password'}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment
            position="end"
          >
            <IconButton
              onClick={() => setVisible(R.not)}
              className={classes.button}
            >
              {visible ? <EyeActive /> : <Eye />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
