import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Button, MenuItem, TextField, Typography,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import pdfMake from 'pdfmake/build/pdfmake';
import * as R from 'ramda';
import VersionInfo from '../VersionInfo';
import { ReactComponent as Chevron } from '../../assets/chevron.svg';
import CustomDatePicker from '../../common/CustomDatePicker';
import { axios } from '../../utils';
import { ProviderContext } from '../../context';
import robotoLight from '../../assets/Roboto-Light.ttf';

pdfMake.fonts = {
  Roboto: {
    normal: window.location.origin + robotoLight,
  },
};

type IPeriod = 'thisYear' | 'lastYear' | 'thisMonth' | 'lastMonth' | 'custom';
type IDateRange = { from: Moment | null; to: Moment | null; };
interface IData {
  calendar: string;
  monthly: {
    month: string;
    days: number;
  }[];
  vehicleKilometers: {
    route: string;
    kilometers: number;
  }[];
}

const useStyles = makeStyles({
  root: {
    marginTop: 32,
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    color: '#37001F',
  },
  row: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'flex-start',
  },
  period: {
    width: 300,
  },
  selectIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 4px',
  },
  dates: {
    display: 'flex',
    marginTop: 16,
  },
  datePicker: {
    width: 142,
    '&:not(:last-child)': {
      marginRight: 16,
    },
  },
  button: {
    marginLeft: 32,
  },
});

export default function Reports() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: { agency } } = useContext(ProviderContext);
  const [dateRange, setDateRange] = useState<IDateRange>({
    from: moment().startOf('year'),
    to: moment(),
  });
  const period = (() => {
    if (!dateRange) return '';
    const { from, to } = dateRange;
    if (!from || !to) return 'custom';
    if (to.isSame(moment(), 'month')) {
      if (from.isSame(moment().startOf('year'), 'month')) return 'thisYear';
      if (from.isSame(moment(), 'month')) return 'thisMonth';
    }
    if (from.isSame(moment().subtract(1, 'year').startOf('year'), 'month')
      && to.isSame(moment().subtract(1, 'year').endOf('year'), 'month')
    ) return 'lastYear';
    if (from.isSame(moment().subtract(1, 'month'), 'month')
      && to.isSame(moment().subtract(1, 'month'), 'month')
    ) return 'lastMonth';
    return 'custom';
  })();
  return (
    <div className={classes.root}>
      <VersionInfo />
      <Typography className={classes.header}>
        {t('data.reports')}
      </Typography>
      <div className={classes.row}>
        <div>
          <TextField
            select
            label={t('data.period')}
            value={period}
            onChange={({ target }) => {
              switch (target.value as IPeriod) {
                case 'thisYear':
                  setDateRange({ from: moment().startOf('year'), to: moment() });
                  break;
                case 'lastYear':
                  setDateRange({
                    from: moment().subtract(1, 'year').startOf('year'),
                    to: moment().subtract(1, 'year').endOf('year'),
                  });
                  break;
                case 'thisMonth':
                  setDateRange({ from: moment(), to: moment() });
                  break;
                case 'lastMonth':
                  setDateRange({
                    from: moment().subtract(1, 'month'),
                    to: moment().subtract(1, 'month'),
                  });
                  break;
                case 'custom':
                default:
                  setDateRange({ from: null, to: null });
              }
            }}
            variant="outlined"
            size="small"
            className={classes.period}
            SelectProps={{
              // eslint-disable-next-line react/prop-types
              IconComponent: ({ className, ...rest }) => (
                <div {...rest} className={classNames(className, classes.selectIcon)}>
                  <Chevron />
                </div>
              ),
            }}
          >
            <MenuItem value="thisYear">
              {t('data.thisYear')}
            </MenuItem>
            <MenuItem value="lastYear">
              {t('data.lastYear')}
            </MenuItem>
            <MenuItem value="thisMonth">
              {t('data.thisMonth')}
            </MenuItem>
            <MenuItem value="lastMonth">
              {t('data.lastMonth')}
            </MenuItem>
            <MenuItem value="custom">
              {t('data.custom')}
            </MenuItem>
          </TextField>
          {period === 'custom' && dateRange && (
            <div className={classes.dates}>
              <CustomDatePicker
                className={classes.datePicker}
                label={t('card.from')}
                value={dateRange.from}
                onChange={(newFrom) => setDateRange({ from: newFrom, to: dateRange.to })}
                maxDate={dateRange.to || undefined}
                format="MM.Y"
              />
              <CustomDatePicker
                className={classes.datePicker}
                label={t('card.to')}
                value={dateRange.to}
                onChange={(newTo) => setDateRange({ from: dateRange.from, to: newTo })}
                minDate={dateRange.from || undefined}
                format="MM.Y"
              />
            </div>
          )}
        </div>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={!dateRange.from || !dateRange.to}
          onClick={async () => {
            const { data }: { data: IData[] } = await axios.get(`/${agency}/vehicle-kilometers`, {
              params: {
                from: dateRange.from!.format('MM.Y'),
                to: dateRange.to!.format('MM.Y'),
              },
            });
            const allRoutes = R.uniq(data.flatMap((x) => (
              x.vehicleKilometers.map(R.prop('route'))
            )));
            const toFixed = (x?: number) => (x ? x.toFixed(3) : '-');
            pdfMake.createPdf({
              pageOrientation: 'landscape',
              pageMargins: 10,
              content: [
                {
                  table: {
                    body: [
                      [
                        `${dateRange.from!.format('MM.Y')}-${dateRange.to!.format('MM.Y')}`,
                        {
                          colSpan: data.length,
                          text: t('common.route'),
                        },
                        ...R.times(() => '', data.length - 1),
                        ...allRoutes,
                        t('data.total'),
                      ],
                      ...data.map((x, i) => [
                        {
                          rowSpan: data.length,
                          text: t('data.month'),
                        },
                        ...R.times((j) => ({
                          text: '',
                          rowSpan: data.length - 1 - j,
                        }), i),
                        {
                          colSpan: data.length - i,
                          text: x.calendar.split(/[ -]/).map((y) => R.take(2, y)).join(''),
                        },
                        ...R.times(() => '', data.length - i - 1),
                        ...allRoutes.map((route) => ({
                          alignment: 'right',
                          text: toFixed(x.vehicleKilometers.find(R.propEq('route', route))?.kilometers),
                        })),
                        {
                          alignment: 'right',
                          text: R.sum(x.vehicleKilometers.map(R.prop('kilometers'))).toFixed(3),
                        },
                      ]),
                      ...data[0].monthly.map((y, i) => {
                        const routeKms = allRoutes.map((route) => (
                          R.sum(data.map((x) => (
                            x.monthly[i].days
                            * (x.vehicleKilometers.find(R.propEq('route', route))?.kilometers || 0)
                          )))
                        ));
                        return [
                          y.month,
                          ...data.map((x) => ({
                            alignment: 'right',
                            text: x.monthly[i].days || '-',
                          })),
                          ...routeKms.map((x) => ({
                            alignment: 'right',
                            text: toFixed(x),
                          })),
                          {
                            alignment: 'right',
                            text: R.sum(routeKms).toFixed(3),
                          },
                        ];
                      }),
                      [
                        t('data.total'),
                        ...data.map((x) => ({
                          alignment: 'right',
                          text: R.sum(x.monthly.map(R.prop('days'))),
                        })),
                        ...allRoutes.map((route) => ({
                          alignment: 'right',
                          text: R.sum(data.map((x) => (
                            R.sum(x.monthly.map(R.prop('days')))
                            * (x.vehicleKilometers.find(R.propEq('route', route))?.kilometers || 0)
                          ))).toFixed(3),
                        })),
                        {
                          alignment: 'right',
                          text: R.sum(data.map((x) => R.sum(x.vehicleKilometers.map(
                            (y) => R.sum(x.monthly.map(R.prop('days'))) * y.kilometers,
                          )))).toFixed(3),
                        },
                      ],
                    ],
                  },
                  layout: {
                    vLineWidth: (i) => {
                      if ([
                        0,
                        1,
                        1 + data.length,
                        1 + data.length + allRoutes.length,
                        1 + data.length + allRoutes.length + 1,
                      ].includes(i)) return 1;
                      return 0.5;
                    },
                    hLineWidth: (i) => {
                      if ([
                        0,
                        1,
                        1 + data.length,
                        1 + data.length + data[0].monthly.length,
                        1 + data.length + data[0].monthly.length + 1,
                      ].includes(i)) return 1;
                      return 0.5;
                    },
                  },
                },
              ],
              defaultStyle: {
                fontSize: 10,
              },
            }).open();
          }}
        >
          {t('data.showVehicleKilometre')}
        </Button>
      </div>
    </div>
  );
}
