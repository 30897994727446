import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, IconButton, TextField, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from 'moment';
import NewHeader from '../../NewHeader';
import {
  IDataCalendar, IDataPattern, IDataRoute, IDataStop, IDataTrip,
} from '../../../interfaces';
import { ProviderContext } from '../../../context';
import { axios } from '../../../utils';
import CustomAutocomplete from '../../../common/CustomAutocomplete';
import ButtonList from '../../ButtonList';
import NumberField from '../../../common/NumberField';
import { ReactComponent as DraggableIcon } from '../../../assets/draggable.svg';
import { ReactComponent as Clear } from '../../../assets/clear.svg';
import { ReactComponent as DirectionArrow } from '../../../assets/direction-arrow.svg';
import DeleteDialog from '../../DeleteDialog';

interface ICrew {
  id: number;
  name: string;
  depot: number;
  trips: { id: number; dept: string; }[];
  active: boolean;
}

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    display: 'flex',
  },
  left: {
    flexGrow: 1,
    marginRight: 32,
  },
  right: {
    width: 800,
    flexShrink: 0,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  searchRoute: {
    width: 176,
    marginRight: 20,
  },
  searchDepot: {
    width: 300,
  },
  searchCalendar: {
    width: 300,
    marginBottom: 24,
  },
  name: {
    width: 300,
    marginRight: 24,
  },
  depot: {
    width: 300,
  },
  copyRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px -16px',
    padding: 16,
    backgroundColor: '#F5F5F5',
  },
  copyText: {
    color: '#37001F',
    marginRight: 16,
  },
  copyField: {
    width: 300,
  },
  copyAdd: {
    marginLeft: 'auto',
  },
  addTripText: {
    marginRight: 16,
    color: '#37001F',
  },
  position: {
    width: 58,
    marginRight: 18,
  },
  route: {
    width: 176,
  },
  or: {
    margin: '0 16px',
    color: '#37001F',
  },
  pattern: {
    width: 300,
  },
  startingStop: {
    width: 300,
  },
  departureTime: {
    width: 300,
  },
  addTrip: {
    marginLeft: 'auto',
  },
  discardChanges: {
    margin: '0 20px 0 auto',
  },
  crewTrips: {
    marginBottom: 20,
  },
  crewTrip: {
    margin: '8px 0',
    backgroundColor: '#F5F5F5',
    padding: '8px 16px',
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
  },
  draggableContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
  },
  tripPosition: {
    width: 30,
    fontSize: 20,
    color: '#37001F',
  },
  tripRouteContainer: {
    width: 60,
    display: 'flex',
    alignItems: 'center',
  },
  tripRouteName: {
    padding: '0 4px',
    border: '1px solid #36011F',
    borderRadius: 7,
    fontSize: 14,
    fontWeight: 400,
    color: '#37001F',
  },
  tripTime: {
    width: 60,
    fontSize: 20,
    fontWeight: 400,
    color: '#37001F',
  },
  tripStop: {
    width: 200,
    fontSize: 20,
    color: '#37001F',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tripPattern: {
    width: 100,
    fontSize: 20,
    color: '#37001F',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  patternArrow: {
    marginRight: 4,
  },
});

interface ICrewTrip {
  id: number;
  departure: string;
  calendar: number;
}

export default function Manage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: { agency } } = useContext(ProviderContext);
  const [routes, setRoutes] = useState<IDataRoute[] | null>(null);
  const [calendars, setCalendars] = useState<IDataCalendar[] | null>(null);
  const [patterns, setPatterns] = useState<IDataPattern[] | null>(null);
  const [stops, setStops] = useState<IDataStop[] | null>(null);
  const [trips, setTrips] = useState<IDataTrip[] | null>(null);
  const [crews, setCrews] = useState<ICrew[] | null>(null);
  const [selectedCrew, setSelectedCrew] = useState<number | 'new' | null>(null);
  const [searchRoute, setSearchRoute] = useState<IDataRoute | null>(null);
  const [searchDepot, setSearchDepot] = useState<IDataStop | null>(null);
  const [searchCalendar, setSearchCalendar] = useState<IDataCalendar | null>(null);
  const [name, setName] = useState('');
  const [depot, setDepot] = useState<IDataStop | null>(null);
  const [copyCrew, setCopyCrew] = useState<ICrew | null>(null);
  const [position, setPosition] = useState('1');
  const [route, setRoute] = useState<IDataRoute | null>(null);
  const [pattern, setPattern] = useState<IDataPattern | null>(null);
  const [startingStop, setStartingStop] = useState<IDataStop | null>(null);
  const [trip, setTrip] = useState<ICrewTrip | null>(null);
  const [crewTrips, setCrewTrips] = useState<ICrewTrip[]>([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  useEffect(() => {
    (async () => {
      const [
        { data: routesData },
        { data: calendarsData },
        { data: patternsData },
        { data: stopsData },
        { data: tripsData },
        { data: crewsData },
      ] = await Promise.all([
        axios.get(`/${agency}/data/routes`),
        axios.get(`/${agency}/data/calendars`),
        axios.get(`/${agency}/data/patterns`),
        axios.get(`/${agency}/data/stops`),
        axios.get(`/${agency}/data/trips`),
        axios.get(`/${agency}/transport-tasks`),
      ]);
      setRoutes(routesData);
      setCalendars(calendarsData);
      setPatterns(patternsData);
      setStops(stopsData);
      setTrips(tripsData);
      setCrews(crewsData);
    })();
  }, [agency]);
  useEffect(() => {
    setPosition(String(crewTrips.length + 1));
  }, [crewTrips]);
  if (!routes || !calendars || !patterns || !stops || !trips || !crews) {
    return null;
  }
  const mapTrips = (backendTrips: { id: number; dept: string; }[]) => backendTrips.map((x) => ({
    id: x.id,
    departure: x.dept,
    calendar: trips.find(R.propEq('id', x.id))!.calendar,
  }));
  const loadData = (crew: ICrew | null) => {
    if (crew) {
      setSelectedCrew(crew.id);
      setName(crew.name);
      setDepot(stops.find(R.propEq('id', crew.depot)) as IDataStop);
      setCrewTrips(mapTrips(crew.trips));
    } else {
      setSelectedCrew('new');
      setName('');
      setDepot(null);
      setCrewTrips([]);
    }
    setCopyCrew(null);
    setRoute(null);
    setPattern(null);
    setStartingStop(null);
    setTrip(null);
  };
  const routeTrips = trips.filter((x) => (
    patterns.filter(R.propEq('route', searchRoute?.id)).some(R.propEq('id', x.pattern))
  )).map(R.prop('id'));
  const depotId = (() => {
    if (depot) return depot.id;
    if (crewTrips[0]) {
      const stop = patterns.find(R.propEq(
        'id',
          trips.find(R.propEq('id', crewTrips[0].id))!.pattern,
      ))!.stops[0];
      if (stops.find(R.propEq('id', stop))!.technical) {
        return stop;
      }
    }
    return null;
  })();
  const saveRow = selectedCrew !== null && (
    <div className={classes.row}>
      {selectedCrew !== 'new' && (
        <Button
          color="primary"
          onClick={() => setDeleteDialog(true)}
          disabled={crews.find(R.propEq('id', selectedCrew))!.active}
        >
          {t('data.deleteCrew')}
        </Button>
      )}
      <Button
        color="primary"
        onClick={() => loadData(
          selectedCrew === 'new' ? null : crews.find(R.propEq('id', selectedCrew)) as ICrew,
        )}
        className={classes.discardChanges}
      >
        {t('data.discardChanges')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={!name || !depotId || crewTrips.length < 1}
        onClick={async () => {
          if (depotId === null) return;
          const requestData = {
            name,
            depot: depotId,
            trips: crewTrips.map(({ id, departure }) => ({
              id,
              dept: departure,
            })),
          };
          if (selectedCrew === 'new') {
            const { data } = await axios.post(`/${agency}/transport-tasks`, requestData);
            setCrews([...crews, data]);
          } else {
            await axios.put(`/${agency}/transport-tasks/${selectedCrew}`, requestData);
            setCrews(crews.map((x) => (x.id === selectedCrew ? {
              ...x,
              ...requestData,
            } : x)));
          }
          setSelectedCrew(null);
        }}
      >
        {t('data.save')}
      </Button>
    </div>
  );
  const getTripLabel = (x: ICrewTrip) => (
    `${x.departure}, ${calendars.find(R.propEq('id', x.calendar))!.name}`
  );
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <NewHeader
          headerText={t('data.crews')}
          newText={t('data.newCrew')}
          onClick={() => loadData(null)}
        />
        <div className={classes.row}>
          <CustomAutocomplete
            options={routes}
            value={searchRoute}
            onChange={(event, value) => setSearchRoute(value)}
            label={t('common.route')}
            className={classes.searchRoute}
            getOptionLabel={R.prop('name')}
          />
          <CustomAutocomplete
            value={searchDepot}
            onChange={(event, value) => setSearchDepot(value)}
            options={stops.filter(R.prop('technical'))}
            className={classes.searchDepot}
            label={t('data.depot')}
            getOptionLabel={R.prop('name')}
          />
        </div>
        <CustomAutocomplete
          options={calendars}
          value={searchCalendar}
          onChange={(event, value) => setSearchCalendar(value)}
          className={classes.searchCalendar}
          getOptionLabel={R.prop('name')}
          label={t('data.calendar')}
        />
        <ButtonList
          elements={(
            crews.filter(
              (x) => (
                (!searchRoute || x.trips.some((y) => routeTrips.includes(y.id)))
                && (!searchDepot || x.depot === searchDepot.id)
                && (!searchCalendar || x.trips.some((y) => (
                  trips.find(R.propEq('id', y.id))!.calendar === searchCalendar.id
                )))
              ),
            ).map((crew) => ({
              id: crew.id,
              name: crew.name,
              onClick: () => loadData(crew),
            }))
          )}
          selected={selectedCrew}
        />
      </div>
      <div className={classes.right}>
        {selectedCrew !== null && (
          <>
            <div className={classes.row}>
              <TextField
                value={name}
                onChange={({ target }) => setName(target.value)}
                label={t('data.crewName')}
                className={classes.name}
                variant="outlined"
                size="small"
                id="crew-name"
              />
              <CustomAutocomplete
                value={depot}
                onChange={(event, value) => setDepot(value)}
                options={stops.filter(R.prop('technical'))}
                className={classes.depot}
                label={t('data.depot')}
                getOptionLabel={R.prop('name')}
              />
            </div>
            <div className={classes.copyRow}>
              <Typography className={classes.copyText}>
                {t('data.copyTrips')}
              </Typography>
              <CustomAutocomplete
                value={copyCrew}
                onChange={(event, value) => setCopyCrew(value)}
                options={crews.filter((x) => x.id !== selectedCrew)}
                className={classes.copyField}
                label={t('data.crewName')}
                getOptionLabel={R.prop('name')}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.copyAdd}
                onClick={() => {
                  setCrewTrips(R.uniq([...crewTrips, ...mapTrips(copyCrew!.trips)]));
                  setCopyCrew(null);
                }}
                disabled={!copyCrew}
              >
                {t('data.add')}
              </Button>
            </div>
            <div className={classes.row}>
              <Typography className={classes.addTripText}>
                {t('data.addTrip')}
              </Typography>
              <NumberField
                value={position}
                onChange={({ target }) => setPosition(target.value)}
                label={t('data.no')}
                className={classes.position}
                variant="outlined"
                size="small"
              />
              <CustomAutocomplete
                options={routes}
                value={route}
                onChange={(event, value) => {
                  setRoute(value);
                  setPattern(null);
                  setStartingStop(null);
                  setTrip(null);
                }}
                label={t('common.route')}
                className={classes.route}
                getOptionLabel={R.prop('name')}
              />
            </div>
            <div className={classes.row}>
              <CustomAutocomplete
                options={patterns.filter(R.propEq('route', route?.id))}
                value={pattern}
                onChange={(event, value) => {
                  setPattern(value);
                  if (value) {
                    setStartingStop(stops.find(R.propEq('id', value.stops[0])) as IDataStop);
                  }
                  setTrip(null);
                }}
                label={t('data.patternName')}
                className={classes.pattern}
                getOptionLabel={R.prop('name')}
              />
              <Typography className={classes.or}>
                {t('data.or')}
              </Typography>
              <CustomAutocomplete
                options={R.uniq(patterns.filter(R.propEq('route', route?.id))
                  .map((x) => stops.find(R.propEq('id', x.stops[0])))) as IDataStop[]}
                value={startingStop}
                onChange={(event, value) => {
                  setStartingStop(value);
                  if (value) {
                    const stopPatterns = patterns.filter((x) => (
                      x.route === route?.id && x.stops[0] === value.id
                    ));
                    if (stopPatterns.length === 1) {
                      setPattern(stopPatterns[0]);
                    }
                  }
                  setTrip(null);
                }}
                label={t('data.startingStop')}
                className={classes.startingStop}
                getOptionLabel={R.prop('name')}
              />
            </div>
            <div className={classes.row}>
              <CustomAutocomplete
                options={R.sortBy(getTripLabel, trips.filter((x) => {
                  const filteredPatterns = pattern ? [pattern] : patterns.filter((y) => (
                    y.route === route?.id && y.stops[0] === startingStop?.id
                  ));
                  return filteredPatterns.some(R.propEq('id', x.pattern));
                }).flatMap((x) => {
                  if (x.type === 'SINGLE') {
                    return [R.pick(['id', 'departure', 'calendar'], x)];
                  }
                  return R.unfold(
                    (n) => n.isBefore(moment(x.to, 'HH:mm'))
                      && [n.format('HH:mm'), n.add(x.interval, 'minutes')],
                    moment(x.firstTrip, 'HH:mm'),
                  ).map((departure) => ({
                    ...R.pick(['id', 'calendar'], x),
                    departure,
                  }));
                }))}
                value={trip}
                onChange={(event, value) => setTrip(value)}
                label={t('data.trip')}
                className={classes.departureTime}
                getOptionLabel={getTripLabel}
                getOptionSelected={R.equals}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.addTrip}
                onClick={() => {
                  if (!trip) return;
                  const positionNumber = Number(position);
                  const newPosition = (positionNumber > 0 && positionNumber <= crewTrips.length + 1)
                    ? positionNumber - 1
                    : crewTrips.length;
                  setCrewTrips(R.insert(newPosition, trip));
                }}
                disabled={!trip}
              >
                {t('data.add')}
              </Button>
            </div>
            {saveRow}
            <DragDropContext
              onDragEnd={(result) => {
                if (result.destination) {
                  setCrewTrips(R.move(Number(result.draggableId), result.destination.index));
                }
              }}
            >
              <Droppable droppableId="stops">
                {(provided) => (
                  <div
                    className={classes.crewTrips}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {crewTrips.map(({ id, departure }, i) => (
                      <Draggable
                        draggableId={String(i)}
                        index={i}
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                      >
                        {(draggableProvided) => {
                          const crewTrip = trips.find(R.propEq('id', id)) as IDataTrip;
                          const tripPattern = patterns
                            .find(R.propEq('id', crewTrip.pattern)) as IDataPattern;
                          return (
                            <div
                              ref={draggableProvided.innerRef}
                              className={classes.crewTrip}
                              {...draggableProvided.draggableProps}
                            >
                              <div
                                {...draggableProvided.dragHandleProps}
                                className={classes.draggableContainer}
                              >
                                <DraggableIcon />
                              </div>
                              <Typography className={classes.tripPosition}>
                                {i + 1}
                              </Typography>
                              <div className={classes.tripRouteContainer}>
                                <Typography className={classes.tripRouteName}>
                                  {routes.find(R.propEq('id', tripPattern.route))!.name}
                                </Typography>
                              </div>
                              <Typography className={classes.tripTime}>
                                {departure}
                              </Typography>
                              <Typography className={classes.tripStop}>
                                {stops.find(R.propEq('id', tripPattern.stops[0]))!.name}
                              </Typography>
                              <Typography className={classes.tripPattern}>
                                <DirectionArrow className={classes.patternArrow} />
                                {tripPattern.name}
                              </Typography>
                              <Typography className={classes.tripTime}>
                                {moment(departure, 'HH:mm')
                                  .add(R.last(crewTrip.stops)!.arrival, 'minutes')
                                  .format('HH:mm')}
                              </Typography>
                              <Typography className={classes.tripStop}>
                                {stops.find(R.propEq('id', R.last(tripPattern.stops)))!.name}
                              </Typography>
                              <IconButton
                                onClick={() => setCrewTrips(R.remove(i, 1))}
                                size="small"
                              >
                                <Clear />
                              </IconButton>
                            </div>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {crewTrips.length > 0 && saveRow}
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={t('data.deleteCrewConfirm')}
        onDelete={async () => {
          await axios.delete(`/${agency}/transport-tasks/${selectedCrew}`);
          setSelectedCrew(null);
          setCrews(crews.filter((x) => x.id !== selectedCrew));
          setDeleteDialog(false);
        }}
        canDelete
      />
    </div>
  );
}
