import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import debounce from 'debounce-promise';
import * as R from 'ramda';
import moment, { Moment } from 'moment';
import { IFlight, IPlace } from '../../interfaces';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import { ReactComponent as YourLocation } from '../../assets/your-location.svg';
import { ReactComponent as MarkerOutlined } from '../../assets/marker-outlined.svg';
import { ReactComponent as Home } from '../../assets/home.svg';
import { ReactComponent as Flight } from '../../assets/flight.svg';
import { ReactComponent as FlightBlack } from '../../assets/flight-black.svg';
import { ReactComponent as Delete } from '../../assets/delete.svg';
import { axios } from '../../utils';
import CustomDatePicker from '../../common/CustomDatePicker';

interface Props {
  value: string | IPlace | null;
  onChange: (event: any, value: string | IPlace | null) => void;
  className?: string;
  label?: string;
  myLocation: { lat: number; lon: number; } | null;
  error?: boolean;
  setArrival: (date: Moment) => void;
  isDestination?: boolean;
}

const useStyles = makeStyles({
  option: {
    display: 'flex',
    fontSize: 16,
    color: '#37001F',
    fontWeight: 300,
  },
  optionIcon: {
    width: 24,
    marginRight: 12,
  },
  locationOption: {
    paddingLeft: 36,
    color: '#37001F',
    fontWeight: 300,
  },
  placeLocation: {
    fontSize: 12,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 100,
    backgroundColor: '#A3A2A2AC',
    display: 'flex',
  },
  selectFlight: {
    margin: '16px 32px',
    flexGrow: 1,
    alignSelf: 'flex-start',
    backgroundColor: '#A1145C',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
    '@media (max-width: 800px)': {
      margin: '16px 8px',
    },
  },
  flightHeader: {
    width: '100%',
    backgroundColor: 'white',
    position: 'relative',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 4,
  },
  headerText: {
    fontSize: 26,
    fontWeight: 400,
    color: '#37001F',
    padding: '32px 20px 8px',
    display: 'flex',
    alignItems: 'center',
  },
  flightBlack: {
    marginRight: 4,
  },
  datePicker: {
    marginLeft: 48,
    marginBottom: 16,
  },
  flights: {
    marginBottom: 20,
  },
  flight: {
    padding: 8,
    marginTop: 8,
    backgroundColor: 'white',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    transition: '300ms',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
  flightTime: {
    width: 40,
    fontSize: 14,
    flexShrink: 0,
  },
  destinationContainer: {
    flexGrow: 1,
    marginRight: 4,
  },
  flightDestination: {
    fontWeight: 400,
    fontSize: 14,
  },
  isOnTime: {
    fontWeight: 400,
    fontSize: 14,
  },
  flightNumber: {
    width: 60,
    fontSize: 14,
    marginRight: 4,
    flexShrink: 0,
  },
  airline: {
    width: 90,
    fontSize: 14,
    flexShrink: 0,
  },
});

const airportCoords = {
  lat: 54.382428,
  lon: 18.466252,
};

export default function LocationSearch(props: Props) {
  const {
    value, onChange, label, className, myLocation, error, setArrival, isDestination,
  } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [results, setResults] = useState<IPlace[]>([]);
  const [inputValue, setInputValue] = useState('');
  const onInputChange = useCallback(debounce(async (query: string) => {
    if (query.length >= 3) {
      const { data } = await axios.get('/public/find-places', {
        params: {
          query,
          lang: i18n.language,
          ...(myLocation || {}),
        },
      });
      setResults(data);
    } else {
      setResults([]);
    }
  }, 200), [myLocation, i18n.language]);
  const [date, setDate] = useState<Moment>(moment());
  const [flights, setFlights] = useState<IFlight[]>([]);
  const [selectedFlight, setSelectedFlight] = useState<IFlight | null>(null);
  useEffect(() => {
    (async () => {
      if (value === 'flight') {
        const { data } = await axios.get('/public/find-flights', {
          params: {
            date: date.format('YYYY-MM-DD'),
            lang: i18n.language,
          },
        });
        setFlights(data);
      }
    })();
  }, [date, value, i18n.language]);
  useEffect(() => {
    setSelectedFlight(null);
  }, [value]);
  const [focused, setFocused] = useState(false);
  const getFlightName = useCallback((flight: IFlight | null) => {
    if (!flight) return t('planning.flightFromGdansk');
    const { scheduled, estimated } = flight.dateTime;
    const time = moment(estimated || scheduled);
    return t('planning.flightTo', {
      time: time.format('HH:mm'),
      destination: flight.destination,
    });
  }, [t]);
  const getOptionLabel = useCallback((option: string | IPlace) => {
    if (typeof option === 'string') {
      if (option === 'your-location') return t('planning.yourLocation');
      if (option === 'select-on-map') return t('planning.selectOnMap');
      if (option === 'pin-location') return t('planning.pinLocation');
      if (option === 'home') return t('planning.home');
      if (option === 'flight') return getFlightName(selectedFlight);
      return '';
    }
    return option.name;
  }, [t, getFlightName, selectedFlight]);
  useEffect(() => {
    setInputValue((value && (typeof value !== 'string' || !focused)) ? getOptionLabel(value) : '');
  }, [value, focused, getOptionLabel]);
  const options: (string | IPlace)[] = results.length > 0 ? results : [
    'your-location',
    'select-on-map',
    // 'home',
    ...(isDestination ? ['flight'] : []),
  ];
  return (
    <>
      <CustomAutocomplete
        label={label}
        className={className}
        value={value}
        onChange={onChange}
        noAdornment
        onInputChange={async (event, query) => {
          setInputValue(query);
          await onInputChange(query);
        }}
        TextFieldProps={{
          error,
          InputProps: {
            onFocus: () => setFocused(true),
            onBlur: () => setFocused(false),
          },
          inputProps: { value: inputValue },
        }}
        blurOnSelect
        filterOptions={R.filter<string | IPlace>((option) => options.includes(option))}
        options={R.uniq([
          ...options,
          ...(value ? [value] : []),
        ])}
        getOptionSelected={R.equals}
        getOptionLabel={(option) => (typeof option === 'string' ? '' : option.name)}
        renderOption={(option) => {
          if (typeof option === 'string') {
            return (
              <div className={classes.option}>
                {option === 'your-location' && (
                  <>
                    <div className={classes.optionIcon}>
                      <YourLocation />
                    </div>
                    {t('planning.yourLocation')}
                  </>
                )}
                {option === 'select-on-map' && (
                  <>
                    <div className={classes.optionIcon}>
                      <MarkerOutlined className={classes.optionIcon} />
                    </div>
                    {t('planning.selectOnMap')}
                  </>
                )}
                {option === 'pin-location' && (
                  <>
                    <div className={classes.optionIcon}>
                      <MarkerOutlined className={classes.optionIcon} />
                    </div>
                    {t('planning.pinLocation')}
                  </>
                )}
                {option === 'home' && (
                  <>
                    <div className={classes.optionIcon}>
                      <Home className={classes.optionIcon} />
                    </div>
                    {t('planning.home')}
                  </>
                )}
                {option === 'flight' && (
                  <>
                    <div className={classes.optionIcon}>
                      <Flight className={classes.optionIcon} />
                    </div>
                    {getFlightName(selectedFlight)}
                  </>
                )}
              </div>
            );
          }
          return (
            <div className={classes.locationOption}>
              {option.name}
              <div className={classes.placeLocation}>
                {option.location}
              </div>
            </div>
          );
        }}
      />
      {value === 'flight' && !selectedFlight && (
        <div className={classes.overlay}>
          <div className={classes.selectFlight}>
            <div className={classes.flightHeader}>
              <IconButton
                className={classes.closeButton}
                onClick={() => onChange({}, null)}
              >
                <Delete />
              </IconButton>
              <Typography className={classes.headerText}>
                <FlightBlack className={classes.flightBlack} />
                {t('planning.selectFlight')}
              </Typography>
              <CustomDatePicker
                value={date}
                onChange={(newDate) => newDate && setDate(newDate)}
                className={classes.datePicker}
                label={t('planning.date')}
              />
            </div>
            <div className={classes.flights}>
              {flights.map((flight) => (
                <div
                  key={flight.flightNumber}
                  className={classes.flight}
                  onClick={() => {
                    setSelectedFlight(flight);
                    const { scheduled, estimated } = flight.dateTime;
                    setArrival(moment(estimated || scheduled).subtract(90, 'minutes'));
                    onChange({}, {
                      ...airportCoords,
                      name: getFlightName(flight),
                      type: 'place',
                    });
                  }}
                >
                  <Typography className={classes.flightTime}>
                    {moment(flight.dateTime.scheduled).format('HH:mm')}
                  </Typography>
                  <div className={classes.destinationContainer}>
                    <Typography className={classes.flightDestination}>
                      {flight.destination}
                    </Typography>
                    <Typography className={classes.isOnTime}>
                      {(!flight.dateTime.estimated
                        || flight.dateTime.estimated === flight.dateTime.scheduled)
                        ? t('planning.onTime')
                        : `${t('planning.delayed')} ${moment(flight.dateTime.estimated).format('HH:mm')}`}
                    </Typography>
                  </div>
                  <Typography className={classes.flightNumber}>
                    {flight.flightNumber}
                  </Typography>
                  <Typography className={classes.airline}>
                    {flight.airline}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
