import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { IProvider, IStatistic } from '../interfaces';
import VehicleIcon from './VehicleIcon';
import CustomAutocomplete from '../common/CustomAutocomplete';

interface Props {
  providers: IProvider[],
  data: IStatistic[],
}

const useStyles = makeStyles({
  root: {
    margin: '24px 0',
    display: 'flex',
  },
  select: {
    width: 296,
    marginRight: 24,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    fontWeight: 400,
    color: '#37001F',
  },
  icon: {
    display: 'inline-flex',
    marginRight: 8,
  },
});

export default function StatisticsSearch({ providers, data }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [provider, setProvider] = useState<IProvider | null>(null);
  const result = provider ? data.find(R.eqProps('id', provider)) : null;
  return (
    <div className={classes.root}>
      <CustomAutocomplete
        options={providers}
        value={provider}
        onChange={(event, value) => setProvider(value)}
        className={classes.select}
        label={t('stats.findDataSource')}
        getOptionLabel={R.prop('name')}
      />
      {result && (
        <div>
          {result.routes.map(({ type, count }) => (
            <Typography className={classes.row} key={type}>
              <span className={classes.icon}>
                <VehicleIcon type={type} />
              </span>
              {count}
              {' '}
              {t('stats.routes', { count })}
            </Typography>
          ))}
          <Typography className={classes.row}>
            {result.stops}
            {' '}
            {t('stats.stops', { count: result.stops })}
          </Typography>
        </div>
      )}
    </div>
  );
}
