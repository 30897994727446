import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  text: string;
  onDelete: () => void;
  canDelete: boolean;
}

const useStyles = makeStyles({
  dialog: {
    padding: 24,
  },
  dialogText: {
    color: '#37001F',
    marginBottom: 16,
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: -8,
  },
});

export default function DeleteDialog(props: Props) {
  const {
    open, onClose, text, onDelete, canDelete,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <Typography className={classes.dialogText}>
        {text}
      </Typography>
      <div className={classes.dialogButtons}>
        <Button
          variant="text"
          color="primary"
          onClick={onClose}
        >
          {t('data.cancel')}
        </Button>
        {canDelete && (
          <Button
            variant="text"
            color="primary"
            onClick={onDelete}
          >
            {t('data.delete')}
          </Button>
        )}
      </div>
    </Dialog>
  );
}
