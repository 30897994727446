import React, {
  SyntheticEvent, MouseEvent, useEffect, useState,
} from 'react';
import {
  useHistory, useLocation, useRouteMatch, Route, Redirect, Switch,
} from 'react-router-dom';
import {
  createMuiTheme, CssBaseline, IconButton, Snackbar, ThemeProvider,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import Provider from './provider/Provider';
import Auth from './auth/Auth';
import { auth0, axios, useGetLink } from './utils';
import Callback from './auth/Callback';
import AppContext, { IValue } from './context';
import LoggedInRoute from './common/LoggedInRoute';
import TripPlanning from './passenger/trip-planning/TripPlanning';
import PrivacyPolicy from './passenger/PrivacyPolicy';
import Terms from './passenger/Terms';
import About from './passenger/About';
import TouristCard from './passenger/Card';
import CookieDisclaimer from './CookieDisclaimer';
import Card from './card/Card';
import Support from './passenger/Support';
import Timetables from './passenger/Timetables';
import TripsPrintView from './provider/home/TripsPrintView';
import AssignPrint from './provider/data/crew/AssignPrint';
import Beacon from './beacon/Beacon';

function useLanguageRedirect() {
  const { i18n: { language } } = useTranslation();
  const match = useRouteMatch<{ language: string }>('/:language?');
  const pathLanguage = match ? match.params.language : null;
  const history = useHistory();
  const getLink = useGetLink();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === '/auth/callback') {
      return;
    }
    if (!pathLanguage) {
      history.push(getLink('/'));
    } else if (pathLanguage !== language) {
      history.replace(pathname.replace(/^\/[a-z]+/, `/${language}`));
    }
  }, [language, pathLanguage, history, getLink, pathname]);
}

const isTokenValid = (token: string) => (
  moment().isBefore(moment.unix((jwtDecode(token) as any).exp))
);

export default function App() {
  useLanguageRedirect();
  const { i18n: { language } } = useTranslation();
  useEffect(() => {
    moment.locale(language);
  }, [language]);
  const getLink = useGetLink();
  const [contextValue, setContextValue] = useState<IValue>({
    snackbarOpen: false,
  });
  const [ready, setReady] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(localStorage.getItem('cookies-accepted') !== null);
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && isTokenValid(accessToken)) {
      auth0.client.userInfo(accessToken, (err, user) => {
        (async () => {
          if (user) {
            const [
              { data },
              { data: { interconnectNumber } },
            ] = await Promise.all([
              axios.get('/users/me'),
              axios.get('/user/account'),
            ]);
            const agency = localStorage.getItem('agency');
            setContextValue(R.mergeLeft({
              user: {
                ...data,
                agency: data.agency || agency,
              },
              name: user.name,
              interconnectNumber,
            }));
          } else {
            localStorage.removeItem('accessToken');
          }
          setReady(true);
        })();
      });
    } else {
      localStorage.removeItem('accessToken');
      setReady(true);
    }
  }, []);
  useEffect(() => {
    axios.interceptors.request.use((config) => new Promise((resolve) => {
      if (config.url?.startsWith('/public/') || config.url?.startsWith('https://')) {
        resolve(config);
        return;
      }
      const accessToken = localStorage.getItem('accessToken');
      const setToken = (token: string) => resolve(R.set(
        R.lensPath(['headers', 'Authorization']),
        `Bearer ${token}`,
        config,
      ));
      if (accessToken && isTokenValid(accessToken)) {
        setToken(accessToken);
      } else {
        auth0.checkSession({}, (err, authResult) => {
          if (err) {
            setContextValue(R.set(R.lensProp('name'), null));
            localStorage.removeItem('accessToken');
          } else {
            const { accessToken: newAccessToken } = authResult;
            localStorage.setItem('accessToken', newAccessToken);
            setToken(newAccessToken);
          }
        });
      }
    }));
  }, []);
  const closeSnackbar = (event: SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason !== 'clickaway') {
      setContextValue(R.mergeLeft({ snackbarOpen: false }));
    }
  };
  if (!ready) {
    return null;
  }
  const { user } = contextValue;
  const isOrganizer = user && (user.role === 'ADMIN' || user.role === 'AGENCY_ADMIN' || user.role === 'AGENCY_EMPL');
  return (
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider
        theme={createMuiTheme({
          palette: {
            primary: {
              main: '#a1145c',
            },
          },
          overrides: {
            MuiButton: {
              root: {
                textTransform: 'none',
                fontWeight: 300,
                fontSize: 16,
              },
              contained: {
                borderRadius: 5,
                boxShadow: 'none',
                '&:hover, &:active': {
                  boxShadow: 'none',
                },
              },
            },
            MuiTypography: {
              root: {
                fontWeight: 300,
              },
              body1: {
                fontWeight: 300,
              },
            },
          },
        })}
      >
        <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
          <AppContext.Provider
            value={{
              ...contextValue,
              updateContext: setContextValue,
              showSnackbar: (message) => setContextValue(R.mergeLeft({
                snackbarMessage: message,
                snackbarOpen: true,
              })),
            }}
          >
            <CssBaseline />
            <Switch>
              <Redirect
                from="/:language"
                exact
                to={isOrganizer ? getLink('/provider') : getLink('/trip-planning')}
              />
              <LoggedInRoute
                exact
                path="/:language/provider/print-trips"
                component={TripsPrintView}
              />
              <LoggedInRoute
                exact
                path="/:language/provider/data/crew/print"
                component={AssignPrint}
              />
              <LoggedInRoute
                exact
                path={[
                  '/:language/provider',
                  '/:language/provider/analysis',
                  '/:language/provider/statistics',
                  '/:language/provider/trips/:tab?',
                  '/:language/provider/import',
                  '/:language/provider/export',
                  '/:language/provider/impact-area',
                  '/:language/provider/roles',
                  '/:language/provider/organizations',
                  '/:language/provider/data',
                  '/:language/provider/data/routes',
                  '/:language/provider/data/patterns',
                  '/:language/provider/data/stops',
                  '/:language/provider/data/trips',
                  '/:language/provider/data/calendars',
                  '/:language/provider/data/crew/:tab?',
                  '/:language/provider/data/reports',
                  '/:language/provider/data/tickets',
                  '/:language/provider/data/manage',
                  '/:language/provider/data/timetables',
                  '/:language/provider/data-source',
                  '/:language/provider/support/:tab?',
                ]}
                component={Provider}
              />
              <LoggedInRoute
                exact
                path={[
                  '/:language/card',
                  '/:language/card/organizations',
                  '/:language/card/roles',
                  '/:language/card/packages',
                  '/:language/card/site',
                  '/:language/card/site/attraction/:id',
                  '/:language/card/cards',
                  '/:language/card/statistics/:tab?',
                ]}
                component={Card}
              />
              <LoggedInRoute
                exact
                path={[
                  '/:language/beacon',
                  '/:language/beacon/assignment/:tab?',
                  '/:language/beacon/logs',
                ]}
                component={Beacon}
              />
              <Route
                exact
                path="/:language/trip-planning/:search?"
                component={TripPlanning}
              />
              <Route
                exact
                path="/:language/timetables"
                component={Timetables}
              />
              <Route
                exact
                path="/:language/tourist-card"
                component={TouristCard}
              />
              <Route
                exact
                path="/:language/privacy-policy"
                component={PrivacyPolicy}
              />
              <Route
                exact
                path="/:language/terms-and-conditions"
                component={Terms}
              />
              <Route
                exact
                path="/:language/about-project"
                component={About}
              />
              <Route
                exact
                path="/:language/support/:tab?"
                component={Support}
              />
              <Route
                path={[
                  '/:language/auth/register',
                  '/:language/auth/email-verified',
                  '/:language/auth/login',
                  '/:language/auth/recover-password',
                  '/:language/auth/reset-password',
                ]}
                component={Auth}
              />
              <Route path="/auth/callback" component={Callback} />
            </Switch>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={contextValue.snackbarOpen}
              autoHideDuration={5000}
              onClose={closeSnackbar}
              message={contextValue.snackbarMessage}
              action={(
                <IconButton
                  size="small"
                  onClick={closeSnackbar}
                  color="inherit"
                >
                  <Close fontSize="small" />
                </IconButton>
              )}
            />
            {!cookiesAccepted && (
              <CookieDisclaimer
                onAccept={() => {
                  localStorage.setItem('cookies-accepted', 'true');
                  setCookiesAccepted(true);
                }}
              />
            )}
          </AppContext.Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </DndProvider>
  );
}
