import React, { Dispatch, SetStateAction, useContext } from 'react';
import {
  Button, makeStyles, Typography,
} from '@material-ui/core';
import natsort from 'natsort';
import classNames from 'classnames';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { axios } from '../../utils';
import { IProvider, IRoute } from '../../interfaces';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import CustomDatePicker from '../../common/CustomDatePicker';
import VersionInfo from '../VersionInfo';
import { ProviderContext } from '../../context';

interface Props {
  providers: IProvider[];
  provider: IProvider | null;
  setProvider: Dispatch<SetStateAction<IProvider | null>>;
  routes: IRoute[] | null;
  setRoutes: Dispatch<SetStateAction<IRoute[] | null>>;
  selectedRoutes: string[];
  setSelectedRoutes: (selectedRoutes: string[]) => void;
  date: Moment;
  setDate: (date: Moment) => void;
}

const useStyles = makeStyles({
  root: {
    width: 500,
    height: '100%',
    margin: '0 24px 16px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  providerSelect: {
    width: '100%',
    marginBottom: 16,
  },
  datePicker: {
    width: '100%',
    marginBottom: 16,
  },
  routesLabel: {
    color: '#37001F',
    marginBottom: 8,
  },
  routes: {
    overflowY: 'auto',
    margin: '0 -4px',
  },
  routeButton: {
    backgroundColor: '#F5F5F5',
    minWidth: 'initial',
    margin: '4px',
    borderRadius: 15,
    fontSize: 24,
    fontWeight: 400,
    padding: '1px 18px',
  },
  selectedButton: {
    backgroundColor: '#a1145c',
    color: 'white',
    '&:hover': {
      backgroundColor: '#c62e8a',
    },
  },
});

export default function Left(props: Props) {
  const {
    providers, provider, setProvider, routes, setRoutes, selectedRoutes, setSelectedRoutes,
    date, setDate,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { version, user } = useContext(ProviderContext);
  return (
    <div className={classes.root}>
      <VersionInfo />
      <CustomAutocomplete
        options={providers}
        value={provider}
        onChange={async (event, value) => {
          setProvider(value);
          if (value) {
            const { data } = await axios.get(`/${value.id}/routes`, {
              params: (version && value.id === user.agency) ? { providerVersion: version.id } : {},
            });
            const sorter = natsort();
            setRoutes(data.sort((a: IRoute, b: IRoute) => sorter(a.name, b.name)));
          } else {
            setRoutes(null);
            setSelectedRoutes([]);
          }
        }}
        className={classes.providerSelect}
        label={t('stats.findDataSource')}
        getOptionLabel={R.prop('name')}
      />
      <CustomDatePicker
        value={date}
        onChange={(newDate) => newDate && setDate(newDate)}
        className={classes.datePicker}
        label={t('main.date')}
      />
      {provider && routes && (
        <>
          <Typography className={classes.routesLabel}>
            {t('main.route')}
            :
          </Typography>
          <div className={classes.routes}>
            {routes.map((route) => (
              <Button
                key={route.id}
                variant="contained"
                className={classNames(classes.routeButton, {
                  [classes.selectedButton]: selectedRoutes.includes(route.id),
                })}
                onClick={() => setSelectedRoutes(
                  selectedRoutes.includes(route.id)
                    ? selectedRoutes.filter((x) => x !== route.id)
                    : [...selectedRoutes, route.id],
                )}
              >
                {route.name || route.id}
              </Button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
