import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Button, Checkbox, FormControlLabel, IconButton, TextField, Typography,
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import moment, { Moment } from 'moment';
import * as R from 'ramda';
import classNames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';
import { TimePicker } from '@material-ui/pickers';
import sanitizeHtml from 'sanitize-html';
import ImageUpload from '../common/ImageUpload';
import NewHeader from '../provider/NewHeader';
import { ReactComponent as Clear } from '../assets/clear.svg';
import { ReactComponent as ClearWhite } from '../assets/clear-white.svg';
import {
  IAttractionAttachment, IAttractionAttachmentUpdate, IAttractionPath, IAttractionPathUpdate,
  IAttractionUpdate, IOpeningHours, IOpeningHoursUpdate, IShapePoint, ISiteAttraction, IWeekDay,
} from '../interfaces';
import CustomDatePicker from '../common/CustomDatePicker';
import { axios, fileToBase64, useGetLink } from '../utils';
import AppContext, { CardContext } from '../context';
import DeleteDialog from '../provider/DeleteDialog';
import PathSelect from '../common/PathSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 32,
    width: '100%',
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    color: '#37001F',
    marginBottom: 24,
  },
  container: {
    display: 'flex',
  },
  left: {
    width: '50%',
    marginRight: 24,
  },
  right: {
    width: '50%',
  },
  row: {
    display: 'flex',
    margin: '0 -12px 24px',
  },
  shortField: {
    width: 'calc(50% - 24px)',
    margin: '0 12px',
  },
  description: {
    height: 200,
    paddingBottom: 42,
    marginBottom: 24,
    backgroundColor: 'white',
  },
  descriptionLabel: {
    fontSize: 14,
    fontWeight: 400,
    margin: '0 0 8px 12px',
  },
  subheader: {
    fontWeight: 400,
    marginBottom: 8,
  },
  checkboxRow: {
    display: 'flex',
    marginBottom: 24,
  },
  formControlLabel: {
    marginRight: 24,
  },
  saveRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 24,
  },
  discardChanges: {
    marginRight: 16,
  },
  section: {
    padding: 16,
    margin: '16px -16px',
    backgroundColor: '#F5F5F5',
  },
  guide: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 15,
    marginBottom: 8,
    border: '1px solid #9E9E9E',
    transition: theme.transitions.create('background-color'),
  },
  numberOf: {
    width: 40,
    fontSize: 20,
    color: '#37001F',
    marginLeft: 16,
    flexShrink: 0,
    transition: theme.transitions.create('color'),
  },
  guidePreview: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: 30,
    alignSelf: 'stretch',
    flexShrink: 0,
    margin: '4px 16px 4px 0',
  },
  guideName: {
    fontSize: 20,
    color: '#37001F',
    margin: '4px auto 4px 0',
    transition: theme.transitions.create('color'),
  },
  sectionNameField: {
    width: 'calc(50% - 24px)',
    margin: '0 12px',
    backgroundColor: 'white',
  },
  guideRow: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  addButton: {
    marginLeft: 24,
  },
  openingHoursSection: {
    padding: 20,
    margin: '16px -16px',
    backgroundColor: '#F5F5F5',
  },
  hoursData: {
    padding: 16,
    border: '1px solid #707070',
    borderRadius: 15,
    position: 'relative',
    marginBottom: 16,
  },
  deleteOpeningHours: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 4,
  },
  hoursSubheader: {
    fontWeight: 400,
    marginBottom: 16,
  },
  elements: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-8px -8px 16px -8px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  outlinedElement: {
    padding: '6px 14px',
    border: '1px solid #9E9E9E',
    borderRadius: 15,
    fontSize: 20,
    color: '#37001F',
    display: 'flex',
    alignItems: 'center',
    margin: 8,
  },
  elementContent: {
    fontSize: 'inherit',
    color: 'inherit',
  },
  weekDays: {
    fontSize: 24,
    fontWeight: 400,
    color: '#37001F',
    marginBottom: 24,
  },
  remarks: {
    fontSize: 16,
    color: '#37001F',
    marginBottom: 24,
  },
  hoursDataRight: {
    width: 'calc(50% - 12px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fieldRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  timePicker: {
    width: 85,
    backgroundColor: 'white',
  },
  hoursDash: {
    margin: '0 16px',
  },
  removeElement: {
    margin: '-8px -12px -8px 4px',
  },
  dayButton: {
    fontSize: 24,
    fontWeight: 400,
    marginRight: 8,
    marginBottom: 32,
    borderRadius: 15,
    padding: '1px 18px',
    backgroundColor: '#F5F5F5',
  },
  buttonSelected: {
    backgroundColor: '#A1145C',
    color: 'white',
    '&:hover': {
      backgroundColor: '#c62e8a',
    },
  },
  datePicker: {
    marginRight: 16,
    backgroundColor: 'white',
  },
  openingHoursRow: {
    display: 'flex',
  },
  map: {
    width: '100%',
    height: 300,
    marginBottom: 24,
  },
  guideSelected: {
    backgroundColor: '#a1145c',
  },
  guideSelectedText: {
    color: 'white',
  },
}));

const formatDate = (date: string) => moment(date).format('DD.MM.YYYY');
const formatTimeISO = (date: Moment) => date.format('HH:mm');
const formatDateISO = (date: Moment) => date.format('YYYY-MM-DD');

export default function Attraction() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppContext);
  const { user } = useContext(CardContext);
  const getLink = useGetLink();
  const [mainCoords, setMainCoords] = useState<IShapePoint>();
  const [namePL, setNamePL] = useState('');
  const [nameEN, setNameEN] = useState('');
  const [descPL, setDescPL] = useState('');
  const [descEN, setDescEN] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [accessible, setAccessible] = useState(false);
  const [elevator, setElevator] = useState(false);
  const [escalator, setEscalator] = useState(false);
  const [newGuide, setNewGuide] = useState(false);
  const [guideNamePL, setGuideNamePL] = useState('');
  const [guideNameEN, setGuideNameEN] = useState('');
  const [guideImage, setGuideImage] = useState<File | null>(null);
  const [guideUrl, setGuideUrl] = useState<string | null>(null);
  const [guides, setGuides] = useState<IAttractionAttachment[]>([]);
  const [selectedPath, setSelectedPath] = useState<number | 'new' | null>(null);
  const [pathNamePL, setPathNamePL] = useState('');
  const [pathNameEN, setPathNameEN] = useState('');
  const [pathStartPL, setPathStartPL] = useState('');
  const [pathStartEN, setPathStartEN] = useState('');
  const [pathEndPL, setPathEndPL] = useState('');
  const [pathEndEN, setPathEndEN] = useState('');
  const [pathPoints, setPathPoints] = useState<IShapePoint[]>([]);
  const [paths, setPaths] = useState<IAttractionPath[]>([]);
  const [selectedHours, setSelectedHours] = useState<number | 'new' | null>(null);
  const [from, setFrom] = useState<Moment | null>(null);
  const [to, setTo] = useState<Moment | null>(null);
  const [hours, setHours] = useState<{ from: string; to: string; }[]>([]);
  const [remarksPL, setRemarksPL] = useState('');
  const [remarksEN, setRemarksEN] = useState('');
  const [days, setDays] = useState<IWeekDay[]>([]);
  const [periodFrom, setPeriodFrom] = useState<Moment | null>(null);
  const [periodTo, setPeriodTo] = useState<Moment | null>(null);
  const [periods, setPeriods] = useState<{ from: string; to: string; }[]>([]);
  const [excluded, setExcluded] = useState<string[]>([]);
  const [openingHours, setOpeningHours] = useState<IOpeningHours[]>([]);
  const [deleteType, setDeleteType] = useState<'guide' | 'hours' | 'path' | null>(null);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [deleteName, setDeleteName] = useState('');
  const [saving, setSaving] = useState(false);
  const [guideSaving, setGuideSaving] = useState(false);
  const [pathSaving, setPathSaving] = useState(false);
  const [hoursSaving, setHoursSaving] = useState(false);
  const loadData = (attraction: ISiteAttraction | null) => {
    if (attraction) {
      setNamePL(attraction.namePL);
      setNameEN(attraction.nameEN);
      setDescPL(attraction.descPL);
      setDescEN(attraction.descEN);
      setAccessible(attraction.accessible);
      setElevator(attraction.elevator);
      setEscalator(attraction.escalator);
      setImageUrl(attraction.image);
      setGuides(attraction.resources);
      setOpeningHours(attraction.openingHours);
      setPaths(attraction.paths);
    } else {
      setNamePL('');
      setNameEN('');
      setDescPL('');
      setDescEN('');
      setAccessible(false);
      setElevator(false);
      setEscalator(false);
      setImageUrl(null);
      setGuides([]);
      setOpeningHours([]);
      setPaths([]);
    }
  };
  const [ready, setReady] = useState(false);
  const params = useParams<{ id: string; }>();
  const history = useHistory();
  useEffect(() => {
    (async () => {
      if (params.id !== 'new') {
        const id = Number(params.id);
        const { data } = await axios.get('/card/site');
        if (data.coordinates.length > 0) {
          setMainCoords(data.coordinates[0]);
        }
        loadData(data.attractions.find((x: ISiteAttraction) => x.id === id));
      }
      setReady(true);
    })();
  }, [params.id]);
  const loadHours = (newHours: IOpeningHours | null) => {
    if (newHours) {
      setSelectedHours(newHours.id);
      setHours(newHours.hours);
      setRemarksPL(newHours.remarksPL);
      setRemarksEN(newHours.remarksEN);
      setDays(newHours.days);
      setPeriods(newHours.periods);
      setExcluded(newHours.excluded);
    } else {
      setSelectedHours('new');
      setHours([]);
      setRemarksPL('');
      setRemarksEN('');
      setDays([]);
      setPeriods([]);
      setExcluded([]);
    }
  };
  const loadPath = (newPath: IAttractionPath | null) => {
    if (newPath) {
      setSelectedPath(newPath.id);
      setPathNamePL(newPath.namePL);
      setPathNameEN(newPath.nameEN);
      setPathStartPL(newPath.startPL);
      setPathStartEN(newPath.startEN);
      setPathEndPL(newPath.endPL);
      setPathEndEN(newPath.endEN);
      setPathPoints(newPath.points);
    } else {
      setSelectedPath('new');
      setPathNamePL('');
      setPathNameEN('');
      setPathStartPL('');
      setPathStartEN('');
      setPathEndPL('');
      setPathEndEN('');
      setPathPoints([]);
    }
  };
  if (!ready) return null;
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {t('card.attraction')}
      </Typography>
      <div className={classes.container}>
        <div className={classes.left}>
          <div className={classes.row}>
            <TextField
              className={classes.shortField}
              label={t('card.nameInPolish')}
              value={namePL}
              onChange={({ target }) => setNamePL(target.value)}
              variant="outlined"
              size="small"
            />
            <TextField
              className={classes.shortField}
              label={t('card.nameInEnglish')}
              value={nameEN}
              onChange={({ target }) => setNameEN(target.value)}
              variant="outlined"
              size="small"
            />
          </div>
          <Typography className={classes.descriptionLabel}>
            {t('card.descriptionInPolish')}
          </Typography>
          <ReactQuill
            value={descPL}
            onChange={setDescPL}
            className={classes.description}
          />
          <Typography className={classes.descriptionLabel}>
            {t('card.descriptionInEnglish')}
          </Typography>
          <ReactQuill
            value={descEN}
            onChange={setDescEN}
            className={classes.description}
          />
        </div>
        <div className={classes.right}>
          <Typography className={classes.subheader}>
            {t('card.image')}
          </Typography>
          <ImageUpload
            onChange={(file, url) => {
              setImageFile(file);
              setImageUrl(url);
            }}
            imageUrl={imageUrl}
          />
          <Typography className={classes.subheader}>
            {t('card.accessibility')}
          </Typography>
          <div className={classes.checkboxRow}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={accessible}
                  onChange={({ target }) => setAccessible(target.checked)}
                  color="primary"
                />
              )}
              label={t('card.accessible')}
              className={classes.formControlLabel}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={elevator}
                  onChange={({ target }) => setElevator(target.checked)}
                  color="primary"
                />
              )}
              label={t('card.elevator')}
              className={classes.formControlLabel}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={escalator}
                  onChange={({ target }) => setEscalator(target.checked)}
                  color="primary"
                />
              )}
              label={t('card.escalator')}
              className={classes.formControlLabel}
            />
          </div>
        </div>
      </div>
      <div className={classes.saveRow}>
        <Button
          color="primary"
          className={classes.discardChanges}
        >
          {t('card.discardChanges')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={saving}
          onClick={async () => {
            const requestData: IAttractionUpdate = {
              site: user.cardSite as number,
              namePL,
              nameEN,
              descPL,
              descEN,
              accessible,
              elevator,
              escalator,
            };
            if (imageFile) {
              requestData.image = {
                name: imageFile.name,
                type: imageFile.type,
                content: await fileToBase64(imageFile),
              };
            } else if (!imageUrl && params.id !== 'new') {
              requestData.image = null;
            }
            try {
              setSaving(true);
              if (params.id === 'new') {
                const { data } = await axios.post('/card/attraction', requestData);
                history.push(getLink(`/card/site/attraction/${data.id}`));
              } else {
                const { data } = await axios.put(`/card/attraction/${params.id}`, requestData);
                if (data) {
                  setImageUrl(data);
                }
              }
              setImageFile(null);
              showSnackbar(t('card.attractionSaved'));
            } finally {
              setSaving(false);
            }
          }}
        >
          {t('card.save')}
        </Button>
      </div>
      <div className={classes.section}>
        <NewHeader
          headerText={t('card.guides')}
          newText={t('card.newGuide')}
          onClick={() => setNewGuide(true)}
          small
          bigMargin
        />
        <div className={classes.container}>
          <div className={classes.left}>
            {guides.map((guide, i) => (
              <div className={classes.guide} key={guide.id}>
                <Typography className={classes.numberOf}>
                  {i + 1}
                </Typography>
                <div
                  style={{ backgroundImage: `url(${guide.file})` }}
                  className={classes.guidePreview}
                />
                <Typography className={classes.guideName}>
                  {guide.namePL}
                  {', '}
                  {guide.nameEN}
                </Typography>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setDeleteType('guide');
                    setDeleteId(guide.id);
                    setDeleteName(guide.namePL);
                  }}
                >
                  <Clear />
                </IconButton>
              </div>
            ))}
          </div>
          <div className={classes.right}>
            {newGuide && (
              <>
                <div className={classes.row}>
                  <TextField
                    className={classes.sectionNameField}
                    label={t('card.nameInPolish')}
                    value={guideNamePL}
                    onChange={({ target }) => setGuideNamePL(target.value)}
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    className={classes.sectionNameField}
                    label={t('card.nameInEnglish')}
                    value={guideNameEN}
                    onChange={({ target }) => setGuideNameEN(target.value)}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <Typography className={classes.subheader}>
                  {t('card.attachFile')}
                </Typography>
                <div className={classes.guideRow}>
                  <ImageUpload
                    onChange={(file, url) => {
                      setGuideImage(file);
                      setGuideUrl(url);
                    }}
                    imageUrl={guideUrl}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.addButton}
                    disabled={
                      params.id === 'new' || !guideNamePL || !guideNameEN || !guideImage || guideSaving
                    }
                    onClick={async () => {
                      if (!guideImage) return;
                      const requestData: IAttractionAttachmentUpdate = {
                        attraction: Number(params.id),
                        namePL: guideNamePL,
                        nameEN: guideNameEN,
                        file: {
                          name: guideImage.name,
                          type: guideImage.type,
                          content: await fileToBase64(guideImage),
                        },
                      };
                      try {
                        setGuideSaving(true);
                        const { data } = await axios.post('/card/attraction/attachment', requestData);
                        setGuides([...guides, data]);
                        setNewGuide(false);
                        setGuideNamePL('');
                        setGuideNameEN('');
                        setGuideImage(null);
                        setGuideUrl(null);
                      } finally {
                        setGuideSaving(false);
                      }
                    }}
                  >
                    {t('card.add')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <NewHeader
          headerText={t('card.paths')}
          newText={t('card.newPath')}
          onClick={() => loadPath(null)}
          small
          bigMargin
        />
        <div className={classes.container}>
          <div className={classes.left}>
            {paths.map((path, i) => {
              const isSelected = selectedPath === path.id;
              return (
                <div
                  className={classNames(classes.guide, {
                    [classes.guideSelected]: isSelected,
                  })}
                  key={path.id}
                  onClick={() => loadPath(path)}
                >
                  <Typography
                    className={classNames(classes.numberOf, {
                      [classes.guideSelectedText]: isSelected,
                    })}
                  >
                    {i + 1}
                  </Typography>
                  <Typography
                    className={classNames(classes.guideName, {
                      [classes.guideSelectedText]: isSelected,
                    })}
                  >
                    {path.namePL}
                    {', '}
                    {path.nameEN}
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={(event) => {
                      event.stopPropagation();
                      setDeleteType('path');
                      setDeleteId(path.id);
                      setDeleteName(path.namePL);
                    }}
                  >
                    {isSelected ? <ClearWhite /> : <Clear />}
                  </IconButton>
                </div>
              );
            })}
          </div>
          <div className={classes.right}>
            {selectedPath !== null && (
              <>
                <div className={classes.row}>
                  <TextField
                    className={classes.sectionNameField}
                    label={t('card.nameInPolish')}
                    value={pathNamePL}
                    onChange={({ target }) => setPathNamePL(target.value)}
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    className={classes.sectionNameField}
                    label={t('card.nameInEnglish')}
                    value={pathNameEN}
                    onChange={({ target }) => setPathNameEN(target.value)}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className={classes.row}>
                  <TextField
                    className={classes.sectionNameField}
                    label={t('card.pathStartPL')}
                    value={pathStartPL}
                    onChange={({ target }) => setPathStartPL(target.value)}
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    className={classes.sectionNameField}
                    label={t('card.pathStartEN')}
                    value={pathStartEN}
                    onChange={({ target }) => setPathStartEN(target.value)}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className={classes.row}>
                  <TextField
                    className={classes.sectionNameField}
                    label={t('card.pathEndPL')}
                    value={pathEndPL}
                    onChange={({ target }) => setPathEndPL(target.value)}
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    className={classes.sectionNameField}
                    label={t('card.pathEndEN')}
                    value={pathEndEN}
                    onChange={({ target }) => setPathEndEN(target.value)}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <PathSelect
                  points={pathPoints}
                  setPoints={setPathPoints}
                  enabled
                  className={classes.map}
                  initialsCoords={mainCoords || { lat: 54.355006, lon: 18.594366 }}
                />
                <div className={classes.saveRow}>
                  {selectedPath !== 'new' && (
                    <Button
                      color="primary"
                      className={classes.discardChanges}
                      onClick={() => loadPath(
                        paths.find((x) => x.id === selectedPath) as IAttractionPath,
                      )}
                    >
                      {t('card.discardChanges')}
                    </Button>
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={(
                      params.id === 'new'
                      || !pathNamePL || !pathNameEN
                      || !pathStartPL || !pathStartEN
                      || !pathEndPL || !pathEndEN
                      || pathPoints.length === 0
                      || pathSaving
                    )}
                    onClick={async () => {
                      const requestData: IAttractionPathUpdate = {
                        attraction: Number(params.id),
                        namePL: pathNamePL,
                        nameEN: pathNameEN,
                        startPL: pathStartPL,
                        startEN: pathStartEN,
                        endPL: pathEndPL,
                        endEN: pathEndEN,
                        points: pathPoints,
                      };
                      try {
                        setPathSaving(true);
                        if (selectedPath === 'new') {
                          const { data } = await axios.post('/card/attraction/path', requestData);
                          setPaths([...paths, data]);
                        } else {
                          await axios.put(`/card/attraction/path/${selectedPath}`, requestData);
                          setPaths(R.map((x) => (
                            x.id === selectedPath ? {
                              ...x,
                              ...requestData,
                            } : x
                          )));
                        }
                        setSelectedPath(null);
                      } finally {
                        setPathSaving(false);
                      }
                    }}
                  >
                    {t('card.save')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.openingHoursSection}>
        <NewHeader
          headerText={t('card.openingHours')}
          newText={t('card.newOpeningHours')}
          onClick={() => loadHours(null)}
          small
          bigMargin
        />
        <div className={classes.container}>
          <div className={classes.left}>
            {openingHours.map((hoursData) => (
              <div
                className={classes.hoursData}
                onClick={() => loadHours(hoursData)}
                key={hoursData.id}
              >
                <IconButton
                  color="primary"
                  className={classes.deleteOpeningHours}
                  onClick={(event) => {
                    event.stopPropagation();
                    setDeleteType('hours');
                    setDeleteId(hoursData.id);
                  }}
                >
                  <Clear />
                </IconButton>
                <div className={classes.openingHoursRow}>
                  <div className={classes.left}>
                    <Typography className={classes.hoursSubheader}>
                      {t('card.openingHours')}
                    </Typography>
                    <div className={classes.elements}>
                      {hoursData.hours.map((currentHours) => (
                        <Typography
                          className={classes.outlinedElement}
                          key={currentHours.from + currentHours.to}
                        >
                          {currentHours.from}
                          {' - '}
                          {currentHours.to}
                        </Typography>
                      ))}
                    </div>
                    <Typography className={classes.hoursSubheader}>
                      {t('card.effective')}
                    </Typography>
                    <Typography className={classes.weekDays}>
                      {hoursData.days.map((day) => t(`data.${day}`)).join(', ')}
                    </Typography>
                  </div>
                  <div className={classes.right}>
                    <div
                      className={classes.remarks}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: sanitizeHtml(hoursData.remarksPL) }}
                    />
                  </div>
                </div>
                <div className={classes.openingHoursRow}>
                  <div className={classes.left}>
                    <Typography className={classes.hoursSubheader}>
                      {t('card.periods')}
                    </Typography>
                    <div className={classes.elements}>
                      {hoursData.periods.map((period) => (
                        <Typography
                          className={classes.outlinedElement}
                          key={period.from + period.to}
                        >
                          {formatDate(period.from)}
                          {' - '}
                          {formatDate(period.to)}
                        </Typography>
                      ))}
                    </div>
                  </div>
                  <div className={classes.right}>
                    <Typography className={classes.hoursSubheader}>
                      {t('card.excludedDates')}
                    </Typography>
                    <div className={classes.elements}>
                      {hoursData.excluded.map((date) => (
                        <Typography className={classes.outlinedElement} key={date}>
                          {formatDate(date)}
                        </Typography>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.right}>
            {selectedHours && (
              <>
                <Typography className={classes.subheader}>
                  {t('card.openingHours')}
                </Typography>
                <div className={classes.fieldRow}>
                  <TimePicker
                    inputVariant="outlined"
                    TextFieldComponent={(props) => (
                      <TextField
                        {...props}
                        size="small"
                        className={classes.timePicker}
                      />
                    )}
                    cancelLabel={t('common.cancel')}
                    ampm={false}
                    value={from}
                    onChange={(newFrom) => {
                      if (newFrom && to) {
                        setHours(R.append({
                          from: formatTimeISO(newFrom),
                          to: formatTimeISO(to),
                        }));
                        setTo(null);
                      } else {
                        setFrom(newFrom);
                      }
                    }}
                  />
                  <Typography className={classes.hoursDash}>
                    -
                  </Typography>
                  <TimePicker
                    inputVariant="outlined"
                    TextFieldComponent={(props) => (
                      <TextField
                        {...props}
                        size="small"
                        className={classes.timePicker}
                      />
                    )}
                    cancelLabel={t('common.cancel')}
                    ampm={false}
                    value={to}
                    onChange={(newTo) => {
                      if (from && newTo) {
                        setHours(R.append({
                          from: formatTimeISO(from),
                          to: formatTimeISO(newTo),
                        }));
                        setFrom(null);
                      } else {
                        setTo(newTo);
                      }
                    }}
                  />
                </div>
                <div className={classes.elements}>
                  {hours.map((hoursElement) => (
                    <div
                      className={classes.outlinedElement}
                      key={hoursElement.from + hoursElement.to}
                    >
                      <Typography className={classes.elementContent}>
                        {hoursElement.from}
                        {' - '}
                        {hoursElement.to}
                      </Typography>
                      <IconButton
                        className={classes.removeElement}
                        onClick={() => setHours(R.filter((x) => x !== hoursElement))}
                      >
                        <Clear />
                      </IconButton>
                    </div>
                  ))}
                </div>
                <Typography className={classes.subheader}>
                  {t('card.remarksPL')}
                </Typography>
                <ReactQuill
                  value={remarksPL}
                  onChange={setRemarksPL}
                  className={classes.description}
                />
                <Typography className={classes.subheader}>
                  {t('card.remarksEN')}
                </Typography>
                <ReactQuill
                  value={remarksEN}
                  onChange={setRemarksEN}
                  className={classes.description}
                />
                <Typography className={classes.subheader}>
                  {t('card.effective')}
                </Typography>
                {(
                  ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'] as IWeekDay[]
                ).map((day) => (
                  <Button
                    variant="contained"
                    className={classNames(classes.dayButton, {
                      [classes.buttonSelected]: days.includes(day),
                    })}
                    onClick={() => setDays(
                      days.includes(day) ? R.filter((x) => x !== day) : R.append(day),
                    )}
                    key={day}
                  >
                    {t(`data.${day}`)}
                  </Button>
                ))}
                <Typography className={classes.subheader}>
                  {t('card.periods')}
                </Typography>
                <div className={classes.fieldRow}>
                  <CustomDatePicker
                    className={classes.datePicker}
                    label={t('card.from')}
                    value={periodFrom}
                    onChange={(newFrom) => {
                      if (newFrom && periodTo) {
                        setPeriods(R.append({
                          from: formatDateISO(newFrom),
                          to: formatDateISO(periodTo),
                        }));
                        setPeriodTo(null);
                      } else {
                        setPeriodFrom(newFrom);
                      }
                    }}
                    maxDate={periodTo || undefined}
                  />
                  <CustomDatePicker
                    className={classes.datePicker}
                    label={t('card.to')}
                    value={periodTo}
                    onChange={(newTo) => {
                      if (periodFrom && newTo) {
                        setPeriods(R.append({
                          from: formatDateISO(periodFrom),
                          to: formatDateISO(newTo),
                        }));
                        setPeriodFrom(null);
                      } else {
                        setPeriodTo(newTo);
                      }
                    }}
                    minDate={periodFrom || undefined}
                  />
                </div>
                <div className={classes.elements}>
                  {periods.map((period) => (
                    <div className={classes.outlinedElement} key={period.from + period.to}>
                      <Typography className={classes.elementContent}>
                        {formatDate(period.from)}
                        {' - '}
                        {formatDate(period.to)}
                      </Typography>
                      <IconButton
                        className={classes.removeElement}
                        onClick={() => setPeriods(R.filter((x) => x !== period))}
                      >
                        <Clear />
                      </IconButton>
                    </div>
                  ))}
                </div>
                <Typography className={classes.subheader}>
                  {t('card.excludedDates')}
                </Typography>
                <div className={classes.fieldRow}>
                  <CustomDatePicker
                    className={classes.datePicker}
                    label={t('card.date')}
                    value={null}
                    onChange={(newDate) => newDate && setExcluded(R.append(formatDateISO(newDate)))}
                  />
                </div>
                <div className={classes.elements}>
                  {excluded.map((date) => (
                    <div className={classes.outlinedElement} key={date}>
                      <Typography className={classes.elementContent}>
                        {formatDate(date)}
                      </Typography>
                      <IconButton
                        className={classes.removeElement}
                        onClick={() => setExcluded(R.filter((x) => x !== date))}
                      >
                        <Clear />
                      </IconButton>
                    </div>
                  ))}
                </div>
                <div className={classes.saveRow}>
                  {selectedHours !== 'new' && (
                    <Button
                      color="primary"
                      className={classes.discardChanges}
                      onClick={() => loadHours(
                        openingHours.find((x) => x.id === selectedHours) as IOpeningHours,
                      )}
                    >
                      {t('card.discardChanges')}
                    </Button>
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={(
                      params.id === 'new'
                      || hours.length === 0
                      || days.length === 0
                      || periods.length === 0
                      || hoursSaving
                    )}
                    onClick={async () => {
                      const requestData: IOpeningHoursUpdate = {
                        attraction: Number(params.id),
                        remarksPL,
                        remarksEN,
                        days,
                        hours,
                        periods,
                        excluded,
                      };
                      try {
                        setHoursSaving(true);
                        if (selectedHours === 'new') {
                          const { data } = await axios.post('/card/attraction/opening-hours', requestData);
                          setOpeningHours([...openingHours, data]);
                        } else {
                          await axios.put(`/card/attraction/opening-hours/${selectedHours}`, requestData);
                          setOpeningHours(R.map((x) => (
                            x.id === selectedHours ? {
                              ...x,
                              ...requestData,
                            } : x
                          )));
                        }
                        setSelectedHours(null);
                      } finally {
                        setHoursSaving(false);
                      }
                    }}
                  >
                    {t('card.save')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <DeleteDialog
        open={deleteId !== null}
        onClose={() => setDeleteId(null)}
        text={t(
          (() => {
            switch (deleteType) {
              case 'guide': return 'card.guideDeleteConfirm';
              case 'path': return 'card.pathDeleteConfirm';
              default: return 'card.hoursDeleteConfirm';
            }
          })(),
          { name: deleteName },
        )}
        onDelete={async () => {
          if (deleteType === 'guide') {
            await axios.delete(`/card/attraction/attachment/${deleteId}`);
            setGuides(R.filter<IAttractionAttachment>((x) => x.id !== deleteId));
          } else if (deleteType === 'path') {
            await axios.delete(`/card/attraction/path/${deleteId}`);
            if (selectedPath === deleteId) {
              setSelectedPath(null);
            }
            setPaths(R.filter<IAttractionPath>((x) => x.id !== deleteId));
          } else {
            await axios.delete(`/card/attraction/opening-hours/${deleteId}`);
            if (selectedHours === deleteId) {
              setSelectedHours(null);
            }
            setOpeningHours(R.filter<IOpeningHours>((x) => x.id !== deleteId));
          }
          setDeleteId(null);
        }}
        canDelete
      />
    </div>
  );
}
