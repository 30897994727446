import React, {
  useContext, useEffect, useRef, useState, useCallback,
} from 'react';
import {
  Button, ButtonGroup, IconButton, InputAdornment, makeStyles, TextField,
  Typography, useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { DateTimePicker } from '@material-ui/pickers';
import {
  Map, Marker, Polyline, ScaleControl, TileLayer,
} from 'react-leaflet';
import leaflet from 'leaflet';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import * as R from 'ramda';
import polyline from '@mapbox/polyline';
import { useParams, useHistory } from 'react-router-dom';
import copy from 'clipboard-copy';
import { Base64 } from 'js-base64';
import { ReactComponent as Swap } from '../../assets/swap.svg';
import { ReactComponent as Calendar } from '../../assets/calendar.svg';
import { ReactComponent as BackArrow } from '../../assets/back-arrow.svg';
import { ReactComponent as MarkerPrimary } from '../../assets/marker-primary.svg';
import { ReactComponent as BookmarkFilled } from '../../assets/bookmark-filled.svg';
import { ReactComponent as Delete } from '../../assets/delete.svg';
import { ReactComponent as ChevronBig } from '../../assets/chevron-big.svg';
import marker from '../../assets/marker.svg';
import {
  IFavoriteTrip, IFavoriteTripPoint, IItinerary, IOldFavoriteTrip, IPlace, ISavedFavoriteTrip,
} from '../../interfaces';
import LocationSearch from './LocationSearch';
import AppContext from '../../context';
import Results from './Results';
import { axios, useGetLink } from '../../utils';
import Nav from '../Nav';
import Footer from '../Footer';

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flexGrow: 1,
    minHeight: 400,
    display: 'flex',
    height: 'calc(100vh - 43px)',
    '@media (max-width: 800px)': {
      height: 'initial',
    },
  },
  left: {
    width: 424,
    backgroundColor: '#A1145C',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    position: 'relative',
    '@media (max-width: 800px)': {
      width: '100%',
    },
  },
  search: {
    backgroundColor: 'white',
    padding: '16px 64px',
    '@media (max-width: 800px)': {
      padding: '16px 40px',
    },
  },
  header: {
    fontSize: 28,
    fontWeight: 400,
    color: '#37001F',
    marginBottom: 8,
  },
  from: {
    display: 'flex',
  },
  to: {
    display: 'flex',
    marginBottom: 24,
  },
  swapButton: {
    display: 'flex',
    padding: 4,
    margin: '-6px -36px -6px auto',
  },
  timeButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px 0',
  },
  pickerInput: {
    paddingRight: 10,
  },
  datePicker: {
    marginBottom: 16,
  },
  map: {
    flexGrow: 1,
    '@media (max-width: 800px)': {
      height: 200,
    },
  },
  marker: {
    width: 20,
    height: 25,
    left: -10,
    top: -25,
  },
  bottom: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  stopMarker: {
    width: 10,
    height: 10,
    backgroundColor: '#E4E4E4',
    borderRadius: '50%',
    border: '2px solid #37001F',
  },
  intermediateStopMarker: {
    width: 8,
    height: 8,
    backgroundColor: '#316FB9',
    borderRadius: '50%',
  },
  walkDotMarker: {
    width: 8,
    height: 8,
    backgroundColor: '#E4E4E4',
    borderRadius: '50%',
    border: '1px solid #37001F',
  },
  backButton: {
    position: 'absolute',
    left: 0,
    top: 0,
    margin: 8,
    zIndex: 400,
  },
  selectFromMapMarker: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 30,
    height: 50,
    zIndex: 400,
  },
  selectFromMapRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  selectFromMapHeader: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    padding: 10,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
    zIndex: 1000,
  },
  selectFromMapBack: {
    position: 'absolute',
    left: 8,
  },
  selectFromMapMap: {
    width: '100%',
    flexGrow: 1,
  },
  noConnections: {
    padding: 24,
    color: 'white',
    fontWeight: 400,
    textAlign: 'center',
  },
  savedSearches: {
    margin: 16,
  },
  savedSearchesHeader: {
    fontSize: 26,
    fontWeight: 400,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  bookmarkIcon: {
    marginRight: 4,
  },
  savedSearch: {
    display: 'flex',
    padding: '8px 8px 8px 32px',
    backgroundColor: 'white',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
    marginBottom: 8,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 8,
      top: 11,
      width: 14,
      height: 14,
      borderRadius: '50%',
      backgroundColor: '#E4E4E4',
    },
  },
  savedSearchText: {
    fontSize: 14,
  },
  savedSearchButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '-6px 0 -6px auto',
  },
  savedSearchButton: {
    padding: 8,
  },
  savedSearchLabel: {
    fontWeight: 400,
  },
});

const getPlaceName = (place: string | IPlace, coords: [number, number]): string => {
  if (typeof place === 'string') {
    return coords.map((x) => String(Math.round(x * 10 ** 5) / 10 ** 5)).join(', ');
  }
  if (!place.location) return place.name;
  return `${place.name}, ${place.location}`;
};

export default function TripPlanning() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showSnackbar, user } = useContext(AppContext);
  const getLink = useGetLink();
  const [myLocation, setMyLocation] = useState<{ lat: number; lon: number; } | null>(null);
  const [locationReady, setLocationReady] = useState(false);
  const [from, setFrom] = useState<string | IPlace | null>(null);
  const [to, setTo] = useState<string | IPlace | null>(null);
  const [fromError, setFromError] = useState(false);
  const [toError, setToError] = useState(false);
  const [fromCoords, setFromCoords] = useState<[number, number] | null>(null);
  const [toCoords, setToCoords] = useState<[number, number] | null>(null);
  const [isDepart, setIsDepart] = useState(true);
  const [date, setDate] = useState<Moment>(moment());
  const [dateEdited, setDateEdited] = useState(false);
  const mapRef = useRef<any>();
  const [mapViewport, setMapViewport] = useState<{
    center: [number, number],
    zoom: number,
  } | null>(null);
  const [itineraries, setItineraries] = useState<IItinerary[]>([]);
  const [hoveredItinerary, setHoveredItinerary] = useState(0);
  const [openItinerary, setOpenItinerary] = useState<number | null>(null);
  const [noConnections, setNoConnections] = useState(false);
  const [savedSearches, setSavedSearches] = (
    useState<(IFavoriteTrip | ISavedFavoriteTrip)[] | null>(null)
  );
  const [triggerSearch, setTriggerSearch] = useState(false);
  const params = useParams<{ search: string }>();
  const history = useHistory();
  const loadSearch = useCallback((searchData: IFavoriteTrip) => {
    const getPlace = (point: IFavoriteTripPoint): string | IPlace | null => {
      if (point.type === 'your-location') return 'your-location';
      if (point.type === 'map-pin') return 'pin-location';
      if (point.name) {
        const split = point.name.split(', ');
        return {
          lat: point.lat,
          lon: point.lon,
          name: split[0],
          location: split[1],
          type: 'place',
        };
      }
      return null;
    };
    const getCoords = (point: IFavoriteTripPoint): [number, number] | null => {
      if (point.type === 'your-location') {
        return myLocation ? [myLocation.lat, myLocation.lon] : null;
      }
      return [point.lat, point.lon];
    };
    setFrom(getPlace(searchData.from));
    setFromCoords(getCoords(searchData.from));
    setTo(getPlace(searchData.to));
    setToCoords(getCoords(searchData.to));
    setIsDepart(searchData.arriveBy ? !searchData.arriveBy : true);
    setDate(
      searchData.dateTime ? moment(searchData.dateTime) : moment(),
    );
    setTriggerSearch(true);
  }, [myLocation]);
  useEffect(() => {
    if (params.search && locationReady) {
      loadSearch(JSON.parse(Base64.decode(params.search)));
      history.push(getLink('/trip-planning'));
    }
  }, [params, getLink, history, loadSearch, locationReady]);
  useEffect(() => {
    (async () => {
      const searchesString = localStorage.getItem('saved-searches');
      const oldLocalSearches: IOldFavoriteTrip[] = searchesString ? JSON.parse(searchesString) : [];
      const localSearches: IFavoriteTrip[] = oldLocalSearches.map((x) => ({
        ...x,
        from: {
          type: x.from.name ? 'search-result' : 'map-pin',
          ...x.from,
        },
        to: {
          type: x.to.name ? 'search-result' : 'map-pin',
          ...x.to,
        },
      }));
      if (user) {
        if (localSearches.length > 0) {
          await axios.post('/user/trips/favorite', localSearches);
          localStorage.removeItem('saved-searches');
        }
        const { data } = await axios.get('/user/trips/favorite');
        setSavedSearches(data);
      } else {
        setSavedSearches(localSearches);
      }
    })();
  }, [user]);
  const isMobile = useMediaQuery('(max-width: 800px)');
  useEffect(() => setOpenItinerary(null), [itineraries]);
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.leafletElement.fitBounds(
        new leaflet.LatLngBounds([
          [56.464660, 14.408800],
          [53.570577, 19.443904],
        ]),
      );
    }
  }, [savedSearches]);
  const getLocation = (errorCallback?: () => void) => {
    let intervalId: NodeJS.Timeout;
    if (navigator.permissions) {
      intervalId = setInterval(async () => {
        const { state } = await navigator.permissions.query({ name: 'geolocation' });
        if (state === 'granted') {
          if (errorCallback) {
            errorCallback();
          }
          setLocationReady(true);
          clearInterval(intervalId);
        }
      }, 1000);
    }
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      setMyLocation({ lat: coords.latitude, lon: coords.longitude });
      setLocationReady(true);
      setMapViewport({
        center: [coords.latitude, coords.longitude],
        zoom: 15,
      });
      clearInterval(intervalId);
    }, () => {
      if (errorCallback) {
        errorCallback();
      }
      setLocationReady(true);
      clearInterval(intervalId);
    });
  };
  useEffect(() => {
    if (savedSearches) {
      getLocation();
    }
  }, [savedSearches]);
  useEffect(() => {
    if (from === 'your-location' || to === 'your-location') {
      if (myLocation === null) {
        getLocation(() => {
          showSnackbar(t('planning.locationError'));
          if (from === 'your-location') {
            setFrom(null);
          }
          if (to === 'your-location') {
            setTo(null);
          }
        });
      } else {
        const location: [number, number] = [myLocation.lat, myLocation.lon];
        if (from === 'your-location' && !R.equals(fromCoords, location)) {
          setFromCoords(location);
        }
        if (to === 'your-location' && !R.equals(toCoords, location)) {
          setToCoords(location);
        }
      }
    }
  }, [from, to, myLocation, showSnackbar, t, fromCoords, toCoords]);
  useEffect(() => {
    if ((fromCoords || toCoords) && mapRef.current) {
      mapRef.current.leafletElement.fitBounds(
        new leaflet.LatLngBounds([
          (fromCoords || toCoords) as [number, number],
          (toCoords || fromCoords) as [number, number],
        ]),
        { padding: [32, 32] },
      );
    }
  }, [fromCoords, toCoords, openItinerary]);
  useEffect(() => {
    setItineraries([]);
    setNoConnections(false);
    setOpenItinerary(null);
  }, [from, to, isDepart, date]);
  const onSearch = useCallback(async () => {
    if (!fromCoords) {
      setFromError(true);
    }
    if (!toCoords) {
      setToError(true);
    }
    if (fromCoords && toCoords) {
      const { data } = await axios.get('/public/plan-trip', {
        params: {
          fromLat: fromCoords[0],
          fromLon: fromCoords[1],
          toLat: toCoords[0],
          toLon: toCoords[1],
          dateTime: date.format('YYYY-MM-DDTHH:mm:ss'),
          arriveBy: !isDepart,
        },
      });
      if (data.plan) {
        setNoConnections(false);
        setHoveredItinerary(0);
        setItineraries(data.plan.itineraries.map((x: IItinerary) => ({
          ...x,
          id: Math.round(Math.random() * 10 ** 10),
        })));
      } else {
        setNoConnections(true);
        setItineraries([]);
      }
    }
  }, [fromCoords, toCoords, date, isDepart]);
  useEffect(() => {
    if (triggerSearch) {
      onSearch();
      setTriggerSearch(false);
    }
  }, [triggerSearch, onSearch]);
  if (!savedSearches) {
    return null;
  }
  const mobileSelectFromMap = isMobile && ((from === 'select-on-map' && !fromCoords) || (to === 'select-on-map' && !toCoords));
  const map = (
    <Map
      className={mobileSelectFromMap ? classes.selectFromMapMap : classes.map}
      minZoom={3}
      maxZoom={18}
      ref={mapRef}
      center={mapViewport ? mapViewport.center : undefined}
      zoom={mapViewport ? mapViewport.zoom : undefined}
      onViewportChanged={({ center, zoom }) => {
        if (center && zoom) {
          setMapViewport({ center, zoom });
        }
      }}
      onclick={({ latlng }) => {
        if (isMobile) return;
        if (from === 'select-on-map') {
          setFromCoords([latlng.lat, latlng.lng]);
          setFrom('pin-location');
        }
        if (to === 'select-on-map') {
          setToCoords([latlng.lat, latlng.lng]);
          setTo('pin-location');
        }
      }}
      zoomControl={!isMobile}
      // @ts-ignore
      fullscreenControl={{ position: 'topright' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ScaleControl position="bottomleft" imperial={false} />
      {isMobile && !mobileSelectFromMap && (
        <IconButton
          className={classes.backButton}
          onClick={() => setOpenItinerary(null)}
        >
          <BackArrow />
        </IconButton>
      )}
      {mobileSelectFromMap && (
        <MarkerPrimary className={classes.selectFromMapMarker} />
      )}
      {fromCoords && (
        <Marker
          position={fromCoords}
          icon={leaflet.icon({
            iconUrl: marker,
            className: classes.marker,
          })}
          draggable
          onDragend={(event: any) => {
            const { lat, lng: lon } = event.target.getLatLng();
            setFrom('pin-location');
            setFromCoords([lat, lon]);
          }}
        />
      )}
      {itineraries.length > 0 && hoveredItinerary !== null && (() => {
        const itinerary = itineraries[hoveredItinerary];
        return (
          <>
            {R.init(itinerary.legs).map((leg) => (
              <Marker
                key={leg.to.lat}
                position={[leg.to.lat, leg.to.lon]}
                icon={leaflet.divIcon({
                  className: classes.stopMarker,
                  html: '<div />',
                  iconSize: [10, 10],
                })}
              />
            ))}
            {R.chain(R.prop('intermediateStops'), itinerary.legs).map((stop) => (
              <Marker
                key={stop.lat}
                position={[stop.lat, stop.lon]}
                icon={leaflet.divIcon({
                  className: classes.intermediateStopMarker,
                  html: '<div />',
                  iconSize: [8, 8],
                })}
              />
            ))}
            {itinerary.legs.map((leg) => {
              const points = polyline.decode(leg.legGeometry.points) as [number, number][];
              if (leg.mode === 'WALK') {
                return (
                  <>
                    <Polyline
                      key={`${leg.legGeometry.points}-border`}
                      positions={points}
                      color="#37001F"
                      weight={12}
                      dashArray="1 20"
                    />
                    <Polyline
                      key={`${leg.legGeometry.points}-background`}
                      positions={points}
                      color="#E4E4E4"
                      weight={8}
                      dashArray="1 20"
                    />
                  </>
                );
              }
              return (
                <Polyline
                  key={leg.legGeometry.points}
                  positions={points}
                  color="#3571B7"
                  weight={4}
                />
              );
            })}
          </>
        );
      })()}
      {toCoords && (
        <Marker
          position={toCoords}
          icon={leaflet.icon({
            iconUrl: marker,
            className: classes.marker,
          })}
          draggable
          onDragend={(event: any) => {
            const { lat, lng: lon } = event.target.getLatLng();
            setTo('pin-location');
            setToCoords([lat, lon]);
          }}
        />
      )}
    </Map>
  );
  if (mobileSelectFromMap) {
    return (
      <div className={classes.selectFromMapRoot}>
        <div className={classes.selectFromMapHeader}>
          <IconButton
            className={classes.selectFromMapBack}
            onClick={() => {
              if (from === 'select-on-map') {
                setFrom(null);
              }
              if (to === 'select-on-map') {
                setTo(null);
              }
            }}
          >
            <BackArrow />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (!mapViewport) return;
              if (from === 'select-on-map') {
                setFromCoords(mapViewport.center);
                setFrom('pin-location');
              }
              if (to === 'select-on-map') {
                setToCoords(mapViewport.center);
                setTo('pin-location');
              }
            }}
          >
            {t('planning.selectThis')}
          </Button>
        </div>
        {map}
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Nav />
      <div className={classes.main}>
        <div className={classes.left}>
          {(!isMobile || openItinerary === null) && (
            <div className={classes.search}>
              <Typography className={classes.header}>
                {t('planning.findRoute')}
              </Typography>
              <LocationSearch
                className={classes.from}
                label={t('planning.from')}
                value={from}
                onChange={(event, value) => {
                  setFrom(value);
                  setFromError(false);
                  if (value && typeof value !== 'string') {
                    setFromCoords([value.lat, value.lon]);
                  } else {
                    setFromCoords(null);
                  }
                }}
                myLocation={myLocation}
                error={fromError}
                setArrival={(newDate) => {
                  setDate(newDate);
                  setIsDepart(false);
                  setDateEdited(true);
                }}
              />
              <IconButton
                color="primary"
                className={classes.swapButton}
                onClick={() => {
                  setFrom(to);
                  setTo(from);
                  setFromCoords(toCoords);
                  setToCoords(fromCoords);
                }}
              >
                <Swap />
              </IconButton>
              <LocationSearch
                className={classes.to}
                label={t('planning.to')}
                value={to}
                onChange={(event, value) => {
                  setTo(value);
                  setToError(false);
                  if (value && typeof value !== 'string') {
                    setToCoords([value.lat, value.lon]);
                  } else {
                    setToCoords(null);
                  }
                }}
                myLocation={myLocation}
                error={toError}
                setArrival={(newDate) => {
                  setDate(newDate);
                  setIsDepart(false);
                  setDateEdited(true);
                }}
                isDestination
              />
              <div className={classes.timeButtons}>
                <ButtonGroup color="primary">
                  <Button
                    variant={isDepart ? 'contained' : 'outlined'}
                    onClick={() => setIsDepart(true)}
                  >
                    {t('planning.depart')}
                  </Button>
                  <Button
                    variant={isDepart ? 'outlined' : 'contained'}
                    onClick={() => setIsDepart(false)}
                  >
                    {t('planning.arrive')}
                  </Button>
                </ButtonGroup>
                <Button
                  color="primary"
                  onClick={() => {
                    setDate(moment());
                    setDateEdited(false);
                  }}
                >
                  {t('planning.now')}
                </Button>
              </div>
              <DateTimePicker
                inputVariant="outlined"
                format={t('planning.dateFormat')}
                TextFieldComponent={(TextFieldProps) => (
                  <TextField
                    {...TextFieldProps}
                    size="small"
                    fullWidth
                    label={t('planning.dateAndTime')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Calendar />
                        </InputAdornment>
                      ),
                      className: classes.pickerInput,
                    }}
                  />
                )}
                cancelLabel={t('common.cancel')}
                ampm={false}
                value={date}
                onChange={(newDate) => {
                  if (newDate) {
                    setDate(newDate);
                    setDateEdited(true);
                  }
                }}
                className={classes.datePicker}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={onSearch}
              >
                {t('planning.search')}
              </Button>
            </div>
          )}
          {isMobile && openItinerary !== null && map}
          <div className={classes.bottom}>
            {noConnections && (
              <Typography className={classes.noConnections}>
                {t('planning.noConnections')}
              </Typography>
            )}
            {(itineraries.length > 0 && from && fromCoords && to && toCoords) ? (() => {
              const getBookmarkPlaceName = (place: string | IPlace | null) => {
                if (typeof place === 'string' || place === null) {
                  return undefined;
                }
                if (!place.location) return place.name;
                return `${place.name}, ${place.location}`;
              };
              const getType = (place: string | IPlace | null) => {
                if (place === 'your-location') return 'your-location';
                if (place === 'pin-location') return 'map-pin';
                return 'search-result';
              };
              const favoriteData: IFavoriteTrip = {
                from: {
                  lat: fromCoords[0],
                  lon: fromCoords[1],
                  name: getBookmarkPlaceName(from),
                  type: getType(from),
                },
                to: {
                  lat: toCoords[0],
                  lon: toCoords[1],
                  name: getBookmarkPlaceName(to),
                  type: getType(to),
                },
                ...(dateEdited ? {
                  dateTime: date.format('YYYY-MM-DDTHH:mm:ss'),
                  arriveBy: !isDepart,
                } : {}),
              };
              return (
                <Results
                  itineraries={itineraries}
                  openItinerary={openItinerary}
                  setOpenItinerary={setOpenItinerary}
                  from={getPlaceName(from, fromCoords)}
                  to={getPlaceName(to, toCoords)}
                  date={date}
                  onBookmark={async () => {
                    if (user) {
                      const { data } = await axios.post('/user/trips/favorite', [favoriteData]);
                      setSavedSearches([...data, ...savedSearches]);
                    } else {
                      const newSearches = [favoriteData, ...savedSearches];
                      localStorage.setItem('saved-searches', JSON.stringify(newSearches));
                      setSavedSearches(newSearches);
                    }
                    showSnackbar(t('planning.bookmarked'));
                  }}
                  onShare={async () => {
                    await copy(window.location.origin
                      + getLink(`/trip-planning/${Base64.encodeURI(JSON.stringify(favoriteData))}`));
                    showSnackbar(t('planning.copied'));
                  }}
                  onHover={setHoveredItinerary}
                  hoveredItinerary={hoveredItinerary}
                />
              );
            })() : (
              <>
                {savedSearches.length > 0 && (
                  <div className={classes.savedSearches}>
                    <Typography className={classes.savedSearchesHeader}>
                      <BookmarkFilled className={classes.bookmarkIcon} />
                      {t('planning.savedSearches')}
                    </Typography>
                    {savedSearches.map((savedSearch, i) => (
                      <div className={classes.savedSearch} key={'id' in savedSearch ? savedSearch.id : i}>
                        <div>
                          <Typography className={classes.savedSearchText}>
                            <span className={classes.savedSearchLabel}>
                              {t('planning.from')}
                              {': '}
                            </span>
                            {savedSearch.from.type === 'your-location' ? t('planning.yourLocation') : (
                              savedSearch.from.name || (
                                `${savedSearch.from.lat.toFixed(5)}, ${savedSearch.from.lon.toFixed(5)}`
                              )
                            )}
                          </Typography>
                          <Typography className={classes.savedSearchText}>
                            <span className={classes.savedSearchLabel}>
                              {t('planning.to')}
                              {': '}
                            </span>
                            {savedSearch.to.type === 'your-location' ? t('planning.yourLocation') : (
                              savedSearch.to.name || (
                                `${savedSearch.to.lat.toFixed(5)}, ${savedSearch.to.lon.toFixed(5)}`
                              )
                            )}
                          </Typography>
                          {savedSearch.dateTime && (
                            <Typography className={classes.savedSearchText}>
                              {moment(savedSearch.dateTime).isSame(moment(), 'day') ? (
                                <>
                                  <span className={classes.savedSearchLabel}>
                                    {savedSearch.arriveBy ? t('planning.arriveAt') : t('planning.departAt')}
                                    {' '}
                                  </span>
                                  {moment(savedSearch.dateTime).format('HH:mm')}
                                </>
                              ) : (
                                <>
                                  <span className={classes.savedSearchLabel}>
                                    {savedSearch.arriveBy ? t('planning.arriveOn') : t('planning.departOn')}
                                    {' '}
                                  </span>
                                  {moment(savedSearch.dateTime).format(t('planning.dateFormat'))}
                                </>
                              )}
                            </Typography>
                          )}
                        </div>
                        <div className={classes.savedSearchButtons}>
                          <IconButton
                            className={classes.savedSearchButton}
                            onClick={async () => {
                              const newSearches = savedSearches.filter((x) => x !== savedSearch);
                              if ('id' in savedSearch) {
                                await axios.delete('/user/trips/favorite', { params: { id: savedSearch.id } });
                              } else {
                                localStorage.setItem('saved-searches', JSON.stringify(newSearches));
                              }
                              setSavedSearches(newSearches);
                            }}
                            data-testid="delete-search"
                          >
                            <Delete />
                          </IconButton>
                          <IconButton
                            className={classes.savedSearchButton}
                            onClick={() => loadSearch(savedSearch)}
                            data-testid="load-search"
                          >
                            <ChevronBig />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            <Footer darkBg narrow />
          </div>
        </div>
        {!isMobile && map}
      </div>
    </div>
  );
}
