import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Typography, TextField, InputAdornment, FormControlLabel, Checkbox, IconButton,
} from '@material-ui/core';
import classNames from 'classnames';
import {
  Map, Marker, Popup, ScaleControl, TileLayer,
} from 'react-leaflet';
import moment from 'moment';
import leaflet, { LatLngBounds } from 'leaflet';
import * as R from 'ramda';
import { ReactComponent as Search } from '../assets/search.svg';
import { ReactComponent as BusWhite } from '../assets/bus-white.svg';
import { ReactComponent as ChevronPrimary } from '../assets/chevron-primary.svg';
import { IBeacon } from '../interfaces';
import { axios } from '../utils';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  top: {
    display: 'flex',
    height: 90,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  header: {
    fontSize: 35,
    fontWeight: 700,
    color: '#37001F',
  },
  percents: {
    display: 'flex',
    alignItems: 'baseline',
  },
  percentHeader: {
    fontSize: 35,
    fontWeight: 700,
    marginRight: 8,
  },
  percentText: {
    fontSize: 20,
    fontWeight: 700,
    marginRight: 24,
  },
  bottom: {
    display: 'flex',
  },
  left: {
    width: 800,
    backgroundColor: '#F5F5F5',
    padding: 16,
  },
  map: {
    flexGrow: 1,
    height: 490,
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    width: 310,
    marginRight: 24,
  },
  checkboxRow: {
    display: 'flex',
  },
  checkboxContainer: {
    width: 140,
    '&:first-child': {
      width: 165,
    },
    '&:last-child': {
      width: 120,
    },
  },
  checkbox: {
    padding: 4,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 24,
    border: '1px solid #37001F',
  },
  cell: {
    padding: '8px 16px',
    fontSize: 16,
    color: '#37001F',
    fontWeight: 300,
    border: '1px solid #37001F',
    borderWidth: '0 1px',
    '&:first-child': {
      textAlign: 'right',
      width: 140,
      fontWeight: 700,
    },
    '&:last-child': {
      width: 200,
    },
  },
  headerCell: {
    fontWeight: 400,
    padding: 16,
    border: '1px solid #37001F',
    '&:first-child': {
      fontWeight: 400,
    },
  },
  marker: {
    borderRadius: '50%',
  },
  markerOperational: {
    backgroundColor: '#188D15',
  },
  markerNeedAttention: {
    backgroundColor: '#FF8022',
  },
  markerMalfunction: {
    backgroundColor: '#C41515',
  },
  tableRow: {
    '&:nth-child(even)': {
      backgroundColor: 'white',
    },
  },
  popup: {
    backgroundColor: 'white',
    borderRadius: 3,
    '& > :first-child': {
      boxShadow: 'none',
      padding: 0,
      '& > div': {
        margin: '16px 24px',
      },
    },
    '& > :last-child': {
      display: 'none',
    },
  },
  popupHeader: {
    backgroundColor: '#37001F',
    color: 'white',
    fontSize: 16,
    fontWeight: 400,
    margin: '0 0 12px -24px !important',
    padding: '4px 16px 4px 24px',
    borderRadius: '0 15px 15px 0',
    display: 'inline-block',
  },
  line: {
    margin: '0 !important',
    fontSize: 14,
    fontWeight: 400,
    color: '#37001F',
  },
  operational: {
    color: '#188D15',
  },
  needAttention: {
    color: '#FF8022',
  },
  malfunction: {
    color: '#C41515',
  },
  chevronFlipped: {
    transform: 'rotate(180deg)',
  },
  sortButton: {
    marginRight: -12,
  },
});

export default function Dashboard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const mapRef = useRef<Map>(null);
  const [beacons, setBeacons] = useState<IBeacon[]>([]);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/beacons/status');
      setBeacons(data);
    })();
  }, []);
  const [search, setSearch] = useState('');
  const [operational, setOperational] = useState(true);
  const [malfunction, setMalfunction] = useState(true);
  const [mobile, setMobile] = useState(true);
  const [needAttention, setNeedAttention] = useState(true);
  const [stationary, setStationary] = useState(true);
  const all = operational && malfunction && mobile && needAttention && stationary;
  const [sortColumn, setSortColumn] = useState<'id' | 'issue' | 'assignment'>('id');
  const [sortAsc, setSortAsc] = useState(true);
  const getIssueText = (beacon: IBeacon) => {
    const lastData = moment(beacon.timestamp);
    const time = lastData.format('H:mm');
    if (beacon.issue?.kind === 'oldData') {
      return `${t('beacon.lastData')} ${lastData.format('MMM D')} ${t('beacon.at')}
        ${time} (${moment().diff(lastData, 'days')} ${t('beacon.daysAgo')})`;
    }
    if (beacon.issue?.kind === 'batteryLevel') {
      return `${t('beacon.batteryLevelTable')} ${beacon.powerValue}% ${t('beacon.todayAt')} ${time}`;
    }
    return t('beacon.tableOperational');
  };
  const filteredBeacons = beacons.filter((beacon) => (
    (((operational && !beacon.issue)
      || (malfunction && beacon.issue?.alertLevel === 2)
      || (needAttention && beacon.issue?.alertLevel === 1)
    ) && (
      (mobile && beacon.category === 'mobile')
      || (stationary && beacon.category === 'stationary'))
    ) && (String(beacon.id).includes(search) || beacon.assignment.includes(search)
      || getIssueText(beacon).includes(search))
  ));
  const beaconsOnMap = filteredBeacons.filter((beacon) => beacon.lat && beacon.lon);
  useEffect(() => {
    if (mapRef.current && beaconsOnMap.length >= 2) {
      mapRef.current.leafletElement.fitBounds(
        new LatLngBounds(beaconsOnMap.map(({ lat, lon }) => [lat, lon])),
        { padding: [16, 16] },
      );
    }
  }, [beaconsOnMap]);
  const operationalCount = beacons.filter((x) => !x.issue).length;
  const needAttentionCount = beacons.filter((x) => x.issue?.alertLevel === 1).length;
  const malfunctionCount = beacons.filter((x) => x.issue?.alertLevel === 2).length;
  if (beacons.length === 0) return null;
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Typography className={classes.header}>
          {t('beacon.beaconStatus')}
        </Typography>
        <div className={classes.percents}>
          <Typography className={classNames(classes.percentHeader, classes.operational)}>
            {`${Math.round((operationalCount / beacons.length) * 100)}%`}
          </Typography>
          <Typography className={classes.percentText}>
            {t('beacon.operational')}
          </Typography>
          <Typography className={classNames(classes.percentHeader, classes.needAttention)}>
            {`${Math.round((needAttentionCount / beacons.length) * 100)}%`}
          </Typography>
          <Typography className={classes.percentText}>
            {t('beacon.needAttention')}
          </Typography>
          <Typography className={classNames(classes.percentHeader, classes.malfunction)}>
            {`${Math.round((malfunctionCount / beacons.length) * 100)}%`}
          </Typography>
          <Typography className={classes.percentText}>
            {t('beacon.malfunction')}
          </Typography>
        </div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.left}>
          <div className={classes.filters}>
            <TextField
              label={t('beacon.dashboardSearch')}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              className={classes.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
            />
            <div>
              <div className={classes.checkboxRow}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={operational}
                      onChange={({ target }) => setOperational(target.checked)}
                      color="primary"
                      className={classes.checkbox}
                    />
                  )}
                  label={t('beacon.operational')}
                  className={classes.checkboxContainer}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={malfunction}
                      onChange={({ target }) => setMalfunction(target.checked)}
                      color="primary"
                      className={classes.checkbox}
                    />
                  )}
                  label={t('beacon.malfunction')}
                  className={classes.checkboxContainer}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={mobile}
                      onChange={({ target }) => setMobile(target.checked)}
                      color="primary"
                      className={classes.checkbox}
                    />
                  )}
                  label={t('beacon.mobile')}
                  className={classes.checkboxContainer}
                />
              </div>
              <div className={classes.checkboxRow}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={needAttention}
                      onChange={({ target }) => setNeedAttention(target.checked)}
                      color="primary"
                      className={classes.checkbox}
                    />
                  )}
                  label={t('beacon.needAttention')}
                  className={classes.checkboxContainer}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={stationary}
                      onChange={({ target }) => setStationary(target.checked)}
                      color="primary"
                      className={classes.checkbox}
                    />
                  )}
                  label={t('beacon.stationary')}
                  className={classes.checkboxContainer}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={all}
                      onChange={({ target }) => {
                        setOperational(target.checked);
                        setMalfunction(target.checked);
                        setMobile(target.checked);
                        setNeedAttention(target.checked);
                        setStationary(target.checked);
                      }}
                      color="primary"
                      className={classes.checkbox}
                    />
                  )}
                  label={t('beacon.all')}
                  className={classes.checkboxContainer}
                />
              </div>
            </div>
          </div>
          <table className={classes.table}>
            <thead>
              <tr>
                <td className={classNames(classes.cell, classes.headerCell)}>
                  {t('beacon.beaconID')}
                  <IconButton
                    color="primary"
                    className={classes.sortButton}
                    onClick={() => (sortColumn === 'id' ? setSortAsc(!sortAsc) : setSortColumn('id'))}
                  >
                    <ChevronPrimary className={(sortColumn === 'id' && sortAsc) ? classes.chevronFlipped : ''} />
                  </IconButton>
                </td>
                <td className={classNames(classes.cell, classes.headerCell)}>
                  {t('beacon.issue')}
                  <IconButton
                    color="primary"
                    className={classes.sortButton}
                    onClick={() => (sortColumn === 'issue' ? setSortAsc(!sortAsc) : setSortColumn('issue'))}
                  >
                    <ChevronPrimary className={(sortColumn === 'issue' && sortAsc) ? classes.chevronFlipped : ''} />
                  </IconButton>
                </td>
                <td className={classNames(classes.cell, classes.headerCell)}>
                  {t('beacon.assignedTo')}
                  <IconButton
                    color="primary"
                    className={classes.sortButton}
                    onClick={() => (
                      sortColumn === 'assignment' ? setSortAsc(!sortAsc) : setSortColumn('assignment')
                    )}
                  >
                    <ChevronPrimary className={(sortColumn === 'assignment' && sortAsc) ? classes.chevronFlipped : ''} />
                  </IconButton>
                </td>
              </tr>
            </thead>
            <tbody>
              {R.sort((() => {
                const sortFn = sortAsc ? R.ascend : R.descend;
                if (sortColumn === 'issue') {
                  return sortFn((x: IBeacon) => (
                    `${x.issue?.alertLevel || 0}${getIssueText(x)}`
                  ));
                }
                return sortFn((x: IBeacon) => x[sortColumn]);
              })(), filteredBeacons).map((beacon) => (
                <tr key={beacon.id + beacon.timestamp} className={classes.tableRow}>
                  <td
                    className={classNames(classes.cell, {
                      [classes.operational]: !beacon.issue,
                      [classes.needAttention]: beacon.issue?.alertLevel === 1,
                      [classes.malfunction]: beacon.issue?.alertLevel === 2,
                    })}
                  >
                    {beacon.id}
                  </td>
                  <td className={classes.cell}>
                    {getIssueText(beacon)}
                  </td>
                  <td className={classes.cell}>
                    {beacon.assignment}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Map
          ref={mapRef}
          className={classes.map}
          minZoom={3}
          maxZoom={18}
          fullscreenControl
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ScaleControl position="topright" imperial={false} />
          {beaconsOnMap.map((beacon) => {
            const lastData = moment(beacon.timestamp);
            const time = lastData.format('H:mm');
            return (
              <Marker
                key={beacon.id + beacon.timestamp}
                position={[beacon.lat, beacon.lon]}
                icon={leaflet.divIcon({
                  className: classNames(classes.marker, {
                    [classes.markerOperational]: !beacon.issue,
                    [classes.markerNeedAttention]: beacon.issue?.alertLevel === 1,
                    [classes.markerMalfunction]: beacon.issue?.alertLevel === 2,
                  }),
                  html: '<div />',
                  iconSize: [10, 10],
                })}
              >
                <Popup
                  className={classes.popup}
                  closeButton={false}
                  maxWidth={400}
                >
                  <Typography className={classes.popupHeader}>
                    {`ID: ${beacon.id}`}
                    <br />
                    {beacon.category === 'stationary'
                      ? `${beacon.type === 'info' ? 'POI: ' : ''}${beacon.assignment}`
                      : <BusWhite />}
                  </Typography>
                  <Typography className={classes.line}>
                    {`${t('beacon.supply')}: ${t('beacon.battery')}`}
                  </Typography>
                  <Typography
                    className={classNames(classes.line, {
                      [classes.operational]: beacon.issue === null,
                      [classes.needAttention]: beacon.issue?.alertLevel === 1,
                      [classes.malfunction]: beacon.issue?.alertLevel === 2,
                    })}
                  >
                    {`${t('beacon.batteryLevel')}: ${beacon.powerValue}%`}
                  </Typography>
                  <Typography className={classes.line}>
                    {`${t('beacon.dataReceivedOn')} ${lastData.format('MMM D')} ${t('beacon.at')}
                    ${time} (${moment().diff(lastData, 'days')} ${t('beacon.daysAgo')})`}
                  </Typography>
                </Popup>
              </Marker>
            );
          })}
        </Map>
      </div>
    </div>
  );
}
