import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Button, Menu, MenuItem, Typography, useMediaQuery, IconButton,
} from '@material-ui/core';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { IDataVersion, IRole } from '../interfaces';
import AppContext, { ProviderContext } from '../context';
import { auth0, useGetLink } from '../utils';
import { ReactComponent as RolesIcon } from '../assets/roles.svg';
import { ReactComponent as LogOut } from '../assets/log-out.svg';
import { ReactComponent as Switch } from '../assets/switch.svg';
import { ReactComponent as DataSource } from '../assets/data-source.svg';
import { ReactComponent as Organizations } from '../assets/organizations.svg';
import { ReactComponent as Help } from '../assets/help.svg';
import { ReactComponent as Hamburger } from '../assets/hamburger.svg';
import { ReactComponent as MenuHome } from '../assets/menu-home.svg';
import { ReactComponent as MenuCard } from '../assets/menu-card.svg';
import { ReactComponent as MenuTimetables } from '../assets/menu-timetables.svg';

interface Props {
  name?: string;
  role?: IRole;
  agency?: string | null;
  showRoles?: boolean;
  versions?: IDataVersion[];
  version?: IDataVersion;
  setVersion?: (version: number) => void;
  passenger?: boolean;
  interconnectNumber?: string;
}

const useStyles = makeStyles({
  user: {
    display: 'flex',
    minWidth: 22,
    width: 22,
    height: 22,
    borderRadius: '50%',
    fontSize: 11,
    fontWeight: 400,
    padding: 0,
  },
  hamburger: {
    padding: 8,
  },
  menu: {
    marginTop: 32,
  },
  userInfo: {
    marginBottom: 8,
    '&:focus': {
      outline: 'none',
    },
  },
  signedInAs: {
    margin: '2px 16px',
    color: '#37001F',
  },
  name: {
    fontWeight: 400,
    color: '#37001F',
    margin: '2px 16px',
  },
  agency: {
    fontWeight: 400,
    color: '#37001F',
    margin: '2px 16px',
  },
  menuItem: {
    fontWeight: 300,
    color: '#37001F',
    alignItems: 'flex-start',
    minHeight: 0,
  },
  menuIcon: {
    marginRight: 16,
    width: 24,
  },
  versionsMenu: {
    marginLeft: -16,
    marginTop: -8,
  },
  dataViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#37001F',
  },
  smallText: {
    fontSize: 14,
  },
  versionsMenuItem: {
    fontWeight: 300,
    color: '#37001F',
  },
  current: {
    marginLeft: 8,
    fontSize: 14,
  },
  noIcon: {
    paddingLeft: 40,
  },
  userNumber: {
    color: '#37001F',
    margin: '2px 16px',
  },
});

export default function UserMenu({
  name, role, agency, showRoles, versions, version, setVersion, passenger, interconnectNumber,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { updateContext } = useContext(AppContext);
  const { providers } = useContext(ProviderContext);
  const getLink = useGetLink();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [versionsAnchorEl, setVersionsAnchorEl] = useState<HTMLElement | null>(null);
  const initials = (() => {
    if (!name) return null;
    const split = name.split(' ');
    return (split.length > 1 ? (split[0][0] + split[1][0]) : (name[0] + name[1]))
      .toUpperCase();
  })();
  const agencyName = providers.find(R.whereEq({ id: agency }))?.name;
  const isMobile = useMediaQuery('(max-width: 800px)');
  return (
    <>
      {initials ? (
        <Button
          color="primary"
          variant="contained"
          className={classes.user}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          {initials}
        </Button>
      ) : (
        <IconButton
          color="primary"
          className={classes.hamburger}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <Hamburger />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={() => setAnchorEl(null)}
        className={classes.menu}
      >
        {name && (
          <div className={classes.userInfo}>
            <Typography className={classes.signedInAs}>
              {t('nav.signedInAs')}
            </Typography>
            <Typography className={classes.name}>
              {name}
            </Typography>
            {agencyName && (
              <Typography className={classes.agency}>
                {agencyName}
              </Typography>
            )}
            {interconnectNumber && (
              <Typography className={classes.userNumber}>
                {`${t('nav.number')}${interconnectNumber}`}
              </Typography>
            )}
          </div>
        )}
        {(passenger && isMobile) && (
          [
            <MenuItem
              className={classes.menuItem}
              component={Link}
              to={getLink('/trip-planning')}
              onClick={() => setAnchorEl(null)}
              key="1"
            >
              <MenuHome className={classes.menuIcon} />
              {t('nav.tripPlanner')}
            </MenuItem>,
            ...(name ? [(
              <MenuItem
                className={classes.menuItem}
                component={Link}
                to={getLink('/tourist-card')}
                onClick={() => setAnchorEl(null)}
                key="2"
              >
                <MenuCard className={classes.menuIcon} />
                {t('touristCard.myCard')}
              </MenuItem>
            )] : []),
            <MenuItem
              className={classes.menuItem}
              component={Link}
              to={getLink('/timetables')}
              onClick={() => setAnchorEl(null)}
              key="3"
            >
              <MenuTimetables className={classes.menuIcon} />
              {t('timetables.timetables')}
            </MenuItem>,
            <MenuItem
              className={classes.menuItem}
              component={Link}
              to={getLink('/support')}
              onClick={() => setAnchorEl(null)}
              key="4"
            >
              <Help className={classes.menuIcon} />
              {t('support.help')}
            </MenuItem>,
          ]
        )}
        {showRoles && (role === 'ADMIN' || role === 'AGENCY_ADMIN') && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={getLink('/provider/roles')}
            onClick={() => setAnchorEl(null)}
          >
            <RolesIcon className={classes.menuIcon} />
            {t('nav.assignRoles')}
          </MenuItem>
        )}
        {showRoles && role === 'ADMIN' && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={getLink('/provider/organizations')}
            onClick={() => setAnchorEl(null)}
          >
            <Organizations className={classes.menuIcon} />
            {t('nav.orgs')}
          </MenuItem>
        )}
        {showRoles && role === 'ADMIN' && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={getLink('/provider/data-source')}
            onClick={() => setAnchorEl(null)}
          >
            <DataSource className={classes.menuIcon} />
            {t('nav.setDataSource')}
          </MenuItem>
        )}
        {versions && version && setVersion && (
          <MenuItem
            className={classes.menuItem}
            onClick={(event) => setVersionsAnchorEl(event.currentTarget)}
          >
            <Switch className={classes.menuIcon} />
            <span className={classes.dataViewContainer}>
              <span>
                {t('nav.switchDataView')}
              </span>
              <span className={classes.smallText}>
                {t('nav.dataView')}
                :
              </span>
              <span className={classes.smallText}>
                {version.type === 'draft' ? t('nav.draft') : version.name}
              </span>
            </span>
          </MenuItem>
        )}
        {showRoles && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={getLink('/provider/support')}
            onClick={() => setAnchorEl(null)}
          >
            <Help className={classes.menuIcon} />
            {t('support.help')}
          </MenuItem>
        )}
        {name ? (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              setAnchorEl(null);
              window.localStorage.removeItem('accessToken');
              updateContext(R.set(R.lensProp('name'), null));
              auth0.logout({
                clientID: process.env.AUTH0_CLIENT_ID as string,
                returnTo: `${window.location.origin}${getLink('/auth/login')}`,
              });
            }}
          >
            <LogOut className={classes.menuIcon} />
            {t('nav.logOut')}
          </MenuItem>
        ) : (
          [
            <MenuItem
              className={classes.menuItem}
              component={Link}
              to={getLink('/auth/login')}
              onClick={() => setAnchorEl(null)}
              key="1"
            >
              <span className={classes.noIcon}>
                {t('auth.logIn')}
              </span>
            </MenuItem>,
            <MenuItem
              className={classes.menuItem}
              component={Link}
              to={getLink('/auth/register')}
              onClick={() => setAnchorEl(null)}
              key="2"
            >
              <span className={classes.noIcon}>
                {t('auth.register')}
              </span>
            </MenuItem>,
          ]
        )}
      </Menu>
      {versions && version && setVersion && (
        <Menu
          anchorEl={versionsAnchorEl}
          open={versionsAnchorEl !== null}
          onClose={() => setVersionsAnchorEl(null)}
          className={classes.versionsMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {versions.map((x) => (
            <MenuItem
              key={x.id}
              className={classes.versionsMenuItem}
              onClick={() => {
                setVersionsAnchorEl(null);
                setVersion(x.id);
                localStorage.setItem('version', String(x.id));
              }}
            >
              {x.type === 'draft' ? t('nav.draft') : x.name}
              {x.type === 'current' && (
                <span className={classes.current}>
                  {t('nav.current')}
                </span>
              )}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}
