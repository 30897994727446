import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import falaLogo from '../../assets/fala.svg';

interface Props {
  agency: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  falaButton: {
    width: 98,
    justifyContent: 'space-between',
    padding: '3px 15px',
    color: '#3571B7',
    borderColor: '#3571B7',
  },
  falaLogo: {
    width: 24,
    height: 26,
  },
  comingUp: {
    marginTop: 4,
    fontSize: 12,
  },
});

export default function BuyTicketButton({ agency }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  if (agency === 'Stena Line' || agency === 'Blekinge trafiken') {
    return (
      <Button
        variant="contained"
        color="primary"
        component="a"
        href={agency === 'Stena Line' ? 'https://www.stenaline.se/till-polen' : 'https://blekingetrafiken.se/#/kop-biljett'}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('planning.buyTicket')}
      </Button>
    );
  }
  return (
    <div className={classes.container}>
      <Button
        variant="outlined"
        className={classes.falaButton}
        component="a"
        href="http://innobaltica.pl/1_141_pzum-bilet-fala.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('planning.buy')}
        <img
          src={falaLogo}
          alt="FALA"
          className={classes.falaLogo}
        />
      </Button>
      <Typography className={classes.comingUp}>
        {t('planning.comingUp')}
      </Typography>
    </div>
  );
}
