import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import { NavLink, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/interconnect-logo.svg';
import UserMenu from '../common/UserMenu';
import AppContext from '../context';
import { useGetLink } from '../utils';
import { ReactComponent as HomeActiveIcon } from '../assets/home-active.svg';
import { ReactComponent as HomeIcon } from '../assets/home.svg';

const useStyles = makeStyles({
  nav: {
    width: '100%',
    height: 43,
    flexShrink: 0,
    padding: '0 32px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(232, 232, 232, 0.5)',
    '@media (max-width: 800px)': {
      padding: '0 8px',
    },
  },
  logo: {
    height: 41,
    marginRight: 'auto',
  },
  navIconLink: {
    display: 'flex',
    justifyContent: 'center',
    width: 100,
    padding: '8px 0',
    '@media (max-width: 800px)': {
      width: 60,
    },
  },
  navLink: {
    color: '#a1145c',
    fontSize: 20,
    textDecoration: 'none',
    marginRight: 36,
    whiteSpace: 'nowrap',
    '@media (max-width: 800px)': {
      marginRight: 12,
    },
  },
  active: {
    fontWeight: 700,
  },
});

export default function Nav() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, name, interconnectNumber } = useContext(AppContext);
  const getLink = useGetLink();
  const isMobile = useMediaQuery('(max-width: 800px)');
  return (
    <div className={classes.nav}>
      <img
        src={logo}
        alt="Interconnect logo"
        className={classes.logo}
      />
      {!isMobile && (
        <>
          <Typography
            component={NavLink}
            to={getLink('/trip-planning')}
            className={classes.navIconLink}
            activeClassName={classes.active}
          >
            <Switch>
              <Route path="/:language/trip-planning" exact render={() => <HomeActiveIcon />} />
              <Route path="/" render={() => <HomeIcon />} />
            </Switch>
          </Typography>
          {(user && name) ? (
            <Typography
              component={NavLink}
              to={getLink('/tourist-card')}
              className={classes.navLink}
              activeClassName={classes.active}
            >
              {t('touristCard.myCard')}
            </Typography>
          ) : (
            <Typography
              component={NavLink}
              to={getLink('/auth/login')}
              className={classes.navLink}
            >
              {t('auth.logIn')}
            </Typography>
          )}
          <Typography
            component={NavLink}
            to={getLink('/timetables')}
            className={classes.navLink}
            activeClassName={classes.active}
          >
            {t('timetables.timetables')}
          </Typography>
          <Typography
            component={NavLink}
            to={getLink('/support')}
            className={classes.navLink}
            activeClassName={classes.active}
          >
            {t('support.help')}
          </Typography>
        </>
      )}
      {(name || isMobile) && (
        <UserMenu
          name={name}
          role={user?.role}
          agency={user?.agency}
          passenger
          interconnectNumber={interconnectNumber}
        />
      )}
    </div>
  );
}
