import React, { useContext, useEffect, useState } from 'react';
import {
  Button, IconButton, makeStyles, MenuItem, TextField, Typography, InputAdornment,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import * as R from 'ramda';
import AppContext, { ProviderContext } from '../context';
import CustomAutocomplete from '../common/CustomAutocomplete';
import { IProvider, IRole, IRolesUser } from '../interfaces';
import { axios } from '../utils';
import { ReactComponent as ChevronWhite } from '../assets/chevron-white.svg';
import { ReactComponent as ChevronPrimary } from '../assets/chevron-primary.svg';
import { ReactComponent as ChevronBig } from '../assets/chevron-big.svg';
import { ReactComponent as Search } from '../assets/search.svg';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    width: '100%',
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    color: '#37001F',
    marginBottom: 24,
  },
  search: {
    width: 600,
    backgroundColor: 'white',
  },
  content: {
    backgroundColor: '#F5F5F5',
    padding: 16,
    margin: '16px -16px',
  },
  table: {
    borderCollapse: 'collapse',
    backgroundColor: 'white',
    width: '100%',
  },
  cell: {
    fontSize: 16,
    fontWeight: 300,
    color: '#37001F',
    padding: '16px 20px',
    border: '1px solid #37001F',
    textAlign: 'left',
    position: 'relative',
    '&:nth-child(1)': {
      width: 60,
    },
    '&:nth-child(4)': {
      width: 260,
    },
    '&:nth-child(5)': {
      width: 300,
    },
  },
  headerCell: {
    fontWeight: 400,
  },
  numberCell: {
    textAlign: 'right',
  },
  roleSelect: {
    width: '100%',
  },
  agencySelect: {
    width: '100%',
  },
  nextPageButton: {
    minWidth: 20,
    width: 20,
    height: 20,
    borderRadius: '50%',
    padding: 0,
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translate(50%, 50%)',
    zIndex: 2,
    '&$pageButtonDisabled': {
      backgroundColor: '#b9b9b9',
    },
  },
  pageButtonDisabled: {},
  chevronFlipped: {
    transform: 'rotate(180deg)',
  },
  legend: {
    marginTop: 32,
  },
  legendElement: {
    display: 'flex',
  },
  legendTerm: {
    width: 170,
    flexShrink: 0,
    fontWeight: 400,
    fontSize: 14,
    color: '#37001F',
  },
  legendDescription: {
    fontSize: 14,
    color: '#37001F',
  },
  saveContainer: {
    display: 'flex',
    margin: '8px 0 24px',
  },
  discardButton: {
    marginLeft: 'auto',
    marginRight: 16,
  },
  sortButton: {
    width: 28,
    height: 28,
    padding: 4,
    margin: '-4px -6px -4px 4px',
  },
  addUser: {
    display: 'flex',
    marginBottom: 24,
  },
  addUserSelect: {
    width: 600,
    marginRight: 16,
  },
  addUserEmail: {
    width: 300,
    marginRight: 16,
  },
  addUserName: {
    width: 300,
    marginRight: 16,
  },
  addUserRole: {
    width: 235,
    marginRight: 16,
  },
  addUserAgency: {
    width: 300,
    marginRight: 16,
  },
  addUserOption: {
    display: 'flex',
    alignItems: 'center',
  },
  selectBorder: {
    border: 'none',
  },
  selectCell: {
    padding: '0 8px',
  },
  selectError: {
    border: '1px solid #f44336',
    margin: -1,
    padding: '1px 0',
    zIndex: 1,
    width: 'calc(100% + 2px)',
  },
  selectIconContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
  },
  selectIcon: {
    transform: 'rotate(90deg)',
  },
  selectInput: {
    fontWeight: 300,
    color: 'inherit',
  },
});

export default function Roles() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppContext);
  const { providers, user: loggedInUser } = useContext(ProviderContext);
  const [users, setUsers] = useState<IRolesUser[] | null>(null);
  const [originalUsers, setOriginalUsers] = useState<IRolesUser[] | null>(null);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/users');
      setUsers(data);
      setOriginalUsers(data);
    })();
  }, []);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState<keyof IRolesUser>('name');
  const [sortDescending, setSortDescending] = useState(false);
  const [addUserEmail, setAddUserEmail] = useState('');
  const [addUserName, setAddUserName] = useState('');
  const [addUserRole, setAddUserRole] = useState<IRole | ''>('');
  const [addUserAgency, setAddUserAgency] = useState<IProvider | null>(null);
  const [agencyErrors, setAgencyErrors] = useState<Record<string, boolean>>({});
  if (!users || !originalUsers) return null;
  const getRoleText = (role: IRole) => {
    switch (role) {
      case 'ADMIN':
        return t('roles.admin');
      case 'AGENCY_ADMIN':
        return t('roles.agencyAdmin');
      case 'AGENCY_EMPL':
        return t('roles.agencyEmployee');
      case 'TECHNICIAN':
        return t('roles.technician');
      case 'TICKET_INSPECTOR':
        return t('roles.ticketInspector');
      default:
        return '';
    }
  };
  const searchLower = search.toLowerCase();
  const filteredUsers = R.sort(
    (sortDescending ? R.ascend : R.descend)(R.compose(R.defaultTo(''), R.prop(sortColumn))),
    users.filter((user) => (
      user.name.toLowerCase().includes(searchLower)
      || user.email.toLowerCase().includes(searchLower)
      || getRoleText(user.role).toLowerCase().includes(searchLower)
      || providers.find(R.whereEq({ id: user.agency }))?.name.toLowerCase().includes(searchLower)
    )),
  );
  const pageSize = 10;
  const renderSortButton = (column: keyof IRolesUser) => (
    <IconButton
      className={classes.sortButton}
      onClick={() => {
        if (sortColumn === column) {
          setSortDescending(R.not);
        } else {
          setSortColumn(column);
          setSortDescending(false);
        }
      }}
    >
      <ChevronPrimary className={sortColumn === column && sortDescending ? '' : classes.chevronFlipped} />
    </IconButton>
  );
  const roleOptions = [
    loggedInUser.role === 'ADMIN' ? (
      <MenuItem key={1} value="ADMIN">
        {t('roles.admin')}
      </MenuItem>
    ) : null,
    <MenuItem key={2} value="AGENCY_ADMIN">
      {t('roles.agencyAdmin')}
    </MenuItem>,
    <MenuItem key={3} value="AGENCY_EMPL">
      {t('roles.agencyEmployee')}
    </MenuItem>,
    loggedInUser.role === 'ADMIN' ? (
      <MenuItem key={4} value="TECHNICIAN">
        {t('roles.technician')}
      </MenuItem>
    ) : null,
    <MenuItem key={5} value="TICKET_INSPECTOR">
      {t('roles.ticketInspector')}
    </MenuItem>,
    <MenuItem key={6} value="">
      {t('roles.none')}
    </MenuItem>,
  ];
  const getChangedUsers = (currentUsers: IRolesUser[]) => currentUsers.map((user) => ({
    ...user,
    role: user.role || null,
  })).filter((user) => (
    !R.equals(originalUsers.find(R.whereEq({ email: user.email })), user)
  ));
  const saveUsers = async (currentUsers: IRolesUser[]) => {
    const newUsers = currentUsers.map((user) => ({
      ...user,
      role: user.role || null,
    }));
    const changedUsers = getChangedUsers(currentUsers);
    let valid = true;
    changedUsers.forEach((user) => {
      if ((user.role === 'AGENCY_ADMIN' || user.role === 'AGENCY_EMPL') && !user.agency) {
        setAgencyErrors(R.set(R.lensProp(user.email), true));
        valid = false;
      }
    });
    if (!valid) return;
    const newOriginalUsers = newUsers.filter((user) => user.role !== null);
    setSaveLoading(true);
    try {
      await axios.post('/users/rights', changedUsers);
      setUsers(newOriginalUsers);
      setOriginalUsers(newOriginalUsers);
      showSnackbar(t('roles.success'));
    } catch {
      showSnackbar(t('roles.failure'));
    }
    setSaveLoading(false);
  };
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {t('roles.assignRoles')}
      </Typography>
      <div className={classes.addUser}>
        <TextField
          value={addUserName}
          onChange={({ target }) => setAddUserName(target.value)}
          className={classes.addUserName}
          variant="outlined"
          size="small"
          label={t('roles.addName')}
          id="add-name"
        />
        <TextField
          value={addUserEmail}
          onChange={({ target }) => setAddUserEmail(target.value)}
          className={classes.addUserEmail}
          variant="outlined"
          size="small"
          label={t('roles.addEmail')}
          id="add-email"
        />
        <TextField
          select
          value={addUserRole}
          onChange={({ target: { value } }) => {
            setAddUserRole(value as IRole);
            if (value === 'ADMIN' || value === 'TECHNICIAN') {
              setAddUserAgency(null);
            }
          }}
          className={classes.addUserRole}
          variant="outlined"
          size="small"
          label={t('roles.addRole')}
          id="add-role"
        >
          {roleOptions}
        </TextField>
        <CustomAutocomplete
          options={loggedInUser.role === 'ADMIN' ? providers
            : providers.filter(R.whereEq({ id: loggedInUser.agency }))}
          value={addUserAgency}
          onChange={(event, value) => setAddUserAgency(value)}
          className={classes.addUserAgency}
          disabled={!['AGENCY_ADMIN', 'AGENCY_EMPL', 'TICKET_INSPECTOR'].includes(addUserRole || '')}
          label={t('roles.addAgency')}
          getOptionLabel={R.prop('name')}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={(
            saveLoading || !addUserEmail || !addUserName || !addUserRole
            || ((addUserRole === 'AGENCY_ADMIN' || addUserRole === 'AGENCY_EMPL') && !addUserAgency)
          )}
          onClick={async () => {
            if (addUserEmail && addUserName && addUserRole) {
              await saveUsers(R.append(
                {
                  email: addUserEmail,
                  name: addUserName,
                  role: addUserRole,
                  agency: addUserAgency ? addUserAgency.id : null,
                },
                users,
              ));
              setAddUserEmail('');
              setAddUserName('');
              setAddUserRole('');
              setAddUserAgency(null);
            }
          }}
        >
          {t('roles.add')}
        </Button>
      </div>
      <div className={classes.content}>
        <div className={classes.saveContainer}>
          <TextField
            value={search}
            onChange={({ target }) => {
              setSearch(target.value);
              setPage(0);
            }}
            label={t('roles.search')}
            className={classes.search}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Button
            color="primary"
            className={classes.discardButton}
            onClick={() => {
              setUsers(originalUsers);
              setAgencyErrors({});
            }}
            disabled={getChangedUsers(users).length === 0}
          >
            {t('roles.discardChanges')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={saveLoading || getChangedUsers(users).length === 0}
            onClick={() => saveUsers(users)}
          >
            {t('roles.save')}
          </Button>
        </div>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classNames(classes.cell, classes.headerCell, classes.numberCell)}>
                {t('roles.no')}
              </th>
              <th className={classNames(classes.cell, classes.headerCell)}>
                {t('roles.name')}
                {renderSortButton('name')}
              </th>
              <th className={classNames(classes.cell, classes.headerCell)}>
                {t('roles.email')}
                {renderSortButton('email')}
              </th>
              <th className={classNames(classes.cell, classes.headerCell)}>
                {t('roles.role')}
                {renderSortButton('role')}
              </th>
              <th className={classNames(classes.cell, classes.headerCell)}>
                {t('roles.organization')}
                {renderSortButton('agency')}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setPage((x) => x - 1)}
                  disabled={page === 0}
                  classes={{
                    contained: classes.nextPageButton,
                    disabled: classes.pageButtonDisabled,
                  }}
                >
                  <ChevronWhite className={classes.chevronFlipped} />
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {R.slice(page * pageSize, (page + 1) * pageSize, filteredUsers).map((user, i, arr) => (
              <tr key={user.email}>
                <td className={classNames(classes.cell, classes.numberCell)}>
                  {filteredUsers.indexOf(user) + 1}
                </td>
                <td className={classes.cell}>
                  {user.name}
                </td>
                <td className={classes.cell}>
                  {user.email}
                </td>
                <td className={classNames(classes.cell, classes.selectCell)}>
                  <TextField
                    select
                    value={user.role}
                    onChange={({ target: { value } }) => setUsers(
                      R.map((x) => (
                        x.email === user.email ? {
                          ...x,
                          role: value as IRole,
                          agency: (value === 'AGENCY_ADMIN' || value === 'AGENCY_EMPL') ? x.agency : null,
                        } : x
                      ), users),
                    )}
                    className={classes.roleSelect}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      className: classes.selectInput,
                      classes: { notchedOutline: classes.selectBorder },
                    }}
                    SelectProps={{
                      // eslint-disable-next-line react/prop-types
                      IconComponent: ({ className, ...rest }) => (
                        <div
                          {...rest}
                          className={classNames(className, classes.selectIconContainer)}
                        >
                          <ChevronBig className={classes.selectIcon} />
                        </div>
                      ),
                    }}
                  >
                    {roleOptions}
                  </TextField>
                </td>
                <td className={classNames(classes.cell, classes.selectCell)}>
                  <CustomAutocomplete
                    options={loggedInUser.role === 'ADMIN' ? providers
                      : providers.filter(R.whereEq({ id: loggedInUser.agency }))}
                    value={providers.find(R.whereEq({ id: user.agency })) || null}
                    onChange={(event, value) => {
                      setUsers(
                        R.map((x) => (
                          x.email === user.email ? { ...x, agency: value ? value.id : null } : x
                        ), users),
                      );
                      setAgencyErrors(R.set(R.lensProp(user.email), false));
                    }}
                    className={classNames(classes.agencySelect, {
                      [classes.selectError]: agencyErrors[user.email],
                    })}
                    disabled={!['AGENCY_ADMIN', 'AGENCY_EMPL', 'TICKET_INSPECTOR'].includes(user.role || '')}
                    TextFieldProps={{
                      InputProps: {
                        className: classes.selectInput,
                        classes: { notchedOutline: classes.selectBorder },
                      },
                    }}
                    getOptionLabel={R.prop('name')}
                    noAdornment
                    popupIcon={(
                      <div className={classes.selectIconContainer}>
                        <ChevronBig className={classes.selectIcon} />
                      </div>
                    )}
                  />
                  {i === arr.length - 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setPage(R.add(1))}
                      disabled={(page + 1) * pageSize >= filteredUsers.length}
                      classes={{
                        contained: classes.nextPageButton,
                        disabled: classes.pageButtonDisabled,
                      }}
                    >
                      <ChevronWhite />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={classes.legend}>
          <Typography className={classes.legendElement}>
            <span className={classes.legendTerm}>
              {t('roles.admin')}
            </span>
            <span className={classes.legendDescription}>
              {t('roles.adminDescription')}
            </span>
          </Typography>
          <Typography className={classes.legendElement}>
            <span className={classes.legendTerm}>
              {t('roles.agencyAdmin')}
            </span>
            <span className={classes.legendDescription}>
              {t('roles.agencyAdminDescription')}
            </span>
          </Typography>
          <Typography className={classes.legendElement}>
            <span className={classes.legendTerm}>
              {t('roles.agencyEmployee')}
            </span>
            <span className={classes.legendDescription}>
              {t('roles.agencyEmployeeDescription')}
            </span>
          </Typography>
          <Typography className={classes.legendElement}>
            <span className={classes.legendTerm}>
              {t('roles.technician')}
            </span>
            <span className={classes.legendDescription}>
              {t('roles.technicianDescription')}
            </span>
          </Typography>
          <Typography className={classes.legendElement}>
            <span className={classes.legendTerm}>
              {t('roles.ticketInspector')}
            </span>
            <span className={classes.legendDescription}>
              {t('roles.ticketInspectorDescription')}
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
}
