import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Typography, IconButton, Button, useMediaQuery,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import * as R from 'ramda';
import { IItinerary } from '../../interfaces';
import { ReactComponent as Walk } from '../../assets/walk.svg';
import { ReactComponent as Train } from '../../assets/train.svg';
import { ReactComponent as Tram } from '../../assets/tram.svg';
import { ReactComponent as Bus } from '../../assets/bus.svg';
import { ReactComponent as Chevron } from '../../assets/chevron-small.svg';
import { ReactComponent as Marker } from '../../assets/marker-outlined-dark.svg';
import { ReactComponent as ChevronBig } from '../../assets/chevron-big.svg';
import { ReactComponent as Bookmark } from '../../assets/bookmark.svg';
import { ReactComponent as Share } from '../../assets/share.svg';
import { ReactComponent as ChevronPrimary } from '../../assets/chevron-primary.svg';
import BuyTicketButton from './BuyTicketButton';
import BuyTickets from './BuyTickets';

interface Props {
  itineraries: IItinerary[];
  openItinerary: number | null;
  setOpenItinerary: (value: number | null) => void;
  from: string;
  to: string;
  date: Moment;
  onBookmark: () => void;
  onShare: () => void;
  onHover: (i: number) => void;
  hoveredItinerary: number;
}

const useStyles = makeStyles({
  root: {
    margin: 16,
  },
  itinerary: {
    padding: '4px 12px',
    backgroundColor: 'white',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
    marginBottom: 8,
    color: '#37001F',
    cursor: 'pointer',
    border: '4px solid white',
    transition: 'border-color 200ms, transform 200ms',
  },
  hovered: {
    borderColor: '#3571B7',
    transform: 'translateX(8px)',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  from: {
    fontWeight: 400,
  },
  duration: {
    fontWeight: 400,
    whiteSpace: 'nowrap',
    marginLeft: 8,
  },
  legs: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8,
  },
  leg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  time: {
    marginTop: 8,
    fontSize: 14,
  },
  destinationTime: {
    fontWeight: 400,
  },
  chevron: {
    margin: '8px 6px',
    flexShrink: 0,
  },
  destination: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 8px 0 auto',
  },
  route: {
    padding: '1px 6px',
    borderRadius: 7,
    backgroundColor: '#F5F5F5',
    margin: '1px 6px 0 0',
    fontSize: 14,
    fontWeight: 400,
  },
  chevronBig: {
    alignSelf: 'flex-end',
    margin: '0 0 2px 8px',
    flexShrink: 0,
  },
  details: {
    backgroundColor: 'white',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
    color: '#37001F',
  },
  detailsHeader: {
    padding: 16,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
  },
  buttons: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  backButton: {
    width: 43,
    marginLeft: -12,
  },
  chevronBack: {
    transform: 'rotate(180deg)',
  },
  bookmark: {
    marginLeft: 'auto',
    '@media (max-width: 800px)': {
      marginLeft: 0,
    },
  },
  share: {
    marginRight: 32,
    '@media (max-width: 800px)': {
      marginRight: 'auto',
    },
  },
  headerBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 6,
  },
  total: {
    fontWeight: 400,
    '@media (max-width: 800px)': {
      fontSize: 13,
    },
  },
  detailsLegs: {
    padding: 16,
  },
  stop: {
    marginBottom: 8,
    display: 'flex',
  },
  stopTime: {
    marginLeft: 'auto',
    fontSize: 14,
    fontWeight: 400,
  },
  walkLeg: {
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
  },
  walkIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '-12px 8px',
  },
  walkDot: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    margin: 4,
    backgroundColor: '#E4E4E4',
  },
  transitLeg: {
    display: 'flex',
  },
  legText: {
    fontSize: 12,
  },
  legRoute: {
    padding: '1px 6px',
    borderRadius: 7,
    backgroundColor: '#F5F5F5',
    fontSize: 14,
    fontWeight: 400,
    marginRight: 8,
  },
  expandButton: {
    fontSize: 12,
    lineHeight: '1',
    margin: '-4px -4px 8px',
    height: 28,
    color: '#A1145C',
  },
  buttonExpanded: {
    fontWeight: 400,
  },
  expandChevrons: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 8,
  },
  chevronFlipped: {
    transform: 'rotate(180deg)',
    marginBottom: 2,
    width: 8,
  },
  expandChevron: {
    width: 8,
  },
  shrinkButton: {
    minWidth: 0,
    margin: '8px -4px',
  },
  chevronShrink: {
    transform: 'rotate(180deg)',
    width: 8,
  },
  intermediateStop: {
    fontSize: 12,
    position: 'relative',
    '&:not(:last-of-type)': {
      marginBottom: 16,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 4,
      left: -24,
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#3571B7',
    },
  },
  startIcon: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    margin: '4px 13px',
    backgroundColor: '#E4E4E4',
  },
  transitModeIcon: {
    margin: '0 8px',
  },
  transitEndIcon: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    margin: '4px 13px',
    backgroundColor: '#E4E4E4',
    border: '5px solid #3571B7',
  },
  transitLine: {
    width: 4,
    backgroundColor: '#3571B7',
    margin: '-12px 18px -4px',
  },
  destinationIcon: {
    margin: '0 8px',
  },
  transitLegText: {
    fontSize: 12,
    marginBottom: 12,
  },
  hiddenLegs: {
    fontSize: 20,
    lineHeight: '22px',
  },
  resultsButtons: {
    display: 'flex',
    marginBottom: 8,
    marginTop: -8,
  },
});

const renderDuration = (duration: number) => {
  const momentDuration = moment.duration(duration, 'seconds');
  const days = momentDuration.days() > 0 ? `${momentDuration.days()} d ` : '';
  const hours = momentDuration.hours() > 0 ? `${momentDuration.hours()} h ` : '';
  const minutes = momentDuration.minutes() > 0 ? `${momentDuration.minutes()} min` : '';
  return `${days}${hours}${minutes}`.trim();
};

export default function Results({
  itineraries, openItinerary, setOpenItinerary, from, to, date, onBookmark,
  onShare, onHover, hoveredItinerary,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expandedLegs, setExpandedLegs] = useState<number[]>([]);
  const [buyTickets, setBuyTickets] = useState(false);
  const isMobile = useMediaQuery('(max-width: 800px)');
  useEffect(() => setExpandedLegs([]), [itineraries]);
  if (buyTickets && openItinerary !== null) {
    return (
      <BuyTickets
        itinerary={itineraries[openItinerary]}
        goBack={() => setBuyTickets(false)}
      />
    );
  }
  return (
    <div className={classes.root} data-testid="planner-results">
      {openItinerary === null ? (
        <>
          <div className={classes.resultsButtons}>
            <IconButton onClick={onBookmark} data-testid="bookmark">
              <Bookmark />
            </IconButton>
            <IconButton onClick={onShare} data-testid="share">
              <Share />
            </IconButton>
          </div>
          {itineraries.map((itinerary, j) => {
            const significantLegs = itinerary.legs.filter((leg) => leg.mode !== 'WALK' || leg.distance > 100);
            const legWidths = significantLegs.map((leg) => (leg.mode === 'WALK' ? 52 : 94));
            let legsToShow = 0;
            let remainingWidth = (isMobile ? window.innerWidth : 424) - 166;
            for (let i = 0; i < significantLegs.length; i += 1) {
              remainingWidth -= legWidths[i];
              if (remainingWidth > 0) {
                legsToShow += 1;
              } else {
                break;
              }
            }
            return (
              <div
                className={classNames(classes.itinerary, {
                  [classes.hovered]: j === hoveredItinerary,
                })}
                onClick={() => setOpenItinerary(j)}
                onMouseOver={() => onHover(j)}
                onFocus={() => onHover(j)}
                key={itinerary.id}
              >
                <div className={classes.top}>
                  <Typography>
                    {itinerary.legs.length > 1 && (
                      <>
                        <span className={classes.from}>
                          {t('planning.from')}
                        </span>
                        {': '}
                        {itinerary.legs[0].to.name}
                      </>
                    )}
                  </Typography>
                  <Typography className={classes.duration}>
                    {renderDuration(itinerary.duration)}
                  </Typography>
                </div>
                <div className={classes.legs}>
                  {R.take(legsToShow, significantLegs).map((leg) => (
                    <Fragment key={leg.startTime}>
                      <div className={classes.leg}>
                        {leg.mode === 'WALK' && <Walk />}
                        {leg.mode === 'RAIL' && <Train />}
                        {leg.mode === 'TRAM' && <Tram />}
                        {leg.mode === 'BUS' && <Bus />}
                        <Typography className={classes.time}>
                          {moment(leg.startTime).format('HH:mm')}
                        </Typography>
                      </div>
                      {leg.route !== '' && (
                        <Typography className={classes.route}>
                          {leg.route}
                        </Typography>
                      )}
                      <Chevron className={classes.chevron} />
                    </Fragment>
                  ))}
                  {significantLegs.length > legsToShow && (
                    <>
                      <Typography className={classes.hiddenLegs}>
                        +
                        {significantLegs.length - legsToShow}
                      </Typography>
                      <Chevron className={classes.chevron} />
                    </>
                  )}
                  <div className={classes.destination}>
                    <Marker />
                    <Typography className={classNames(classes.time, classes.destinationTime)}>
                      {moment(itinerary.endTime).format('HH:mm')}
                    </Typography>
                  </div>
                  <ChevronBig className={classes.chevronBig} />
                </div>
              </div>
            );
          })}
        </>
      ) : (() => {
        const itinerary = itineraries[openItinerary];
        const agencies = itinerary.legs.filter((x) => x.mode !== 'WALK').map(({ agencyName }) => (
          (agencyName === 'Stena Line' || agencyName === 'Blekinge trafiken') ? agencyName : 'FALA'
        ));
        const singleTicket = agencies.every((x) => x === agencies[0]);
        return (
          <div className={classes.details} data-testid="details">
            <div className={classes.detailsHeader}>
              <div className={classes.buttons}>
                {isMobile ? (
                  <Typography>
                    {date.format('MMM. D, YYYY, HH:mm')}
                  </Typography>
                ) : (
                  <IconButton
                    onClick={() => setOpenItinerary(null)}
                    className={classes.backButton}
                  >
                    <ChevronBig className={classes.chevronBack} />
                  </IconButton>
                )}
                {agencies.length > 0 && (
                  singleTicket ? (
                    <BuyTicketButton agency={agencies[0]} />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setBuyTickets(true)}
                    >
                      {t('planning.buyTicket')}
                    </Button>
                  )
                )}
              </div>
              <Typography className={classes.total}>
                {t('planning.total')}
                {': '}
                {renderDuration(itinerary.duration)}
              </Typography>
            </div>
            <div className={classes.detailsLegs}>
              <div className={classes.stop}>
                <div className={classes.startIcon} />
                <Typography>
                  {from}
                </Typography>
                <Typography className={classes.stopTime}>
                  {moment(itinerary.legs[0].from.departure).format('HH:mm')}
                </Typography>
              </div>
              {itinerary.legs.map((leg, legId) => {
                const minutesDuration = Math.ceil(leg.duration / 60);
                const minutesWaiting = Math.ceil((leg.to.departure - leg.to.arrival) / (1000 * 60));
                return (
                  <Fragment key={leg.to.lat}>
                    {leg.mode === 'WALK' ? (
                      <div className={classes.walkLeg}>
                        <div className={classes.walkIcon}>
                          <div className={classes.walkDot} />
                          <div className={classes.walkDot} />
                          <Walk />
                          <div className={classes.walkDot} />
                          <div className={classes.walkDot} />
                        </div>
                        <div>
                          <Typography className={classes.legText}>
                            {t('planning.walk')}
                            {' '}
                            {minutesDuration}
                            {' '}
                            {t('planning.minutes', { count: minutesDuration })}
                            {' ('}
                            {Math.round(leg.distance)}
                            {' m).'}
                          </Typography>
                          {minutesWaiting > 0 && (
                            <Typography className={classes.legText}>
                              {t('planning.waitingTime')}
                              {' '}
                              {minutesWaiting}
                              {' '}
                              {t('planning.minutes', { count: minutesWaiting })}
                              .
                            </Typography>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className={classes.transitLeg}>
                        <div className={classes.transitLine} />
                        <div>
                          <Typography className={classes.transitLegText}>
                            <span className={classes.legRoute}>
                              {leg.route}
                            </span>
                            {leg.headsign}
                          </Typography>
                          {leg.intermediateStops.length > 0 && (
                            <Button
                              className={classNames(classes.expandButton, {
                                [classes.buttonExpanded]: expandedLegs.includes(legId),
                              })}
                              onClick={() => {
                                if (expandedLegs.includes(legId)) {
                                  setExpandedLegs(expandedLegs.filter((x) => x !== legId));
                                } else {
                                  setExpandedLegs([...expandedLegs, legId]);
                                }
                              }}
                            >
                              <div className={classes.expandChevrons}>
                                {!expandedLegs.includes(legId) && (
                                  <ChevronPrimary className={classes.chevronFlipped} />
                                )}
                                <ChevronPrimary className={classes.expandChevron} />
                              </div>
                              {leg.intermediateStops.length + 1}
                              {' '}
                              {t('planning.stops', { count: leg.intermediateStops.length + 1 })}
                            </Button>
                          )}
                          {expandedLegs.includes(legId) && (
                            <>
                              {leg.intermediateStops.map((stop) => (
                                <Typography key={stop.name} className={classes.intermediateStop}>
                                  {stop.name}
                                </Typography>
                              ))}
                              <Button
                                className={classes.shrinkButton}
                                onClick={() => {
                                  setExpandedLegs(expandedLegs.filter((x) => x !== legId));
                                }}
                              >
                                <ChevronPrimary className={classes.chevronShrink} />
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {leg.to.name !== 'Destination' && (
                      <div className={classes.stop}>
                        {leg.mode === 'WALK' ? (
                          <div className={classes.transitModeIcon}>
                            {itinerary.legs[legId + 1].mode === 'RAIL' && <Train />}
                            {itinerary.legs[legId + 1].mode === 'TRAM' && <Tram />}
                            {itinerary.legs[legId + 1].mode === 'BUS' && <Bus />}
                          </div>
                        ) : (
                          <div className={classes.transitEndIcon} />
                        )}
                        <Typography>
                          {leg.to.name}
                        </Typography>
                        <Typography className={classes.stopTime}>
                          {moment(leg.to.departure).format('HH:mm')}
                        </Typography>
                      </div>
                    )}
                  </Fragment>
                );
              })}
              <div className={classes.stop}>
                <Marker className={classes.destinationIcon} />
                <Typography>
                  {to}
                </Typography>
                <Typography className={classes.stopTime}>
                  {moment(R.last(itinerary.legs)!.to.arrival).format('HH:mm')}
                </Typography>
              </div>
            </div>
          </div>
        );
      })()}
    </div>
  );
}
