import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  InputAdornment, TextField, Typography, Button, IconButton, FormControlLabel, Checkbox,
} from '@material-ui/core';
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import * as R from 'ramda';
import { axios } from '../../utils';
import NewHeader from '../../provider/NewHeader';
import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as Clear } from '../../assets/clear.svg';
import { ReactComponent as ClearWhite } from '../../assets/clear-white.svg';
import DeleteDialog from '../../provider/DeleteDialog';

type Category = 'passenger' | 'provider' | 'card' | 'beacon';

interface Entry {
  id: number;
  questionPL: string;
  questionEN: string;
  answerPL: string;
  answerEN: string;
  categories: Category[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 32,
    display: 'flex',
  },
  left: {
    width: 600,
    flexShrink: 0,
    marginRight: 32,
  },
  right: {
    flexGrow: 1,
  },
  search: {
    width: 400,
    marginBottom: 24,
  },
  portalsHeader: {
    fontWeight: 400,
    marginBottom: 10,
  },
  portals: {
    display: 'flex',
    marginBottom: 24,
  },
  portal: {
    borderRadius: 16,
    fontSize: 14,
    fontWeight: 400,
    backgroundColor: '#F5F5F5',
    color: '#37001F',
    marginRight: 8,
  },
  selectedPortal: {
    backgroundColor: '#A1145C',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgb(112, 14, 64)',
    },
  },
  entry: {
    borderRadius: 15,
    padding: '8px 16px',
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#F5F5F5',
    color: '#37001F',
    transition: theme.transitions.create(['background-color', 'color']),
  },
  entryText: {
    color: 'inherit',
    fontSize: 16,
    fontWeight: 300,
    '& p': {
      margin: 0,
    },
  },
  selectedEntry: {
    backgroundColor: '#A1145C',
    color: 'white',
  },
  quillLabel: {
    fontSize: 14,
    fontWeight: 400,
    margin: '0 0 8px 12px',
  },
  quill: {
    height: 140,
    paddingBottom: 42,
    marginBottom: 16,
  },
  checkboxes: {
    display: 'flex',
    marginBottom: 24,
  },
  formControlLabel: {
    marginRight: 24,
  },
  saveRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  discardChanges: {
    marginRight: 16,
  },
}));

const allCategories: Category[] = ['passenger', 'provider', 'card', 'beacon'];

export default function ManageFAQ() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [entries, setEntries] = useState<Entry[] | null>(null);
  const [selectedEntry, setSelectedEntry] = useState<number | 'new' | null>(null);
  const [search, setSearch] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<Category[]>(allCategories);
  const [questionPL, setQuestionPL] = useState('');
  const [questionEN, setQuestionEN] = useState('');
  const [answerPL, setAnswerPL] = useState('');
  const [answerEN, setAnswerEN] = useState('');
  const [isPassenger, setIsPassenger] = useState(false);
  const [isProvider, setIsProvider] = useState(false);
  const [isCard, setIsCard] = useState(false);
  const [isBeacon, setIsBeacon] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/faq');
      setEntries(data.map((x: any, i: number) => ({ id: i, ...x })));
    })();
  }, []);
  if (!entries) return null;
  const filteredEntries = entries.filter((x) => (
    [x.questionPL, x.questionEN, x.answerPL, x.answerEN]
      .some((y) => y.toLowerCase().includes(search.toLowerCase()))
    && x.categories.some((y) => selectedCategories.includes(y))
  ));
  const loadData = (entry: Entry | null) => {
    if (entry) {
      setSelectedEntry(entry.id);
      setQuestionPL(entry.questionPL);
      setQuestionEN(entry.questionEN);
      setAnswerPL(entry.answerPL);
      setAnswerEN(entry.answerEN);
      setIsPassenger(entry.categories.includes('passenger'));
      setIsProvider(entry.categories.includes('provider'));
      setIsCard(entry.categories.includes('card'));
      setIsBeacon(entry.categories.includes('beacon'));
    } else {
      setSelectedEntry('new');
      setQuestionPL('');
      setQuestionEN('');
      setAnswerPL('');
      setAnswerEN('');
      setIsPassenger(false);
      setIsProvider(false);
      setIsCard(false);
      setIsBeacon(false);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <NewHeader
          headerText={t('support.faq')}
          newText={t('support.newQuestion')}
          onClick={() => loadData(null)}
          bigMargin
        />
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          label={t('support.searchFaq')}
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
          className={classes.search}
          variant="outlined"
          size="small"
        />
        <Typography className={classes.portalsHeader}>
          {t('support.portals')}
        </Typography>
        <div className={classes.portals}>
          {allCategories.map((category) => (
            <Button
              key={category}
              variant="contained"
              className={classNames(classes.portal, {
                [classes.selectedPortal]: selectedCategories.includes(category),
              })}
              onClick={() => setSelectedCategories(
                selectedCategories.includes(category)
                  ? selectedCategories.filter((x) => x !== category)
                  : [...selectedCategories, category],
              )}
            >
              {t(`support.${category}`)}
            </Button>
          ))}
        </div>
        {filteredEntries.map((entry) => (
          <div
            key={entry.id}
            className={classNames(classes.entry, {
              [classes.selectedEntry]: selectedEntry === entry.id,
            })}
            onClick={() => (selectedEntry === entry.id ? setSelectedEntry(null) : loadData(entry))}
          >
            {/* eslint-disable-next-line react/no-danger */}
            <div
              className={classes.entryText}
              dangerouslySetInnerHTML={{ __html: entry.questionPL }}
            />
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setDeleteId(entry.id);
              }}
              size="small"
              data-testid="delete-button"
            >
              {selectedEntry === entry.id ? <ClearWhite /> : <Clear />}
            </IconButton>
          </div>
        ))}
      </div>
      <div className={classes.right}>
        {selectedEntry !== null && (
          <>
            <Typography className={classes.quillLabel}>
              {t('support.questionPL')}
            </Typography>
            <ReactQuill
              value={questionPL}
              onChange={setQuestionPL}
              className={classes.quill}
            />
            <Typography className={classes.quillLabel}>
              {t('support.questionEN')}
            </Typography>
            <ReactQuill
              value={questionEN}
              onChange={setQuestionEN}
              className={classes.quill}
            />
            <Typography className={classes.quillLabel}>
              {t('support.answerPL')}
            </Typography>
            <ReactQuill
              value={answerPL}
              onChange={setAnswerPL}
              className={classes.quill}
            />
            <Typography className={classes.quillLabel}>
              {t('support.answerEN')}
            </Typography>
            <ReactQuill
              value={answerEN}
              onChange={setAnswerEN}
              className={classes.quill}
            />
            <Typography className={classes.portalsHeader}>
              {t('support.portals')}
            </Typography>
            <div className={classes.checkboxes}>
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={isPassenger}
                    onChange={({ target }) => setIsPassenger(target.checked)}
                  />
                )}
                label={t('support.passenger')}
                className={classes.formControlLabel}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={isProvider}
                    onChange={({ target }) => setIsProvider(target.checked)}
                  />
                )}
                label={t('support.provider')}
                className={classes.formControlLabel}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={isCard}
                    onChange={({ target }) => setIsCard(target.checked)}
                  />
                )}
                label={t('support.card')}
                className={classes.formControlLabel}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={isBeacon}
                    onChange={({ target }) => setIsBeacon(target.checked)}
                  />
                )}
                label={t('support.beacon')}
                className={classes.formControlLabel}
              />
            </div>
            <div className={classes.saveRow}>
              <Button
                color="primary"
                onClick={() => loadData(
                  selectedEntry === 'new'
                    ? null
                    : entries.find(R.propEq('id', selectedEntry)) || null,
                )}
                className={classes.discardChanges}
              >
                {t('common.discardChanges')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={(
                  !questionPL || !questionEN || !answerPL || !answerEN
                  || ![isPassenger, isProvider, isCard, isBeacon].some((x) => x)
                )}
                onClick={async () => {
                  const newEntry: Entry = {
                    id: selectedEntry === 'new' ? (R.last(entries)?.id || 0) + 1 : selectedEntry,
                    questionPL,
                    questionEN,
                    answerPL,
                    answerEN,
                    categories: [
                      isPassenger ? 'passenger' : null,
                      isProvider ? 'provider' : null,
                      isCard ? 'card' : null,
                      isBeacon ? 'beacon' : null,
                    ].filter((x) => x !== null) as Category[],
                  };
                  const newEntries = selectedEntry === 'new'
                    ? [...entries, newEntry]
                    : entries.map((x) => (x.id === selectedEntry ? newEntry : x));
                  await axios.put('/faq', newEntries);
                  setEntries(newEntries);
                  setSelectedEntry(null);
                }}
              >
                {t('common.save')}
              </Button>
            </div>
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteId !== null}
        onClose={() => setDeleteId(null)}
        text={t('support.deleteConfirm')}
        onDelete={async () => {
          const newEntries = entries.filter((x) => x.id !== deleteId);
          await axios.put('/faq', newEntries);
          if (selectedEntry === deleteId) {
            setSelectedEntry(null);
          }
          setEntries(newEntries);
          setDeleteId(null);
        }}
        canDelete
      />
    </div>
  );
}
