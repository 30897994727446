import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useGetLink } from './utils';

interface Props {
  onAccept: () => void;
}

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 1200,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.88)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    '@media (max-width: 800px)': {
      flexWrap: 'wrap',
    },
  },
  text: {
    color: 'white',
  },
  link: {
    color: 'white',
  },
  button: {
    flexShrink: 0,
    marginLeft: 40,
    '@media (max-width: 800px)': {
      marginLeft: 'auto',
      marginTop: 8,
    },
  },
});

export default function CookieDisclaimer({ onAccept }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const getLink = useGetLink();
  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        <Trans
          i18nKey="common.cookieDisclaimer"
          components={[<Link to={getLink('/privacy-policy')} className={classes.link} />]}
        />
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onAccept}
      >
        {t('common.accept')}
      </Button>
    </div>
  );
}
