import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  useHistory, useParams, Switch, Redirect, Route,
} from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import { useGetLink } from '../../utils';
import Contact from './Contact';
import FAQ from './FAQ';
import AppContext from '../../context';
import ManageFAQ from './ManageFAQ';

interface Props {
  basePath: string;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tabs: {
    backgroundColor: '#F5F5F5',
    paddingTop: 16,
  },
  tab: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 300,
  },
});

export default function SupportContent({ basePath }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const getLink = useGetLink();
  const { tab } = useParams<{ tab: string; }>();
  const { user } = useContext(AppContext);
  if (!tab || (user?.role !== 'ADMIN' && tab === 'manage-faq')) {
    return (
      <Redirect from={basePath} exact to={getLink(`${basePath}/faq`)} />
    );
  }
  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={(event, value) => history.push(getLink(`${basePath}/${value}`))}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.tabs}
      >
        <Tab
          className={classes.tab}
          value="faq"
          label={t('support.faq')}
        />
        <Tab
          className={classes.tab}
          value="contact"
          label={t('support.contactUs')}
        />
        {user?.role === 'ADMIN' && (
          <Tab
            className={classes.tab}
            value="manage-faq"
            label={t('support.manageFaq')}
          />
        )}
      </Tabs>
      <Switch>
        <Route path={`/:language${basePath}/faq`} component={FAQ} />
        <Route path={`/:language${basePath}/contact`} component={Contact} />
        <Route path={`/:language${basePath}/manage-faq`} component={ManageFAQ} />
      </Switch>
    </div>
  );
}
