import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { MenuItem, TextField } from '@material-ui/core';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { ReactComponent as Chevron } from '../assets/chevron.svg';
import CustomDatePicker from './CustomDatePicker';

type IPeriod = 'today' | 'yesterday' | 'last7Days' | 'last30Days' | 'last90Days' | 'custom';
export type IDateRange = { from: Moment | null; to: Moment | null; } | null;

interface Props {
  value: IDateRange;
  onChange: (value: IDateRange) => void;
  inline?: boolean;
}

const useStyles = makeStyles({
  period: {
    width: 300,
  },
  selectIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 4px',
  },
  dates: {
    display: 'flex',
    marginTop: 16,
  },
  datePicker: {
    marginRight: 16,
    width: 160,
  },
  inline: {
    display: 'flex',
    '& $dates': {
      marginTop: 0,
      marginLeft: 16,
    },
  },
});

export const getPeriod = (range: IDateRange) => {
  if (!range) return '';
  const { from, to } = range;
  if (!from || !to) return 'custom';
  if (from.isSame(to, 'day')) {
    if (from.isSame(moment(), 'day')) return 'today';
    if (from.isSame(moment().subtract(1, 'days'), 'day')) return 'yesterday';
  }
  if (to.isSame(moment(), 'day')) {
    if (from.isSame(moment().subtract(7, 'days'), 'day')) return 'last7Days';
    if (from.isSame(moment().subtract(30, 'days'), 'day')) return 'last30Days';
    if (from.isSame(moment().subtract(90, 'days'), 'day')) return 'last90Days';
  }
  return 'custom';
};

export default function PeriodSelect({ value, onChange, inline }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const period = getPeriod(value);
  return (
    <div className={inline ? classes.inline : ''}>
      <TextField
        select
        label={t('card.period')}
        value={period}
        onChange={({ target }) => {
          switch (target.value as IPeriod) {
            case 'today':
              onChange({ from: moment(), to: moment() });
              break;
            case 'yesterday': {
              const yesterday = moment().subtract(1, 'days');
              onChange({ from: yesterday, to: yesterday });
              break;
            }
            case 'last7Days':
              onChange({ from: moment().subtract(7, 'days'), to: moment() });
              break;
            case 'last30Days':
              onChange({ from: moment().subtract(30, 'days'), to: moment() });
              break;
            case 'last90Days':
              onChange({ from: moment().subtract(90, 'days'), to: moment() });
              break;
            case 'custom':
            default:
              onChange({ from: null, to: null });
          }
        }}
        variant="outlined"
        size="small"
        className={classes.period}
        SelectProps={{
          // eslint-disable-next-line react/prop-types
          IconComponent: ({ className, ...rest }) => (
            <div {...rest} className={classNames(className, classes.selectIcon)}>
              <Chevron />
            </div>
          ),
        }}
        id="period-select"
      >
        <MenuItem value="today">
          {t('card.today')}
        </MenuItem>
        <MenuItem value="yesterday">
          {t('card.yesterday')}
        </MenuItem>
        <MenuItem value="last7Days">
          {t('card.lastDays', { days: 7 })}
        </MenuItem>
        <MenuItem value="last30Days">
          {t('card.lastDays', { days: 30 })}
        </MenuItem>
        <MenuItem value="last90Days">
          {t('card.lastDays', { days: 90 })}
        </MenuItem>
        <MenuItem value="custom">
          {t('card.custom')}
        </MenuItem>
      </TextField>
      {period === 'custom' && value && (
        <div className={classes.dates}>
          <CustomDatePicker
            className={classes.datePicker}
            label={t('card.from')}
            value={value.from}
            onChange={(newFrom) => onChange({ from: newFrom, to: value.to })}
            maxDate={value.to || undefined}
          />
          <CustomDatePicker
            className={classes.datePicker}
            label={t('card.to')}
            value={value.to}
            onChange={(newTo) => onChange({ from: value.from, to: newTo })}
            minDate={value.from || undefined}
          />
        </div>
      )}
    </div>
  );
}
