import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  IconButton, InputAdornment, TextField, Typography,
} from '@material-ui/core';
import moment from 'moment';
import classNames from 'classnames';
import * as R from 'ramda';
import { ReactComponent as Search } from '../assets/search.svg';
import { ReactComponent as ChevronPrimary } from '../assets/chevron-primary.svg';
import { axios } from '../utils';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: 32,
  },
  search: {
    width: 500,
    marginLeft: 32,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 24,
    backgroundColor: '#F5F5F5',
    border: '1px solid #37001F',
  },
  cell: {
    padding: '6px 12px',
    fontSize: 16,
    color: '#37001F',
    fontWeight: 300,
    border: '1px solid #37001F',
    borderWidth: '0 1px',
    '&:nth-child(4), &:nth-child(7), &:nth-child(8), &:nth-child(10)': {
      textAlign: 'right',
    },
  },
  headerCell: {
    fontWeight: 400,
    border: '1px solid #37001F',
    '&:nth-child(n)': {
      textAlign: 'left',
    },
  },
  headerCellContent: {
    display: 'flex',
    alignItems: 'center',
  },
  tableRow: {
    '&:nth-child(even)': {
      backgroundColor: 'white',
    },
  },
  sortButton: {
    marginRight: -4,
    marginLeft: 4,
  },
  chevronFlipped: {
    transform: 'rotate(180deg)',
  },
  noLogs: {
    marginTop: 24,
  },
});

interface ILog {
  timestamp: string;
  servicePerson: string;
  beaconAssignment: string;
  beaconId: number;
  buildVersion: string;
  supply: 'MAINS' | 'BATTERY';
  powerValue: number;
  lastReset: string;
  lastOutage?: string;
  transmitPower: {
    initial: number;
    changed?: number;
  };
}

export default function Logs() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [sortColumn, setSortColumn] = useState('datetime');
  const [sortAsc, setSortAsc] = useState(false);
  const [logs, setLogs] = useState<ILog[] | null>(null);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/beacons/logs');
      setLogs(data);
    })();
  }, []);
  if (!logs) return null;
  const tableData = logs.map((x) => ({
    serviceDateTime: x.timestamp,
    servicePerson: x.servicePerson,
    beaconNameAssigned: x.beaconAssignment,
    beaconID: x.beaconId,
    buildID: x.buildVersion,
    supply: x.supply === 'BATTERY' ? t('beacon.battery') : t('beacon.mains'),
    batteryLevel: x.powerValue,
    uptime: moment().diff(moment(x.lastReset), 'days'),
    lastPowerOutage: x.lastOutage || '-',
    receivedPowerLevel: '-',
    transmitPowerLevel: `${x.transmitPower.initial}${x.transmitPower.changed
      ? ` → ${x.transmitPower.changed}` : ''} `,
  }))
    .sort((sortAsc ? R.ascend : R.descend)(R.prop(sortColumn)))
    .map((x) => ({
      ...x,
      beaconID: String(x.beaconID),
      batteryLevel: `${x.batteryLevel}%`,
      uptime: `${x.uptime} d`,
      serviceDateTime: moment(x.serviceDateTime).format('DD.MM.YY HH:mm'),
      lastPowerOutage: x.lastPowerOutage === '-'
        ? '-'
        : moment(x.lastPowerOutage).format('DD.MM.YY HH:mm'),
    }))
    .filter((row) => (
      Object.values(row).some((cell) => cell.toLowerCase().includes(search.toLowerCase()))
    ));
  const columns = tableData[0] ? Object.keys(tableData[0]) : null;
  return (
    <div className={classes.root}>
      <TextField
        label={t('beacon.logsSearch')}
        value={search}
        onChange={({ target }) => setSearch(target.value)}
        className={classes.search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        size="small"
      />
      {columns ? (
        <table className={classes.table}>
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column}
                  className={classNames(classes.cell, classes.headerCell)}
                >
                  <div className={classes.headerCellContent}>
                    {t(`beacon.${column}`)}
                    <IconButton
                      color="primary"
                      size="small"
                      className={classes.sortButton}
                      onClick={() => (
                        sortColumn === column ? setSortAsc(!sortAsc) : setSortColumn(column)
                      )}
                    >
                      <ChevronPrimary
                        className={(sortColumn === column && sortAsc) ? classes.chevronFlipped : ''}
                      />
                    </IconButton>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr className={classes.tableRow} key={i}>
                {Object.entries(row).map(([key, cell]) => (
                  <td className={classes.cell} key={key}>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Typography className={classes.noLogs}>
          {t('beacon.noLogs')}
          .
        </Typography>
      )}
    </div>
  );
}
