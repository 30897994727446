import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Button, IconButton, InputAdornment, TextField, Typography,
} from '@material-ui/core';
import * as R from 'ramda';
import NewHeader from '../provider/NewHeader';
import { IOrganization } from '../interfaces';
import { axios, fileToBase64 } from '../utils';
import { ReactComponent as Search } from '../assets/search.svg';
import ButtonList from '../provider/ButtonList';
import DeleteDialog from '../provider/DeleteDialog';
import AppContext from '../context';
import { ReactComponent as Clear } from '../assets/clear.svg';
import ImageUpload from '../common/ImageUpload';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    width: '100%',
    display: 'flex',
  },
  left: {
    flexGrow: 1,
    marginRight: 48,
  },
  right: {
    flexShrink: 0,
    width: 650,
    marginRight: 20,
  },
  search: {
    width: 300,
    marginBottom: 24,
  },
  fieldRow: {
    display: 'flex',
    margin: '0 -12px 24px',
  },
  shortField: {
    width: 'calc(50% - 24px)',
    margin: '0 12px',
  },
  saveRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 24,
  },
  discardChanges: {
    marginRight: 16,
  },
  subheader: {
    fontWeight: 400,
  },
  templates: {
    margin: '10px -10px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  template: {
    width: 164,
    height: 105,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: 5,
    margin: 10,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  removeImage: {
    margin: 4,
  },
  removeIcon: {
    background: 'white',
    borderRadius: '50%',
  },
  uploadRow: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  add: {
    marginLeft: 24,
  },
  templatesSection: {
    margin: '0 -30px',
    padding: '18px 30px 0',
    backgroundColor: '#F5F5F5',
  },
});

export default function Organizations() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppContext);
  const [organizations, setOrganizations] = useState<IOrganization[] | null>(null);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/card/organizations');
      setOrganizations(data);
    })();
  }, []);
  const [selectedOrganization, setSelectedOrganization] = useState<number | 'new' | null>(null);
  const [search, setSearch] = useState('');
  const [fullName, setFullName] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [phone, setPhone] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const loadData = (organization: IOrganization | null) => {
    if (organization) {
      setFullName(organization.fullName);
      setName(organization.name);
      setAddress(organization.address || '');
      setEmail(organization.email || '');
      setWebsite(organization.website || '');
      setPhone(organization.phone || '');
    } else {
      setFullName('');
      setName('');
      setAddress('');
      setEmail('');
      setWebsite('');
      setPhone('');
    }
    setImageFile(null);
    setImageUrl('');
  };
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [deleteName, setDeleteName] = useState('');
  if (!organizations) return null;
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <NewHeader
          headerText={t('card.organizations')}
          newText={t('card.newOrganization')}
          onClick={() => {
            setSelectedOrganization('new');
            loadData(null);
          }}
          bigMargin
        />
        <TextField
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          label={t('card.searchOrganizations')}
          className={classes.search}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          id="search"
        />
        <ButtonList
          elements={organizations.filter((organization) => (
            organization.name.includes(search)
          )).map((organization) => ({
            id: organization.id,
            name: organization.name,
            onClick: () => {
              setSelectedOrganization(organization.id);
              loadData(organization);
            },
            onDelete: () => {
              setDeleteId(organization.id);
              setDeleteName(organization.name);
            },
          }))}
          selected={selectedOrganization}
        />
      </div>
      <div className={classes.right}>
        {selectedOrganization && (
          <>
            <div className={classes.fieldRow}>
              <TextField
                label={t('card.organizationShortName')}
                value={name}
                onChange={({ target }) => setName(target.value)}
                variant="outlined"
                size="small"
                className={classes.shortField}
                id="short-name"
              />
              <TextField
                label={t('card.organizationFullName')}
                value={fullName}
                onChange={({ target }) => setFullName(target.value)}
                variant="outlined"
                size="small"
                className={classes.shortField}
                id="full-name"
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={t('card.address')}
                value={address}
                onChange={({ target }) => setAddress(target.value)}
                variant="outlined"
                size="small"
                className={classes.shortField}
                id="address"
              />
              <TextField
                label={t('card.emailAddress')}
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                variant="outlined"
                size="small"
                className={classes.shortField}
                id="email"
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={t('card.website')}
                value={website}
                onChange={({ target }) => setWebsite(target.value)}
                variant="outlined"
                size="small"
                className={classes.shortField}
                id="website"
              />
              <TextField
                label={t('card.telephoneNumber')}
                value={phone}
                onChange={({ target }) => setPhone(target.value)}
                variant="outlined"
                size="small"
                className={classes.shortField}
                id="phone"
              />
            </div>
            <div className={classes.saveRow}>
              {selectedOrganization !== 'new' && (
                <Button
                  color="primary"
                  className={classes.discardChanges}
                  onClick={() => loadData(
                    organizations.find((x) => x.id === selectedOrganization) || null,
                  )}
                >
                  {t('card.discardChanges')}
                </Button>
              )}
              <Button
                color="primary"
                variant="contained"
                onClick={async () => {
                  const requestData = {
                    name,
                    fullName,
                    address: address || null,
                    website: website || null,
                    email: email || null,
                    phone: phone || null,
                    templates: [],
                  };
                  if (selectedOrganization === 'new') {
                    const { data } = await axios.post('/card/organization', requestData);
                    setOrganizations(R.sortBy(R.prop('name'), [...organizations, data]));
                  } else {
                    await axios.put(`/card/organization/${selectedOrganization}`, requestData);
                    setOrganizations((orgs) => orgs!.map((x) => (
                      x.id === selectedOrganization ? ({
                        ...x,
                        ...requestData,
                      }) : x
                    )));
                  }
                  setSelectedOrganization(null);
                }}
                disabled={!name}
              >
                {t('card.save')}
              </Button>
            </div>
            {selectedOrganization !== 'new' && (
              <div className={classes.templatesSection}>
                <Typography className={classes.subheader}>
                  {t('card.cardTemplates')}
                </Typography>
                <div className={classes.templates}>
                  {organizations.find(R.propEq('id', selectedOrganization))!.templates
                    .map((template) => (
                      <div
                        key={template.id}
                        className={classes.template}
                        style={{ backgroundImage: `url('${template.file}')` }}
                      >
                        <IconButton
                          color="primary"
                          size="small"
                          className={classes.removeImage}
                          onClick={async () => {
                            await axios.delete(`/card/template/${template.id}`);
                            setOrganizations(organizations.map((x) => (
                              x.id === selectedOrganization ? {
                                ...x,
                                templates: x.templates.filter((y) => y.id !== template.id),
                              } : x
                            )));
                          }}
                        >
                          <Clear className={classes.removeIcon} />
                        </IconButton>
                      </div>
                    ))}
                </div>
                <div className={classes.uploadRow}>
                  <ImageUpload
                    onChange={(file, url) => {
                      setImageFile(file);
                      setImageUrl(url);
                    }}
                    imageUrl={imageUrl}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.add}
                    disabled={!imageFile}
                    onClick={async () => {
                      if (!imageFile) return;
                      const { data } = await axios.post('/card/template', {
                        organization: selectedOrganization,
                        name: imageFile.name,
                        type: imageFile.type,
                        content: await fileToBase64(imageFile),
                      });
                      setOrganizations(organizations.map((x) => (
                        x.id === selectedOrganization ? {
                          ...x,
                          templates: [...x.templates, data],
                        } : x
                      )));
                      setImageUrl('');
                      setImageFile(null);
                    }}
                  >
                    {t('card.add')}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteId !== null}
        onClose={() => setDeleteId(null)}
        text={t('card.organizationDeleteConfirm', { name: deleteName })}
        onDelete={async () => {
          try {
            await axios.delete(`/card/organization/${deleteId}`);
            if (selectedOrganization === deleteId) {
              setSelectedOrganization(null);
            }
            setOrganizations((orgs) => orgs!.filter((x) => x.id !== deleteId));
          } catch {
            showSnackbar(t('card.organizationDeleteFailed'));
          }
          setDeleteId(null);
          setDeleteName('');
        }}
        canDelete
      />
    </div>
  );
}
