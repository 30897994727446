import React, {
  useEffect, useRef, useState, Fragment,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import {
  Map, Marker, Popup, ScaleControl, TileLayer,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import * as R from 'ramda';
import leaflet from 'leaflet';
import classNames from 'classnames';
import { Bar } from 'react-chartjs-2';
import PeriodSelect, { getPeriod, IDateRange } from '../common/PeriodSelect';
import { IAdmissionStats } from '../interfaces';
import { axios } from '../utils';

const useStyles = makeStyles({
  root: {
    marginTop: 16,
  },
  row: {
    display: 'flex',
    marginBottom: 24,
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    marginRight: 50,
  },
  markerIcon: {
    borderRadius: '50%',
    fontSize: 35,
    fontWeight: 700,
    fontFamily: '"Roboto"',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  map: {
    width: '100%',
    height: 'calc(100vh - 260px)',
  },
  color1: {
    backgroundColor: 'rgb(0, 141, 202, 0.7)',
  },
  color2: {
    backgroundColor: 'rgb(75, 195, 230, 0.7)',
  },
  color3: {
    backgroundColor: 'rgb(112, 129, 17, 0.7)',
  },
  color4: {
    backgroundColor: 'rgb(243, 149, 63, 0.7)',
  },
  color5: {
    backgroundColor: 'rgb(215, 53, 52, 0.7)',
  },
  popup: {
    backgroundColor: 'white',
    maxHeight: 600,
    overflow: 'auto',
    paddingRight: 8,
    '& > :first-child': {
      boxShadow: 'none',
      padding: 0,
      '& > div': {
        margin: '16px 24px',
        '& p': {
          margin: 0,
        },
      },
    },
    '& > :last-child': {
      display: 'none',
    },
  },
  site: {
    fontWeight: 700,
    margin: '0 0 8px 0 !important',
    color: '#37001F',
  },
  attraction: {
    fontWeight: 700,
    margin: '0 0 4px 0 !important',
    color: '#37001F',
  },
  admissions: {
    fontWeight: 400,
    color: '#37001F',
    margin: '0 0 4px 0 !important',
  },
  admissionsValue: {
    fontSize: 20,
    fontWeight: 700,
  },
  chartOuterContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    marginBottom: 24,
  },
  chartInnerContainer: {
    display: 'flex',
  },
  chart: {
    width: 250,
  },
  yLabel: {
    paddingTop: 5,
  },
  xLabel: {
    textAlign: 'right',
    paddingRight: 10,
  },
  chartLabel: {
    fontSize: 9,
    color: '#37001F',
    whiteSpace: 'nowrap',
  },
});

export default function VisitorAttendance() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<IDateRange>(null);
  const [stats, setStats] = useState<IAdmissionStats[]>([]);
  const mapRef = useRef<any>();
  const [dataMax, setDataMax] = useState(0);
  const [maxCount, setMaxCount] = useState(0);
  useEffect(() => {
    (async () => {
      if (dateRange && dateRange.from && dateRange.to) {
        const { data }: { data: IAdmissionStats[] } = await axios.get('/card/statistics/admissions', {
          params: {
            from: dateRange.from.format('YYYY-MM-DD'),
            to: dateRange.to.format('YYYY-MM-DD'),
          },
        });
        const filteredData = data.filter((x) => x.attractions);
        setStats(filteredData);
        const max = R.reduce(Math.max, 0, filteredData.map((x) => R.sum(
          x.attractions.map(R.prop('admissionsTotal')),
        )));
        setMaxCount(max);
        setDataMax(max);
        if (filteredData.length > 1) {
          mapRef.current.leafletElement.fitBounds(
            new leaflet.LatLngBounds(filteredData.map(({ site: { lat, lon } }) => [lat, lon])),
            {
              padding: [64, 64],
            },
          );
        }
      } else {
        mapRef.current.leafletElement.fitBounds(
          new leaflet.LatLngBounds([
            [56.464660, 14.408800],
            [53.570577, 19.443904],
          ]),
        );
      }
    })();
  }, [dateRange]);
  const makeIcon = (count: number) => {
    const percent = (count / maxCount) * 100;
    return leaflet.divIcon({
      className: classNames(classes.markerIcon, {
        [classes.color1]: percent < 20,
        [classes.color2]: percent >= 20 && percent < 40,
        [classes.color3]: percent >= 40 && percent < 60,
        [classes.color4]: percent >= 60 && percent < 80,
        [classes.color5]: percent >= 80,
      }),
      html: `${count}`,
      iconSize: [100, 100],
    });
  };
  const periodText = (() => {
    switch (getPeriod(dateRange)) {
      case 'today':
        return t('card.mapToday');
      case 'yesterday':
        return t('card.mapYesterday');
      case 'last7Days':
        return t('card.mapLastDays', { days: 7 });
      case 'last30Days':
        return t('card.mapLastDays', { days: 30 });
      case 'last90Days':
        return t('card.mapLastDays', { days: 90 });
      case 'custom':
        if (dateRange && dateRange.from && dateRange.to) {
          return t('card.mapFromTo', {
            from: dateRange.from.format('DD.MM.YYYY'),
            to: dateRange.to.format('DD.MM.YYYY'),
          });
        }
        return '';
      default:
        return '';
    }
  })();
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Typography className={classes.header} data-testid="period-header">
          {t('card.period')}
        </Typography>
        <PeriodSelect value={dateRange} onChange={setDateRange} inline />
      </div>
      <Map
        ref={mapRef}
        className={classes.map}
        minZoom={3}
        maxZoom={18}
        fullscreenControl
        onViewportChanged={() => {
          const markerPane = mapRef.current.leafletElement.getPane('markerPane');
          const counts = [...markerPane.children].map((x) => Number(x.textContent));
          setMaxCount(R.reduce(Math.max, dataMax, counts));
        }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ScaleControl position="topright" imperial={false} />
        <MarkerClusterGroup
          iconCreateFunction={(cluster) => (
            makeIcon(R.sum(cluster.getAllChildMarkers().map((x) => (
              Number((x.options.icon!.options as { html: string }).html)
            ))))
          )}
          animate={false}
        >
          {stats.map((x) => {
            const siteAdmissions = R.sum(x.attractions.map(R.prop('admissionsTotal')));
            return (
              <Marker
                key={x.site.name}
                position={[x.site.lat, x.site.lon]}
                icon={makeIcon(siteAdmissions)}
              >
                <Popup
                  className={classes.popup}
                  closeButton={false}
                  maxWidth={350}
                >
                  <Typography className={classes.site}>
                    {x.site.name}
                  </Typography>
                  {x.attractions.map((attraction) => (
                    <Fragment key={attraction.name}>
                      <Typography className={classes.attraction}>
                        {attraction.name}
                      </Typography>
                      <Typography className={classes.admissions}>
                        <span className={classes.admissionsValue}>
                          {attraction.admissionsTotal}
                        </span>
                        {` ${t('card.mapAdmissions', {
                          count: attraction.admissionsTotal,
                        })} ${periodText}`}
                      </Typography>
                      <div className={classes.chartOuterContainer}>
                        <div className={classes.chartInnerContainer}>
                          <div className={classes.yLabel}>
                            <Typography className={classes.chartLabel}>
                              {`# ${t('card.visitors')}`}
                            </Typography>
                          </div>
                          <div className={classes.chart}>
                            <Bar
                              height={170}
                              options={{
                                maintainAspectRatio: false,
                                tooltips: {
                                  enabled: false,
                                },
                                layout: {
                                  padding: {
                                    right: 20,
                                  },
                                },
                                scales: {
                                  xAxes: [{
                                    display: false,
                                    ticks: {
                                      max: R.last(attraction.admissionsByHour)?.from,
                                    },
                                  }, {
                                    display: true,
                                    gridLines: {
                                      drawOnChartArea: false,
                                      color: '#9E9E9E',
                                      drawTicks: false,
                                    },
                                    ticks: {
                                      fontSize: 9,
                                      fontFamily: '"Roboto"',
                                      fontColor: '#37001F',
                                      padding: 2,
                                    },
                                  }],
                                  yAxes: [{
                                    gridLines: {
                                      drawOnChartArea: false,
                                      color: '#9E9E9E',
                                      tickMarkLength: 7,
                                    },
                                    ticks: {
                                      fontSize: 9,
                                      fontFamily: '"Roboto"',
                                      fontColor: '#37001F',
                                      lineHeight: 3,
                                      padding: 4,
                                      beginAtZero: true,
                                      callback: (value) => (value === 0 ? '' : value),
                                      // @ts-ignore
                                      precision: 0,
                                    },
                                  }],
                                },
                              }}
                              legend={{ display: false }}
                              data={{
                                labels: [
                                  ...attraction.admissionsByHour.map(R.prop('from')),
                                  R.last(attraction.admissionsByHour)?.to || '',
                                ],
                                datasets: [{
                                  data: attraction.admissionsByHour.map(R.prop('visits')),
                                  backgroundColor: '#034EA2',
                                  barThickness: 25,
                                }],
                              }}
                            />
                          </div>
                        </div>
                        <div className={classes.xLabel}>
                          <Typography className={classes.chartLabel}>
                            {t('card.time')}
                          </Typography>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </Popup>
              </Marker>
            );
          })}
        </MarkerClusterGroup>
      </Map>
    </div>
  );
}
