import React from 'react';
import { Link as MuiLink, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGetLink } from '../utils';

const useStyles = makeStyles({
  header: {
    fontSize: 35,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 39,
  },
  text: {
    fontSize: 20,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 138,
  },
  goToLogin: {
    fontSize: 16,
  },
});

export default function EmailVerified() {
  const classes = useStyles();
  const { t } = useTranslation();
  const getLink = useGetLink();
  return (
    <>
      <Typography className={classes.header}>
        {t('auth.emailVerified')}
      </Typography>
      <Typography className={classes.text}>
        {t('auth.verifiedText')}
      </Typography>
      <MuiLink
        component={Link}
        to={getLink('/auth/login')}
        className={classes.goToLogin}
      >
        {t('auth.goToLogin')}
      </MuiLink>
    </>
  );
}
