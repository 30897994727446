import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { IProps } from './Trips';
import { axios } from '../../utils';

interface IStats {
  valid: number;
  invalid: number;
  missing: number;
}

const useStyles = makeStyles({
  header: {
    color: '#37001F',
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 16,
  },
  table: {
    borderCollapse: 'collapse',
  },
  headerCell: {
    padding: 16,
    backgroundColor: '#F5F5F5',
    fontSize: 16,
    fontWeight: 400,
    color: '#37001F',
    border: '1px solid #37001F',
    textAlign: 'center',
  },
  cell: {
    padding: 4,
    fontSize: 16,
    fontWeight: 300,
    color: '#37001F',
    border: '1px solid #37001F',
    textAlign: 'center',
  },
});

export default function Tickets({ provider, params }: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [stats, setStats] = useState<IStats | null>(null);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/${provider}/trip-tickets`, { params });
      setStats({
        valid: data.valid || 0,
        invalid: data.invalid || 0,
        missing: data.missing || 0,
      });
    })();
  }, [provider, params]);
  if (!stats) return null;
  return (
    <div>
      <Typography className={classes.header}>
        {t('trips.trips')}
      </Typography>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.headerCell}>
              {t('trips.withValidTicket')}
            </th>
            <th className={classes.headerCell}>
              {t('trips.withInvalidTicket')}
            </th>
            <th className={classes.headerCell}>
              {t('trips.noTicket')}
            </th>
            <th className={classes.headerCell}>
              {t('trips.total')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.cell}>
              {stats.valid}
            </td>
            <td className={classes.cell}>
              {stats.invalid}
            </td>
            <td className={classes.cell}>
              {stats.missing}
            </td>
            <td className={classes.cell}>
              {stats.valid + stats.invalid + stats.missing}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
