import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Typography, TextField, IconButton, Button, FormControlLabel, Checkbox, Collapse,
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import { Link, useHistory } from 'react-router-dom';
import * as R from 'ramda';
import 'react-quill/dist/quill.snow.css';
import SelectLocation from '../common/SelectLocation';
import { ReactComponent as Clear } from '../assets/clear.svg';
import NewHeader from '../provider/NewHeader';
import ImageUpload from '../common/ImageUpload';
import { axios, fileToBase64, useGetLink } from '../utils';
import {
  IAttraction, ISite, ISiteCoords, ISiteUpdate,
} from '../interfaces';
import AppContext from '../context';
import DeleteDialog from '../provider/DeleteDialog';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    width: '100%',
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    color: '#37001F',
    marginBottom: 24,
  },
  container: {
    display: 'flex',
  },
  left: {
    width: '50%',
    marginRight: 27,
  },
  right: {
    width: '50%',
  },
  row: {
    width: 'calc(50% + 12px)',
    display: 'flex',
    margin: '0 -12px 24px',
  },
  shortField: {
    width: 'calc(50% - 24px)',
    margin: '0 12px',
    backgroundColor: 'white',
  },
  description: {
    height: 160,
    marginBottom: 64,
  },
  descriptionLabel: {
    fontSize: 14,
    fontWeight: 400,
    margin: '0 0 8px 12px',
  },
  subheader: {
    fontWeight: 400,
    marginBottom: 6,
  },
  saveRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '24px 0',
  },
  discardChanges: {
    marginRight: 16,
  },
  attractions: {
    marginTop: 40,
  },
  attraction: {
    width: 543,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderRadius: 15,
    marginBottom: 8,
    textDecoration: 'none',
  },
  numberOf: {
    width: 40,
    fontSize: 20,
    color: '#37001F',
    marginLeft: 16,
  },
  attractionName: {
    fontSize: 20,
    color: '#37001F',
    marginRight: 'auto',
  },
  location: {
    display: 'flex',
    margin: '0 -16px',
    padding: 16,
    backgroundColor: '#F5F5F5',
  },
  locationRow: {
    display: 'flex',
    margin: '0 -12px 24px',
  },
  coords: {
    border: '1px solid #9E9E9E',
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
    cursor: 'pointer',
    marginBottom: 8,
  },
  coordsName: {
    fontSize: 20,
    color: '#37001F',
    marginRight: 'auto',
  },
  coordsMain: {
    fontSize: 20,
    color: '#37001F',
    fontWeight: 400,
    marginRight: 16,
  },
  address: {
    width: 300,
    margin: '52px 0 20px',
    display: 'flex',
  },
  rightField: {
    width: 300,
    margin: '20px 0',
    display: 'flex',
  },
});

export default function Site() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppContext);
  const getLink = useGetLink();
  const history = useHistory();
  const [site, setSite] = useState<ISite | null>(null);
  const [namePL, setNamePL] = useState('');
  const [nameEN, setNameEN] = useState('');
  const [website, setWebsite] = useState('');
  const [descPL, setDescPL] = useState('');
  const [descEN, setDescEN] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [coordinates, setCoordinates] = useState<ISiteCoords[]>([]);
  const [selectedCoords, setSelectedCoords] = useState<number | 'new' | null>(null);
  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');
  const [coordsDescPL, setCoordsDescPL] = useState('');
  const [coordsDescEN, setCoordsDescEN] = useState('');
  const [isMain, setIsMain] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [attractions, setAttractions] = useState<IAttraction[]>([]);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [deleteName, setDeleteName] = useState('');
  const loadData = (currentSite: ISite) => {
    setNamePL(currentSite.namePL);
    setNameEN(currentSite.nameEN);
    setWebsite(currentSite.website || '');
    setDescPL(currentSite.descPL);
    setDescEN(currentSite.descEN);
    setAddress(currentSite.address || '');
    setPhone(currentSite.phone || '');
    setEmail(currentSite.email || '');
    setCoordinates(currentSite.coordinates);
    setImageFile(null);
    setImageUrl(currentSite.image);
    setAttractions(currentSite.attractions);
  };
  const loadCoords = (coords: ISiteCoords | null, coordsIsMain?: boolean) => {
    if (coords) {
      setLat(String(coords.lat));
      setLon(String(coords.lon));
      setCoordsDescPL(coords.descPL);
      setCoordsDescEN(coords.descEN);
      setIsMain(coordsIsMain || false);
    } else {
      setLat('');
      setLon('');
      setCoordsDescPL('');
      setCoordsDescEN('');
      setIsMain(false);
    }
  };
  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/card/site');
      setSite(data);
      loadData(data);
    })();
  }, []);
  if (!site) return null;
  const multipleCoords = (coordinates.length + (selectedCoords === 'new' ? 1 : 0)) > 1;
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {t('card.site')}
      </Typography>
      <div className={classes.row}>
        <TextField
          className={classes.shortField}
          label={t('card.nameInPolish')}
          value={namePL}
          onChange={({ target }) => setNamePL(target.value)}
          variant="outlined"
          size="small"
        />
        <TextField
          className={classes.shortField}
          label={t('card.nameInEnglish')}
          value={nameEN}
          onChange={({ target }) => setNameEN(target.value)}
          variant="outlined"
          size="small"
        />
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.shortField}
          label={t('card.website')}
          value={website}
          onChange={({ target }) => setWebsite(target.value)}
          variant="outlined"
          size="small"
        />
      </div>
      <div className={classes.container}>
        <div className={classes.left}>
          <Typography className={classes.descriptionLabel}>
            {t('card.descriptionInPolish')}
          </Typography>
          <ReactQuill
            value={descPL}
            onChange={setDescPL}
            className={classes.description}
          />
          <Typography className={classes.descriptionLabel}>
            {t('card.descriptionInEnglish')}
          </Typography>
          <ReactQuill
            value={descEN}
            onChange={setDescEN}
            className={classes.description}
          />
        </div>
        <div className={classes.right}>
          <Typography className={classes.subheader}>
            {t('card.image')}
          </Typography>
          <ImageUpload
            onChange={(file, url) => {
              setImageFile(file);
              setImageUrl(url);
            }}
            imageUrl={imageUrl}
          />
          <TextField
            className={classes.address}
            label={t('card.address')}
            value={address}
            onChange={({ target }) => setAddress(target.value)}
            variant="outlined"
            size="small"
            multiline
            rows={3}
          />
          <TextField
            className={classes.rightField}
            label={t('card.telephoneNumber')}
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
            variant="outlined"
            size="small"
          />
          <TextField
            className={classes.rightField}
            label={t('card.emailAddress')}
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            variant="outlined"
            size="small"
          />
        </div>
      </div>
      <div className={classes.location}>
        <div className={classes.left}>
          <NewHeader
            headerText={t('card.landmarks')}
            newText={t('card.newLandmark')}
            onClick={() => {
              setSelectedCoords('new');
              loadCoords(null);
            }}
            small
            bigMargin
          />
          {coordinates.map((coords, i) => (
            <div
              className={classes.coords}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              onClick={() => {
                setSelectedCoords(i);
                loadCoords(coords, i === 0);
              }}
            >
              <Typography className={classes.coordsName}>
                {coords.descPL}
              </Typography>
              {i === 0 && (
                <Typography className={classes.coordsMain}>
                  {t('card.main')}
                </Typography>
              )}
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  if (selectedCoords === i) {
                    setSelectedCoords(null);
                  }
                  setCoordinates(R.remove(i, 1));
                }}
              >
                <Clear />
              </IconButton>
            </div>
          ))}
        </div>
        <div className={classes.right}>
          <Collapse in={selectedCoords !== null}>
            <div>
              <SelectLocation
                lat={lat}
                setLat={setLat}
                lon={lon}
                setLon={setLon}
                noHeader
              />
              <div className={classes.locationRow}>
                <TextField
                  className={classes.shortField}
                  label={t('card.nameInPolish')}
                  value={coordsDescPL}
                  onChange={({ target }) => setCoordsDescPL(target.value)}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  className={classes.shortField}
                  label={t('card.nameInEnglish')}
                  value={coordsDescEN}
                  onChange={({ target }) => setCoordsDescEN(target.value)}
                  variant="outlined"
                  size="small"
                />
              </div>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={multipleCoords ? isMain : true}
                    onChange={({ target }) => setIsMain(target.checked)}
                    color="primary"
                    disabled={!multipleCoords}
                  />
                )}
                label={t('card.main')}
              />
              <div className={classes.saveRow}>
                {selectedCoords !== 'new' && (
                  <Button
                    color="primary"
                    className={classes.discardChanges}
                    onClick={() => {
                      if (selectedCoords !== null) {
                        loadCoords(coordinates[selectedCoords], selectedCoords === 0);
                      }
                    }}
                  >
                    {t('card.discardChanges')}
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    const newCoords = {
                      lat: Number(lat),
                      lon: Number(lon),
                      descPL: coordsDescPL,
                      descEN: coordsDescEN,
                    };
                    if (selectedCoords === 'new') {
                      if (isMain) {
                        setCoordinates([newCoords, ...coordinates]);
                      } else {
                        setCoordinates([...coordinates, newCoords]);
                      }
                    } else if (typeof selectedCoords === 'number') {
                      if (isMain) {
                        setCoordinates([newCoords, ...R.remove(selectedCoords, 1, coordinates)]);
                      } else {
                        setCoordinates(R.update(selectedCoords, newCoords, coordinates));
                      }
                    }
                    setSelectedCoords(null);
                  }}
                >
                  {selectedCoords === 'new' ? t('card.add') : t('card.update')}
                </Button>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      <div className={classes.saveRow}>
        <Button
          color="primary"
          className={classes.discardChanges}
          onClick={() => loadData(site)}
        >
          {t('card.discardChanges')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={async () => {
            const siteUpdate: ISiteUpdate = {
              namePL,
              nameEN,
              website: website || null,
              descPL,
              descEN,
              address: address || null,
              phone: phone || null,
              email: email || null,
              coordinates,
            };
            if (imageFile) {
              siteUpdate.image = {
                name: imageFile.name,
                type: imageFile.type,
                content: await fileToBase64(imageFile),
              };
            }
            if (!imageUrl && site?.image) {
              siteUpdate.image = null;
            }
            const { data } = await axios.post('/card/site', siteUpdate);
            const newSite: ISite = {
              ...siteUpdate,
              image: imageFile ? data : imageUrl,
              attractions,
            };
            setSite(newSite);
            if (imageFile) {
              setImageFile(null);
              setImageUrl(data);
            }
            showSnackbar(t('card.siteUpdated'));
          }}
        >
          {t('card.save')}
        </Button>
      </div>
      <NewHeader
        headerText={t('card.attractions')}
        newText={t('card.newAttraction')}
        onClick={() => history.push(getLink('/card/site/attraction/new'))}
      />
      <div className={classes.attractions}>
        {attractions.map((attraction, i) => (
          <Link
            to={getLink(`/card/site/attraction/${attraction.id}`)}
            className={classes.attraction}
            key={attraction.id}
          >
            <Typography className={classes.numberOf}>
              {i + 1}
            </Typography>
            <Typography className={classes.attractionName}>
              {attraction.namePL}
            </Typography>
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteId(attraction.id);
                setDeleteName(attraction.namePL);
              }}
            >
              <Clear />
            </IconButton>
          </Link>
        ))}
      </div>
      <DeleteDialog
        open={deleteId !== null}
        onClose={() => setDeleteId(null)}
        text={t('card.attractionDeleteConfirm', { name: deleteName })}
        onDelete={async () => {
          await axios.delete(`/card/attraction/${deleteId}`);
          setAttractions(R.filter<IAttraction>((x) => x.id !== deleteId));
          setDeleteId(null);
        }}
        canDelete
      />
    </div>
  );
}
