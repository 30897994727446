import React, { useEffect, useRef } from 'react';
// @ts-ignore
import bwipjs from 'bwip-js';

interface Props {
  text: string;
  scale?: number;
  className?: string;
}

export default function AztecCode({ text, scale, className }: Props) {
  const aztecRef = useRef<HTMLCanvasElement | null>(null);
  useEffect(() => {
    bwipjs.toCanvas(aztecRef.current, {
      bcid: 'azteccode',
      text,
      scale: scale || 4,
    });
  }, [text, scale]);
  return (
    <canvas ref={aztecRef} className={className} />
  );
}
