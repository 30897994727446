import React, { useState, FormEvent } from 'react';
import {
  Button, makeStyles, TextField, Typography, Link as MuiLink,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { validate as isValidEmail } from 'email-validator';
import { Auth0Error } from 'auth0-js';
import { ReactComponent as GoogleIcon } from '../assets/google.svg';
import { ReactComponent as FacebookIcon } from '../assets/facebook.svg';
import PasswordField from '../common/PasswordField';
import { useGetLink, auth0 } from '../utils';

const useStyles = makeStyles({
  header: {
    fontSize: 35,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 24,
  },
  googleButton: {
    justifyContent: 'flex-start',
    color: '#37001F',
    borderColor: '#37001F',
    padding: '5px 10px',
    marginBottom: 9,
  },
  googleIcon: {
    margin: '0 12px 0 2px',
  },
  facebookButton: {
    justifyContent: 'flex-start',
    color: '#3571B7',
    borderColor: '#3571B7',
    padding: '5px 10px',
    marginBottom: 13,
  },
  facebookIcon: {
    marginRight: 11,
  },
  or: {
    textAlign: 'center',
  },
  agreement: {
    fontSize: 14,
    margin: '12px 0',
  },
  alreadyRegistered: {
    fontSize: 14,
    marginTop: 8,
  },
  verify: {
    fontSize: 26,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 42,
  },
  paragraph: {
    marginBottom: 24,
  },
  goToLogin: {
    fontSize: 16,
    marginTop: 48,
    display: 'block',
  },
});

export default function Register() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const getLink = useGetLink();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [repeatPassword, setRepeatPassword] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState<string | null>(null);
  const [registered, setRegistered] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    const emailValid = isValidEmail(email);
    setEmailError(emailValid ? null : 'auth.invalidEmail');
    const passwordValid = /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password);
    setPasswordError(passwordValid ? null : 'auth.invalidPassword');
    const repeatPasswordValid = repeatPassword === password;
    setRepeatPasswordError(repeatPasswordValid ? null : 'auth.passwordsDontMatch');
    if (emailValid && passwordValid && repeatPasswordValid) {
      auth0.signup({
        connection: 'Username-Password-Authentication',
        email,
        password,
        userMetadata: {
          lang: i18n.language,
        },
      }, ((err: null | Auth0Error) => {
        if (err) {
          if (err.code === 'invalid_signup') {
            setEmailError('auth.alreadyExists');
          }
        } else {
          setRegistered(true);
        }
      }));
    }
  };
  if (registered) {
    return (
      <>
        <Typography className={classes.verify}>
          {t('auth.verifyEmail')}
        </Typography>
        <Typography className={classes.paragraph}>
          {t('auth.weHaveSent', { email })}
        </Typography>
        <Typography className={classes.paragraph}>
          {t('auth.pleaseOpen')}
        </Typography>
        <Typography className={classes.paragraph}>
          <Trans
            i18nKey="auth.toResend"
            components={[
              <MuiLink
                component={Link}
                to={getLink('/auth/login')}
              />,
            ]}
          />
        </Typography>
        <MuiLink
          component={Link}
          to={getLink('/auth/login')}
          className={classes.goToLogin}
        >
          {t('auth.goToLogin')}
        </MuiLink>
      </>
    );
  }
  return (
    <>
      <Typography className={classes.header}>
        {t('auth.register')}
      </Typography>
      <Button
        variant="outlined"
        fullWidth
        className={classes.googleButton}
        onClick={() => auth0.authorize({ connection: 'google-oauth2' })}
        disabled
      >
        <GoogleIcon className={classes.googleIcon} />
        {t('auth.continueWithGoogle')}
      </Button>
      <Button
        variant="outlined"
        fullWidth
        className={classes.facebookButton}
        onClick={() => auth0.authorize({ connection: 'facebook' })}
        disabled
      >
        <FacebookIcon className={classes.facebookIcon} />
        {t('auth.continueWithFacebook')}
      </Button>
      <Typography className={classes.or}>
        {t('auth.or')}
      </Typography>
      <form
        onSubmit={(event) => event.preventDefault()}
      >
        <TextField
          label={t('auth.email')}
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          value={email}
          onChange={({ target }) => {
            setEmail(target.value);
            setEmailError(null);
          }}
          error={emailError !== null}
          helperText={emailError ? t(emailError) : null}
          id="email"
        />
        <PasswordField
          label={t('auth.password')}
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          value={password}
          onChange={({ target }) => {
            setPassword(target.value);
            setPasswordError(null);
          }}
          error={passwordError !== null}
          helperText={passwordError ? t(passwordError) : null}
          id="password"
        />
        <PasswordField
          label={t('auth.repeatPassword')}
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          value={repeatPassword}
          onChange={({ target }) => {
            setRepeatPassword(target.value);
            setRepeatPasswordError(null);
          }}
          error={repeatPasswordError !== null}
          helperText={repeatPasswordError ? t(repeatPasswordError) : null}
          id="repeat-password"
        />
        <Typography className={classes.agreement}>
          <Trans
            i18nKey="auth.registerAgreement"
            components={[
              <MuiLink component={Link} to={getLink('/terms-and-conditions')} />,
              <MuiLink component={Link} to={getLink('/privacy-policy')} />,
            ]}
          />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          data-testid="submit-button"
          disabled
        >
          {t('auth.registerButton')}
        </Button>
      </form>
      <Typography className={classes.alreadyRegistered}>
        <Trans
          i18nKey="auth.alreadyRegistered"
          components={[
            <MuiLink
              component={Link}
              to={getLink('/auth/login')}
            />,
          ]}
        />
      </Typography>
    </>
  );
}
