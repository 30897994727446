import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProviderContext } from '../../../context';
import { axios } from '../../../utils';
import NewHeader from '../../NewHeader';
import { ReactComponent as Search } from '../../../assets/search.svg';
import ButtonList from '../../ButtonList';
import DeleteDialog from '../../DeleteDialog';
import { IDriver } from '../../../interfaces';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    display: 'flex',
  },
  left: {
    flexGrow: 1,
    marginRight: 32,
  },
  right: {
    width: 300,
    flexShrink: 0,
  },
  search: {
    width: 300,
    marginBottom: 24,
  },
  name: {
    width: 300,
    marginBottom: 24,
  },
  saveRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    marginRight: 20,
  },
});

export default function Drivers() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: { agency } } = useContext(ProviderContext);
  const [drivers, setDrivers] = useState<IDriver[] | null>(null);
  const [search, setSearch] = useState('');
  const [selectedDriver, setSelectedDriver] = useState<number | 'new' | null>(null);
  const [name, setName] = useState('');
  const [active, setActive] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/${agency}/drivers`);
      setDrivers(data);
    })();
  }, [agency]);
  if (!drivers) return null;
  const loadData = (driver: IDriver | null) => {
    if (driver) {
      setSelectedDriver(driver.id);
      setName(driver.name);
      setActive(driver.active);
    } else {
      setSelectedDriver('new');
      setName('');
      setActive(false);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <NewHeader
          headerText={t('data.drivers')}
          newText={t('data.newDriver')}
          onClick={() => loadData(null)}
        />
        <TextField
          label={t('data.driversName')}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          className={classes.search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
        />
        <ButtonList
          elements={drivers.filter((driver) => (
            driver.name.toLowerCase().includes(search.toLowerCase())
          )).map((driver) => ({
            id: driver.id,
            name: driver.name,
            onClick: () => loadData(driver),
          }))}
          selected={selectedDriver}
        />
      </div>
      <div className={classes.right}>
        {selectedDriver !== null && (
          <>
            <TextField
              value={name}
              onChange={({ target }) => setName(target.value)}
              label={t('data.driversName')}
              className={classes.name}
              variant="outlined"
              size="small"
              inputProps={{ 'data-testid': 'drivers-name' }}
            />
            <div className={classes.saveRow}>
              {selectedDriver !== 'new' && (
                <Button
                  color="primary"
                  onClick={() => setDeleteDialog(true)}
                  className={classes.deleteButton}
                  disabled={active}
                >
                  {t('data.deleteDriver')}
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                disabled={!name}
                onClick={async () => {
                  if (selectedDriver === 'new') {
                    const { data } = await axios.post(`/${agency}/drivers`, { name });
                    setDrivers([...drivers, data]);
                  } else {
                    await axios.put(`/${agency}/drivers/${selectedDriver}`, { name });
                    setDrivers(drivers.map((x) => (x.id === selectedDriver ? {
                      ...x,
                      name,
                    } : x)));
                  }
                  setSelectedDriver(null);
                }}
              >
                {t('data.save')}
              </Button>
            </div>
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={t('data.deleteDriverConfirm')}
        onDelete={async () => {
          await axios.delete(`/${agency}/drivers/${selectedDriver}`);
          setSelectedDriver(null);
          setDrivers(drivers.filter((x) => x.id !== selectedDriver));
          setDeleteDialog(false);
        }}
        canDelete
      />
    </div>
  );
}
