import React from 'react';
import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { ReactComponent as Chevron } from '../assets/chevron.svg';
import { ReactComponent as Search } from '../assets/search.svg';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
  > extends WithOptional<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
  noAdornment?: boolean;
}

const useStyles = makeStyles({
  chevronIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 4px',
    margin: '-1px 0',
  },
  searchIcon: {
    margin: '0 4px 0 8px',
  },
});

export default function CustomAutocomplete<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
  >(
  {
    options, value, onChange, label, className,
    TextFieldProps: {
      variant, InputProps, inputProps, ...TFProps
    } = {}, noAdornment, getOptionLabel, blurOnSelect, getOptionSelected,
    getOptionDisabled, popupIcon, filterOptions, ...rest
  }: Props<T, Multiple, DisableClearable, FreeSolo>,
) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Autocomplete
      options={options}
      value={value}
      onChange={onChange}
      size="small"
      popupIcon={popupIcon || (
        <div className={classes.chevronIcon}>
          <Chevron />
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          {...(
            variant === 'filled'
              ? { variant: 'filled' }
              : { variant: 'outlined' }
          )}
          className={className}
          InputProps={{
            ...params.InputProps,
            startAdornment: noAdornment ? null : <Search className={classes.searchIcon} />,
            ...InputProps,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            ...inputProps,
          }}
          {...TFProps}
        />
      )}
      autoHighlight
      noOptionsText={t('common.noOptions')}
      getOptionLabel={getOptionLabel}
      blurOnSelect={blurOnSelect}
      getOptionSelected={getOptionSelected}
      getOptionDisabled={getOptionDisabled}
      filterOptions={filterOptions}
      {...rest}
    />
  );
}
