import React, {
  forwardRef, useEffect, useRef, useState,
} from 'react';
import {
  Map, ScaleControl, TileLayer, FeatureGroup,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { LatLng, Polyline, LatLngBounds } from 'leaflet';
import 'leaflet-fullscreen';
import { IShapePoint } from '../interfaces';

type IPoints = { lat: number; lon: number; }[];

interface Props {
  points: IPoints;
  setPoints: (points: IPoints) => void;
  enabled: boolean;
  className?: string;
  children?: React.ReactNode;
  initialsCoords?: IShapePoint;
}

const PathSelect = forwardRef<Map, Props>((props: Props, ref) => {
  const {
    points, setPoints, enabled, className, children, initialsCoords,
  } = props;
  const mapRef = useRef<Map>();
  const layer = useRef<Polyline>();
  const featureGroup = useRef<FeatureGroup>(null);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (layer.current) {
      layer.current.remove();
    }
    if (points.length > 0 && featureGroup.current) {
      layer.current = new Polyline(points.map(({ lat, lon }) => ({ lat, lng: lon })));
      featureGroup.current.leafletElement.addLayer(layer.current);
      if (mapRef && 'current' in mapRef && mapRef.current) {
        mapRef.current.leafletElement.fitBounds(
          new LatLngBounds(points.map(({ lat, lon }) => [lat, lon])),
        );
      }
    }
  }, [points, ready]);
  useEffect(() => {
    if (mapRef && 'current' in mapRef && mapRef.current && initialsCoords) {
      mapRef.current.leafletElement.setView(
        { lat: initialsCoords.lat, lng: initialsCoords.lon },
        16,
      );
    }
  }, [initialsCoords, ref]);
  return (
    <Map
      className={className}
      minZoom={3}
      maxZoom={18}
      // @ts-ignore
      ref={ref || mapRef}
      fullscreenControl
      whenReady={() => setReady(true)}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ScaleControl position="topright" imperial={false} />
      {enabled && (
        <FeatureGroup ref={featureGroup}>
          <EditControl
            draw={{
              polyline: points.length === 0 ? {
                shapeOptions: {
                  color: '#1D3998',
                  weight: 4,
                },
              } : false,
              polygon: false,
              rectangle: false,
              circle: false,
              marker: false,
              circlemarker: false,
            }}
            onCreated={(event: any) => {
              layer.current = event.layer;
              const latLngs: LatLng[] = event.layer.getLatLngs();
              setPoints(latLngs.map(({ lat, lng }) => ({ lat, lon: lng })));
            }}
            onEdited={(event: any) => {
              const layers = event.layers.getLayers();
              if (layers.length === 1) {
                const latLngs: LatLng[] = layers[0].getLatLngs();
                setPoints(latLngs.map(({ lat, lng }) => ({ lat, lon: lng })));
              }
            }}
            onDeleted={() => setPoints([])}
          />
        </FeatureGroup>
      )}
      {children}
    </Map>
  );
});

export default PathSelect;
