import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Button, IconButton, TextField, Typography,
} from '@material-ui/core';
import * as R from 'ramda';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import { ProviderContext } from '../../context';
import {
  IDataCalendar, IDataPattern, IDataRoute, IDataTrip, IWeekDay,
} from '../../interfaces';
import VersionInfo from '../VersionInfo';
import CustomDatePicker from '../../common/CustomDatePicker';
import { ReactComponent as Clear } from '../../assets/clear.svg';
import { axios } from '../../utils';
import NewHeader from '../NewHeader';
import ButtonList from '../ButtonList';
import DeleteDialog from '../DeleteDialog';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    display: 'flex',
  },
  left: {
    flexGrow: 1,
  },
  filtersRow: {
    display: 'flex',
    marginBottom: 16,
  },
  forRoute: {
    width: 184,
    marginRight: 16,
  },
  forTrip: {
    width: 300,
  },
  right: {
    width: 620,
    flexShrink: 0,
  },
  name: {
    width: 500,
  },
  copyCalendar: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px -16px',
    padding: 16,
    backgroundColor: '#F5F5F5',
  },
  copyField: {
    width: 300,
    marginLeft: 16,
  },
  copyButton: {
    marginLeft: 16,
  },
  subheader: {
    fontWeight: 400,
    color: '#37001F',
    marginBottom: 16,
  },
  dayButton: {
    fontSize: 24,
    fontWeight: 400,
    marginRight: 8,
    marginBottom: 32,
    borderRadius: 15,
    padding: '1px 18px',
    backgroundColor: '#F5F5F5',
  },
  buttonSelected: {
    backgroundColor: '#A1145C',
    color: 'white',
    '&:hover': {
      backgroundColor: '#c62e8a',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  datePicker: {
    width: 160,
    marginRight: 16,
  },
  dates: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-8px -8px 24px -8px',
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 15,
    backgroundColor: '#F5F5F5',
    padding: '7px 14px',
    margin: 8,
  },
  dateText: {
    fontSize: 20,
    color: '#37001F',
    fontWeight: 400,
  },
  dateButton: {
    marginLeft: 16,
  },
  discardChanges: {
    marginLeft: 'auto',
    marginRight: 16,
  },
  remarks: {
    marginBottom: 40,
  },
});

export default function Calendars() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: { agency }, version } = useContext(ProviderContext);
  const [calendars, setCalendars] = useState<IDataCalendar[]>([]);
  const [selectedCalendar, setSelectedCalendar] = useState<number | 'new' | null>(null);
  const [routes, setRoutes] = useState<IDataRoute[]>([]);
  const [trips, setTrips] = useState<IDataTrip[]>([]);
  const [patterns, setPatterns] = useState<IDataPattern[]>([]);
  const [route, setRoute] = useState<IDataRoute | null>(null);
  const [trip, setTrip] = useState<IDataTrip | null>(null);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [copyCalendar, setCopyCalendar] = useState<IDataCalendar | null>(null);
  const [days, setDays] = useState<IWeekDay[]>([]);
  const [from, setFrom] = useState<Moment | null>(null);
  const [to, setTo] = useState<Moment | null>(null);
  const [periods, setPeriods] = useState<{ from: string; to: string; }[]>([]);
  const [additional, setAdditional] = useState<string[]>([]);
  const [excluded, setExcluded] = useState<string[]>([]);
  const [remarks, setRemarks] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  useEffect(() => {
    (async () => {
      const [
        { data },
        { data: tripsData },
        { data: patternsData },
        { data: routesData },
      ] = await Promise.all([
        axios.get(`/${agency}/data/calendars`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/trips`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/patterns`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/routes`, { params: { providerVersion: version!.id } }),
      ]);
      setCalendars(data);
      setTrips(tripsData);
      setPatterns(patternsData);
      setRoutes(routesData);
    })();
  }, [agency, version]);
  const formatDate = (date: string) => moment(date).format('DD.MM.YYYY');
  const formatISO = (date: Moment) => date.format('YYYY-MM-DD');
  const loadData = (calendar: IDataCalendar | null) => {
    setCopyCalendar(null);
    setFrom(null);
    setTo(null);
    if (calendar) {
      setName(calendar.name);
      setDays(calendar.days);
      setPeriods(calendar.periods);
      setAdditional(calendar.additional);
      setExcluded(calendar.excluded);
      setRemarks(calendar.remarks || '');
    } else {
      setName('');
      setDays([]);
      setPeriods([]);
      setAdditional([]);
      setExcluded([]);
      setRemarks('');
    }
    setNameError(false);
  };
  const readOnly = !version?.hasUserLock;
  const routeTrips = trips.filter((x) => (
    patterns.filter(R.propEq('route', route?.id)).some(R.propEq('id', x.pattern))
  ));
  const canDelete = typeof selectedCalendar !== 'number' || !trips.some(R.propEq('calendar', selectedCalendar));
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <VersionInfo />
        <NewHeader
          headerText={t('data.effectiveCalendars')}
          newText={t('data.newCalendar')}
          onClick={() => {
            setSelectedCalendar('new');
            loadData(null);
          }}
          disabled={readOnly}
        />
        <div className={classes.filtersRow}>
          <CustomAutocomplete
            options={routes}
            value={route}
            onChange={(event, value) => {
              setRoute(value);
              setTrip(null);
            }}
            label={t('data.forRoute')}
            className={classes.forRoute}
            getOptionLabel={R.prop('name')}
          />
          <CustomAutocomplete
            options={routeTrips}
            value={trip}
            onChange={(event, value) => setTrip(value)}
            label={t('data.forTrip')}
            className={classes.forTrip}
            getOptionLabel={(x: IDataTrip) => {
              const calendarName = calendars.find(R.propEq('id', x.calendar))!.name;
              return `${x.type === 'SINGLE' ? x.departure : `${x.from}-${x.to}`}, ${calendarName}`;
            }}
          />
        </div>
        <ButtonList
          elements={(
            calendars.filter((x) => (
              (!route || routeTrips.some(R.propEq('calendar', x.id)))
              && (!trip || x.id === trip.calendar)
            )).map((calendar) => ({
              id: calendar.id,
              name: calendar.name,
              onClick: () => {
                setSelectedCalendar(calendar.id);
                loadData(calendar);
              },
            }))
          )}
          selected={selectedCalendar}
        />
      </div>
      <div className={classes.right}>
        {selectedCalendar !== null && (
          <>
            <TextField
              value={name}
              onChange={({ target }) => {
                setName(target.value);
                setNameError(false);
              }}
              label={t('data.calendarName')}
              variant="outlined"
              size="small"
              className={classes.name}
              error={nameError}
              helperText={nameError ? t('data.calendarExists', { name }) : ''}
            />
            <div className={classes.copyCalendar}>
              <Typography>
                {t('data.copyCalendarFrom')}
              </Typography>
              <CustomAutocomplete
                options={calendars}
                value={copyCalendar}
                onChange={(event, value) => setCopyCalendar(value)}
                label={t('data.calendar')}
                className={classes.copyField}
                getOptionLabel={R.prop('name')}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.copyButton}
                disabled={!copyCalendar}
                onClick={() => {
                  if (!copyCalendar) return;
                  setDays(copyCalendar.days);
                  /* eslint-disable no-underscore-dangle */
                  setPeriods(R.concat(R.__, copyCalendar.periods));
                  setAdditional(R.concat(R.__, copyCalendar.additional));
                  setExcluded(R.concat(R.__, copyCalendar.excluded));
                  /* eslint-enable no-underscore-dangle */
                  setCopyCalendar(null);
                }}
              >
                {t('data.copy')}
              </Button>
            </div>
            <Typography className={classes.subheader}>
              {t('data.scheduleEffective')}
            </Typography>
            {(
              ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'] as IWeekDay[]
            ).map((day) => (
              <Button
                variant="contained"
                className={classNames(classes.dayButton, {
                  [classes.buttonSelected]: days.includes(day),
                })}
                onClick={() => setDays(
                  days.includes(day) ? R.filter((x) => x !== day) : R.append(day),
                )}
                key={day}
              >
                {t(`data.${day}`)}
              </Button>
            ))}
            <Typography className={classes.subheader}>
              {t('data.periods')}
            </Typography>
            <div className={classes.row}>
              <CustomDatePicker
                value={from}
                onChange={(newDate) => {
                  if (newDate && to) {
                    setPeriods(R.append({
                      from: formatISO(newDate),
                      to: formatISO(to),
                    }));
                    setFrom(null);
                    setTo(null);
                  } else {
                    setFrom(newDate);
                  }
                }}
                className={classes.datePicker}
                label={t('data.from')}
                maxDate={to || undefined}
              />
              <CustomDatePicker
                value={to}
                onChange={(newDate) => {
                  if (newDate && from) {
                    setPeriods(R.append({
                      from: formatISO(from),
                      to: formatISO(newDate),
                    }));
                    setFrom(null);
                    setTo(null);
                  } else {
                    setTo(newDate);
                  }
                }}
                className={classes.datePicker}
                label={t('data.to')}
                minDate={from || undefined}
              />
            </div>
            <div className={classes.dates}>
              {periods.map((period) => (
                <div className={classes.date} key={`${period.from}-${period.to}`}>
                  <Typography className={classes.dateText}>
                    {formatDate(period.from)}
                    {' - '}
                    {formatDate(period.to)}
                  </Typography>
                  <IconButton
                    color="primary"
                    size="small"
                    className={classes.dateButton}
                    onClick={() => {
                      setPeriods(R.filter((x) => x !== period));
                    }}
                  >
                    <Clear />
                  </IconButton>
                </div>
              ))}
            </div>
            <Typography className={classes.subheader}>
              {t('data.additionalDates')}
            </Typography>
            <div className={classes.row}>
              <CustomDatePicker
                value={null}
                onChange={(newDate) => {
                  if (newDate) {
                    setAdditional(R.append(formatISO(newDate)));
                  }
                }}
                className={classes.datePicker}
                label={t('data.date')}
              />
            </div>
            <div className={classes.dates}>
              {additional.map((date) => (
                <div className={classes.date} key={date}>
                  <Typography className={classes.dateText}>
                    {formatDate(date)}
                  </Typography>
                  <IconButton
                    color="primary"
                    size="small"
                    className={classes.dateButton}
                    onClick={() => {
                      setAdditional(R.filter((x) => x !== date));
                    }}
                  >
                    <Clear />
                  </IconButton>
                </div>
              ))}
            </div>
            <Typography className={classes.subheader}>
              {t('data.excludedDates')}
            </Typography>
            <div className={classes.row}>
              <CustomDatePicker
                value={null}
                onChange={(newDate) => {
                  if (newDate) {
                    setExcluded(R.append(formatISO(newDate)));
                  }
                }}
                className={classes.datePicker}
                label={t('data.date')}
              />
            </div>
            <div className={classes.dates}>
              {excluded.map((date) => (
                <div className={classes.date} key={date}>
                  <Typography className={classes.dateText}>
                    {formatDate(date)}
                  </Typography>
                  <IconButton
                    color="primary"
                    size="small"
                    className={classes.dateButton}
                    onClick={() => {
                      setExcluded(R.filter((x) => x !== date));
                    }}
                  >
                    <Clear />
                  </IconButton>
                </div>
              ))}
            </div>
            <TextField
              value={remarks}
              onChange={({ target }) => setRemarks(target.value)}
              label={t('data.patternRemarks')}
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={2}
              className={classes.remarks}
            />
            <div className={classes.row}>
              {selectedCalendar !== 'new' && (
                <Button
                  color="primary"
                  onClick={() => {
                    setDeleteDialog(true);
                    setDeleteName(calendars.find(R.propEq('id', selectedCalendar))!.name);
                  }}
                  disabled={readOnly}
                >
                  {t('data.deleteCalendar')}
                </Button>
              )}
              <Button
                color="primary"
                className={classes.discardChanges}
                onClick={() => {
                  loadData(
                    selectedCalendar === 'new'
                      ? null
                      : calendars.find((x) => x.id === selectedCalendar) || null,
                  );
                }}
              >
                {t('data.discardChanges')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={readOnly || !name || periods.length === 0}
                onClick={async () => {
                  if (calendars.some((x) => x.name === name && x.id !== selectedCalendar)) {
                    setNameError(true);
                    return;
                  }
                  const calendarData = {
                    name,
                    days,
                    periods,
                    additional,
                    excluded,
                    remarks: remarks || undefined,
                  };
                  if (selectedCalendar === 'new') {
                    const { data } = await axios.post(`/${agency}/data/calendar`, calendarData);
                    setCalendars(R.append(data));
                  } else {
                    await axios.put(`/${agency}/data/calendar/${selectedCalendar}`, calendarData);
                    setCalendars(R.map((x) => (x.id === selectedCalendar ? {
                      ...x,
                      ...calendarData,
                    } : x)));
                  }
                  setSelectedCalendar(null);
                }}
              >
                {t('data.save')}
              </Button>
            </div>
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={canDelete ? t(
          'data.deleteCalendarConfirm',
          { name: deleteName },
        ) : t('data.calendarDeleteError')}
        onDelete={async () => {
          await axios.delete(`/${agency}/data/calendar/${selectedCalendar}`);
          setSelectedCalendar(null);
          setCalendars(R.filter<IDataCalendar>((x) => x.id !== selectedCalendar));
          setDeleteDialog(false);
        }}
        canDelete={canDelete}
      />
    </div>
  );
}
