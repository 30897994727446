import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import moment, { Moment } from 'moment';
import * as R from 'ramda';
import {
  Map, Polyline, ScaleControl, TileLayer,
} from 'react-leaflet';
import leaflet from 'leaflet';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import { useTranslation } from 'react-i18next';
import { axios } from '../../utils';
import {
  IProvider, IRoute, IRouteSocket, IStops, ITrip,
} from '../../interfaces';
import StopMarker from './StopMarker';
import VehicleMarker from './VehicleMarker';
import Left from './Left';
import Trips from './Trips';
import { ProviderContext } from '../../context';
import { getRouteData } from '../common';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 32,
  },
  top: {
    display: 'flex',
    height: 537,
  },
  map: {
    height: '100%',
    flexGrow: 1,
  },
  showVehicles: {
    position: 'absolute',
    right: 8,
    bottom: 20,
    zIndex: 1000,
    backgroundColor: 'white',
    paddingRight: 12,
    borderRadius: 3,
    fontSize: 16,
    color: '#37001F',
  },
});

export default function Home() {
  const classes = useStyles();
  const { providers, version, user } = useContext(ProviderContext);
  const [routes, setRoutes] = useState<IRoute[] | null>(null);
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [date, setDate] = useState<Moment>(moment());
  const [selectedRoutes, setSelectedRoutes] = useState<string[]>([]);
  const [trips, setTrips] = useState<Record<string, ITrip[]>>({});
  const [stops, setStops] = useState<Record<string, IStops>>({});
  const [routeSockets, setRouteSockets] = useState<Record<string, IRouteSocket>>({});
  const [showVehicles, setShowVehicles] = useState(false);
  const { t } = useTranslation();
  const getRTData = useCallback(async (id: string) => {
    const { data: socketData } = await axios.get(`/public/${provider!.id}/route/${id}/rtdata`, {
      // This parameter should be removed
      params: { date: moment().format('YYYY-MM-DD') },
    });
    return socketData;
  }, [provider]);
  useEffect(() => {
    const intervalId = setInterval(async () => {
      selectedRoutes.forEach((route) => {
        getRTData(route).then((rtData) => {
          setRouteSockets(R.set(
            R.lensProp(route),
            rtData,
          ));
        });
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }, [selectedRoutes, provider, getRTData]);
  const mapRef = useRef<any>();
  const [mapReady, setMapReady] = useState(false);
  useEffect(() => {
    if (mapReady) {
      const gdansk = providers.find(R.whereEq({ id: 'gdansk' }));
      const flatTrips = R.values(trips).flatMap((x) => x);
      if (flatTrips && flatTrips.length > 0) {
        mapRef.current.leafletElement.fitBounds(
          new leaflet.LatLngBounds(
            R.chain(R.prop('stops'), flatTrips).map(
              (stop) => [stop.lat, stop.lon],
            ),
          ),
          {
            padding: [32, 32],
          },
        );
      } else if (gdansk) {
        mapRef.current.leafletElement.setZoom(11);
        mapRef.current.leafletElement.panTo([gdansk.lat, gdansk.lon]);
      }
    }
  }, [trips, providers, mapReady]);
  // eslint-disable-next-line
  useEffect(() => {
    if (mapRef.current && !mapReady) {
      setMapReady(true);
    }
  });
  const [selectedTrips, setSelectedTrips] = useState<Record<string, string[]>>({});
  useEffect(() => {
    setRoutes(null);
    setProvider(null);
    setDate(moment());
    setSelectedRoutes([]);
    setTrips({});
    setStops({});
    setRouteSockets({});
  }, [version]);
  const tripsOnMap = R.fromPairs(Object.entries(trips).map(([route, routeTrips]) => (
    [route, routeTrips.filter((x) => (selectedTrips[route] || []).includes(x.pattern))]
  )));
  const allTripsOnMap = Object.values(tripsOnMap).flatMap((x) => x);
  const stopsOnMap = R.uniqBy(
    R.prop('id'),
    R.chain(R.prop('stops'), allTripsOnMap),
  );
  const shapesOnMap = R.uniq(R.map(
    (x) => (x.shape.length > 0 ? x.shape : x.stops.map(R.pick(['lat', 'lon']))),
    allTripsOnMap,
  ));
  const vehiclesOnMap = R.fromPairs(Object.entries(routeSockets)
    .map(([route, routeSocket]) => (
      [
        route,
        routeSocket.vehicles.filter((x) => (
          (tripsOnMap[route] || []).find(R.propEq('pattern', x.pattern)) !== undefined
        )),
      ]
    )));
  const providerVersion = (provider?.id === user.agency) ? version : undefined;
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Left
          providers={providers}
          provider={provider}
          setProvider={(newProvider) => {
            setProvider(newProvider);
            setRouteSockets({});
            setSelectedRoutes([]);
            setTrips({});
            setStops({});
          }}
          routes={routes}
          setRoutes={setRoutes}
          selectedRoutes={selectedRoutes}
          setSelectedRoutes={async (routeIds) => {
            setSelectedRoutes(routeIds);
            if (provider) {
              const newRoutes = R.difference(routeIds, selectedRoutes);
              newRoutes.forEach((route) => {
                Promise.all([
                  getRouteData(provider.id, date, route, providerVersion),
                  getRTData(route),
                ]).then(([newData, rtData]) => {
                  setTrips(R.set(
                    R.lensProp(route),
                    newData.trips,
                  ));
                  setSelectedTrips(R.set(
                    R.lensProp(route),
                    newData.trips.filter(R.whereEq({ direction: 0 })).map(R.prop('pattern')),
                  ));
                  setStops(R.set(
                    R.lensProp(route),
                    newData.stops,
                  ));
                  setRouteSockets(R.set(
                    R.lensProp(route),
                    rtData,
                  ));
                });
              });
              const removedRoutes = R.difference(selectedRoutes, routeIds);
              setTrips(R.omit(removedRoutes));
              setSelectedTrips(R.omit(removedRoutes));
              setStops(R.omit(removedRoutes));
              setRouteSockets(R.omit(removedRoutes));
            }
          }}
          date={date}
          setDate={async (newDate) => {
            setDate(newDate);
            if (provider) {
              selectedRoutes.forEach((route) => {
                getRouteData(
                  provider.id,
                  newDate,
                  route,
                  providerVersion,
                ).then((newData) => {
                  setTrips(R.set(
                    R.lensProp(route),
                    newData.trips,
                  ));
                  setStops(R.set(
                    R.lensProp(route),
                    newData.stops,
                  ));
                });
              });
            }
          }}
        />
        <Map
          className={classes.map}
          ref={mapRef}
          minZoom={3}
          maxZoom={18}
          fullscreenControl
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ScaleControl position="topright" imperial={false} />
          {routes && stopsOnMap && shapesOnMap && vehiclesOnMap && (
            <>
              {stopsOnMap.map((stop) => {
                const stopSocket = Object.values(routeSockets).flatMap(R.prop('stops'))
                  .find(R.propEq('id', stop.id));
                return (
                  <StopMarker
                    stop={stop}
                    stopSocket={stopSocket}
                    key={stop.id}
                  />
                );
              })}
              {shapesOnMap.map((shape, i) => (
                <Polyline
                  positions={shape.map((stop) => ({ lat: stop.lat, lng: stop.lon }))}
                  color="#1D3998"
                  weight={4}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                />
              ))}
              {showVehicles && Object.entries(vehiclesOnMap)
                .flatMap(([route, vehicles]) => vehicles.map((vehicle) => (
                  <VehicleMarker
                    vehicle={vehicle}
                    routeType={routes.find(R.propEq('id', route))!.type}
                    key={vehicle.code}
                  />
                )))}
            </>
          )}
          <div className={classes.showVehicles}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={showVehicles}
                  onChange={({ target }) => setShowVehicles(target.checked)}
                  color="primary"
                />
              )}
              label={t('map.showVehicles')}
              labelPlacement="start"
            />
          </div>
        </Map>
      </div>
      {provider && selectedRoutes.length === 1 && stops[selectedRoutes[0]]
      && trips[selectedRoutes[0]] && (
        <Trips
          selectedTrips={selectedTrips[selectedRoutes[0]]}
          setSelectedTrips={(newTrips) => setSelectedTrips(R.set(
            R.lensProp(selectedRoutes[0]),
            Array.isArray(newTrips) ? newTrips : newTrips(selectedTrips[selectedRoutes[0]]),
          ))}
          stops={stops[selectedRoutes[0]]}
          trips={trips[selectedRoutes[0]]}
          provider={provider.id}
          date={date.format('YYYY-MM-DD')}
          route={selectedRoutes[0]}
        />
      )}
    </div>
  );
}
