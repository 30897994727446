import React, { useState } from 'react';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Synchronization from './Synchronization';
import RouteTransfers from './RouteTransfers';
import StopTransfer from './StopTransfer';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tabs: {
    margin: '0 -32px',
    backgroundColor: '#F5F5F5',
    paddingTop: 16,
  },
  tab: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 300,
    width: 250,
  },
});

export default function Analysis() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={(event, value) => setTab(value)}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.tabs}
      >
        <Tab className={classes.tab} label={t('analysis.transfersForStop')} />
        <Tab className={classes.tab} label={t('analysis.transfersForRoute')} />
        <Tab className={classes.tab} label={t('analysis.synchronization')} />
      </Tabs>
      {tab === 0 && <StopTransfer />}
      {tab === 1 && <RouteTransfers />}
      {tab === 2 && <Synchronization />}
    </div>
  );
}
