import { Moment } from 'moment';
import * as R from 'ramda';
import { axios } from '../utils';
import {
  IDataVersion, IRouteDetails, IStops, IStopWithTimes, ITrip,
} from '../interfaces';

// eslint-disable-next-line import/prefer-default-export
export const getRouteData = async (
  providerId: string,
  date: Moment,
  routeId: string,
  version?: IDataVersion,
): Promise<{ trips: ITrip[]; stops: IStops }> => {
  const { data }: { data: IRouteDetails } = await axios.get(`/${providerId}/route/${routeId}/trips`, {
    params: {
      date: date.format('YYYY-MM-DD'),
      ...(version ? { providerVersion: version.id } : {}),
    },
  });
  const stops = [...data.stops.default, ...data.stops.opposite];
  return {
    trips: data.trips.map((trip) => ({
      id: trip.id,
      pattern: trip.pattern,
      direction: trip.direction,
      stops: trip.stops
        .map((stopTimes) => {
          const stop = stops.find((x) => x.id === stopTimes.id);
          return stop ? { ...stopTimes, ...stop } : null;
        })
        .filter((x): x is IStopWithTimes => x !== null),
      shape: R.propOr(
        [],
        'shape',
        data.shapes.find((shape) => shape.id === trip.shapeId),
      ),
    })),
    stops: data.stops,
  };
};
