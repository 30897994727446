import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { WebAuth } from 'auth0-js';

export function useGetLink() {
  const { i18n } = useTranslation();
  return (path: string) => `/${i18n.language}${path}`;
}

const axiosInstance = axios.create({
  baseURL: process.env.BACKEND_URL,
});

export { axiosInstance as axios };

export const auth0 = new WebAuth({
  domain: process.env.AUTH0_DOMAIN as string,
  clientID: process.env.AUTH0_CLIENT_ID as string,
  redirectUri: `${window.location.origin}/auth/callback`,
  responseType: 'token id_token',
  scope: 'openid profile email',
  audience: process.env.AUTH0_AUDIENCE,
});

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve((reader.result as string).replace(/^.*,/, '')));
    reader.addEventListener('error', reject);
    reader.readAsDataURL(file);
  });
}
