import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import fileDownload from 'js-file-download';
import classNames from 'classnames';
import {
  Map, Marker, Polyline, Popup, ScaleControl, TileLayer,
} from 'react-leaflet';
import leaflet from 'leaflet';
import * as R from 'ramda';
import { axios } from '../../utils';
import stopMarker from '../../assets/stop-marker.svg';
import { ReactComponent as Entering } from '../../assets/entering.svg';
import { ReactComponent as Leaving } from '../../assets/leaving.svg';
import { ReactComponent as Clock } from '../../assets/clock.svg';
import { ReactComponent as Passengers } from '../../assets/passengers.svg';
import { IProps } from './Trips';

interface IStop {
  id: string;
  name: string;
  lat: number;
  lon: number;
  entering?: {
    mean: number;
    median: number;
  };
  leaving?: {
    mean: number;
    median: number;
  };
  waitTime?: {
    mean: number;
    min: number;
    max: number;
    median: number;
  };
  passengers?: {
    mean: number;
    min: number;
    max: number;
    median: number;
  };
}

interface IRouteStats {
  route: string;
  passengers: {
    max: number;
    mean: number;
    median: number;
  };
  graph: {
    first: string;
    second: string;
  }[];
  stops: IStop[];
}

const useStyles = makeStyles({
  root: {
    marginTop: 32,
  },
  table: {
    borderCollapse: 'collapse',
    border: '1px solid #707070',
    backgroundColor: '#F5F5F5',
    width: 750,
    marginBottom: 32,
  },
  cell: {
    fontSize: 16,
    fontWeight: 300,
    color: '#37001F',
    padding: '4px 16px',
    textAlign: 'center',
    border: '1px solid #707070',
    borderWidth: '0 1px',
  },
  headerCell: {
    fontWeight: 400,
    borderWidth: '1px',
  },
  tableRow: {
    '&:nth-child(even)': {
      backgroundColor: 'white',
    },
  },
  selectedRow: {
    border: '4px solid #A1145C',
  },
  map: {
    height: 650,
    marginBottom: 32,
  },
  popup: {
    backgroundColor: 'white',
    borderRadius: 4,
    '& > :first-child': {
      boxShadow: 'none',
      padding: 0,
      '& > div': {
        margin: 0,
        '& p': {
          margin: 0,
        },
      },
    },
    '& > :last-child': {
      display: 'none',
    },
  },
  popupHeader: {
    margin: '12px 16px 8px 0 !important',
    padding: '4px 24px',
    borderRadius: '0 15px 15px 0',
    backgroundColor: '#37001F',
    fontSize: 24,
    fontWeight: 400,
    color: 'white',
  },
  popupRow: {
    display: 'flex',
    marginRight: 16,
    marginBottom: 8,
  },
  popupSubheader: {
    fontSize: 14,
    fontWeight: 700,
    color: '#37001F',
    marginLeft: '4px !important',
  },
  shortText: {
    width: 120,
    display: 'inline-block',
  },
  popupText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#37001F',
  },
  waitTime: {
    margin: '0 16px 16px 28px',
  },
  clock: {
    marginTop: -2,
  },
  grayHeader: {
    backgroundColor: '#E4E4E4',
    color: '#37001F',
    fontWeight: 700,
    fontSize: 16,
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 10px)',
    top: 'calc(50% - 10px)',
  },
});

export default function VehicleOccupancy({ provider, params }: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [routeStats, setRouteStats] = useState<IRouteStats[] | null>(null);
  const mapRef = useRef<Map>(null);
  const [mapReady, setMapReady] = useState(false);
  const [, setZoom] = useState(0);
  const [selectedRoute, setSelectedRoute] = useState<string | null>(null);
  const [exportLoading, setExportLoading] = useState(false);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/${provider}/vehicle-occupancy`, { params });
      setRouteStats(data);
      if (data.length > 0) {
        setSelectedRoute(data[0].route);
      }
    })();
  }, [provider, params]);
  const selectedStats = routeStats?.find(R.propEq('route', selectedRoute)) as IRouteStats;
  useEffect(() => {
    if (mapRef.current && selectedStats) {
      mapRef.current.leafletElement.fitBounds(new leaflet.LatLngBounds(
        selectedStats.stops.map((stop) => [stop.lat, stop.lon]),
      ));
    }
  }, [selectedStats]);
  if (!routeStats) return null;
  const routeMin = selectedStats
    ? selectedStats.stops.map((x) => x.passengers?.mean || Infinity).reduce(R.min)
    : 0;
  const routeMax = selectedStats
    ? selectedStats.stops.map((x) => x.passengers?.mean || 0).reduce(R.max)
    : 0;
  const renderTime = (seconds: number) => {
    const min = seconds >= 60 ? `${Math.floor(seconds / 60)} min.` : '';
    const sec = seconds % 60 > 0 ? `${seconds % 60} ${t('trips.sec')}.` : '';
    return [min, sec].filter((x) => x).join(', ');
  };
  return (
    <div className={classes.root}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classNames(classes.cell, classes.headerCell)}>
              {t('common.route')}
            </th>
            <th className={classNames(classes.cell, classes.headerCell)}>
              {t('trips.maxPassengers')}
            </th>
            <th className={classNames(classes.cell, classes.headerCell)}>
              {t('trips.meanPassengers')}
            </th>
            <th className={classNames(classes.cell, classes.headerCell)}>
              {t('trips.medianPassengers')}
            </th>
          </tr>
        </thead>
        <tbody>
          {routeStats.map((stats) => (
            <tr
              key={stats.route}
              className={classNames(classes.tableRow, {
                [classes.selectedRow]: stats.route === selectedRoute,
              })}
              onClick={() => setSelectedRoute(stats.route)}
            >
              <td className={classes.cell}>
                {stats.route}
              </td>
              <td className={classes.cell}>
                {stats.passengers.max}
              </td>
              <td className={classes.cell}>
                {stats.passengers.mean}
              </td>
              <td className={classes.cell}>
                {stats.passengers.median}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedStats && (
        <Map
          className={classes.map}
          minZoom={3}
          maxZoom={18}
          ref={mapRef}
          zoomControl
          // @ts-ignore
          fullscreenControl={{ position: 'topright' }}
          whenReady={() => setMapReady(true)}
          onViewportChange={(viewport) => setZoom(viewport.zoom || 0)}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ScaleControl position="bottomleft" imperial={false} />
          {selectedStats.stops.map((stop) => (
            <Marker
              key={stop.name}
              position={[stop.lat, stop.lon]}
              icon={leaflet.icon({
                iconUrl: stopMarker,
                iconSize: [10, 10],
              })}
            >
              <Popup
                className={classes.popup}
                maxWidth={400}
              >
                <Typography className={classes.popupHeader}>
                  {stop.name}
                </Typography>
                <div className={classes.popupRow}>
                  <Entering />
                  <Typography className={classNames(classes.popupSubheader, classes.shortText)}>
                    {t('trips.entering')}
                  </Typography>
                  {stop.entering ? (
                    <div>
                      <Typography className={classes.popupText}>
                        {`${t('trips.mean')} ${stop.entering.mean}`}
                      </Typography>
                      <Typography className={classes.popupText}>
                        {`${t('trips.median')} ${stop.entering.median}`}
                      </Typography>
                    </div>
                  ) : (
                    <Typography className={classes.popupText}>-</Typography>
                  )}
                </div>
                <div className={classes.popupRow}>
                  <Leaving />
                  <Typography className={classNames(classes.popupSubheader, classes.shortText)}>
                    {t('trips.leaving')}
                  </Typography>
                  {stop.leaving ? (
                    <div>
                      <Typography className={classes.popupText}>
                        {`${t('trips.mean')} ${stop.leaving.mean}`}
                      </Typography>
                      <Typography className={classes.popupText}>
                        {`${t('trips.median')} ${stop.leaving.median}`}
                      </Typography>
                    </div>
                  ) : (
                    <Typography className={classes.popupText}>-</Typography>
                  )}
                </div>
                <div className={classes.popupRow}>
                  <Clock className={classes.clock} />
                  <Typography className={classes.popupSubheader}>
                    {t('trips.waitTime')}
                  </Typography>
                </div>
                <div className={classes.waitTime}>
                  {stop.waitTime ? (
                    <>
                      <Typography className={classes.popupText}>
                        <span className={classes.shortText}>
                          {t('trips.timeMean')}
                        </span>
                        {renderTime(stop.waitTime.mean)}
                      </Typography>
                      <Typography className={classes.popupText}>
                        <span className={classes.shortText}>
                          {t('trips.timeMin')}
                        </span>
                        {renderTime(stop.waitTime.min)}
                      </Typography>
                      <Typography className={classes.popupText}>
                        <span className={classes.shortText}>
                          {t('trips.timeMax')}
                        </span>
                        {renderTime(stop.waitTime.max)}
                      </Typography>
                      <Typography className={classes.popupText}>
                        <span className={classes.shortText}>
                          {t('trips.timeMedian')}
                        </span>
                        {renderTime(stop.waitTime.median)}
                      </Typography>
                    </>
                  ) : (
                    <Typography className={classes.popupText}>-</Typography>
                  )}
                </div>
              </Popup>
            </Marker>
          ))}
          {mapReady && selectedStats && selectedStats.graph.map(({ first, second }) => {
            const stop = selectedStats.stops.find(R.propEq('id', first)) as IStop;
            const nextStop = selectedStats.stops.find(R.propEq('id', second)) as IStop;
            const weight = 4 + (routeMax === 0 ? 1
              : ((stop.passengers!.mean - routeMin) / (routeMax - routeMin))) * 8;
            const positions = [
              { lat: stop.lat, lng: stop.lon },
              { lat: nextStop.lat, lng: nextStop.lon },
            ];
            const polyline = new leaflet.Polyline(positions).addTo(mapRef.current!.leafletElement);
            const element = polyline.getElement();
            // @ts-ignore
            const polylineLength = element.getTotalLength ? element.getTotalLength() : 0;
            polyline.remove();
            return (
              <Polyline
                key={stop.name + nextStop.name}
                positions={positions}
                color="#1A359B"
                weight={weight}
                fillOpacity={1}
                dashArray={`${polylineLength - 20}`}
                dashOffset="-10"
                lineCap="butt"
              >
                <Popup
                  className={classes.popup}
                  maxWidth={400}
                >
                  <Typography className={classNames(classes.popupHeader, classes.grayHeader)}>
                    {`${stop.name} - ${nextStop.name}`}
                  </Typography>
                  <div className={classes.popupRow}>
                    <Passengers className={classes.clock} />
                    <Typography className={classes.popupSubheader}>
                      {t('trips.passengerCount')}
                    </Typography>
                  </div>
                  <div className={classes.waitTime}>
                    <Typography className={classes.popupText}>
                      <span className={classes.shortText}>
                        {t('trips.mean')}
                      </span>
                      {stop.passengers!.mean}
                    </Typography>
                    <Typography className={classes.popupText}>
                      <span className={classes.shortText}>
                        {t('trips.min')}
                      </span>
                      {stop.passengers!.min}
                    </Typography>
                    <Typography className={classes.popupText}>
                      <span className={classes.shortText}>
                        {t('trips.max')}
                      </span>
                      {stop.passengers!.max}
                    </Typography>
                    <Typography className={classes.popupText}>
                      <span className={classes.shortText}>
                        {t('trips.median')}
                      </span>
                      {stop.passengers!.median}
                    </Typography>
                  </div>
                </Popup>
              </Polyline>
            );
          })}
        </Map>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          setExportLoading(true);
          try {
            const { data } = await axios.get('/stats/raw');
            fileDownload(data, 'trips-data.csv');
          } finally {
            setExportLoading(false);
          }
        }}
        disabled={exportLoading}
      >
        {t('trips.export')}
        {exportLoading && (
          <CircularProgress
            size={20}
            color="primary"
            className={classes.loader}
          />
        )}
      </Button>
    </div>
  );
}
