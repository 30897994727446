import React from 'react';
import ReactDOM from 'react-dom';
import i18next from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { safeLoad } from 'js-yaml';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pl';
import pl from './translations/pl.yaml';
import en from './translations/en.yaml';
import App from './App';

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      pl: {
        translation: safeLoad(pl) as string,
      },
      en: {
        translation: safeLoad(en) as string,
      },
    },
    detection: {
      order: ['localStorage', 'navigator', 'path'],
    },
    whitelist: ['pl', 'en'],
    fallbackLng: 'en',
  }, () => {});
i18next.on('languageChanged', (lng) => {
  moment.locale(lng);
});

const render = (Component: React.FC) => {
  ReactDOM.render(
    (
      <BrowserRouter>
        <Component />
      </BrowserRouter>
    ),
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
