import React, { useContext, useEffect, useState } from 'react';
import {
  Button, IconButton, makeStyles, TextField, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import natsort from 'natsort';
import * as R from 'ramda';
import classNames from 'classnames';
import CustomDatePicker from '../../common/CustomDatePicker';
import { ProviderContext } from '../../context';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import {
  IDataVersion,
  IProvider, IRoute, IStopTransfers, ITrip,
} from '../../interfaces';
import { axios } from '../../utils';
import { getRouteData } from '../common';
import { ReactComponent as ChevronPrimary } from '../../assets/chevron-primary.svg';
import { ReactComponent as PlusMinus } from '../../assets/plus-minus.svg';
import VersionInfo from '../VersionInfo';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
  },
  datePicker: {
    width: 200,
    marginBottom: 32,
  },
  header: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 16,
  },
  fromContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  from: {
    width: 40,
    marginRight: 8,
    textAlign: 'right',
  },
  provider: {
    width: 230,
    marginRight: 16,
  },
  route: {
    width: 150,
    marginRight: 16,
  },
  trip: {
    width: 300,
  },
  toContainer: {
    display: 'flex',
    marginBottom: 16,
    minHeight: 54,
  },
  toRoutes: {
    width: 65,
    textAlign: 'right',
    margin: '15px 16px 0 0',
    flexShrink: 0,
  },
  routes: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  routeButton: {
    margin: 4,
    fontSize: 24,
    fontWeight: 400,
    padding: '1px 18px',
    borderRadius: 15,
  },
  buttonContained: {
    border: '1px solid transparent',
  },
  main: {
    backgroundColor: '#F5F5F5',
    padding: 16,
    margin: '0 -16px',
  },
  tableControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: 800,
  },
  minTransferContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  minTransferLabel: {
    marginRight: 16,
    fontSize: 18,
    color: '#37001F',
  },
  minTransfer: {
    width: 110,
  },
  changeDepartureTop: {
    display: 'flex',
    alignItems: 'center',
  },
  changeDepartureLabel: {
    marginRight: 8,
    fontSize: 18,
    color: '#37001F',
  },
  changeDepartureTime: {
    width: 100,
  },
  changeDepartureButton: {
    padding: 8,
    margin: 4,
  },
  minus: {
    width: 16,
    height: 16,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      width: '100%',
      height: 1,
      transform: 'translateY(-50%)',
      backgroundColor: '#A1145C',
    },
  },
  plus: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: 0,
      width: 1,
      height: '100%',
      transform: 'translateX(-50%)',
      backgroundColor: '#A1145C',
    },
  },
  resetButton: {
    display: 'flex',
    padding: '4px 8px',
    margin: '4px 40px 0 auto',
  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '16px 0',
  },
  table: {
    borderCollapse: 'collapse',
    backgroundColor: 'white',
    border: '1px solid #37001F',
    textAlign: 'center',
  },
  headerRow: {
    border: '1px solid #37001F',
  },
  cell: {
    padding: '8px 12px',
    color: '#37001F',
    fontWeight: 300,
  },
  patternCell: {
    textAlign: 'left',
  },
  arrivalCell: {
    border: '0 solid #37001F',
    borderWidth: '0 1px',
  },
  headerCell: {
    fontWeight: 400,
    '&:nth-child(1)': {
      width: 330,
    },
    '&:nth-child(2)': {
      width: 80,
    },
    '&:nth-child(3)': {
      width: 90,
    },
    '&:nth-child(4)': {
      width: 90,
    },
    '&:nth-child(5)': {
      width: 100,
    },
    '&:nth-child(6)': {
      width: 100,
    },
  },
  evenRow: {
    backgroundColor: '#F5F5F5',
  },
  positive: {
    color: '#C82727',
  },
  negative: {
    color: '#12A72F',
  },
  expandButton: {
    width: 28,
    height: 28,
    padding: 8,
  },
  chevronFlipped: {
    transform: 'rotate(180deg)',
  },
  tableRoute: {
    backgroundColor: 'white',
    padding: '1px 9px',
    border: '1px solid',
    borderRadius: 10,
    fontSize: 20,
    fontWeight: 400,
  },
  patternContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  averageRow: {
    border: '1px solid #37001F',
    fontSize: 18,
  },
  averageLabel: {
    textAlign: 'right',
  },
  optimizeContainer: {
    marginLeft: 32,
    width: 460,
    padding: '40px 30px',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  optimizeRow: {
    fontSize: 18,
    margin: '4px 0',
  },
  optimizeLabel: {
    display: 'inline-block',
    width: 200,
  },
  optimizeValue: {
    display: 'inline-block',
    width: 110,
  },
  optimizeArrivalRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0',
  },
  optimizeArrivalLabel: {
    display: 'inline-block',
    width: 150,
  },
  optimizeArrivalValue: {
    display: 'inline-block',
    width: 60,
  },
  plusMinus: {
    marginRight: 8,
  },
  offset: {
    width: 110,
  },
  minuteInput: {
    paddingRight: '36px !important',
  },
  optimize: {
    display: 'flex',
    marginLeft: 'auto',
    marginTop: 16,
  },
});

const sorter = natsort();

const formatDuration = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours > 0 ? `${hours} h ` : ''}${minutes > 0 ? `${minutes} min` : ''}`.trim();
};

export default function RouteTransfers() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { providers, version, user } = useContext(ProviderContext);
  const [date, setDate] = useState<Moment>(moment());
  const [selectedProvider, setSelectedProvider] = useState<IProvider | null>(null);
  const [routes, setRoutes] = useState<IRoute[]>([]);
  const [fromRoute, setFromRoute] = useState<IRoute | null>(null);
  const [trips, setTrips] = useState<ITrip[]>([]);
  const [selectedTrip, setSelectedTrip] = useState<ITrip | null>(null);
  const [stopTransfers, setStopTransfers] = useState<IStopTransfers[]>([]);
  const [toRoutes, setToRoutes] = useState<string[]>([]);
  const [minTransfer, setMinTransfer] = useState<number>(3);
  const [arrivalDelta, setArrivalDelta] = useState<number>(0);
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});
  const [offset, setOffset] = useState<number>(10);
  const [prevVersion, setPrevVersion] = useState<IDataVersion | undefined>(version);
  useEffect(() => {
    setSelectedProvider(null);
    setRoutes([]);
    setFromRoute(null);
    setTrips([]);
    setSelectedTrip(null);
    setStopTransfers([]);
    setToRoutes([]);
    setMinTransfer(3);
    setArrivalDelta(0);
    setExpandedRows({});
    setOffset(10);
    setPrevVersion(version);
  }, [version]);
  useEffect(() => {
    (async () => {
      if (selectedProvider && fromRoute && version === prevVersion) {
        const data = await getRouteData(
          selectedProvider.id,
          date,
          fromRoute.id,
          selectedProvider.id === user?.agency ? version : undefined,
        );
        setTrips(data.trips);
      }
    })();
  }, [date, fromRoute, selectedProvider, version, user, prevVersion]);
  const getRoutes = (currentStopTransfers: IStopTransfers[]) => (
    R.uniqBy(R.prop('id'), R.chain(
      (x) => x.departures.map(({ route }) => (
        routes.find(R.whereEq({ id: route }))
      )),
      currentStopTransfers,
    ).filter((x): x is IRoute => x !== undefined))
      .sort((a, b) => sorter(a.name, b.name))
  );
  const tableData = selectedTrip ? stopTransfers.map((stopTransfer) => {
    const key = `${stopTransfer.stop}-${stopTransfer.arrival}`;
    const stop = selectedTrip.stops.find(R.whereEq({ id: stopTransfer.stop }))!.name;
    const arrival = moment(stopTransfer.arrival, 'H:m').add(arrivalDelta, 'minutes');
    const departures = stopTransfer.departures.filter(
      (departure) => toRoutes.includes(departure.route),
    ).map((departure) => {
      const route = routes.find(R.whereEq({ id: departure.route }))!.name;
      const time = departure.departures.find((x) => (
        moment(x, 'H:m').isSameOrAfter(moment(arrival).add(minTransfer, 'minutes'))
      ));
      const originalTime = departure.departures.find((x) => (
        moment(x, 'H:m').isSameOrAfter(moment(stopTransfer.arrival, 'H:m').add(minTransfer, 'minutes'))
      ));
      const transferTime = time ? moment(time, 'H:m').diff(arrival, 'minutes') : null;
      const originalTransferTime = originalTime ? moment(originalTime, 'H:m')
        .diff(moment(stopTransfer.arrival, 'H:m'), 'minutes') : null;
      const diff = (transferTime !== null && originalTransferTime !== null)
        ? transferTime - originalTransferTime
        : null;
      return {
        route,
        time,
        transferTime,
        originalTransferTime,
        diff,
      };
    });
    return {
      key,
      stop,
      arrival: arrival.format('HH:mm'),
      departures,
    };
  }) : null;
  const getAverage = (xs: number[]) => Math.round(R.sum(xs) / xs.length);
  const transferTimes = tableData ? R.chain(
    (x) => x.departures.map(R.prop('transferTime')).filter((y): y is number => y !== null),
    tableData,
  ) : null;
  const originalTimes = tableData ? R.chain(
    (x) => x.departures.map(R.prop('originalTransferTime')).filter((y): y is number => y !== null),
    tableData,
  ) : null;
  const average = transferTimes ? getAverage(transferTimes) : null;
  const originalAverage = originalTimes ? getAverage(originalTimes) : null;
  const averageDiff = (average && originalAverage) ? average - originalAverage : null;
  const max = transferTimes ? R.reduce((a, b) => R.max(a, b), 0, transferTimes) : null;
  const originalMax = originalTimes ? R.reduce((a, b) => R.max(a, b), 0, originalTimes) : null;
  const maxDiff = (max && originalMax) ? max - originalMax : null;
  return (
    <div className={classes.root}>
      <VersionInfo />
      <CustomDatePicker
        value={date}
        onChange={(newDate) => {
          if (newDate) {
            setDate(newDate);
            setSelectedTrip(null);
            setTrips([]);
            setStopTransfers([]);
            setToRoutes([]);
            setExpandedRows({});
            setArrivalDelta(0);
          }
        }}
        className={classes.datePicker}
        label={t('synchronization.date')}
      />
      <Typography className={classes.header}>
        {t('routeTransfers.transfer')}
      </Typography>
      <div className={classes.fromContainer}>
        <Typography className={classes.from}>
          {t('routeTransfers.from')}
        </Typography>
        <CustomAutocomplete
          options={providers}
          value={selectedProvider}
          onChange={async (event, value) => {
            setSelectedProvider(value);
            setFromRoute(null);
            setSelectedTrip(null);
            setRoutes([]);
            setTrips([]);
            setStopTransfers([]);
            setToRoutes([]);
            setExpandedRows({});
            setArrivalDelta(0);
            if (value) {
              const { data }: { data: IRoute[] } = await axios.get(`/${value.id}/routes`, {
                params: (version && value.id === user.agency)
                  ? { providerVersion: version.id }
                  : {},
              });
              setRoutes(data.sort((a, b) => sorter(a.name, b.name)));
            }
          }}
          label={t('export.dataSource')}
          className={classes.provider}
          getOptionLabel={R.prop('name')}
        />
        <CustomAutocomplete
          options={routes}
          value={fromRoute}
          onChange={(event, value) => {
            setFromRoute(value);
            setSelectedTrip(null);
            setTrips([]);
            setStopTransfers([]);
            setToRoutes([]);
            setExpandedRows({});
            setArrivalDelta(0);
          }}
          label={t('common.route')}
          className={classes.route}
          getOptionLabel={R.prop('name')}
        />
        <CustomAutocomplete
          options={trips}
          getOptionLabel={({ stops }) => `${R.slice(0, 5, stops[0].arrival)} ${stops[0].name}`}
          value={selectedTrip}
          onChange={async (event, value) => {
            setSelectedTrip(value);
            setStopTransfers([]);
            setToRoutes([]);
            setExpandedRows({});
            setArrivalDelta(0);
            if (selectedProvider && value) {
              const { data } = await axios.get(`/${selectedProvider.id}/routes/transfers`, {
                params: {
                  trip: value.id,
                  date: date.format('YYYY-MM-DD'),
                  transferTime: minTransfer,
                  offset,
                  ...((version && selectedProvider.id === user.agency)
                    ? { providerVersion: version.id }
                    : {}),
                },
              });
              setStopTransfers(data);
              setToRoutes(getRoutes(data).map(R.prop('id')));
              setExpandedRows({});
            }
          }}
          label={t('routeTransfers.firstStopDeparture')}
          className={classes.trip}
        />
      </div>
      <div className={classes.toContainer}>
        <Typography className={classes.toRoutes}>
          {t('routeTransfers.toRoutes')}
        </Typography>
        <div className={classes.routes}>
          {getRoutes(stopTransfers).map((route) => (
            <Button
              key={route.id}
              color="primary"
              variant={toRoutes.includes(route.id) ? 'contained' : 'outlined'}
              onClick={() => setToRoutes(
                toRoutes.includes(route.id)
                  ? toRoutes.filter((x) => x !== route.id)
                  : [...toRoutes, route.id],
              )}
              className={classes.routeButton}
              classes={{
                contained: classes.buttonContained,
              }}
            >
              {route.name}
            </Button>
          ))}
        </div>
      </div>
      <div className={classes.main}>
        <div className={classes.tableControls}>
          <div className={classes.minTransferContainer}>
            <Typography className={classes.minTransferLabel}>
              {t('routeTransfers.minTransfer')}
            </Typography>
            <CustomAutocomplete
              options={R.times((n) => n + 1, 15)}
              getOptionLabel={(x) => `${x} ${t('synchronization.min')}.`}
              value={minTransfer}
              onChange={(event, value) => {
                if (value) {
                  setMinTransfer(value);
                }
              }}
              noAdornment
              disableClearable
              className={classes.minTransfer}
              TextFieldProps={{
                InputProps: {
                  classes: { root: classes.minuteInput },
                },
              }}
            />
          </div>
          <div>
            <div className={classes.changeDepartureTop}>
              <Typography className={classes.changeDepartureLabel}>
                {t('routeTransfers.changeDeparture')}
                :
                {' '}
                {tableData && tableData.length > 0 && tableData[0].arrival}
              </Typography>
              <IconButton
                color="primary"
                className={classes.changeDepartureButton}
                onClick={() => setArrivalDelta(arrivalDelta - 1)}
              >
                <div className={classes.minus} />
              </IconButton>
              <TextField
                value={`${arrivalDelta >= 0 ? '+' : '-'} ${Math.abs(arrivalDelta)} ${t('synchronization.min')}.`}
                InputProps={{ readOnly: true }}
                inputProps={{ // eslint-disable-line react/jsx-no-duplicate-props
                  'data-testid': 'change-departure',
                }}
                className={classes.changeDepartureTime}
                variant="outlined"
                size="small"
              />
              <IconButton
                color="primary"
                className={classes.changeDepartureButton}
                onClick={() => setArrivalDelta(arrivalDelta + 1)}
              >
                <div className={classNames(classes.minus, classes.plus)} />
              </IconButton>
            </div>
            <Button
              color="primary"
              className={classes.resetButton}
              onClick={() => setArrivalDelta(0)}
            >
              {t('routeTransfers.reset')}
            </Button>
          </div>
        </div>
        {tableData && tableData.length > 0 && (
          <div className={classes.bottom}>
            <table className={classes.table}>
              <thead>
                <tr className={classes.headerRow}>
                  <th className={classNames(classes.cell, classes.patternCell, classes.headerCell)}>
                    {t('routeTransfers.pattern')}
                  </th>
                  <th className={classNames(classes.cell, classes.arrivalCell, classes.headerCell)}>
                    {t('routeTransfers.arrival')}
                  </th>
                  <th className={classNames(classes.cell, classes.headerCell)}>
                    {t('common.route')}
                  </th>
                  <th className={classNames(classes.cell, classes.headerCell)}>
                    {t('routeTransfers.departure')}
                  </th>
                  <th className={classNames(classes.cell, classes.headerCell)}>
                    {t('routeTransfers.transferTime')}
                  </th>
                  <th className={classNames(classes.cell, classes.headerCell)}>
                    {t('routeTransfers.difference')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((x, i) => {
                  const departureRows = x.departures.map((departure) => (
                    <>
                      <td className={classes.cell}>
                        <span className={classes.tableRoute}>
                          {departure.route}
                        </span>
                      </td>
                      <td className={classes.cell}>
                        {departure.time || '-'}
                      </td>
                      <td className={classes.cell}>
                        {departure.transferTime || '-'}
                      </td>
                      <td className={classes.cell}>
                        {departure.diff ? (
                          <span
                            className={departure.diff > 0 ? classes.positive : classes.negative}
                          >
                            {departure.diff > 0 ? '+' : '-'}
                            {Math.abs(departure.diff)}
                          </span>
                        ) : '-'}
                      </td>
                    </>
                  ));
                  return (
                    <>
                      <tr
                        key={x.key}
                        className={classNames({
                          [classes.evenRow]: i % 2 === 1,
                        })}
                      >
                        <td className={classNames(classes.cell, classes.patternCell)}>
                          <div className={classes.patternContainer}>
                            {x.stop}
                            {x.departures.length > 1 && (
                              <IconButton
                                color="primary"
                                className={classes.expandButton}
                                onClick={() => setExpandedRows(
                                  R.over(R.lensProp(String(i)), R.not),
                                )}
                              >
                                <ChevronPrimary
                                  className={classNames({
                                    [classes.chevronFlipped]: expandedRows[i],
                                  })}
                                />
                              </IconButton>
                            )}
                          </div>
                        </td>
                        <td className={classNames(classes.cell, classes.arrivalCell)}>
                          {x.arrival}
                        </td>
                        {departureRows[0] ? departureRows[0] : (
                          <>
                            <td className={classes.cell} />
                            <td className={classes.cell} />
                            <td className={classes.cell} />
                            <td className={classes.cell} />
                          </>
                        )}
                      </tr>
                      {expandedRows[i] && R.tail(departureRows).map((cells, j) => (
                        <tr
                          key={
                            // eslint-disable-next-line react/no-array-index-key
                            `${x.stop}-${x.arrival}-${j}`
                          }
                          className={classNames({
                            [classes.evenRow]: i % 2 === 1,
                          })}
                        >
                          <td className={classNames(classes.cell, classes.patternCell)} />
                          <td className={classNames(classes.cell, classes.arrivalCell)} />
                          {cells}
                        </tr>
                      ))}
                    </>
                  );
                })}
              </tbody>
              <tfoot>
                <tr className={classes.averageRow}>
                  <td colSpan={4} className={classNames(classes.cell, classes.averageLabel)}>
                    {t('routeTransfers.averageTransferTime')}
                    :
                  </td>
                  <td className={classes.cell}>
                    {average ? formatDuration(average) : null}
                  </td>
                  <td className={classes.cell}>
                    {averageDiff ? (
                      <span className={averageDiff > 0 ? classes.positive : classes.negative}>
                        [
                        {averageDiff > 0 ? '+' : '-'}
                        {Math.abs(averageDiff)}
                        {' '}
                        {t('synchronization.min')}
                        ]
                      </span>
                    ) : null}
                  </td>
                </tr>
              </tfoot>
            </table>
            <div className={classes.optimizeContainer}>
              <Typography className={classes.optimizeRow}>
                <span className={classes.optimizeLabel}>
                  {t('routeTransfers.averageTransferTime')}
                </span>
                <span className={classes.optimizeValue}>
                  {average ? formatDuration(average) : null}
                </span>
                {averageDiff ? (
                  <span className={averageDiff > 0 ? classes.positive : classes.negative}>
                    [
                    {averageDiff > 0 ? '+' : '-'}
                    {Math.abs(averageDiff)}
                    {' '}
                    {t('synchronization.min')}
                    ]
                  </span>
                ) : null}
              </Typography>
              <Typography className={classes.optimizeRow}>
                <span className={classes.optimizeLabel}>
                  {t('routeTransfers.longestTransfer')}
                </span>
                <span className={classes.optimizeValue}>
                  {max ? formatDuration(max) : null}
                </span>
                {maxDiff ? (
                  <span className={maxDiff > 0 ? classes.positive : classes.negative}>
                    [
                    {maxDiff > 0 ? '+' : '-'}
                    {Math.abs(maxDiff)}
                    {' '}
                    {t('synchronization.min')}
                    ]
                  </span>
                ) : null}
              </Typography>
              <div className={classes.optimizeArrivalRow}>
                <Typography className={classes.optimizeRow}>
                  <span className={classes.optimizeArrivalLabel}>
                    {t('routeTransfers.departureTime')}
                  </span>
                  <span className={classes.optimizeArrivalValue}>
                    {tableData[0].arrival}
                  </span>
                </Typography>
                <PlusMinus className={classes.plusMinus} />
                <CustomAutocomplete
                  options={R.times((n) => n + 1, 30)}
                  getOptionLabel={(x) => `${x} ${t('synchronization.min')}.`}
                  value={offset}
                  onChange={(event, value) => {
                    if (value) {
                      setOffset(value);
                    }
                  }}
                  noAdornment
                  disableClearable
                  className={classes.offset}
                  TextFieldProps={{
                    InputProps: {
                      classes: { root: classes.minuteInput },
                    },
                  }}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                className={classes.optimize}
                onClick={async () => {
                  if (!selectedProvider || !selectedTrip) return;
                  const { data } = await axios.get(`/${selectedProvider.id}/routes/transfers/optimize`, {
                    params: {
                      trip: selectedTrip.id,
                      date: date.format('YYYY-MM-DD'),
                      transferTime: minTransfer,
                      offset,
                      routes: toRoutes.join(','),
                      ...((version && selectedProvider.id === user.agency)
                        ? { providerVersion: version.id }
                        : {}),
                    },
                  });
                  setArrivalDelta(data);
                }}
              >
                {t('routeTransfers.optimize')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
