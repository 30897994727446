import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProviderContext } from '../../../context';
import { axios } from '../../../utils';
import NewHeader from '../../NewHeader';
import { ReactComponent as Search } from '../../../assets/search.svg';
import ButtonList from '../../ButtonList';
import DeleteDialog from '../../DeleteDialog';
import { ICrewVehicle } from '../../../interfaces';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    display: 'flex',
  },
  left: {
    flexGrow: 1,
    marginRight: 32,
  },
  right: {
    width: 300,
    flexShrink: 0,
  },
  search: {
    width: 300,
    marginBottom: 24,
  },
  name: {
    width: 300,
    marginBottom: 24,
  },
  saveRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    marginRight: 20,
  },
});

export default function Vehicles() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: { agency } } = useContext(ProviderContext);
  const [vehicles, setVehicles] = useState<ICrewVehicle[] | null>(null);
  const [search, setSearch] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState<number | 'new' | null>(null);
  const [name, setName] = useState('');
  const [active, setActive] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/${agency}/vehicles`);
      setVehicles(data);
    })();
  }, [agency]);
  if (!vehicles) return null;
  const loadData = (vehicle: ICrewVehicle | null) => {
    if (vehicle) {
      setSelectedVehicle(vehicle.id);
      setName(vehicle.number);
      setActive(vehicle.active);
    } else {
      setSelectedVehicle('new');
      setName('');
      setActive(false);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <NewHeader
          headerText={t('data.vehicles')}
          newText={t('data.newVehicle')}
          onClick={() => loadData(null)}
        />
        <TextField
          label={t('data.vehicleID')}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          className={classes.search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
        />
        <ButtonList
          elements={vehicles.filter((vehicle) => (
            vehicle.number.toLowerCase().includes(search.toLowerCase())
          )).map((vehicle) => ({
            id: vehicle.id,
            name: vehicle.number,
            onClick: () => loadData(vehicle),
          }))}
          selected={selectedVehicle}
        />
      </div>
      <div className={classes.right}>
        {selectedVehicle !== null && (
          <>
            <TextField
              value={name}
              onChange={({ target }) => setName(target.value)}
              label={t('data.vehicleID')}
              className={classes.name}
              variant="outlined"
              size="small"
              inputProps={{ 'data-testid': 'vehicle-id' }}
            />
            <div className={classes.saveRow}>
              {selectedVehicle !== 'new' && (
                <Button
                  color="primary"
                  onClick={() => setDeleteDialog(true)}
                  className={classes.deleteButton}
                  disabled={active}
                >
                  {t('data.deleteVehicle')}
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                disabled={!name}
                onClick={async () => {
                  if (selectedVehicle === 'new') {
                    const { data } = await axios.post(`/${agency}/vehicles`, { number: name });
                    setVehicles([...vehicles, data]);
                  } else {
                    await axios.put(`/${agency}/vehicles/${selectedVehicle}`, { number: name });
                    setVehicles(vehicles.map((x) => (x.id === selectedVehicle ? {
                      ...x,
                      number: name,
                    } : x)));
                  }
                  setSelectedVehicle(null);
                }}
              >
                {t('data.save')}
              </Button>
            </div>
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={t('data.deleteVehicleConfirm')}
        onDelete={async () => {
          await axios.delete(`/${agency}/vehicles/${selectedVehicle}`);
          setSelectedVehicle(null);
          setVehicles(vehicles.filter((x) => x.id !== selectedVehicle));
          setDeleteDialog(false);
        }}
        canDelete
      />
    </div>
  );
}
