import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Button, FormControlLabel, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import CustomAutocomplete from '../common/CustomAutocomplete';
import { IDiscountType, IPackageDiscount, ISimpleAttraction } from '../interfaces';
import NumberField from '../common/NumberField';

interface Props {
  attractions: ISimpleAttraction[];
  packageName: string;
  discount: IPackageDiscount | null;
  onClose: () => void;
  onSave: (discount: IPackageDiscount) => void;
  disabled: boolean;
}

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    width: '100%',
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    color: '#37001F',
  },
  package: {
    fontSize: 20,
    fontWeight: 400,
    color: '#37001F',
    marginBottom: 24,
  },
  saveRow: {
    width: 520,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 24,
  },
  discardChanges: {
    marginRight: 16,
  },
  attraction: {
    width: 300,
    marginBottom: 32,
  },
  subheader: {
    fontWeight: 400,
    marginBottom: 16,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  field: {
    width: 300,
  },
  fieldInfo: {
    fontWeight: 300,
    color: '#37001F',
    marginLeft: 8,
  },
  formControlLabel: {
    marginBottom: 24,
  },
  radioRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
  },
  radioField: {
    width: 180,
  },
});

export default function PackageAttraction(props: Props) {
  const {
    attractions, packageName, discount, onClose, onSave, disabled,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [attraction, setAttraction] = useState<ISimpleAttraction | null>(
    discount
      ? attractions.find((x) => x.id === discount.attraction) as ISimpleAttraction
      : null,
  );
  const [numberOfEntries, setNumberOfEntries] = useState(discount ? String(discount.entries) : '');
  const [basePrice, setBasePrice] = useState(discount ? String(discount.basePrice) : '');
  const [discountType, setDiscountType] = useState<IDiscountType | null>(
    discount ? discount.type : null,
  );
  const [percentage, setPercentage] = useState(
    discount?.type === 'PERCENTAGE' ? String(discount.amount) : '',
  );
  const [amount, setAmount] = useState(
    discount?.type === 'PLN' ? String(discount.amount) : '',
  );
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {t('card.attractionDiscount')}
      </Typography>
      <Typography className={classes.package}>
        {`${t('card.discountPackage')}: ${packageName}`}
      </Typography>
      <CustomAutocomplete
        options={attractions}
        value={attraction}
        onChange={(event, value) => setAttraction(value)}
        label={t('card.attractionName')}
        className={classes.attraction}
        getOptionLabel={(x) => `${x.site}, ${x.name}`}
        disabled={disabled}
      />
      <div className={classes.row}>
        <NumberField
          label={t('card.numberOfEntries')}
          value={numberOfEntries}
          onChange={({ target }) => setNumberOfEntries(target.value)}
          variant="outlined"
          size="small"
          className={classes.field}
          disabled={disabled}
        />
      </div>
      <div className={classes.row}>
        <NumberField
          label={t('card.baseAdmissionPrice')}
          value={basePrice}
          onChange={({ target }) => setBasePrice(target.value)}
          variant="outlined"
          size="small"
          className={classes.field}
          disabled={disabled}
        />
        <Typography className={classes.fieldInfo}>
          PLN
        </Typography>
      </div>
      <Typography className={classes.subheader}>
        {t('card.discount')}
      </Typography>
      <RadioGroup
        value={discountType}
        onChange={({ target }) => setDiscountType(target.value as IDiscountType)}
      >
        <FormControlLabel
          value="PERCENTAGE"
          control={(
            <Radio color="primary" />
          )}
          label={(
            <div className={classes.radioRow}>
              <NumberField
                label={t('card.percentageDiscount')}
                value={percentage}
                onChange={({ target }) => {
                  setPercentage(target.value);
                  setDiscountType('PERCENTAGE');
                }}
                variant="outlined"
                size="small"
                className={classes.radioField}
                disabled={disabled}
              />
              <Typography className={classes.fieldInfo}>
                %
              </Typography>
            </div>
          )}
          className={classes.formControlLabel}
          disabled={disabled}
        />
        <FormControlLabel
          value="PLN"
          control={(
            <Radio color="primary" />
          )}
          label={(
            <div className={classes.radioRow}>
              <NumberField
                label={t('card.amountOfDiscount')}
                value={amount}
                onChange={({ target }) => {
                  setAmount(target.value);
                  setDiscountType('PLN');
                }}
                variant="outlined"
                size="small"
                className={classes.radioField}
                disabled={disabled}
              />
              <Typography className={classes.fieldInfo}>
                PLN
              </Typography>
            </div>
          )}
          className={classes.formControlLabel}
          disabled={disabled}
        />
        <FormControlLabel
          value="FREE_ENTRY"
          control={(
            <Radio color="primary" />
          )}
          label={(
            <div className={classes.radioRow}>
              <Typography>
                {t('card.freeEntrance')}
              </Typography>
            </div>
          )}
          className={classes.formControlLabel}
          disabled={disabled}
        />
      </RadioGroup>
      <div className={classes.saveRow}>
        <Button
          color="primary"
          className={classes.discardChanges}
          onClick={onClose}
        >
          {t('card.discardChanges')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={(
            !attraction || !numberOfEntries || !basePrice || !discountType
            || (discountType === 'PERCENTAGE' && !percentage)
            || (discountType === 'PLN' && !amount)
          )}
          onClick={() => {
            if (!attraction || !discountType) {
              return;
            }
            const data: any = {
              attraction: attraction.id,
              type: discountType,
              entries: Number(numberOfEntries),
              basePrice: Number(basePrice),
            };
            if (discountType === 'PERCENTAGE') {
              data.amount = Number(percentage);
            } else if (discountType === 'PLN') {
              data.amount = Number(amount);
            }
            onSave(data);
          }}
        >
          {t('card.saveToPackage')}
        </Button>
      </div>
    </div>
  );
}
