import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import * as R from 'ramda';
import {
  Map, Marker, Polyline, Popup, ScaleControl, TileLayer, Tooltip,
} from 'react-leaflet';
import leaflet from 'leaflet';
import { Typography } from '@material-ui/core';
import 'leaflet-polylineoffset';
import stopMarker from '../../assets/stop-marker.svg';
import { ReactComponent as Clock } from '../../assets/clock.svg';
import { IProps } from './Trips';
import { axios } from '../../utils';

interface IHub {
  name: string;
  lat: number;
  lon: number;
  passengers: number;
  transferTime?: {
    avg: number;
    min: number;
    max: number;
    med: number;
  };
}

interface IStop {
  id: string;
  name: string;
  lat: number;
  lon: number;
}

interface IStream {
  passengers: number;
  graph: {
    first: string;
    last: string;
    route: string;
  }[];
  stops: IStop[];
}

const useStyles = makeStyles({
  tables: {
    display: 'flex',
    marginBottom: 32,
  },
  table: {
    borderCollapse: 'collapse',
    border: '1px solid #707070',
    backgroundColor: '#F5F5F5',
    marginRight: 24,
  },
  cell: {
    fontSize: 16,
    fontWeight: 300,
    color: '#37001F',
    padding: '4px 16px',
    textAlign: 'center',
    border: '1px solid #707070',
    borderWidth: '0 1px',
    '&:first-child': {
      textAlign: 'left',
    },
  },
  headerCell: {
    fontWeight: 400,
    borderWidth: '1px',
    '&:first-child': {
      fontWeight: 700,
    },
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: 'white',
    },
  },
  selectedRow: {
    border: '4px solid #A1145C',
  },
  route: {
    padding: '2px 6px',
    borderRadius: 7,
    color: 'white',
    fontSize: 14,
    fontWeight: 400,
  },
  map: {
    height: 650,
    marginBottom: 32,
  },
  popup: {
    backgroundColor: 'white',
    borderRadius: 4,
    '& > :first-child': {
      boxShadow: 'none',
      padding: 0,
      '& > div': {
        margin: 0,
        '& p': {
          margin: 0,
        },
      },
    },
    '& > :last-child': {
      display: 'none',
    },
  },
  popupHeader: {
    margin: '12px 16px 8px 0 !important',
    padding: '4px 24px',
    borderRadius: '0 15px 15px 0',
    backgroundColor: '#37001F',
    fontSize: 24,
    fontWeight: 400,
    color: 'white',
  },
  popupRow: {
    display: 'flex',
    marginRight: 16,
    marginBottom: 4,
  },
  popupSubheader: {
    fontSize: 14,
    fontWeight: 700,
    color: '#37001F',
    marginLeft: '4px !important',
  },
  shortText: {
    width: 120,
    display: 'inline-block',
  },
  popupText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#37001F',
  },
  waitTime: {
    margin: '0 16px 16px 28px',
  },
  clock: {
    marginTop: -2,
  },
  tooltip: {
    boxShadow: 'none',
  },
  tooltipText: {
    fontSize: 24,
    padding: '0 4px',
  },
});

export default function PassengerStreams({ provider, params }: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hubs, setHubs] = useState<IHub[] | null>(null);
  const [streams, setStreams] = useState<IStream[] | null>(null);
  useEffect(() => {
    (async () => {
      const [
        { data },
        { data: streamsData },
      ] = await Promise.all([
        axios.get(`/${provider}/nodes`, { params }),
        axios.get(`/${provider}/streams`, { params }),
      ]);
      setHubs(data);
      setStreams(streamsData);
    })();
  }, [provider, params]);
  const [selectedStream, setSelectedStream] = useState<number | null>(null);
  const [selectedHub, setSelectedHub] = useState<number | null>(null);
  const mapRef = useRef<Map>(null);
  const [mapReady, setMapReady] = useState(false);
  const [, setZoom] = useState(0);
  useEffect(() => {
    if (mapRef.current && streams) {
      mapRef.current.leafletElement.fitBounds(new leaflet.LatLngBounds(
        R.uniqBy(R.prop('name'), streams.flatMap((stream) => stream.stops))
          .map((stop) => [stop.lat, stop.lon]),
      ));
    }
  }, [streams]);
  const markerRefs = useRef<Record<string, Marker>>({});
  if (!hubs || !streams) return null;
  const colors = ['#9B1A1F', '#339B1A'];
  const selectedColors = ['#c91219', '#35b516'];
  const routes = R.uniq(streams.flatMap((x) => x.graph.map(R.prop('route'))));
  const getRouteColor = (route: string, selected?: boolean) => (
    (selected ? selectedColors : colors)[routes.indexOf(route) % colors.length]
  );
  const getHub = (name: string) => hubs.find(R.propEq('name', name));
  const getHubIndex = (name: string) => hubs.findIndex(R.propEq('name', name));
  const renderTime = (seconds: number) => {
    const min = seconds >= 60 ? `${Math.floor(seconds / 60)} min.` : '';
    const sec = seconds % 60 > 0 ? `${seconds % 60} ${t('trips.sec')}.` : '';
    return [min, sec].filter((x) => x).join(', ');
  };
  const passengersMin = streams.map((x) => x.passengers || Infinity).reduce(R.min);
  const passengersMax = streams.map((x) => x.passengers || 0).reduce(R.max);
  const groupedSegments = Object.values(R.groupBy(
    (x) => `${x.from.name}-${x.to.name}`,
    streams.flatMap((stream, index) => {
      const weight = 6 + (passengersMax === passengersMin ? 1 : ((stream.passengers - passengersMin)
        / (passengersMax - passengersMin))) * 8;
      return stream.graph.map(({ first, last, route }) => {
        const from = stream.stops.find(R.propEq('id', first)) as IStop;
        const to = stream.stops.find(R.propEq('id', last)) as IStop;
        return ({
          streamIndex: index,
          weight,
          from,
          to,
          route,
        });
      });
    }),
  ));
  return (
    <div>
      <div className={classes.tables}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classNames(classes.cell, classes.headerCell)}>
                {t('trips.stream')}
              </th>
              <th className={classNames(classes.cell, classes.headerCell)}>
                {t('trips.passengerCountTable')}
              </th>
            </tr>
          </thead>
          <tbody>
            {streams.map((stream, i) => (
              <tr
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={classNames(classes.tableRow, {
                  [classes.selectedRow]: i === selectedStream,
                })}
                onClick={() => setSelectedStream(selectedStream === i ? null : i)}
              >
                <td className={classes.cell}>
                  {stream.stops.find(R.propEq('id', stream.graph[0].first))!.name}
                  {' › '}
                  <span
                    className={classes.route}
                    style={{ backgroundColor: getRouteColor(stream.graph[0].route) }}
                  >
                    {stream.graph[0].route}
                  </span>
                  {' › '}
                  {stream.graph.map((x, j) => {
                    if (j === 0 || j === stream.graph.length - 1
                      || x.route === stream.graph[j - 1].route
                    ) return null;
                    return (
                      <Fragment key={x.route}>
                        {stream.stops.find(R.propEq('id', x.last))!.name}
                        {' › '}
                        <span
                          className={classes.route}
                          style={{ backgroundColor: getRouteColor(x.route) }}
                        >
                          {x.route}
                        </span>
                        {' › '}
                      </Fragment>
                    );
                  })}
                  {stream.stops[stream.stops.length - 1].name}
                </td>
                <td className={classes.cell}>
                  {stream.passengers}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classNames(classes.cell, classes.headerCell)}>
                {t('trips.hub')}
              </th>
              <th className={classNames(classes.cell, classes.headerCell)}>
                {t('trips.passengerCountTable')}
              </th>
            </tr>
          </thead>
          <tbody>
            {hubs.map((hub, i) => (
              <tr
                key={hub.name}
                className={classNames(classes.tableRow, {
                  [classes.selectedRow]: i === selectedHub,
                })}
                onClick={() => {
                  if (selectedHub === i) {
                    setSelectedHub(null);
                    markerRefs.current[hub.name].leafletElement.closePopup();
                  } else {
                    setSelectedHub(i);
                    markerRefs.current[hub.name].leafletElement.openPopup();
                  }
                }}
              >
                <td className={classes.cell}>
                  {hub.name}
                </td>
                <td className={classes.cell}>
                  {hub.passengers}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Map
        className={classes.map}
        minZoom={3}
        maxZoom={18}
        ref={mapRef}
        zoomControl
        // @ts-ignore
        fullscreenControl={{ position: 'topright' }}
        whenReady={() => setMapReady(true)}
        onViewportChange={(viewport) => setZoom(viewport.zoom || 0)}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ScaleControl position="bottomleft" imperial={false} />
        {R.uniqBy(
          R.prop('name'),
          [
            ...streams.flatMap((stream) => stream.stops),
            ...hubs,
          ],
        ).map((stop) => {
          const hub = getHub(stop.name);
          const hubIndex = getHubIndex(stop.name);
          return (
            <Marker
              key={stop.name}
              position={[stop.lat, stop.lon]}
              icon={leaflet.icon({
                iconUrl: stopMarker,
                iconSize: hubIndex === selectedHub ? [18, 18] : [10, 10],
              })}
              ref={(ref) => {
                if (ref) markerRefs.current[stop.name] = ref;
              }}
            >
              <Tooltip className={classes.tooltip} opacity={1}>
                <Typography className={classes.tooltipText}>
                  {stop.name}
                </Typography>
              </Tooltip>
              {hub && (
                <Popup
                  className={classes.popup}
                  maxWidth={400}
                >
                  <Typography className={classes.popupHeader}>
                    {stop.name}
                  </Typography>
                  {hub.transferTime && (
                    <>
                      <div className={classes.popupRow}>
                        <Clock className={classes.clock} />
                        <Typography className={classes.popupSubheader}>
                          {t('trips.transferTime')}
                        </Typography>
                      </div>
                      <div className={classes.waitTime}>
                        <Typography className={classes.popupText}>
                          <span className={classes.shortText}>
                            {t('trips.timeMean')}
                          </span>
                          {renderTime(hub.transferTime.avg)}
                        </Typography>
                        <Typography className={classes.popupText}>
                          <span className={classes.shortText}>
                            {t('trips.timeMin')}
                          </span>
                          {renderTime(hub.transferTime.min)}
                        </Typography>
                        <Typography className={classes.popupText}>
                          <span className={classes.shortText}>
                            {t('trips.timeMax')}
                          </span>
                          {renderTime(hub.transferTime.max)}
                        </Typography>
                        <Typography className={classes.popupText}>
                          <span className={classes.shortText}>
                            {t('trips.timeMedian')}
                          </span>
                          {renderTime(hub.transferTime.med)}
                        </Typography>
                      </div>
                    </>
                  )}
                </Popup>
              )}
            </Marker>
          );
        })}
        {mapReady && groupedSegments.flatMap((segments, i) => {
          const totalWeight = R.sum(segments.map(R.prop('weight'))) + (segments.length - 1) * 2;
          let currentPosition = -totalWeight / 2;
          return (
            segments.map((segment, j) => {
              const positions = [
                { lat: segment.from.lat, lng: segment.from.lon },
                { lat: segment.to.lat, lng: segment.to.lon },
              ];
              const polyline = new leaflet.Polyline(positions)
                .addTo(mapRef.current!.leafletElement);
              const element = polyline.getElement();
              // @ts-ignore
              const polylineLength = element.getTotalLength ? element.getTotalLength() : 0;
              polyline.remove();
              const offset = currentPosition + segment.weight / 2;
              currentPosition += segment.weight + 2;
              return (
                <Polyline
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${i}-${j}`}
                  positions={positions}
                  color={getRouteColor(segment.route, segment.streamIndex === selectedStream)}
                  weight={segment.weight}
                  fillOpacity={1}
                  dashArray={`${polylineLength - 20}`}
                  dashOffset="-10"
                  lineCap="butt"
                  offset={offset}
                />
              );
            })
          );
        })}
      </Map>
    </div>
  );
}
