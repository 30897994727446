import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Link as MuiLink, Typography, useMediaQuery } from '@material-ui/core';
import i18next from 'i18next';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useGetLink } from '../utils';
import { ReactComponent as Eusbsr } from '../assets/eusbsr.svg';
import { ReactComponent as Interconnect } from '../assets/interconnect-logo.svg';
import { ReactComponent as Interreg } from '../assets/interreg.svg';

interface Props {
  darkBg?: boolean;
  narrow?: boolean;
}

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '24px 16px',
    marginTop: 'auto',
    width: '100%',
  },
  switch: {
    width: 250,
    textAlign: 'left',
  },
  switchText: {
    fontSize: 14,
    color: '#37001F',
  },
  copyright: {
    whiteSpace: 'nowrap',
    fontSize: 14,
    color: '#37001F',
    width: 250,
    '@media (max-width: 800px)': {
      width: 'auto',
      margin: '0 auto',
    },
  },
  darkBg: {
    '& $switchText, & $copyright, & $link': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
  },
  links: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 -4px',
  },
  link: {
    margin: '0 8px',
    fontSize: 14,
    color: '#37001F',
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  aboutLink: {
    '&:hover + $about, &:focus + $about': {
      display: 'flex',
    },
  },
  about: {
    display: 'none',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '16px 32px',
    backgroundColor: 'white',
    zIndex: 1100,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      display: 'flex',
    },
  },
  logo: {
    height: 54,
    margin: '0 16px',
    flexShrink: 0,
  },
  disclaimer: {
    fontSize: 14,
    color: '#37001F',
  },
  narrow: {
    '& $switch': {
      width: 'auto',
    },
    '& $copyright': {
      width: 'auto',
      margin: '0 auto',
    },
    '& $links': {
      width: '100%',
      margin: '16px -4px',
    },
    '& $link': {
      margin: '0 4px',
    },
  },
});

export default function Footer({ darkBg, narrow }: Props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const getLink = useGetLink();
  const isMobile = useMediaQuery('(max-width: 800px)');
  return (
    <div
      className={classNames(classes.footer, {
        [classes.darkBg]: darkBg,
        [classes.narrow]: narrow || isMobile,
      })}
    >
      <MuiLink
        className={classes.switch}
        component="button"
        onClick={() => {
          i18next.changeLanguage(i18n.language === 'pl' ? 'en' : 'pl');
        }}
      >
        <Typography className={classes.switchText}>
          {t('footer.switchLanguage')}
        </Typography>
      </MuiLink>
      <div className={classes.links}>
        <Typography
          component={Link}
          to={getLink('/terms-and-conditions')}
          className={classes.link}
        >
          {t('passenger.termsAndConditions')}
        </Typography>
        <Typography
          component={Link}
          to={getLink('/privacy-policy')}
          className={classes.link}
        >
          {t('passenger.privacyPolicy')}
        </Typography>
        {isMobile ? (
          <Typography
            component={Link}
            to={getLink('/about-project')}
            className={classes.link}
          >
            {t('passenger.aboutProject')}
          </Typography>
        ) : (
          <Typography
            className={classNames(classes.link, classes.aboutLink)}
            component="div"
            tabIndex={0}
          >
            {t('passenger.aboutProject')}
          </Typography>
        )}
        <div className={classes.about}>
          <Eusbsr className={classes.logo} />
          <Interconnect className={classes.logo} />
          <Interreg className={classes.logo} />
          <Typography className={classes.disclaimer}>
            {t('passenger.disclaimer')}
          </Typography>
        </div>
      </div>
      <Typography className={classes.copyright}>
        Copyright © 2020 Innobaltica Sp. z o.o.
      </Typography>
    </div>
  );
}
