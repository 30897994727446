import React, { useContext, useState } from 'react';
import {
  makeStyles, Tab, Tabs, TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import * as R from 'ramda';
import { TimePicker } from '@material-ui/pickers';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import VehicleOccupancy from './VehicleOccupancy';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import { ProviderContext } from '../../context';
import { IProvider } from '../../interfaces';
import PeriodSelect, { IDateRange } from '../../common/PeriodSelect';
import PassengerStreams from './PassengerStreams';
import { useGetLink } from '../../utils';
import Tickets from './Tickets';

export interface IProps {
  provider: string;
  params: {
    dateStart: string;
    dateEnd: string;
    timeStart: string;
    timeEnd: string;
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tabs: {
    margin: '0 -32px',
    backgroundColor: '#F5F5F5',
    paddingTop: 16,
  },
  tab: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 300,
    width: 250,
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '32px 0',
  },
  dataSource: {
    width: 300,
    marginRight: 16,
  },
  timePicker: {
    width: 120,
    marginLeft: 16,
  },
});

export default function Trips() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const getLink = useGetLink();
  const { tab } = useParams<{ tab: string; }>();
  const { providers } = useContext(ProviderContext);
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [dateRange, setDateRange] = useState<IDateRange>(null);
  const [hourFrom, setHourFrom] = useState<Moment | null>(null);
  const [hourTo, setHourTo] = useState<Moment | null>(null);
  if (!tab) {
    return (
      <Redirect from="/:language/provider/trips/" exact to={getLink('/provider/trips/occupancy')} />
    );
  }
  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={(event, value) => history.push(getLink(`/provider/trips/${value}`))}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.tabs}
      >
        <Tab
          className={classes.tab}
          label={t('trips.vehicleOccupancy')}
          value="occupancy"
        />
        <Tab
          className={classes.tab}
          label={t('trips.passengerStreams')}
          value="streams"
        />
        <Tab
          className={classes.tab}
          label={t('trips.tickets')}
          value="tickets"
        />
      </Tabs>
      <div className={classes.row}>
        <CustomAutocomplete
          value={provider}
          onChange={(event, value) => setProvider(value)}
          options={providers.filter(R.propEq('id', 'gryf'))}
          getOptionLabel={R.prop('name')}
          label={t('common.dataSource')}
          className={classes.dataSource}
        />
        <PeriodSelect
          value={dateRange}
          onChange={setDateRange}
        />
        <TimePicker
          inputVariant="outlined"
          TextFieldComponent={(props) => (
            <TextField
              {...props}
              size="small"
              className={classes.timePicker}
              id="from-hour"
            />
          )}
          cancelLabel={t('common.cancel')}
          ampm={false}
          value={hourFrom}
          onChange={setHourFrom}
          label={t('trips.fromHour')}
        />
        <TimePicker
          inputVariant="outlined"
          TextFieldComponent={(props) => (
            <TextField
              {...props}
              size="small"
              className={classes.timePicker}
              id="to-hour"
            />
          )}
          cancelLabel={t('common.cancel')}
          ampm={false}
          value={hourTo}
          onChange={setHourTo}
          label={t('trips.toHour')}
        />
      </div>
      {(() => {
        if (!provider || !dateRange || !dateRange.from || !dateRange.to || !hourFrom || !hourTo) {
          return null;
        }
        const params = {
          dateStart: dateRange.from.format('YYYY-MM-DD'),
          dateEnd: dateRange.to.format('YYYY-MM-DD'),
          timeStart: hourFrom.format('HH:mm'),
          timeEnd: hourTo.format('HH:mm'),
        };
        switch (tab) {
          case 'occupancy':
            return <VehicleOccupancy provider={provider.id} params={params} />;
          case 'streams':
            return <PassengerStreams provider={provider.id} params={params} />;
          case 'tickets':
            return <Tickets provider={provider.id} params={params} />;
          default:
            return null;
        }
      })()}
    </div>
  );
}
