import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, TextField, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import classNames from 'classnames';
import moment from 'moment';
import { getDistance } from 'geolib';
import { ProviderContext } from '../../context';
import VersionInfo from '../VersionInfo';
import {
  IDataCalendar,
  IDataPattern, IDataRoute, IDataShape, IDataStop, IDataTrip,
} from '../../interfaces';
import { axios } from '../../utils';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import { ReactComponent as CopyArrow } from '../../assets/copy-arrow.svg';
import Shapes from './Shapes';
import NewHeader from '../NewHeader';
import ButtonList from '../ButtonList';
import DeleteDialog from '../DeleteDialog';
import NumberField from '../../common/NumberField';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    display: 'flex',
  },
  left: {
    flexGrow: 1,
  },
  right: {
    width: 680,
    flexShrink: 0,
    marginLeft: 16,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  forRoute: {
    width: 230,
    marginRight: 16,
  },
  forPattern: {
    width: 300,
  },
  type: {
    width: 230,
  },
  copyRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px -16px',
    padding: 16,
    backgroundColor: '#F5F5F5',
  },
  copyTrip: {
    width: 300,
    margin: '0 16px',
  },
  scheduleHeader: {
    fontSize: 18,
    fontWeight: 400,
    color: '#37001F',
    marginBottom: 16,
  },
  tableHeader: {
    fontWeight: 400,
    color: '#37001F',
    textAlign: 'left',
  },
  tableNo: {
    width: 60,
  },
  tableStop: {
    width: 300,
  },
  tableArrival: {
    width: 100,
  },
  tableDeparture: {
    width: 100,
  },
  tableShape: {
    width: 130,
  },
  cell: {
    color: '#37001F',
  },
  timeField: {
    width: 80,
  },
  tableRow: {
    height: 48,
  },
  addShape: {
    transform: 'translateY(24px)',
  },
  copyTimesButton: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 220,
  },
  copyArrow: {
    marginLeft: 8,
  },
  totalDistance: {
    marginBottom: 32,
    fontSize: 20,
    color: '#37001F',
  },
  totalDistanceLabel: {
    fontWeight: 400,
  },
  calendar: {
    width: 300,
    marginBottom: 16,
  },
  discardChanges: {
    marginLeft: 'auto',
    marginRight: 16,
  },
  tripName: {
    marginLeft: 16,
    fontSize: 24,
    fontWeight: 700,
    color: '#37001F',
  },
  blockField: {
    width: 160,
    '&:not(:last-child)': {
      marginRight: 16,
    },
  },
  hoursFormat: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    marginBottom: 8,
  },
});

export default function Trips() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: { agency }, version } = useContext(ProviderContext);
  const [trips, setTrips] = useState<IDataTrip[]>([]);
  const [routes, setRoutes] = useState<IDataRoute[]>([]);
  const [patterns, setPatterns] = useState<IDataPattern[]>([]);
  const [stops, setStops] = useState<IDataStop[]>([]);
  const [calendars, setCalendars] = useState<IDataCalendar[]>([]);
  const [shapes, setShapes] = useState<IDataShape[]>([]);
  const [searchRoute, setSearchRoute] = useState<IDataRoute | null>(null);
  const [searchPattern, setSearchPattern] = useState<IDataPattern | null>(null);
  const [selectedTrip, setSelectedTrip] = useState<number | 'new' | null>(null);
  const [route, setRoute] = useState<IDataRoute | null>(null);
  const [pattern, setPattern] = useState<IDataPattern | null>(null);
  const [type, setType] = useState<'SINGLE' | 'BLOCK' | null>(null);
  const [copyTrip, setCopyTrip] = useState<IDataTrip | null>(null);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [firstTrip, setFirstTrip] = useState('');
  const [tripInterval, setTripInterval] = useState('');
  const [tripStops, setTripStops] = useState<{
    arrival: string;
    departure: string;
    shape: number | null;
  }[]>([]);
  const [editedShape, setEditedShape] = useState<number | null>(null);
  const [calendar, setCalendar] = useState<IDataCalendar | null>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    (async () => {
      const [
        { data },
        { data: routesData },
        { data: patternsData },
        { data: stopsData },
        { data: calendarsData },
        { data: shapesData },
      ]: [
        { data: IDataTrip[] },
        { data: IDataRoute[] },
        { data: IDataPattern[] },
        { data: IDataStop[] },
        { data: IDataCalendar[] },
        { data: IDataShape[] },
      ] = await Promise.all([
        axios.get(`/${agency}/data/trips`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/routes`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/patterns`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/stops`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/calendars`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/shapes`, { params: { providerVersion: version!.id } }),
      ]);
      setTrips(data);
      setPatterns(patternsData);
      setRoutes(routesData);
      setStops(stopsData);
      setCalendars(calendarsData);
      setShapes(shapesData);
    })();
  }, [agency, version]);
  const getTripName = (trip: IDataTrip) => {
    const calendarName = calendars.find(R.propEq('id', trip.calendar))!.name;
    return `${trip.type === 'SINGLE' ? trip.departure : `${trip.from}-${trip.to}`}, ${calendarName}`;
  };
  const loadSchedule = (trip: IDataTrip) => {
    const firstDeparture = trip.type === 'SINGLE' ? trip.departure : '00:00';
    const formatTime = (minutes: number | null) => (
      minutes === null ? '' : moment(firstDeparture, 'H:m').add(minutes, 'minutes').format('HH:mm')
    );
    const tripPattern = patterns.find(R.propEq('id', trip.pattern)) as IDataPattern;
    setTripStops(tripPattern.stops.map((x, i) => {
      const stop = trip.stops[i];
      return ({
        arrival: stop ? formatTime(stop.arrival) : '',
        departure: stop ? formatTime(stop.departure) : '',
        shape: stop?.shape || null,
      });
    }));
    if (trip.type === 'BLOCK') {
      setFrom(trip.from);
      setTo(trip.to);
      setFirstTrip(trip.firstTrip);
      setTripInterval(String(trip.interval));
    }
  };
  const loadData = (trip: IDataTrip | null) => {
    setCopyTrip(null);
    if (trip) {
      const tripPattern = patterns.find(R.propEq('id', trip.pattern)) as IDataPattern;
      setRoute(routes.find(R.propEq('id', tripPattern.route)) as IDataRoute);
      setPattern(tripPattern);
      setType(trip.type);
      loadSchedule(trip);
      setCalendar(calendars.find(R.propEq('id', trip.calendar)) || null);
    } else {
      setRoute(null);
      setPattern(null);
      setType(null);
      setTripStops([]);
      setCalendar(null);
      setFrom('');
      setTo('');
      setFirstTrip('');
      setTripInterval('');
    }
  };
  const readOnly = !version?.hasUserLock;
  if (editedShape !== null && pattern) {
    const start = stops.find(R.propEq('id', pattern.stops[editedShape])) as IDataStop;
    const end = stops.find(R.propEq('id', pattern.stops[editedShape + 1])) as IDataStop;
    return (
      <Shapes
        shapes={shapes.filter((x) => x.from === start.id && x.to === end.id)}
        setShapes={setShapes}
        selectedShape={tripStops[editedShape].shape}
        chooseShape={(shape) => setTripStops(R.adjust(editedShape, R.mergeLeft({ shape })))}
        start={start}
        end={end}
        goBack={() => setEditedShape(null)}
        trips={trips}
        selectedTrip={selectedTrip}
      />
    );
  }
  const distance = R.init(tripStops).some((x) => x.shape === null) ? null : R.sum(
    R.init(tripStops).map(({ shape }) => {
      const { points } = shapes.find(R.propEq('id', shape)) as IDataShape;
      return R.sum(points.map((x, i) => (
        i === points.length - 1 ? 0 : getDistance(x, points[i + 1])
      )));
    }),
  );
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <VersionInfo />
        <NewHeader
          headerText={t('data.trips')}
          newText={t('data.newTrip')}
          onClick={() => {
            setSelectedTrip('new');
            loadData(null);
          }}
          disabled={readOnly}
        />
        <div className={classes.row}>
          <CustomAutocomplete
            options={routes}
            value={searchRoute}
            onChange={(event, value) => {
              setSearchRoute(value);
              setSearchPattern(null);
            }}
            label={t('data.forRoute')}
            className={classes.forRoute}
            getOptionLabel={R.prop('name')}
          />
          <CustomAutocomplete
            options={patterns.filter(R.propEq('route', searchRoute?.id))}
            value={searchPattern}
            onChange={(event, value) => setSearchPattern(value)}
            label={t('data.forPattern')}
            className={classes.forPattern}
            getOptionLabel={R.prop('name')}
          />
        </div>
        <ButtonList
          elements={searchRoute ? trips.filter((x) => (
            searchPattern ? (x.pattern === searchPattern.id) : (
              patterns.filter(R.propEq('route', searchRoute.id)).map(R.prop('id')).includes(x.pattern)
            )
          )).map((trip) => ({
            id: trip.id,
            name: getTripName(trip),
            onClick: () => {
              setSelectedTrip(trip.id);
              loadData(trip);
            },
          })) : []}
          selected={selectedTrip}
        />
      </div>
      <div className={classes.right}>
        {selectedTrip !== null && (
          <>
            <div className={classes.row}>
              <CustomAutocomplete
                options={routes}
                value={route}
                onChange={(event, value) => {
                  setRoute(value);
                  setPattern(null);
                }}
                label={t('data.forRoute')}
                className={classes.forRoute}
                getOptionLabel={R.prop('name')}
              />
              <CustomAutocomplete
                options={patterns.filter(R.propEq('route', route?.id))}
                value={pattern}
                onChange={(event, value) => {
                  setPattern(value);
                  if (value) {
                    setTripStops(value.stops.map(() => ({
                      arrival: '',
                      departure: '',
                      shape: null,
                    })));
                  }
                }}
                label={t('data.forPattern')}
                className={classes.forPattern}
                getOptionLabel={R.prop('name')}
              />
            </div>
            <div className={classes.row}>
              <CustomAutocomplete
                options={['SINGLE', 'BLOCK']}
                value={type}
                onChange={(event, value) => {
                  setType(value as 'SINGLE' | 'BLOCK' | null);
                  if (pattern) {
                    setTripStops(pattern.stops.map(() => ({
                      arrival: '',
                      departure: '',
                      shape: null,
                    })));
                  }
                }}
                label={t('data.type')}
                className={classes.type}
                getOptionLabel={(option) => (option === 'SINGLE' ? t('data.singleTrip') : t('data.tripBlock'))}
                noAdornment
              />
              <Typography className={classes.tripName}>
                {calendar && (type === 'SINGLE' ? tripStops[0].departure : (from && to)) && (
                  `${type === 'SINGLE' ? tripStops[0].departure : `${from}-${to}`}, ${calendar.name}`
                )}
              </Typography>
            </div>
            {pattern && type && (
              <>
                <div className={classes.copyRow}>
                  <Typography>
                    {t('data.copyScheduleFrom')}
                  </Typography>
                  <CustomAutocomplete
                    options={trips.filter(R.propEq('pattern', pattern.id))}
                    value={copyTrip}
                    onChange={(event, value) => setCopyTrip(value)}
                    label={t('data.trip')}
                    className={classes.copyTrip}
                    getOptionLabel={getTripName}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!copyTrip}
                    onClick={() => {
                      if (!copyTrip) return;
                      loadSchedule(copyTrip);
                      setCopyTrip(null);
                    }}
                  >
                    {t('data.copy')}
                  </Button>
                </div>
                {type === 'BLOCK' && (
                  <div className={classes.row}>
                    <TextField
                      value={from}
                      onChange={({ target }) => setFrom(target.value)}
                      label={t('data.fromHour')}
                      className={classes.blockField}
                      variant="outlined"
                      size="small"
                      placeholder="hh:mm"
                    />
                    <TextField
                      value={to}
                      onChange={({ target }) => setTo(target.value)}
                      label={t('data.toHour')}
                      className={classes.blockField}
                      variant="outlined"
                      size="small"
                      placeholder="hh:mm"
                    />
                    <TextField
                      value={firstTrip}
                      onChange={({ target }) => setFirstTrip(target.value)}
                      label={t('data.firstTrip')}
                      className={classes.blockField}
                      variant="outlined"
                      size="small"
                      placeholder="hh:mm"
                    />
                    <NumberField
                      value={tripInterval}
                      onChange={({ target }) => setTripInterval(target.value)}
                      label={t('data.interval')}
                      className={classes.blockField}
                      variant="outlined"
                      size="small"
                    />
                  </div>
                )}
                <Typography className={classes.scheduleHeader}>
                  {type === 'SINGLE' ? t('data.schedule') : t('data.masterSchedule')}
                </Typography>
                <table>
                  <thead>
                    <tr className={classes.tableRow}>
                      <th className={classNames(classes.tableHeader, classes.tableNo)}>
                        {t('data.no')}
                      </th>
                      <th className={classNames(classes.tableHeader, classes.tableStop)}>
                        {t('data.stop')}
                      </th>
                      <th className={classNames(classes.tableHeader, classes.tableArrival)}>
                        {t('data.arrival')}
                      </th>
                      <th className={classNames(classes.tableHeader, classes.tableDeparture)}>
                        {t('data.departure')}
                      </th>
                      <th className={classNames(classes.tableHeader, classes.tableShape)}>
                        {t('data.shape')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pattern.stops.map((stopId, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={i} className={classes.tableRow}>
                        <Typography component="td" className={classes.cell}>
                          {i + 1}
                        </Typography>
                        <Typography component="td" className={classes.cell}>
                          {stops.find(R.propEq('id', stopId))!.name}
                        </Typography>
                        <td>
                          {i !== 0 ? (
                            <TextField
                              value={tripStops[i].arrival}
                              onChange={({ target }) => setTripStops(
                                R.adjust(i, R.mergeLeft({ arrival: target.value })),
                              )}
                              className={classes.timeField}
                              variant="outlined"
                              size="small"
                              placeholder="hh:mm"
                            />
                          ) : (
                            <Typography>
                              -
                            </Typography>
                          )}
                        </td>
                        <td>
                          {(() => {
                            if (type === 'BLOCK') {
                              if (i === 0) {
                                return (
                                  <Typography>
                                    00:00
                                  </Typography>
                                );
                              }
                            }
                            if (i === pattern.stops.length - 1) {
                              return (
                                <Typography>
                                  -
                                </Typography>
                              );
                            }
                            return (
                              <TextField
                                value={tripStops[i].departure}
                                onChange={({ target }) => setTripStops(
                                  R.adjust(i, R.mergeLeft({ departure: target.value })),
                                )}
                                className={classes.timeField}
                                variant="outlined"
                                size="small"
                                placeholder="hh:mm"
                              />
                            );
                          })()}
                        </td>
                        <td>
                          {i !== pattern.stops.length - 1 && (
                            <Button
                              color="primary"
                              className={classes.addShape}
                              onClick={() => setEditedShape(i)}
                            >
                              {tripStops[i].shape
                                ? shapes.find(R.propEq('id', tripStops[i].shape))!.name
                                : t('data.addShape')}
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  className={classes.copyTimesButton}
                  onClick={() => {
                    setTripStops(R.map((x) => ({
                      ...x,
                      departure: x.arrival || x.departure,
                    })));
                  }}
                >
                  {t('data.copy')}
                  <CopyArrow className={classes.copyArrow} />
                </Button>
                <Typography className={classes.totalDistance}>
                  <span className={classes.totalDistanceLabel}>
                    {t('data.totalDistance')}
                    {': '}
                  </span>
                  {distance ? `${distance} m` : '-'}
                </Typography>
                <CustomAutocomplete
                  options={calendars}
                  value={calendar}
                  onChange={(event, value) => setCalendar(value)}
                  className={classes.calendar}
                  getOptionLabel={R.prop('name')}
                  label={t('data.scheduleEffective')}
                />
                <div className={classes.row}>
                  {selectedTrip !== 'new' && (
                    <Button
                      color="primary"
                      onClick={() => setDeleteDialog(true)}
                      disabled={readOnly}
                    >
                      {t('data.deleteTrip')}
                    </Button>
                  )}
                  <Button
                    color="primary"
                    className={classes.discardChanges}
                    onClick={() => {
                      loadData(
                        selectedTrip === 'new'
                          ? null
                          : trips.find((x) => x.id === selectedTrip) || null,
                      );
                    }}
                  >
                    {t('data.discardChanges')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={(() => {
                      if (readOnly || saving) return true;
                      if (type === 'BLOCK' && (!from || !to || !firstTrip || !tripInterval)) return true;
                      return !(calendar !== null && tripStops.every((x, i) => {
                        if (i === 0) {
                          return type === 'BLOCK' || x.departure !== '';
                        }
                        if (i === tripStops.length - 1) {
                          return x.arrival !== '';
                        }
                        return x.arrival !== '' && x.departure !== '';
                      }));
                    })()}
                    onClick={async () => {
                      if (!calendar) return;
                      const pad = (x: string) => x.padStart(5, '0');
                      const firstDeparture = type === 'SINGLE' ? tripStops[0].departure : '00:00';
                      const getDiff = (time: string) => {
                        const diff = moment(time, 'H:m')
                          .diff(moment(firstDeparture, 'H:m'), 'minutes');
                        return diff < 0 ? diff + (24 * 60) : diff;
                      };
                      const tripData = {
                        pattern: pattern.id,
                        type,
                        stops: tripStops.map((x, i) => ({
                          arrival: getDiff(x.arrival),
                          departure: i === tripStops.length - 1 ? null : getDiff(x.departure),
                          shape: x.shape,
                        })),
                        calendar: calendar.id,
                        ...(type === 'SINGLE' ? {
                          departure: pad(firstDeparture),
                        } : {
                          from: pad(from),
                          to: pad(to),
                          firstTrip: pad(firstTrip),
                          interval: Number(tripInterval),
                        }),
                      };
                      try {
                        setSaving(true);
                        if (selectedTrip === 'new') {
                          const { data } = await axios.post(`/${agency}/data/trip`, tripData);
                          setTrips(R.append(data));
                        } else {
                          await axios.put(`/${agency}/data/trip/${selectedTrip}`, tripData);
                          setTrips(R.map((x) => (x.id === selectedTrip ? {
                            ...x,
                            ...tripData,
                          } as IDataTrip : x)));
                        }
                        setSelectedTrip(null);
                        setSearchRoute(routes.find(R.propEq('id', pattern.route)) as IDataRoute);
                        setSearchPattern(pattern);
                      } finally {
                        setSaving(false);
                      }
                    }}
                  >
                    {t('data.save')}
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={typeof selectedTrip === 'number' ? t(
          'data.deleteTripConfirm',
          { name: getTripName(trips.find(R.propEq('id', selectedTrip)) as IDataTrip) },
        ) : ''}
        onDelete={async () => {
          await axios.delete(`/${agency}/data/trip/${selectedTrip}`);
          setSelectedTrip(null);
          setTrips(R.filter<IDataTrip>((x) => x.id !== selectedTrip));
          setDeleteDialog(false);
        }}
        canDelete
      />
    </div>
  );
}
