import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Button, Dialog, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField, Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import * as R from 'ramda';
import { ICardTemplate, IDiscountType } from '../interfaces';
import CustomAutocomplete from '../common/CustomAutocomplete';
import { ReactComponent as Search } from '../assets/search.svg';
import { axios } from '../utils';
import AppContext from '../context';

interface IUserInfo {
  id: string;
  number: string;
  name: string;
  email: string;
  cards: {
    number: number;
    packages: string[];
  }[];
}

interface IAttraction {
  site: string;
  attraction: string;
  discount: {
    entries: number;
    type: IDiscountType;
    savings: number;
    entryFee: number;
  };
}

interface IPackage {
  id: number;
  name: string;
  price: number;
  value: number;
  discounts: IAttraction[];
  image: number | null;
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: 32,
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    color: '#37001F',
    marginBottom: 8,
  },
  searchContainer: {
    margin: '0 -24px',
    padding: '16px 24px 4px',
    width: 'calc(100% + 48px)',
    backgroundColor: '#F5F5F5',
  },
  searchRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  search: {
    width: 440,
    backgroundColor: 'white',
  },
  searchButton: {
    marginLeft: 24,
  },
  name: {
    fontWeight: 700,
  },
  userInfo: {
    color: '#37001F',
    fontSize: 20,
    marginBottom: 12,
    height: 30,
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: 57,
  },
  card: {
    padding: '4px 12px',
    color: '#37001F',
    marginRight: 12,
    marginBottom: 12,
    border: '1px solid #9E9E9E',
    borderRadius: 15,
    fontSize: 20,
    '&:hover': {
      borderColor: '#9E9E9E',
    },
  },
  cardSelected: {
    color: 'white',
  },
  radioGroup: {
    margin: '16px 0',
  },
  formControlLabel: {
    marginRight: 60,
  },
  images: {
    display: 'flex',
    marginBottom: 32,
  },
  image: {
    borderRadius: 5,
    marginRight: 24,
    padding: 0,
  },
  selectedImage: {
    '&::before': {
      content: '""',
      width: '100%',
      height: '100%',
      border: '5px solid #A1145C',
      position: 'absolute',
      borderRadius: 5,
    },
  },
  field: {
    width: 440,
    marginBottom: 24,
    display: 'flex',
  },
  dialog: {
    padding: '24px 32px',
  },
  dialogText: {
    color: '#37001F',
    marginBottom: 40,
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  generateButton: {
    width: 250,
  },
  bottom: {
    display: 'flex',
  },
  packageInfo: {
    flexGrow: 1,
    padding: '16px 24px',
    backgroundColor: '#F5F5F5',
    marginLeft: 40,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    '& > :last-child': {
      textAlign: 'right',
    },
  },
  text: {
    color: '#37001F',
    fontSize: 20,
  },
  boldText: {
    color: '#37001F',
    fontSize: 20,
    fontWeight: 700,
  },
  packageName: {
    color: '#37001F',
    fontSize: 24,
    fontWeight: 700,
  },
  template: {
    width: 82,
    height: 52,
  },
});

export default function Cards() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppContext);
  const [search, setSearch] = useState('');
  const [user, setUser] = useState<IUserInfo | null>(null);
  const [card, setCard] = useState<number | null>(null);
  const [isNew, setIsNew] = useState(true);
  const [image, setImage] = useState<number | null>(null);
  const [cardNumber, setCardNumber] = useState('');
  const [newPackage, setNewPackage] = useState<IPackage | null>(null);
  const [name, setName] = useState('');
  const [interconnectNumber, setInterconnectNumber] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [packages, setPackages] = useState<IPackage[] | null>(null);
  const [templates, setTemplates] = useState<ICardTemplate[] | null>(null);
  const [noResults, setNoResults] = useState(false);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    (async () => {
      const [{ data }, { data: templatesData }] = await Promise.all([
        axios.get('/card/packages/on-sale'),
        axios.get('/card/templates'),
      ]);
      setPackages(data);
      setTemplates(templatesData);
    })();
  }, []);
  if (!packages || !templates) return null;
  const resetState = () => {
    setSearch('');
    setUser(null);
    setIsNew(true);
    setImage(null);
    setCardNumber('');
    setNewPackage(null);
    setName('');
    setInterconnectNumber('');
  };
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {t('card.card')}
      </Typography>
      <div className={classes.searchContainer}>
        <form
          className={classes.searchRow}
          onSubmit={async (event) => {
            event.preventDefault();
            const { data } = await axios.get('/card/tourists', {
              params: { query: search },
            });
            if (data[0]) {
              setNoResults(false);
              const newUser = data[0];
              setUser(newUser);
              setName(newUser.name);
              setInterconnectNumber(newUser.number);
            } else {
              setNoResults(true);
              setUser(null);
            }
          }}
        >
          <TextField
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            label={t('card.interconnectNumberOrEmail')}
            variant="outlined"
            size="small"
            className={classes.search}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.searchButton}
            disabled={!search}
            type="submit"
          >
            {t('card.search')}
          </Button>
        </form>
        <Typography className={classes.userInfo}>
          {noResults && t('card.noResults')}
          {user && (
            <>
              <span className={classes.name}>
                {user.name}
              </span>
              {` #${user.number} ${user.email}`}
            </>
          )}
        </Typography>
        <div className={classes.cards}>
          {user && user.cards.map((x) => (
            <Button
              key={x.number}
              className={classNames(classes.card, {
                [classes.cardSelected]: x.number === card,
              })}
              variant={x.number === card ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => {
                if (x.number === card) {
                  setCard(null);
                  setIsNew(true);
                  setCardNumber('');
                } else {
                  setCard(x.number);
                  setIsNew(false);
                  setCardNumber(String(x.number));
                }
              }}
            >
              {`${x.number}: ${x.packages.join(', ')}`}
            </Button>
          ))}
        </div>
      </div>
      <RadioGroup
        value={isNew ? 'new' : 'existing'}
        onChange={({ target }) => {
          setIsNew(target.value === 'new');
          if (target.value === 'new') {
            setCardNumber('');
          }
        }}
        className={classes.radioGroup}
        row
      >
        <FormControlLabel
          value="new"
          control={<Radio color="primary" />}
          label={t('card.new')}
          className={classes.formControlLabel}
        />
        <FormControlLabel
          value="existing"
          control={<Radio color="primary" />}
          label={t('card.existing')}
          className={classes.formControlLabel}
        />
      </RadioGroup>
      <div className={classes.images}>
        {templates.map((x) => (
          <Button
            key={x.id}
            onClick={() => setImage(x.id)}
            className={classNames(classes.image, {
              [classes.selectedImage]: x.id === image,
            })}
          >
            <img
              src={x.file}
              alt=""
              className={classes.template}
            />
          </Button>
        ))}
      </div>
      <div className={classes.bottom}>
        <div>
          <TextField
            value={cardNumber}
            onChange={({ target }) => setCardNumber(target.value)}
            label={t('card.cardNumber')}
            variant="outlined"
            size="small"
            className={classes.field}
            disabled={isNew}
          />
          <CustomAutocomplete
            options={packages}
            value={newPackage}
            onChange={(event, value) => {
              setNewPackage(value);
              if (value && value.image) {
                setImage(value.image);
              }
            }}
            label={t('card.addPackage')}
            getOptionLabel={R.prop('name')}
            className={classes.field}
          />
          <TextField
            value={name}
            onChange={({ target }) => setName(target.value)}
            label={t('card.firstAndLastName')}
            variant="outlined"
            size="small"
            className={classes.field}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            value={interconnectNumber}
            onChange={({ target }) => setInterconnectNumber(target.value)}
            label={t('card.interconnectNumber')}
            variant="outlined"
            size="small"
            className={classes.field}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.generateButton}
            disabled={!newPackage || !name || (!isNew && !cardNumber) || saving}
            onClick={async () => {
              if (!newPackage) return;
              if (isNew) {
                try {
                  setSaving(true);
                  await axios.post('/card', {
                    package: newPackage.id,
                    ownerName: name,
                    ownerId: interconnectNumber || null,
                    image,
                  });
                  showSnackbar(t('card.cardGenerated'));
                  resetState();
                } finally {
                  setSaving(false);
                }
              } else {
                setDialogOpen(true);
              }
            }}
          >
            {isNew ? t('card.generateNewCard') : t('card.addPackageToCard')}
          </Button>
        </div>
        {newPackage && (
          <div className={classes.packageInfo}>
            <div className={classes.row}>
              <Typography className={classes.packageName}>
                {newPackage.name}
              </Typography>
              <div>
                <Typography className={classes.boldText}>
                  {`${t('card.price')}: `}
                  {newPackage.price
                    ? `${newPackage.price.toFixed(2)} PLN`
                    : t('card.free')}
                </Typography>
                <Typography className={classes.text}>
                  {`${t('card.packageWorth')}: ${newPackage.value.toFixed(2)} PLN`}
                </Typography>
              </div>
            </div>
            {newPackage.discounts.map((attraction) => {
              const { savings, entryFee } = attraction.discount;
              return (
                <div className={classes.row} key={attraction.site + attraction.attraction}>
                  <div>
                    <Typography className={classes.boldText}>
                      {attraction.site}
                    </Typography>
                    <Typography className={classes.text}>
                      {attraction.attraction}
                    </Typography>
                  </div>
                  <div>
                    <Typography className={classes.boldText}>
                      {`${attraction.discount.entries}x `}
                      {entryFee === 0
                        ? t('card.freeEntrance')
                        : t('card.entry', { price: `${entryFee.toFixed(2)} PLN` })}
                    </Typography>
                    <Typography className={classes.text}>
                      {`${t('card.youSave')} ${savings.toFixed(2)} PLN`}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        PaperProps={{ className: classes.dialog }}
      >
        <Typography className={classes.dialogText}>
          {t('card.confirmAdd')}
        </Typography>
        <div className={classes.dialogButtons}>
          <Button
            color="primary"
            onClick={() => setDialogOpen(false)}
          >
            {t('common.cancel')}
          </Button>
          <Button
            color="primary"
            disabled={saving}
            onClick={async () => {
              if (!newPackage) return;
              try {
                setSaving(true);
                await axios.post(`/card/${cardNumber}/package`, {
                  package: newPackage.id,
                  ownerName: name,
                  ownerId: interconnectNumber || null,
                  image,
                });
                showSnackbar(t('card.packageAdded'));
                resetState();
                setDialogOpen(false);
              } finally {
                setSaving(false);
              }
            }}
          >
            {t('data.add')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
