import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Nav from './Nav';
import Footer from './Footer';
import headerArrow from '../assets/header-arrow.svg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  header: {
    fontSize: 22,
    fontWeight: 700,
    color: '#38001F',
    margin: '64px 100px 32px',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      width: 22,
      height: 32,
      backgroundImage: `url('${headerArrow}')`,
      marginRight: 13,
    },
    '@media (max-width: 800px)': {
      margin: '64px 16px 32px',
    },
  },
  content: {
    flexGrow: 1,
    padding: '16px 100px',
    background: '#F5F5F5',
    '& li': {
      margin: '4px 0',
    },
    '& ol': {
      marginBottom: 24,
    },
    '@media (max-width: 800px)': {
      padding: 16,
    },
  },
  subheader: {
    fontSize: 18,
    fontWeight: 700,
    color: '#38001F',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      width: 22,
      height: 32,
      backgroundImage: `url('${headerArrow}')`,
      marginRight: 13,
    },
  },
  footerContainer: {
    marginTop: 'auto',
    background: '#F5F5F5',
  },
});

export default function PrivacyPolicy() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Nav />
      <Typography className={classes.header}>
        {t('passenger.privacyPolicy')}
      </Typography>
      <div className={classes.content}>
        <Typography className={classes.subheader}>
          {t('privacy.header1')}
        </Typography>
        <ol>
          <li>
            <Trans
              i18nKey="privacy.li1"
              components={[
                <Link href="https://opendata.info.pl/" />,
                <i />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="privacy.li2"
              components={[
                <i />,
                <i />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="privacy.li3"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="privacy.li4"
              components={[
                <i />,
              ]}
            />
            <ul>
              <li>
                <Trans
                  i18nKey="privacy.li4li1"
                  components={[
                    <i />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="privacy.li4li2"
                  components={[
                    <i />,
                  ]}
                />
              </li>
            </ul>
          </li>
          <li>
            {t('privacy.li5')}
            <ul>
              <li>
                {t('privacy.li5li1')}
              </li>
              <li>
                {t('privacy.li5li2')}
              </li>
              <li>
                {t('privacy.li5li3')}
              </li>
              <li>
                {t('privacy.li5li4')}
              </li>
              <li>
                {t('privacy.li5li5')}
              </li>
            </ul>
          </li>
          <li>
            {t('privacy.li6')}
          </li>
        </ol>
        <Typography className={classes.subheader}>
          {t('privacy.header2')}
        </Typography>
        <ol>
          <li>
            {t('privacy.li7')}
          </li>
          <li>
            {t('privacy.li8')}
          </li>
          <li>
            <Trans
              i18nKey="privacy.li9"
              components={[
                <b />,
              ]}
            />
          </li>
          <li>
            {t('privacy.li10')}
            <ul>
              <li>
                {t('privacy.li10li1')}
              </li>
              <li>
                <Trans
                  i18nKey="privacy.li10li2"
                  components={[
                    <Link href="mailto:wieslaw.telepski@innobaltica.pl" />,
                  ]}
                />
              </li>
            </ul>
          </li>
          <li>
            {t('privacy.li11')}
            <ul>
              <li>
                {t('privacy.li11li1')}
              </li>
              <li>
                {t('privacy.li11li2')}
              </li>
              <li>
                {t('privacy.li11li3')}
              </li>
              <li>
                {t('privacy.li11li4')}
              </li>
              <li>
                {t('privacy.li11li5')}
              </li>
            </ul>
          </li>
          <li>
            {t('privacy.li12')}
            <ul>
              <li>
                {t('privacy.li12li1')}
              </li>
              <li>
                {t('privacy.li12li2')}
              </li>
            </ul>
          </li>
          <li>
            {t('privacy.li13')}
          </li>
          <li>
            {t('privacy.li14')}
          </li>
          <li>
            {t('privacy.li15')}
          </li>
          <li>
            {t('privacy.li16')}
            <ul>
              <li>
                {t('privacy.li16li1')}
              </li>
              <li>
                {t('privacy.li16li2')}
              </li>
              <li>
                {t('privacy.li16li3')}
              </li>
              <li>
                {t('privacy.li16li4')}
              </li>
              <li>
                {t('privacy.li16li5')}
              </li>
            </ul>
          </li>
          <li>
            {t('privacy.li17')}
            <ul>
              <li>
                {t('privacy.li17li1')}
              </li>
              <li>
                {t('privacy.li17li2')}
              </li>
              <li>
                <Trans
                  i18nKey="privacy.li17li3"
                  components={[
                    <i />,
                    <i />,
                    <i />,
                  ]}
                />
              </li>
            </ul>
          </li>
          <li>
            {t('privacy.li18')}
          </li>
          <li>
            <Trans
              i18nKey="privacy.li19"
              components={[
                <i />,
                <Link
                  href="http://innobaltica.pl/index.php?fdownload=file-politykabezpieczenstwadanychosobowych.pdf"
                />,
              ]}
            />
          </li>
        </ol>
      </div>
      <div className={classes.footerContainer}>
        <Footer />
      </div>
    </div>
  );
}
