import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import classNames from 'classnames';
import { ReactComponent as Directions } from '../../assets/directions.svg';
import { ReactComponent as ChevronWhite } from '../../assets/chevron-white.svg';
import { IStops, ITrip } from '../../interfaces';
import { useGetLink } from '../../utils';

interface Props {
  selectedTrips: string[];
  setSelectedTrips: Dispatch<SetStateAction<string[]>>;
  stops: IStops;
  trips: ITrip[];
  provider: string;
  date: string;
  route: string;
  print?: boolean;
  direction?: 0 | 1;
}

const useStyles = makeStyles({
  switchDirection: {
    marginRight: 60,
    display: 'flex',
    alignItems: 'center',
    color: '#A1145C',
  },
  directions: {
    marginLeft: 16,
  },
  trips: {
    backgroundColor: '#F5F5F5',
    margin: '0 -32px',
    padding: '0 32px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  tripsTable: {
    borderCollapse: 'collapse',
    border: '1px solid #37001F',
    backgroundColor: 'white',
  },
  tableTripHeader: {
    textAlign: 'left',
    padding: '8px 16px',
    fontWeight: 400,
    color: '#37001F',
    border: '1px solid #37001F',
  },
  tableTrip: {
    padding: '4px 16px',
    border: '1px solid #37001F',
    position: 'relative',
    color: '#37001F',
  },
  tripButton: {
    width: '100%',
    padding: 1,
    fontWeight: 400,
    backgroundColor: '#F5F5F5',
  },
  row: {
    '&:nth-child(even)': {
      backgroundColor: '#F5F5F5',
    },
  },
  tableStop: {
    padding: '4px 16px',
    color: '#37001F',
    borderRight: '1px solid #37001F',
  },
  tableTime: {
    padding: '4px 16px',
    color: '#37001F',
    textAlign: 'center',
  },
  tableTimeBorder: {
    borderRight: '1px solid #37001F',
  },
  tableTimeHeader: {
    fontSize: 14,
    fontWeight: 400,
    color: '#37001F',
    padding: '4px 16px',
    textAlign: 'center',
  },
  emptyHeaderCell: {
    borderRight: '1px solid #37001F',
  },
  noTrips: {
    padding: '16px 0',
    color: '#37001F',
  },
  selectedButton: {
    backgroundColor: '#a1145c',
    color: 'white',
    wordBreak: 'break-all',
    '&:hover': {
      backgroundColor: '#c62e8a',
    },
  },
  iconButton: {
    backgroundColor: '#A1145C',
    width: 20,
    height: 20,
    position: 'absolute',
    top: '50%',
    padding: 0,
    borderRadius: '50%',
    minWidth: 0,
    '&$disabled': {
      backgroundColor: '#c3c3c3',
    },
  },
  disabled: {},
  iconButtonLeft: {
    left: 0,
    transform: 'translate(-50%, -50%)',
  },
  iconButtonRight: {
    right: 0,
    transform: 'translate(50%, -50%)',
  },
  iconLeft: {
    transform: 'rotate(90deg)',
  },
  iconRight: {
    transform: 'rotate(-90deg)',
  },
  restoreCurrentState: {
    marginLeft: 8,
  },
  buttons: {
    display: 'flex',
    margin: '16px 0',
  },
  printWrapper: {
    padding: 16,
  },
});

export default function Trips(props: Props) {
  const {
    selectedTrips, setSelectedTrips, stops, trips, provider, date, route, print, direction,
  } = props;
  const getLink = useGetLink();
  const classes = useStyles();
  const { t } = useTranslation();
  const [oppositeDirection, setOppositeDirection] = useState(direction === 1);
  useEffect(() => {
    setOppositeDirection(direction === 1);
  }, [trips, direction]);
  const [tripIndex, setTripIndex] = useState(0);
  const columns = Math.floor((window.innerWidth - 450) / 162);
  const directionTrips = trips.filter(R.propEq('direction', oppositeDirection ? 1 : 0));
  const tripsToShow = print
    ? directionTrips
    : R.slice(tripIndex, tripIndex + columns, directionTrips);
  const tripsClone = R.clone(tripsToShow);
  const table = (
    <table className={classes.tripsTable}>
      <thead>
        <tr className={classes.row}>
          <Typography component="th" className={classes.tableTripHeader}>
            {t('main.trip')}
          </Typography>
          {tripsToShow.map((trip, i) => (
            <Typography component="th" colSpan={2} className={classes.tableTrip} key={trip.id}>
              {i === 0 && !print && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.iconButton, classes.iconButtonLeft)}
                  onClick={() => setTripIndex((x) => Math.max(x - columns, 0))}
                  disabled={tripIndex === 0}
                  classes={{ disabled: classes.disabled }}
                >
                  <ChevronWhite className={classes.iconLeft} />
                </Button>
              )}
              {print ? trip.pattern : (
                <Button
                  variant="contained"
                  className={classNames(classes.tripButton, {
                    [classes.selectedButton]: selectedTrips.includes(trip.pattern),
                  })}
                  onClick={() => setSelectedTrips(
                    selectedTrips.includes(trip.pattern)
                      ? R.filter((x) => x !== trip.pattern)
                      : R.append(trip.pattern),
                  )}
                >
                  {trip.pattern}
                </Button>
              )}
              {i === tripsToShow.length - 1 && !print && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.iconButton, classes.iconButtonRight)}
                  onClick={() => setTripIndex(
                    (x) => Math.min(x + columns, directionTrips.length - 1),
                  )}
                  disabled={tripIndex >= directionTrips.length - columns}
                  classes={{ disabled: classes.disabled }}
                >
                  <ChevronWhite className={classes.iconRight} />
                </Button>
              )}
            </Typography>
          ))}
        </tr>
        <tr className={classes.row}>
          <Typography component="th" className={classes.emptyHeaderCell} />
          {tripsToShow.map((trip) => (
            <>
              <Typography
                component="th"
                className={classes.tableTimeHeader}
                key={`${trip.id}-arrival`}
              >
                {t('main.arrival')}
              </Typography>
              <Typography
                component="th"
                className={classNames(classes.tableTimeHeader, classes.tableTimeBorder)}
                key={`${trip.id}-departure`}
              >
                {t('main.departure')}
              </Typography>
            </>
          ))}
        </tr>
      </thead>
      <tbody>
        {stops[oppositeDirection ? 'opposite' : 'default'].map((stop, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr className={classes.row} key={`${stop}-${i}`}>
            <Typography component="td" className={classes.tableStop}>
              {stop.name}
            </Typography>
            {tripsClone.map((trip) => {
              let tripStop = null;
              if (R.pathEq([0, 'id'], stop.id, trip.stops)) {
                ({ 0: tripStop } = trip.stops);
                trip.stops.shift();
              }
              return (
                <>
                  <Typography
                    component="td"
                    className={classes.tableTime}
                    key={
                      /* eslint-disable-next-line react/no-array-index-key */
                      `${trip.id}-${stop}-${i}-arrival`
                    }
                  >
                    {(tripStop && tripStop.arrival) ? R.take(5, tripStop.arrival) : '-'}
                  </Typography>
                  <Typography
                    component="td"
                    className={classNames(classes.tableTime, classes.tableTimeBorder)}
                    key={
                      /* eslint-disable-next-line react/no-array-index-key */
                      `${trip.id}-${stop}-${i}-departure`
                    }
                  >
                    {(tripStop && tripStop.departure) ? R.take(5, tripStop.departure) : '-'}
                  </Typography>
                </>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
  if (print) {
    return (
      <div className={classes.printWrapper}>
        {table}
      </div>
    );
  }
  return (
    <div>
      <div className={classes.buttons}>
        <Button
          variant="text"
          className={classes.switchDirection}
          onClick={() => {
            setOppositeDirection(R.not);
            setTripIndex(0);
            setSelectedTrips(trips.filter(R.propEq('direction', oppositeDirection ? 0 : 1)).map(R.prop('pattern')));
          }}
        >
          {t('main.switchDirection')}
          <Directions className={classes.directions} />
        </Button>
        <Button
          variant="text"
          color="primary"
          component="a"
          href={getLink(`/provider/print-trips?${new URLSearchParams({
            provider,
            date,
            route,
            direction: oppositeDirection ? '1' : '0',
          })}`)}
          target="_blank"
        >
          {t('main.printView')}
        </Button>
      </div>
      <div className={classes.trips}>
        {tripsToShow.length > 0 ? (
          <>
            {table}
            <Button
              variant="text"
              color="primary"
              className={classes.restoreCurrentState}
              onClick={() => setSelectedTrips([])}
            >
              {t('main.restoreCurrentState')}
            </Button>
          </>
        ) : (
          <Typography className={classes.noTrips}>
            {t('main.noTrips')}
          </Typography>
        )}
      </div>
    </div>
  );
}
