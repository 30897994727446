import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import classNames from 'classnames';
import * as R from 'ramda';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import PackageIcon from './PackageIcon';
import {
  IPackage, IPackageAdmissionStats, IPackageStatus, IPackageUtilizationStats,
} from '../interfaces';
import PeriodSelect, { IDateRange } from '../common/PeriodSelect';
import { axios } from '../utils';

const useStyles = makeStyles({
  root: {
    marginTop: 16,
    display: 'flex',
  },
  left: {
    flexGrow: 1,
    marginRight: 24,
  },
  right: {
    width: 800,
    flexShrink: 0,
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 40,
  },
  package: {
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    marginBottom: 8,
    backgroundColor: '#F5F5F5',
    cursor: 'pointer',
    width: '100%',
    justifyContent: 'flex-start',
  },
  packageName: {
    fontSize: 20,
    padding: '1px 0',
  },
  packageIcon: {
    marginRight: 16,
  },
  selectedButton: {
    backgroundColor: '#a1145c',
    color: 'white',
    '&:hover': {
      backgroundColor: '#c62e8a',
    },
  },
  totalSection: {
    padding: '16px 32px 24px',
    backgroundColor: '#F5F5F5',
    marginBottom: 24,
  },
  subheader: {
    fontWeight: 400,
    marginBottom: 16,
  },
  chartOuterContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    marginBottom: 24,
  },
  chartInnerContainer: {
    display: 'flex',
  },
  chart: {
    width: 350,
  },
  yLabel: {
    paddingTop: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  chartButton: {
    whiteSpace: 'nowrap',
    fontSize: 14,
    padding: 4,
    lineHeight: 1,
    '&:disabled': {
      color: '#37001F',
    },
  },
  xLabel: {
    textAlign: 'right',
  },
  chartLabel: {
    fontSize: 14,
    color: '#37001F',
  },
  table: {
    border: '1px solid #37001F',
    borderCollapse: 'collapse',
    backgroundColor: 'white',
    width: '100%',
    marginBottom: 12,
  },
  tableCell: {
    fontSize: 16,
    fontWeight: 300,
    padding: '14px 12px',
    border: '1px solid #37001F',
    color: '#37001F',
  },
  tableHeader: {
    fontWeight: 400,
    textAlign: 'left',
  },
  usageHeader: {
    textAlign: 'center',
  },
  percentCell: {
    borderRight: 0,
    textAlign: 'right',
    width: 100,
  },
  usageCell: {
    borderLeft: 0,
    textAlign: 'right',
    paddingRight: 50,
    width: 120,
  },
  tableNote: {
    fontSize: 13,
    fontWeight: 400,
  },
  packagesSold: {
    fontWeight: 400,
    marginBottom: 24,
  },
  soldValue: {
    display: 'inline-block',
    paddingLeft: 8,
    fontSize: 24,
    fontWeight: 700,
  },
  numberCell: {
    textAlign: 'center',
  },
  periodTable: {
    width: 460,
  },
  exportReport: {
    marginTop: 16,
  },
  periodContainer: {
    display: 'flex',
    marginBottom: 16,
  },
  periodText: {
    fontWeight: 400,
    marginRight: 24,
    paddingTop: 8,
  },
});

export default function PackageUsage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [packages, setPackages] = useState<IPackage[]>([]);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/card/packages');
      setPackages(data.filter((x: IPackage) => x.status !== 'DRAFT'));
    })();
  }, []);
  const [selectedPackage, setSelectedPackage] = useState<number | null>(null);
  const [utilizationStats, setUtilizationStats] = useState<IPackageUtilizationStats | null>(null);
  useEffect(() => {
    (async () => {
      if (selectedPackage) {
        const { data } = await axios.get(`/card/statistics/package/${selectedPackage}/usage`);
        setUtilizationStats(data);
      } else {
        setUtilizationStats(null);
      }
    })();
  }, [selectedPackage]);
  const [chartPercent, setChartPercent] = useState(false);
  const [dateRange, setDateRange] = useState<IDateRange>(null);
  const [admissionStats, setAdmissionStats] = useState<IPackageAdmissionStats | null>(null);
  useEffect(() => {
    (async () => {
      if (dateRange && dateRange.from && dateRange.to) {
        const { data } = await axios.get(`/card/statistics/package/${selectedPackage}/admissions`, {
          params: {
            from: dateRange.from.format('YYYY-MM-DD'),
            to: dateRange.to.format('YYYY-MM-DD'),
          },
        });
        setAdmissionStats(data);
      } else {
        setAdmissionStats(null);
      }
    })();
  }, [dateRange, selectedPackage]);
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Typography className={classes.header}>
          {t('card.package')}
        </Typography>
        {packages.map((pkg) => (
          <Button
            variant="contained"
            className={classNames(classes.package, {
              [classes.selectedButton]: pkg.id === selectedPackage,
            })}
            onClick={() => {
              setSelectedPackage(pkg.id);
              setDateRange(null);
            }}
            key={pkg.id}
          >
            <PackageIcon status={pkg.status as IPackageStatus} className={classes.packageIcon} />
            <Typography className={classes.packageName}>
              {pkg.namePL}
            </Typography>
          </Button>
        ))}
      </div>
      <div className={classes.right}>
        {utilizationStats && (
          <>
            <div className={classes.totalSection}>
              <Typography className={classes.subheader}>
                {`${t('card.usage')} (${
                  moment(utilizationStats.salesFrom).format('MM.Y')} - ${t('card.now')})`}
              </Typography>
              <div className={classes.chartOuterContainer}>
                <div className={classes.chartInnerContainer}>
                  <div className={classes.yLabel}>
                    <Button
                      disabled
                      color="primary"
                      className={classes.chartButton}
                    >
                      {`${chartPercent ? '%' : '#'} ${t('card.visitors')}`}
                    </Button>
                    <Button
                      onClick={() => setChartPercent(R.not)}
                      color="primary"
                      className={classes.chartButton}
                    >
                      {`${chartPercent ? '#' : '%'} ${t('card.visitors')}`}
                    </Button>
                  </div>
                  <div className={classes.chart}>
                    <Bar
                      height={250}
                      options={{
                        maintainAspectRatio: false,
                        tooltips: {
                          enabled: false,
                        },
                        layout: {
                          padding: {
                            right: 20,
                          },
                        },
                        scales: {
                          xAxes: [{
                            display: false,
                            ticks: {
                              max: 75,
                            },
                          }, {
                            display: true,
                            gridLines: {
                              drawOnChartArea: false,
                              color: '#9E9E9E',
                              drawTicks: false,
                            },
                            ticks: {
                              fontSize: 14,
                              fontFamily: '"Roboto"',
                              fontColor: '#37001F',
                              padding: 8,
                            },
                          }],
                          yAxes: [{
                            gridLines: {
                              drawOnChartArea: false,
                              color: '#9E9E9E',
                              tickMarkLength: 7,
                            },
                            ticks: {
                              fontSize: 14,
                              fontFamily: '"Roboto"',
                              fontColor: '#37001F',
                              lineHeight: 3,
                              padding: 8,
                              beginAtZero: true,
                              callback: (value) => (value === 0 ? '' : value),
                            },
                          }],
                        },
                      }}
                      legend={{ display: false }}
                      data={{
                        labels: [0, 25, 50, 75, 100],
                        datasets: [{
                          data: utilizationStats.buckets.map(R.prop(chartPercent ? 'percentage' : 'visitors')),
                          backgroundColor: ['#E06307', '#120C9B', '#99319D', '#EFD828'],
                          barThickness: 36,
                        }],
                      }}
                    />
                  </div>
                </div>
                <div className={classes.xLabel}>
                  <Typography className={classes.chartLabel}>
                    {`${t('card.singlePackageUsage')} [%]`}
                  </Typography>
                </div>
              </div>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th className={classNames(classes.tableCell, classes.tableHeader)}>
                      {t('card.attraction')}
                    </th>
                    <th
                      className={classNames(
                        classes.tableCell,
                        classes.tableHeader,
                        classes.usageHeader,
                      )}
                      colSpan={2}
                    >
                      {`${t('card.packageUsage')} [%]*`}
                    </th>
                    <th className={classNames(classes.tableCell, classes.tableHeader)}>
                      {t('card.discount')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {utilizationStats.attractions.map((attraction) => (
                    <tr key={attraction.name}>
                      <td className={classes.tableCell}>
                        {attraction.name}
                      </td>
                      <td className={classNames(classes.tableCell, classes.percentCell)}>
                        {`${Math.round((attraction.usage.used / attraction.usage.available) * 100)}%`}
                      </td>
                      <td className={classNames(classes.tableCell, classes.usageCell)}>
                        {`(${attraction.usage.used}/${attraction.usage.available})`}
                      </td>
                      <td className={classes.tableCell}>
                        {attraction.discount.type === 'PERCENTAGE' && (
                          t('card.discountValue', { value: `${attraction.discount.amount}%` })
                        )}
                        {attraction.discount.type === 'PLN' && (
                          t('card.discountValue', { value: `${attraction.discount.amount} PLN` })
                        )}
                        {attraction.discount.type === 'FREE_ENTRY' && t('card.freeEntranceLower')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Typography className={classes.tableNote}>
                {`*(${t('card.usageDesc')})`}
              </Typography>
            </div>
            <div className={classes.periodContainer}>
              <Typography className={classes.periodText}>
                {t('card.salesAndAdmissionsFor')}
              </Typography>
              <PeriodSelect value={dateRange} onChange={setDateRange} />
            </div>
            {admissionStats && (
              <>
                <Typography className={classes.packagesSold}>
                  {`${t('card.packagesSold')}: `}
                  <span className={classes.soldValue}>
                    {admissionStats.sold}
                  </span>
                </Typography>
                <table className={classNames(classes.table, classes.periodTable)}>
                  <thead>
                    <tr>
                      <th className={classNames(classes.tableCell, classes.tableHeader)}>
                        {t('card.attraction')}
                      </th>
                      <th
                        className={classNames(
                          classes.tableCell, classes.tableHeader, classes.numberCell,
                        )}
                      >
                        {t('card.admissions')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {admissionStats.attractions.map((attraction) => (
                      <tr key={attraction.name}>
                        <td className={classes.tableCell}>
                          {attraction.name}
                        </td>
                        <td className={classNames(classes.tableCell, classes.numberCell)}>
                          {attraction.admissions}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th className={classNames(classes.tableCell, classes.tableHeader)}>
                        {t('card.total')}
                      </th>
                      <th
                        className={classNames(
                          classes.tableCell, classes.tableHeader, classes.numberCell,
                        )}
                      >
                        {R.sum(admissionStats.attractions.map(R.prop('admissions')))}
                      </th>
                    </tr>
                  </tfoot>
                </table>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.exportReport}
                >
                  {t('card.exportReport')}
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
