import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { FormHelperText, IconButton, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as Clear } from '../assets/clear.svg';
import { ReactComponent as ImagePlaceholder } from '../assets/image-placeholder.svg';

interface Props {
  onChange: (file: File | null, imageUrl: string | null) => void;
  imageUrl: string | null;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 24,
  },
  dropzone: {
    width: 320,
    height: 200,
    backgroundColor: 'white',
    border: '1px solid #9E9E9E',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: theme.transitions.create('background-color'),
    overflow: 'hidden',
    position: 'relative',
    marginBottom: 8,
    '&:focus': {
      outline: 'none',
    },
  },
  dropzoneActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
  },
  dragAndDrop: {
    color: '#666666',
    marginBottom: 34,
    zIndex: 10,
  },
  selectFile: {
    color: '#A1145C',
    cursor: 'pointer',
    zIndex: 10,
  },
  imagePreview: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  removeImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 4,
  },
  removeIcon: {
    background: 'white',
    borderRadius: '50%',
  },
  placeholder: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    margin: 8,
  },
}));

export default function ImageUpload(props: Props) {
  const { onChange, imageUrl } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => {
      const file = files[0];
      if (file.size > 3 * 1024 ** 2) {
        setError(true);
      } else {
        onChange(files[0], URL.createObjectURL(files[0]));
        setError(false);
      }
    },
  });
  useEffect(() => {
    setError(false);
  }, [imageUrl]);
  return (
    <div className={classes.root}>
      <div
        className={classNames(classes.dropzone, {
          [classes.dropzoneActive]: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {imageUrl ? (
          <>
            <img src={imageUrl} alt="" className={classes.imagePreview} />
            <IconButton
              className={classes.removeImage}
              onClick={(event) => {
                event.stopPropagation();
                URL.revokeObjectURL(imageUrl);
                onChange(null, null);
              }}
            >
              <Clear className={classes.removeIcon} />
            </IconButton>
          </>
        ) : (
          <>
            <Typography className={classes.dragAndDrop}>
              {t('card.dragAndDrop')}
            </Typography>
            <Typography className={classes.selectFile}>
              {t('card.selectFile')}
            </Typography>
            <ImagePlaceholder className={classes.placeholder} />
          </>
        )}
      </div>
      {error && (
        <FormHelperText error>
          {t('card.fileSizeError')}
        </FormHelperText>
      )}
    </div>
  );
}
