import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { ProviderContext } from '../context';

const useStyles = makeStyles({
  dataView: {
    fontSize: 14,
    marginBottom: 16,
  },
  dataViewLabel: {
    fontWeight: 400,
  },
  name: {
    fontWeight: 400,
  },
});

export default function VersionInfo() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { version, user, providers } = useContext(ProviderContext);
  if (!version || !user.agency) return null;
  return (
    <Typography className={classes.dataView}>
      <span className={classes.dataViewLabel}>
        {t('data.dataView')}
      </span>
      {': '}
      {providers.find(R.propEq('id', user.agency))!.name}
      {', '}
      {version.type === 'draft' ? (
        <>
          {t('data.draft')}
          {version.author && (
            <>
              {' '}
              {t('data.editedBy')}
              {' '}
              <span className={classes.name}>
                {version.author}
              </span>
            </>
          )}
        </>
      ) : version.name}
    </Typography>
  );
}
