import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Button, IconButton, MenuItem, TextField, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Map, Marker, Polyline, ScaleControl, TileLayer,
} from 'react-leaflet';
import leaflet from 'leaflet';
import marker from '../../assets/stop-marker.svg';
import { ProviderContext } from '../../context';
import {
  IDataPattern, IDataRoute, IDataStop, IDataTrip,
} from '../../interfaces';
import { axios } from '../../utils';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import { ReactComponent as Chevron } from '../../assets/chevron.svg';
import { ReactComponent as Clear } from '../../assets/clear.svg';
import { ReactComponent as DraggableIcon } from '../../assets/draggable.svg';
import VersionInfo from '../VersionInfo';
import NewHeader from '../NewHeader';
import ButtonList from '../ButtonList';
import DeleteDialog from '../DeleteDialog';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    display: 'flex',
  },
  left: {
    flexGrow: 1,
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  searchRoute: {
    width: 200,
    marginLeft: 16,
  },
  right: {
    width: 870,
    marginLeft: 24,
    flexShrink: 0,
  },
  row: {
    display: 'flex',
  },
  forRoute: {
    width: 176,
    marginRight: 20,
  },
  patternName: {
    width: 296,
    marginRight: 20,
  },
  direction: {
    width: 176,
  },
  selectIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 4px',
  },
  copyFromRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px -16px',
    padding: 16,
    backgroundColor: '#F5F5F5',
  },
  addStops: {
    marginRight: 16,
    color: '#37001F',
  },
  copyRoute: {
    width: 140,
    marginRight: 16,
  },
  copyPattern: {
    width: 300,
    marginRight: 16,
  },
  addStopRow: {
    display: 'flex',
    alignItems: 'center',
  },
  position: {
    width: 60,
    marginRight: 16,
  },
  stop: {
    width: 300,
    marginRight: 16,
  },
  stops: {
    margin: '16px 0',
  },
  patternStop: {
    margin: '8px 0',
    backgroundColor: '#F5F5F5',
    padding: '8px 16px',
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
  },
  patternStopPosition: {
    width: 50,
    fontSize: 20,
    color: '#37001F',
  },
  patternStopName: {
    marginRight: 'auto',
    fontSize: 20,
    color: '#37001F',
  },
  deleteStop: {
    margin: -10,
    padding: 10,
  },
  draggableContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
  },
  saveRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0',
  },
  discardChanges: {
    marginLeft: 'auto',
    marginRight: 16,
  },
  headsignRow: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 16,
  },
  headsign: {
    width: 296,
    marginRight: 20,
  },
  remarks: {
    width: 372,
  },
  stopsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 16,
  },
  map: {
    width: '50%',
    height: 405,
    marginRight: 32,
    marginTop: 16,
  },
  stopsRight: {
    flexGrow: 1,
  },
});

export default function Patterns() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user: { agency }, version } = useContext(ProviderContext);
  const [patterns, setPatterns] = useState<IDataPattern[]>([]);
  const [routes, setRoutes] = useState<IDataRoute[]>([]);
  const [stops, setStops] = useState<IDataStop[]>([]);
  const [trips, setTrips] = useState<IDataTrip[]>([]);
  const [searchRoute, setSearchRoute] = useState<IDataRoute | null>(null);
  const [selectedPattern, setSelectedPattern] = useState<number | 'new' | null>(null);
  useEffect(() => {
    (async () => {
      const [
        { data },
        { data: routesData },
        { data: stopsData },
        { data: tripsData },
      ]: [
        { data: IDataPattern[] },
        { data: IDataRoute[] },
        { data: IDataStop[] },
        { data: IDataTrip[] },
      ] = await Promise.all([
        axios.get(`/${agency}/data/patterns`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/routes`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/stops`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/trips`, { params: { providerVersion: version!.id } }),
      ]);
      setPatterns(data);
      setRoutes(routesData);
      setStops(stopsData);
      setTrips(tripsData);
    })();
  }, [agency, version]);
  const [route, setRoute] = useState<number | null>(null);
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState(false);
  const [direction, setDirection] = useState<'INBOUND' | 'OUTBOUND'>('INBOUND');
  const [headsign, setHeadsign] = useState('');
  const [remarks, setRemarks] = useState('');
  const [copyRoute, setCopyRoute] = useState<IDataRoute | null>(null);
  const [copyPattern, setCopyPattern] = useState<IDataPattern | null>(null);
  const [position, setPosition] = useState('1');
  const [stop, setStop] = useState<IDataStop | null>(null);
  const [patternStops, setPatternStops] = useState<number[]>([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const mapRef = useRef<Map>(null);
  useEffect(() => {
    if (!mapRef.current) return;
    if (patternStops.length + (stop ? 1 : 0) >= 2) {
      mapRef.current.leafletElement.fitBounds(
        new leaflet.LatLngBounds([
          ...patternStops.map((id) => {
            const x = stops.find(R.whereEq({ id })) as IDataStop;
            return [x.lat, x.lon];
          }),
          ...(stop ? [[stop.lat, stop.lon]] : []),
        ] as [number, number][]),
      );
    } else if (stop || patternStops.length === 1) {
      const x = stop || stops.find(R.whereEq({ id: patternStops[0] })) as IDataStop;
      mapRef.current.leafletElement.setView(
        [x.lat, x.lon],
        16,
      );
    } else {
      mapRef.current.leafletElement.fitBounds(
        new leaflet.LatLngBounds([
          [56.464660, 14.408800],
          [53.570577, 19.443904],
        ]),
      );
    }
  }, [patternStops, stop, stops]);
  const positionNumber = Number(position);
  const positionValid = position !== '' && positionNumber >= 1 && positionNumber <= patternStops.length + 1;
  const loadData = (pattern: IDataPattern | null) => {
    if (pattern) {
      setRoute(pattern.route);
      setName(pattern.name);
      setDirection(pattern.direction);
      setHeadsign(pattern.headsign);
      setRemarks(pattern.remarks || '');
      setCopyRoute(null);
      setCopyPattern(null);
      setPosition(String(pattern.stops.length + 1));
      setStop(null);
      setPatternStops(pattern.stops);
    } else {
      setRoute(null);
      setName('');
      setDirection('INBOUND');
      setHeadsign('');
      setRemarks('');
      setCopyRoute(null);
      setCopyPattern(null);
      setPosition('1');
      setStop(null);
      setPatternStops([]);
    }
    setNameError(false);
  };
  const readOnly = !version?.hasUserLock;
  const isUsed = trips.some((x) => x.pattern === selectedPattern);
  const canDelete = typeof selectedPattern !== 'number' || !isUsed;
  const saveRow = (
    <div className={classes.saveRow}>
      {selectedPattern !== 'new' && (
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            setDeleteDialog(true);
            setDeleteName(patterns.find(R.propEq('id', selectedPattern))!.name);
          }}
          disabled={readOnly}
        >
          {t('data.deletePattern')}
        </Button>
      )}
      <Button
        variant="text"
        color="primary"
        className={classes.discardChanges}
        onClick={() => {
          loadData(
            selectedPattern === 'new'
              ? null
              : patterns.find(R.propEq('id', selectedPattern)) as IDataPattern,
          );
        }}
      >
        {t('data.discardChanges')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          if (!route) return;
          if (
            patterns.some((x) => x.route === route && x.name === name && x.id !== selectedPattern)
          ) {
            setNameError(true);
            return;
          }
          const patternData = {
            name,
            route,
            direction,
            headsign,
            remarks: remarks || undefined,
            stops: patternStops,
          };
          if (selectedPattern === 'new') {
            const { data } = await axios.post(`/${agency}/data/pattern`, patternData);
            setPatterns(R.append(data));
          } else {
            await axios.put(`/${agency}/data/pattern/${selectedPattern}`, patternData);
            setPatterns(R.map((x) => (x.id === selectedPattern ? { ...x, ...patternData } : x)));
          }
          setSelectedPattern(null);
        }}
        disabled={readOnly}
      >
        {t('data.save')}
      </Button>
    </div>
  );
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <VersionInfo />
        <NewHeader
          headerText={t('data.patterns')}
          newText={t('data.newPattern')}
          onClick={() => {
            setSelectedPattern('new');
            loadData(null);
          }}
          disabled={readOnly}
        />
        <div className={classes.filter}>
          <Typography>
            {t('data.patternsFor')}
          </Typography>
          <CustomAutocomplete
            options={routes}
            value={searchRoute}
            onChange={(event, value) => setSearchRoute(value)}
            label={t('data.routeName')}
            className={classes.searchRoute}
            getOptionLabel={R.prop('name')}
          />
        </div>
        <ButtonList
          elements={(
            patterns.filter(
              (x) => !searchRoute || x.route === searchRoute.id,
            ).map((pattern) => ({
              id: pattern.id,
              name: pattern.name,
              onClick: () => {
                setSelectedPattern(pattern.id);
                loadData(pattern);
              },
            }))
          )}
          selected={selectedPattern}
        />
      </div>
      <div className={classes.right}>
        {selectedPattern && (
          <>
            <div className={classes.row}>
              <CustomAutocomplete
                options={routes}
                value={routes.find(R.propEq('id', route)) || null}
                onChange={(event, value) => {
                  setRoute(value ? value.id : null);
                  setNameError(false);
                }}
                label={t('data.forRoute')}
                getOptionLabel={R.prop('name')}
                className={classes.forRoute}
                disabled={isUsed}
              />
              <TextField
                label={t('data.patternName')}
                value={name}
                onChange={({ target }) => {
                  setName(target.value);
                  setNameError(false);
                }}
                variant="outlined"
                size="small"
                className={classes.patternName}
                error={nameError}
                helperText={nameError ? t('data.patternExists', { name }) : ''}
              />
              <TextField
                label={t('data.direction')}
                value={direction}
                onChange={({ target }) => setDirection(target.value as 'INBOUND' | 'OUTBOUND')}
                variant="outlined"
                size="small"
                select
                className={classes.direction}
                SelectProps={{
                  // eslint-disable-next-line react/prop-types
                  IconComponent: ({ className, ...rest }) => (
                    <div {...rest} className={classNames(className, classes.selectIcon)}>
                      <Chevron />
                    </div>
                  ),
                }}
              >
                <MenuItem value="INBOUND">
                  {t('data.forward')}
                </MenuItem>
                <MenuItem value="OUTBOUND">
                  {t('data.backward')}
                </MenuItem>
              </TextField>
            </div>
            <div className={classes.headsignRow}>
              <TextField
                label={t('data.heading')}
                value={headsign}
                onChange={({ target }) => setHeadsign(target.value)}
                variant="outlined"
                size="small"
                className={classes.headsign}
              />
              <TextField
                label={t('data.patternRemarks')}
                value={remarks}
                onChange={({ target }) => setRemarks(target.value)}
                variant="outlined"
                size="small"
                className={classes.remarks}
              />
            </div>
            <div className={classes.copyFromRow}>
              <Typography className={classes.addStops}>
                {t('data.addStopsFrom')}
              </Typography>
              <CustomAutocomplete
                options={routes}
                value={copyRoute}
                onChange={(event, value) => setCopyRoute(value)}
                label={t('data.routeName')}
                getOptionLabel={R.prop('name')}
                className={classes.copyRoute}
                disabled={isUsed}
              />
              <CustomAutocomplete
                options={patterns.filter(R.propEq('route', copyRoute?.id))}
                value={copyPattern}
                onChange={(event, value) => setCopyPattern(value)}
                label={t('data.patternName')}
                getOptionLabel={R.prop('name')}
                className={classes.copyPattern}
                disabled={isUsed}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!copyRoute || !copyPattern}
                onClick={() => {
                  if (!copyPattern) return;
                  // eslint-disable-next-line no-underscore-dangle
                  setPatternStops(R.concat(R.__, copyPattern.stops));
                  setCopyRoute(null);
                  setCopyPattern(null);
                }}
              >
                {t('data.add')}
              </Button>
            </div>
            <div className={classes.addStopRow}>
              <TextField
                label={t('data.no')}
                value={position}
                onChange={({ target }) => setPosition(target.value)}
                variant="outlined"
                size="small"
                className={classes.position}
                disabled={isUsed}
              />
              <CustomAutocomplete
                options={stops}
                value={stop}
                onChange={(event, value) => setStop(value)}
                label={t('data.stopName')}
                getOptionLabel={R.prop('name')}
                className={classes.stop}
                disabled={isUsed}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!positionValid || !stop}
                onClick={() => {
                  if (!stop) return;
                  setPatternStops(R.insert(positionNumber - 1, stop.id));
                  setPosition(String(patternStops.length + 2));
                  setStop(null);
                }}
              >
                {t('data.add')}
              </Button>
            </div>
            <div className={classes.stopsContainer}>
              <Map
                ref={mapRef}
                className={classes.map}
                minZoom={3}
                maxZoom={18}
                fullscreenControl
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <ScaleControl position="topright" imperial={false} />
                {stop && (
                  <Marker
                    position={[stop.lat, stop.lon]}
                    icon={leaflet.icon({
                      iconUrl: marker,
                      iconSize: [15, 15],
                    })}
                  />
                )}
                {patternStops.map((id) => {
                  const x = stops.find(R.whereEq({ id })) as IDataStop;
                  return (
                    <Marker
                      key={id}
                      position={[x.lat, x.lon]}
                      icon={leaflet.icon({
                        iconUrl: marker,
                        iconSize: [15, 15],
                      })}
                    />
                  );
                })}
                <Polyline
                  positions={patternStops.map((id) => {
                    const x = stops.find(R.whereEq({ id })) as IDataStop;
                    return { lat: x.lat, lng: x.lon };
                  })}
                  color="#1D3998"
                  weight={4}
                />
              </Map>
              <div className={classes.stopsRight}>
                {saveRow}
                <DragDropContext
                  onDragEnd={(result) => {
                    if (result.destination) {
                      setPatternStops(R.move(Number(result.draggableId), result.destination.index));
                    }
                  }}
                >
                  <Droppable droppableId="stops">
                    {(provided) => (
                      <div
                        className={classes.stops}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {patternStops.map((id, i) => (
                          <Draggable
                            draggableId={String(i)}
                            index={i}
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            isDragDisabled={isUsed}
                          >
                            {(draggableProvided) => (
                              <div
                                ref={draggableProvided.innerRef}
                                className={classes.patternStop}
                                {...draggableProvided.draggableProps}
                              >
                                <div
                                  {...draggableProvided.dragHandleProps}
                                  className={classes.draggableContainer}
                                >
                                  <DraggableIcon />
                                </div>
                                <Typography className={classes.patternStopPosition}>
                                  {i + 1}
                                </Typography>
                                <Typography className={classes.patternStopName}>
                                  {stops.find(R.whereEq({ id }))!.name}
                                </Typography>
                                <IconButton
                                  onClick={() => setPatternStops(R.remove(i, 1))}
                                  className={classes.deleteStop}
                                  disabled={isUsed}
                                >
                                  <Clear />
                                </IconButton>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {patternStops.length > 0 && saveRow}
              </div>
            </div>
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={canDelete ? t(
          'data.deletePatternConfirm',
          { name: deleteName },
        ) : t('data.patternDeleteError')}
        onDelete={async () => {
          await axios.delete(`/${agency}/data/pattern/${selectedPattern}`);
          setSelectedPattern(null);
          setPatterns(R.filter<IDataPattern>((x) => x.id !== selectedPattern));
          setDeleteDialog(false);
        }}
        canDelete={canDelete}
      />
    </div>
  );
}
