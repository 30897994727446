import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {
  Marker, Popup, Tooltip,
} from 'react-leaflet';
import leaflet from 'leaflet';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { IVehicle } from '../../interfaces';
import tram from '../../assets/tram.svg';
import train from '../../assets/train.svg';
import bus from '../../assets/bus.svg';
import trolleybus from '../../assets/trolleybus.svg';
import ferry from '../../assets/ferry.svg';
import tramDelayed from '../../assets/tram-delayed.svg';
import trainDelayed from '../../assets/train-delayed.svg';
import busDelayed from '../../assets/bus-delayed.svg';
import trolleybusDelayed from '../../assets/trolleybus-delayed.svg';
import classNames from 'classnames';

interface Props {
  vehicle: IVehicle;
  routeType: number;
}

const useStyles = makeStyles({
  root: {
    top: -12,
    left: -12,
    width: 24,
    height: 24,
    transition: '250ms',
    '&:hover': {
      width: 30,
      height: 30,
      top: -15,
      left: -15,
    },
  },
  popup: {
    backgroundColor: 'white',
    borderRadius: 3,
    '& > :first-child': {
      boxShadow: 'none',
      padding: 0,
      '& > div': {
        margin: '16px 24px',
      },
    },
    '& > :last-child': {
      display: 'none',
    },
  },
  popupHeader: {
    backgroundColor: '#37001F',
    color: 'white',
    fontSize: 24,
    margin: '0 0 12px -24px !important',
    padding: '4px 16px 4px 24px',
    borderRadius: '0 15px 15px 0',
    display: 'inline-block',
  },
  tooltip: {
    boxShadow: 'none',
  },
  tooltipText: {
    fontSize: 18,
    padding: '0 4px',
  },
  code: {
    fontSize: 24,
  },
  line: {
    margin: '0 !important',
    fontSize: 14,
    color: '#37001F',
  },
  date: {
    color: '#9E9E9E',
  },
});

export default function VehicleMarker({ vehicle, routeType }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const routeIcon = (() => {
    const delayed = vehicle.delay >= 180;
    switch (routeType) {
      case 0:
      case 900:
        return delayed ? tramDelayed : tram;
      case 2:
      case 101:
      case 102:
      case 106:
      case 109:
        return delayed ? trainDelayed : train;
      case 800:
        return delayed ? trolleybusDelayed : trolleybus;
      case 1200:
        return ferry;
      case 3:
      case 700:
      case 702:
      default:
        return delayed ? busDelayed : bus;
    }
  })();
  return (
    <Marker
      key={vehicle.code}
      position={[vehicle.lat, vehicle.lon]}
      icon={leaflet.icon({
        className: classes.root,
        iconUrl: routeIcon,
      })}
      zIndexOffset={100}
    >
      <Popup
        className={classes.popup}
        closeButton={false}
        maxWidth={400}
      >
        <Typography className={classes.popupHeader}>
          {t('map.vehicleCode')}
          {': '}
          <span className={classes.code}>
            {vehicle.code}
          </span>
        </Typography>
        <Typography className={classes.line}>
          {t('map.vehicleService')}
          {': '}
          {vehicle.service}
        </Typography>
        <Typography className={classes.line}>
          {t('map.tripCode')}
          {': '}
          {vehicle.pattern}
        </Typography>
        <Typography className={classes.line}>
          {t('map.speed')}
          {': '}
          {vehicle.speed}
          km/h
        </Typography>
        <Typography className={classes.line}>
          {t('map.delay')}
          {': '}
          {vehicle.delay >= 60 && `${Math.floor(vehicle.delay / 60)}${t('map.minutes')} `}
          {vehicle.delay % 60}
          {' '}
          {t('map.seconds')}
        </Typography>
        <Typography className={classNames(classes.line, classes.date)}>
          {moment(vehicle.received).format('DD.MM.YYYY, HH:mm')}
        </Typography>
      </Popup>
      <Tooltip className={classes.tooltip} opacity={1}>
        <Typography className={classes.tooltipText}>
          {t('map.vehicleCode')}
          {': '}
          <span className={classes.code}>
            {vehicle.code}
          </span>
        </Typography>
      </Tooltip>
    </Marker>
  );
}
