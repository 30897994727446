import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Nav from './Nav';
import { ReactComponent as BackArrow } from '../assets/back-arrow.svg';
import { ReactComponent as Eusbsr } from '../assets/eusbsr.svg';
import { ReactComponent as Interconnect } from '../assets/interconnect-logo.svg';
import { ReactComponent as Interreg } from '../assets/interreg.svg';
import Footer from './Footer';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '100%',
  },
  back: {
    margin: 8,
  },
  content: {
    margin: '0 32px',
  },
  row: {
    display: 'flex',
  },
  logo: {
    height: 60,
    margin: 16,
  },
  disclaimer: {
    fontSize: 12,
    color: '#37001F',
    marginTop: 16,
  },
});

export default function About() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root} data-testid="about-page">
      <Nav />
      <IconButton
        color="primary"
        onClick={() => window.history.back()}
        className={classes.back}
      >
        <BackArrow />
      </IconButton>
      <div className={classes.content}>
        <div className={classes.row}>
          <Eusbsr className={classes.logo} />
          <Interconnect className={classes.logo} />
        </div>
        <Interreg className={classes.logo} />
        <Typography className={classes.disclaimer}>
          {t('passenger.disclaimer')}
        </Typography>
      </div>
      <Footer />
    </div>
  );
}
