import React from 'react';
import { IPackageStatus } from '../interfaces';
import { ReactComponent as PackageOnSale } from '../assets/package-on-sale.svg';
import { ReactComponent as PackageOnSaleStarting } from '../assets/package-on-sale-starting.svg';
import { ReactComponent as PackageArchived } from '../assets/package-archived.svg';

interface Props {
  status: IPackageStatus;
  className?: string;
}

export default function PackageIcon({ status, className }: Props) {
  switch (status) {
    case 'ON_SALE':
      return <PackageOnSale className={className} />;
    case 'DRAFT':
      return <PackageOnSaleStarting className={className} />;
    case 'ARCHIVED':
      return <PackageArchived className={className} />;
    default:
      return null;
  }
}
