import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  IconButton, InputAdornment, TextField, Button,
} from '@material-ui/core';
import classNames from 'classnames';
import * as R from 'ramda';
import NewHeader from '../provider/NewHeader';
import { ReactComponent as Search } from '../assets/search.svg';
import { ReactComponent as ChevronPrimary } from '../assets/chevron-primary.svg';
import DeleteDialog from '../provider/DeleteDialog';
import { IProvider } from '../interfaces';
import { axios } from '../utils';
import CustomAutocomplete from '../common/CustomAutocomplete';

const useStyles = makeStyles({
  root: {
    marginTop: 24,
    display: 'flex',
  },
  search: {
    width: 400,
    marginTop: 16,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 24,
    backgroundColor: '#F5F5F5',
    border: '1px solid #37001F',
  },
  cell: {
    padding: '6px 12px',
    fontSize: 16,
    color: '#37001F',
    fontWeight: 300,
    border: '1px solid #37001F',
    borderWidth: '0 1px',
    '&:first-child, &:last-child': {
      textAlign: 'right',
      width: 130,
    },
  },
  headerCell: {
    fontWeight: 400,
    textAlign: 'left',
    border: '1px solid #37001F',
  },
  headerCellContent: {
    display: 'flex',
    alignItems: 'center',
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-child(odd)': {
      backgroundColor: 'white',
    },
  },
  sortButton: {
    marginRight: -4,
    marginLeft: 4,
  },
  chevronFlipped: {
    transform: 'rotate(180deg)',
  },
  left: {
    flexGrow: 1,
  },
  right: {
    width: 720,
    flexShrink: 0,
    marginLeft: 24,
  },
  fieldRow: {
    display: 'flex',
    marginBottom: 24,
  },
  beaconID: {
    width: 160,
    marginRight: 24,
  },
  provider: {
    width: 300,
    marginRight: 24,
  },
  vehicleID: {
    width: 210,
  },
  saveRow: {
    display: 'flex',
    alignItems: 'center',
  },
  discard: {
    marginLeft: 'auto',
    marginRight: 24,
  },
});

interface IMobileBeacon {
  id: number;
  vehicleOwner: string;
  vehicle: string;
}

export default function Mobile() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [beacons, setBeacons] = useState<IMobileBeacon[] | null>([]);
  const [search, setSearch] = useState('');
  const [sortColumn, setSortColumn] = useState<keyof IMobileBeacon>('id');
  const [sortAsc, setSortAsc] = useState(false);
  const [selectedBeacon, setSelectedBeacon] = useState<number | 'new' | null>(null);
  const [id, setId] = useState('');
  const [idError, setIdError] = useState(false);
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [vehicle, setVehicle] = useState<string | null>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [providers, setProviders] = useState<IProvider[] | null>();
  const [vehicles, setVehicles] = useState<string[]>([]);
  useEffect(() => {
    (async () => {
      const [
        { data },
        { data: beaconData },
      ] = await Promise.all([
        axios.get('/providers'),
        axios.get('/beacons?category=mobile'),
      ]);
      setProviders(data);
      setBeacons(beaconData);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (provider) {
        const { data } = await axios.get(`/${provider.id}/vehicles`);
        setVehicles(data.map(R.prop('number')));
      } else {
        setVehicles([]);
      }
    })();
  }, [provider]);
  if (!providers || !beacons) return null;
  const getProvider = (searchId: string) => providers.find(R.propEq('id', searchId)) as IProvider;
  const loadData = (beacon: IMobileBeacon | null) => {
    if (beacon) {
      setSelectedBeacon(beacon.id);
      setId(String(beacon.id));
      setProvider(getProvider(beacon.vehicleOwner));
      setVehicle(beacon.vehicle);
    } else {
      setSelectedBeacon('new');
      setId('');
      setProvider(null);
      setVehicle(null);
    }
    setIdError(false);
  };
  const columnNames = {
    id: 'beacon.beaconID',
    vehicleOwner: 'beacon.assignedTo',
    vehicle: 'beacon.vehicleID',
  };
  const tableData = beacons
    .filter((row) => Object.entries(row).some(([property, cell]) => (
      String(property === 'owner' ? getProvider(cell).name : cell)
        .toLowerCase().includes(search.toLowerCase())
    )))
    .sort((sortAsc ? R.ascend : R.descend)((x) => String(x[sortColumn])));
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <NewHeader
          headerText={t('beacon.mobileBeacons')}
          newText={t('beacon.newBeacon')}
          onClick={() => loadData(null)}
        />
        <TextField
          label={t('beacon.stationarySearch')}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          className={classes.search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
        />
        <table className={classes.table}>
          <thead>
            <tr>
              {Object.entries(columnNames).map(([property, columnName]) => (
                <th
                  key={property}
                  className={classNames(classes.cell, classes.headerCell)}
                >
                  <div className={classes.headerCellContent}>
                    {t(columnName)}
                    <IconButton
                      color="primary"
                      size="small"
                      className={classes.sortButton}
                      onClick={() => (
                        sortColumn === property
                          ? setSortAsc(!sortAsc)
                          : setSortColumn(property as keyof IMobileBeacon)
                      )}
                    >
                      <ChevronPrimary
                        className={(sortColumn === property && sortAsc) ? classes.chevronFlipped : ''}
                      />
                    </IconButton>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row) => (
              <tr
                className={classes.tableRow}
                key={row.id}
                onClick={() => loadData(row)}
              >
                {Object.keys(columnNames).map((property) => (
                  <td className={classes.cell} key={property}>
                    {property === 'vehicleOwner'
                      ? getProvider(row.vehicleOwner).name
                      : row[property as keyof IMobileBeacon]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.right}>
        {selectedBeacon !== null && (
          <>
            <div className={classes.fieldRow}>
              <TextField
                value={id}
                onChange={({ target }) => {
                  setId(target.value);
                  setIdError(false);
                }}
                label={t('beacon.beaconID')}
                variant="outlined"
                size="small"
                className={classes.beaconID}
                error={idError}
                helperText={idError ? t('beacon.idError', { from: 32768, to: 65536 }) : ''}
              />
              <CustomAutocomplete
                options={providers}
                value={provider}
                onChange={async (event, value) => {
                  setProvider(value);
                  setVehicle(null);
                }}
                label={t('beacon.provider')}
                getOptionLabel={R.prop('name')}
                className={classes.provider}
              />
              <CustomAutocomplete
                options={vehicles}
                value={vehicle}
                onChange={(event, value) => setVehicle(value)}
                label={t('beacon.vehicleID')}
                className={classes.vehicleID}
              />
            </div>
            <div className={classes.saveRow}>
              {selectedBeacon !== 'new' && (
                <Button
                  color="primary"
                  onClick={() => setDeleteDialog(true)}
                >
                  {t('beacon.deleteBeacon')}
                </Button>
              )}
              <Button
                color="primary"
                onClick={() => loadData(beacons.find(R.propEq('id', selectedBeacon)) || null)}
                className={classes.discard}
              >
                {t('common.discardChanges')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!id || !provider || !vehicle}
                onClick={async () => {
                  if (!vehicle) return;
                  const numberId = Number(id);
                  if (Number.isNaN(numberId) || numberId < 32768 || numberId > 65536) {
                    setIdError(true);
                    return;
                  }
                  const data = {
                    id: numberId,
                    vehicle,
                    vehicleOwner: provider!.id,
                  };
                  await axios.put('/beacon', data);
                  if (selectedBeacon === 'new') {
                    setBeacons([...beacons, data]);
                  } else {
                    setBeacons(beacons.map((x) => (x.id === selectedBeacon ? data : x)));
                  }
                  setSelectedBeacon(null);
                }}
              >
                {t('common.save')}
              </Button>
            </div>
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={t('beacon.deleteBeaconConfirm')}
        onDelete={async () => {
          await axios.delete('/beacon', { params: { id: selectedBeacon } });
          setDeleteDialog(false);
          setSelectedBeacon(null);
          setBeacons(beacons.filter((x) => x.id !== selectedBeacon));
        }}
        canDelete
      />
    </div>
  );
}
