import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { IStops, ITrip } from '../../interfaces';
import { ProviderContext } from '../../context';
import { getRouteData } from '../common';
import Trips from './Trips';

export default function TripsPrintView() {
  const [trips, setTrips] = useState<ITrip[] | null>(null);
  const [stops, setStops] = useState<IStops | null>(null);
  const location = useLocation();
  const {
    provider, date, route, direction,
  } = Object.fromEntries(new URLSearchParams(location.search).entries());
  const { version, user } = useContext(ProviderContext);
  useEffect(() => {
    (async () => {
      const providerVersion = (provider === user.agency) ? version : undefined;
      const data = await getRouteData(provider, moment(date, 'YYYY-MM-DD'), route, providerVersion);
      setTrips(data.trips);
      setStops(data.stops);
    })();
  }, [provider, date, route, user.agency, version]);
  if (!trips || !stops) return null;
  return (
    <Trips
      selectedTrips={[]}
      setSelectedTrips={() => {}}
      stops={stops}
      trips={trips}
      provider={provider}
      date={date}
      route={route}
      print
      direction={direction === '1' ? 1 : 0}
    />
  );
}
