import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  InputAdornment, TextField, Typography, Collapse,
} from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as Search } from '../../assets/search.svg';
import { axios } from '../../utils';

interface Entry {
  question: string;
  answer: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '32px 64px',
    '@media (max-width: 1200px)': {
      margin: '32px 16px',
    },
  },
  header: {
    fontSize: 22,
    fontWeight: 700,
    color: '#38001F',
    marginBottom: 32,
  },
  search: {
    width: '100%',
    maxWidth: 400,
    marginBottom: 24,
  },
  entry: {
    borderRadius: 15,
    backgroundColor: '#F5F5F5',
    marginBottom: 16,
    maxWidth: 700,
    transition: theme.transitions.create('max-width'),
  },
  expandedEntry: {
    maxWidth: '100%',
  },
  question: {
    padding: '8px 16px',
    fontSize: 18,
    fontWeight: 500,
    color: '#37001F',
    cursor: 'pointer',
    '& p': {
      margin: 0,
    },
  },
  answer: {
    width: 'calc(100vw - 128px)',
    padding: '0 16px 8px',
    fontSize: 18,
    fontWeight: 300,
    color: '#37001F',
    '@media (max-width: 1200px)': {
      width: 'calc(100vw - 32px)',
    },
    '& p': {
      margin: 0,
    },
  },
}));

export default function FAQ() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [entries, setEntries] = useState<Entry[] | null>(null);
  useEffect(() => {
    (async () => {
      const pathSegment = location.pathname.split('/')[2];
      const category = pathSegment === 'support' ? 'passenger' : pathSegment;
      const { data } = await axios.get(`/faq/${category}?lang=${i18n.language}`);
      setEntries(data);
    })();
  }, [i18n.language, location.pathname]);
  const [search, setSearch] = useState('');
  const [expandedEntry, setExpandedEntry] = useState<number | null>(null);
  if (!entries) return null;
  const filteredEntries = entries.filter((entry) => (
    entry.question.toLowerCase().includes(search.toLowerCase())
    || entry.answer.toLowerCase().includes(search.toLowerCase())
  ));
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {t('support.haveAQuestion')}
      </Typography>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        label={t('support.searchFaq')}
        value={search}
        onChange={({ target: { value } }) => {
          setSearch(value);
          setExpandedEntry(null);
        }}
        className={classes.search}
        variant="outlined"
        size="small"
        id="search-faq"
      />
      {filteredEntries.map((entry, i) => (
        <div
          key={entry.question}
          className={classNames(classes.entry, {
            [classes.expandedEntry]: expandedEntry === i,
          })}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div
            className={classes.question}
            onClick={() => setExpandedEntry(expandedEntry === i ? null : i)}
            dangerouslySetInnerHTML={{ __html: entry.question }}
          />
          <Collapse in={expandedEntry === i}>
            {/* eslint-disable-next-line react/no-danger */}
            <div
              className={classes.answer}
              dangerouslySetInnerHTML={{ __html: entry.answer }}
            />
          </Collapse>
        </div>
      ))}
    </div>
  );
}
