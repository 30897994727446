import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Nav from './Nav';
import Footer from './Footer';
import headerArrow from '../assets/header-arrow.svg';
import { useGetLink } from '../utils';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  header: {
    fontSize: 22,
    fontWeight: 700,
    color: '#38001F',
    margin: '64px 100px 32px',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      width: 22,
      height: 32,
      backgroundImage: `url('${headerArrow}')`,
      marginRight: 13,
      flexShrink: 0,
    },
    '@media (max-width: 800px)': {
      margin: '64px 16px 32px',
    },
  },
  content: {
    flexGrow: 1,
    padding: '16px 100px',
    background: '#F5F5F5',
    '& li': {
      margin: '4px 0',
    },
    '& > ol': {
      marginBottom: 24,
    },
    '& ol ol': {
      listStyleType: 'lower-alpha',
    },
    '@media (max-width: 800px)': {
      padding: 16,
    },
  },
  subheader: {
    fontSize: 18,
    fontWeight: 700,
    color: '#38001F',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      width: 22,
      height: 32,
      backgroundImage: `url('${headerArrow}')`,
      marginRight: 13,
    },
  },
  footerContainer: {
    marginTop: 'auto',
    background: '#F5F5F5',
  },
  terms2: {
    fontStyle: 'italic',
  },
  terms3: {
    fontWeight: 300,
  },
  term: {
    display: 'inline-block',
    width: 220,
    flexShrink: 0,
  },
  definitions: {
    paddingLeft: 0,
    '& li': {
      display: 'flex',
      '@media (max-width: 800px)': {
        flexWrap: 'wrap',
      },
    },
  },
});

export default function Terms() {
  const classes = useStyles();
  const { t } = useTranslation();
  const getLink = useGetLink();
  return (
    <div className={classes.root}>
      <Nav />
      <Typography className={classes.header}>
        <span>
          {t('terms.terms1')}
          <br />
          <span className={classes.terms2}>
            {t('terms.terms2')}
          </span>
          <br />
          <span className={classes.terms3}>
            {t('terms.terms3')}
          </span>
        </span>
      </Typography>
      <div className={classes.content}>
        <Typography className={classes.subheader}>
          {t('terms.header1')}
        </Typography>
        <ol>
          <li>
            <Trans
              i18nKey="terms.li1"
              components={[
                <i />,
                <Link href="https://opendata.info.pl/" />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="terms.li2"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="terms.li3"
              components={[
                <i />,
                <i />,
                <i />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="terms.li4"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="terms.li5"
              components={[
                <i />,
                <u />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="terms.li6"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            {t('terms.li7')}
          </li>
          <li>
            {t('terms.li8')}
          </li>
          <li>
            <Trans
              i18nKey="terms.li9"
              components={[
                <Link component={RouterLink} to={getLink('/privacy-policy')} />,
              ]}
            />
          </li>
          <li>
            {t('terms.li10')}
          </li>
          <li>
            <Trans
              i18nKey="terms.li11"
              components={[
                <i />,
                <i />,
                <i />,
              ]}
            />
          </li>
        </ol>
        <Typography className={classes.subheader}>
          {t('terms.header2')}
        </Typography>
        <ol>
          <li>
            <Trans
              i18nKey="terms.li12"
              components={[
                <i />,
                <i />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="terms.li13"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="terms.li14"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="terms.li15"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="terms.li16"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            {t('terms.li17')}
          </li>
          <li>
            {t('terms.li18')}
          </li>
        </ol>
        <Typography className={classes.subheader}>
          {t('terms.header3')}
        </Typography>
        <ol>
          <li>
            <Trans
              i18nKey="terms.li19"
              components={[
                <i />,
              ]}
            />
            <ul className={classes.definitions}>
              <li>
                <Trans
                  i18nKey="terms.li19li1"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="terms.li19li2"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
              <li>
                <i className={classes.term}>
                  {t('terms.li19li3t')}
                </i>
                <span>
                  <Trans
                    i18nKey="terms.li19li3d"
                    components={[
                      <Link component={RouterLink} to={getLink('/privacy-policy')} />,
                    ]}
                  />
                </span>
              </li>
              <li>
                <Trans
                  i18nKey="terms.li19li4"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="terms.li19li5"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="terms.li19li6"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="terms.li19li7"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="terms.li19li8"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="terms.li19li9"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="terms.li19li10"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="terms.li19li11"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="terms.li19li12"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="terms.li19li13"
                  components={[
                    <i className={classes.term} />,
                  ]}
                />
              </li>
            </ul>
          </li>
          <li>
            <Trans
              i18nKey="terms.li20"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            {t('terms.li21')}
          </li>
          <li>
            {t('terms.li22')}
          </li>
          <li>
            <Trans
              i18nKey="terms.li23"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            <Trans
              i18nKey="terms.li24"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            {t('terms.li25')}
          </li>
        </ol>
        <Typography className={classes.subheader}>
          {t('terms.header4')}
        </Typography>
        <ol>
          <li>
            <Trans
              i18nKey="terms.li26"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            {t('terms.li27')}
            <ol>
              <li>
                {t('terms.li27li1')}
              </li>
              <li>
                {t('terms.li27li2')}
              </li>
              <li>
                {t('terms.li27li3')}
              </li>
            </ol>
          </li>
          <li>
            {t('terms.li28')}
            <ol>
              <li>
                <Trans
                  i18nKey="terms.li28li1"
                  components={[
                    <Link href="http://www.ztm.gda.pl/otwarty_ztm" />,
                  ]}
                />
              </li>
              <li>
                {t('terms.li28li2')}
              </li>
              <li>
                <Trans
                  i18nKey="terms.li28li3"
                  components={[
                    <i />,
                  ]}
                />
              </li>
            </ol>
          </li>
          <li>
            {t('terms.li29')}
            <ol>
              <li>
                <Trans
                  i18nKey="terms.li29li1"
                  components={[
                    <Link href="http://otwartedane.gdynia.pl/pl/dataset?groups=transport" />,
                  ]}
                />
              </li>
              <li>
                {t('terms.li29li2')}
              </li>
              <li>
                {t('terms.li29li3')}
              </li>
            </ol>
          </li>
          <li>
            {t('terms.li30')}
            <ol>
              <li>
                {t('terms.li30li1')}
              </li>
              <li>
                <Trans
                  i18nKey="terms.li30li2"
                  components={[
                    <i />,
                  ]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="terms.li30li3"
                  components={[
                    <Link href="http://creativecommons.org/publicdomain/zero/1.0/" />,
                  ]}
                />
              </li>
            </ol>
          </li>
          <li>
            <Trans
              i18nKey="terms.li31"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            {t('terms.li32')}
          </li>
          <li>
            {t('terms.li33')}
          </li>
          <li>
            {t('terms.li34')}
          </li>
        </ol>
        <Typography className={classes.subheader}>
          {t('terms.header5')}
        </Typography>
        <ol>
          <li>
            <Trans
              i18nKey="terms.li35"
              components={[
                <i />,
              ]}
            />
          </li>
          <li>
            {t('terms.li36')}
          </li>
          <li>
            {t('terms.li37')}
          </li>
          <li>
            <Trans
              i18nKey="terms.li38"
              components={[
                <Link href="mailto:planer@opendata.info.pl" />,
              ]}
            />
          </li>
        </ol>
      </div>
      <div className={classes.footerContainer}>
        <Footer />
      </div>
    </div>
  );
}
