import React, { useEffect, useRef, useState } from 'react';
import {
  Map, Marker, ScaleControl, TileLayer,
} from 'react-leaflet';
import leaflet from 'leaflet';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import marker from '../assets/stop-marker.svg';
import NumberField from './NumberField';

export const coordsValid = (lat: string, lon: string) => (
  lat !== '' && Number(lat) >= -90 && Number(lat) <= 90
  && lon !== '' && Number(lon) >= -180 && Number(lon) <= 180
);

const useStyles = makeStyles({
  root: {},
  header: {
    fontWeight: 400,
    marginBottom: 8,
  },
  map: {
    width: '100%',
    height: 425,
    marginBottom: 24,
  },
  row: {
    display: 'flex',
    margin: '0 -12px 24px',
  },
  field: {
    width: '50%',
    margin: '0 12px',
    backgroundColor: 'white',
  },
});

interface Props {
  lat: string;
  setLat: (lat: string) => void;
  lon: string;
  setLon: (lon: string) => void;
  initialViewport?: {
    center: [number, number],
    zoom: number,
  };
  noHeader?: boolean;
}

export default function SelectLocation(props: Props) {
  const {
    lat, setLat, lon, setLon, initialViewport = null, noHeader,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const mapRef = useRef<any>();
  const [mapViewport, setMapViewport] = useState(initialViewport);
  useEffect(() => {
    if (mapRef.current && !mapViewport) {
      mapRef.current.leafletElement.fitBounds(
        new leaflet.LatLngBounds([
          [56.464660, 14.408800],
          [53.570577, 19.443904],
        ]),
      );
    }
  }, [mapViewport]);
  useEffect(() => {
    if (coordsValid(lat, lon)) {
      setMapViewport({
        center: [Number(lat), Number(lon)],
        zoom: 16,
      });
    }
  }, [lat, lon]);
  return (
    <div className={classes.root}>
      {!noHeader && (
        <Typography className={classes.header}>
          {t('common.location')}
        </Typography>
      )}
      <Map
        className={classes.map}
        minZoom={3}
        maxZoom={18}
        ref={mapRef}
        center={mapViewport?.center}
        zoom={mapViewport?.zoom}
        onViewportChanged={({ center, zoom }) => {
          if (center && zoom) {
            setMapViewport({ center, zoom });
          }
        }}
        onclick={({ latlng }) => {
          setLat(String(latlng.lat));
          setLon(String(latlng.lng));
        }}
        // @ts-ignore
        fullscreenControl
      >
        <ScaleControl position="topright" imperial={false} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {coordsValid(lat, lon) && (
          <Marker
            position={[Number(lat), Number(lon)]}
            icon={leaflet.icon({
              iconUrl: marker,
              iconSize: [15, 15],
            })}
          />
        )}
      </Map>
      <div className={classes.row}>
        <NumberField
          type="number"
          className={classes.field}
          label={t('common.latitude')}
          value={lat}
          onChange={({ target }) => setLat(target.value)}
          variant="outlined"
          size="small"
          id="lat"
          inputProps={{ step: 0.00005 }}
        />
        <NumberField
          type="number"
          className={classes.field}
          label={t('common.longitude')}
          value={lon}
          onChange={({ target }) => setLon(target.value)}
          variant="outlined"
          size="small"
          id="lon"
          inputProps={{ step: 0.00005 }}
        />
      </div>
    </div>
  );
}
