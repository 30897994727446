import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Nav from './Nav';
import Footer from './Footer';
import SupportContent from '../common/SupportContent/SupportContent';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  footerContainer: {
    marginTop: 'auto',
  },
});

export default function Support() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Nav />
      <SupportContent basePath="/support" />
      <div className={classes.footerContainer}>
        <Footer />
      </div>
    </div>
  );
}
