import React, { FormEvent, useState } from 'react';
import {
  Button, makeStyles, TextField, Typography,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { validate as isValidEmail } from 'email-validator';
import { auth0 } from '../utils';

const useStyles = makeStyles({
  header: {
    fontSize: 35,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 37,
  },
  email: {
    fontWeight: 400,
  },
  button: {
    margin: '16px 0',
  },
});

export default function RecoverPassword() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [linkSent, setLinkSent] = useState(false);
  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    const emailValid = isValidEmail(email);
    setEmailError(emailValid ? null : 'auth.invalidEmail');
    if (emailValid) {
      auth0.changePassword({
        connection: 'Username-Password-Authentication',
        email,
      }, () => {
        setLinkSent(true);
      });
    }
  };
  return (
    <>
      <Typography className={classes.header}>
        {t('auth.recoverPassword')}
      </Typography>
      <Typography>
        {t('auth.recoverText')}
      </Typography>
      <form
        onSubmit={onSubmit}
      >
        <TextField
          label={t('auth.email')}
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          value={email}
          onChange={({ target }) => {
            setEmail(target.value);
            setEmailError(null);
          }}
          error={emailError !== null}
          helperText={emailError ? t(emailError) : null}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          className={classes.button}
        >
          {t('auth.sendResetLink')}
        </Button>
      </form>
      {linkSent && (
        <Typography>
          <Trans
            i18nKey="auth.linkSent"
            components={[
              <span className={classes.email} />,
            ]}
            values={{ email }}
          />
        </Typography>
      )}
    </>
  );
}
