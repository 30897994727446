import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IItinerary } from '../../interfaces';
import { ReactComponent as ChevronBig } from '../../assets/chevron-big.svg';
import { ReactComponent as Tram } from '../../assets/tram.svg';
import { ReactComponent as Bus } from '../../assets/bus.svg';
import BuyTicketButton from './BuyTicketButton';
import { ReactComponent as Walk } from '../../assets/walk.svg';
import { ReactComponent as Train } from '../../assets/train.svg';

interface Props {
  itinerary: IItinerary;
  goBack: () => void;
}

const useStyles = makeStyles({
  root: {
    margin: 16,
    backgroundColor: 'white',
  },
  headerContainer: {
    padding: 16,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
  },
  backButton: {
    width: 43,
    marginLeft: -12,
  },
  chevronBack: {
    transform: 'rotate(180deg)',
  },
  header: {
    fontSize: 20,
    fontWeight: 400,
  },
  leg: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.29)',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
  },
  route: {
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 7,
    backgroundColor: '#F5F5F5',
    padding: '1px 6px',
    marginLeft: 4,
    marginRight: 8,
  },
  headsign: {
    fontSize: 12,
  },
  agency: {
    marginLeft: 24,
  },
});

export default function BuyTickets({ itinerary, goBack }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <IconButton
          onClick={goBack}
          className={classes.backButton}
        >
          <ChevronBig className={classes.chevronBack} />
        </IconButton>
        <Typography className={classes.header}>
          {t('planning.buyTicketsHeader')}
        </Typography>
      </div>
      {itinerary.legs.filter((x) => x.mode !== 'WALK').map((leg) => (
        <div className={classes.leg} key={leg.startTime}>
          <div>
            <div className={classes.top}>
              {leg.mode === 'WALK' && <Walk />}
              {leg.mode === 'RAIL' && <Train />}
              {leg.mode === 'TRAM' && <Tram />}
              {leg.mode === 'BUS' && <Bus />}
              <Typography className={classes.route}>
                {leg.route}
              </Typography>
              <Typography className={classes.headsign}>
                {leg.headsign}
              </Typography>
            </div>
            <Typography className={classes.agency}>
              {leg.agencyName}
            </Typography>
          </div>
          <BuyTicketButton agency={leg.agencyName} />
        </div>
      ))}
    </div>
  );
}
