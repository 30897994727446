import React, {
  ChangeEvent, useContext, useEffect, useRef, useState,
} from 'react';
import {
  makeStyles, Typography, IconButton, TextField, MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie, HorizontalBar } from 'react-chartjs-2';
import {
  Map, Marker, ScaleControl, TileLayer,
} from 'react-leaflet';
import leaflet from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import classNames from 'classnames';
import { axios } from '../utils';
import { IProvider, IStatistic } from '../interfaces';
import { ReactComponent as ChartPie } from '../assets/chart-pie.svg';
import { ReactComponent as ChartLine } from '../assets/chart-line.svg';
import { ReactComponent as Chevron } from '../assets/chevron.svg';
import StatisticsSearch from './StatisticsSearch';
import { ProviderContext } from '../context';
import VersionInfo from './VersionInfo';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 32,
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  header: {
    fontSize: 70,
    fontWeight: 700,
    color: '#37001F',
  },
  topData: {
    display: 'flex',
  },
  sources: {
    fontSize: 35,
    fontWeight: 700,
    color: '#37001F',
  },
  routes: {
    fontSize: 35,
    fontWeight: 700,
    marginLeft: 64,
    color: '#37001F',
  },
  topValue: {
    fontSize: 70,
  },
  middle: {
    margin: '0 -32px',
    padding: '0 32px',
    backgroundColor: '#F5F5F5',
    display: 'flex',
  },
  chartContainer: {
    width: '50%',
    paddingTop: 16,
  },
  chartOptions: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    width: 296,
    marginRight: 24,
  },
  selectIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 4px',
  },
  chart: {
    position: 'relative',
    margin: '32px 0',
  },
  map: {
    width: '50%',
    height: 490,
    marginLeft: 32,
  },
  markerIconContainer: {
    margin: '-50px 0 0 -50px !important',
    width: '100px !important',
    height: '100px !important',
  },
  markerIcon: {
    width: 100,
    height: 100,
    borderRadius: '50%',
    backgroundColor: 'rgba(200, 39, 139, 0.45)',
    fontSize: 35,
    fontWeight: 700,
    fontFamily: '"Roboto"',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

Chart.plugins.unregister(ChartDataLabels);

export default function Statistics() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { providers, version, user } = useContext(ProviderContext);
  const [data, setData] = useState<IStatistic[] | null>(null);
  useEffect(() => {
    (async () => {
      const { data: newData } = await axios.get('/statistics', {
        params: (user.agency && version) ? {
          providerName: user.agency,
          providerVersion: version.id,
        } : {},
      });
      setData(newData);
    })();
  }, [version, user.agency]);
  const [pieChart, setPieChart] = useState(true);
  const [dataType, setDataType] = useState<'routes' | 'stops'>('routes');
  const mapRef = useRef<any>();
  const [mapLoaded, setMapLoaded] = useState(false);
  useEffect(() => {
    if (providers && mapLoaded) {
      mapRef.current.leafletElement.fitBounds(
        new leaflet.LatLngBounds(providers.map(({ lat, lon }) => [lat, lon])),
        {
          padding: [32, 32],
        },
      );
    }
  }, [providers, mapLoaded]);
  // eslint-disable-next-line
  useEffect(() => {
    if (mapRef.current && !mapLoaded) {
      setMapLoaded(true);
    }
  });
  if (!data) return null;
  const sources = data.length;
  const providerCounts = R.compose(
    R.map((provider: IStatistic) => ({
      id: provider.id,
      routes: R.sum(provider.routes.map(R.prop('count'))),
      stops: provider.stops,
    })),
  )(data);
  const chartData = R.compose(
    ([firstNine, rest]) => (rest.length === 0 ? firstNine : [
      ...firstNine,
      {
        name: t('stats.other'),
        count: R.sum(rest.map(R.prop('count'))),
      },
    ]),
    (x: { name: string; count: number; }[]) => R.splitAt(9, x),
    R.sort(R.descend(R.prop('count'))),
    R.map(({ id, routes, stops }: { id: string; routes: number; stops: number; }) => ({
      name: providers.find(R.whereEq({ id }))!.name,
      count: dataType === 'routes' ? routes : stops,
    })),
  )(providerCounts);
  const routes = R.sum(providerCounts.map(R.prop('routes')));
  const ChartComponent = pieChart ? Pie : HorizontalBar;
  return (
    <div className={classes.root}>
      <VersionInfo />
      <div className={classes.top}>
        <Typography className={classes.header}>
          {t('stats.header')}
        </Typography>
        <div className={classes.topData}>
          <Typography className={classes.sources}>
            <span className={classes.topValue}>
              {sources}
              {' '}
            </span>
            {t('stats.sources', { count: sources })}
          </Typography>
          <Typography className={classes.routes}>
            <span className={classes.topValue}>
              {routes}
              {' '}
            </span>
            {t('stats.routes', { count: routes })}
          </Typography>
        </div>
      </div>
      <div className={classes.middle}>
        <div className={classes.chartContainer}>
          <div className={classes.chartOptions}>
            <TextField
              select
              label={t('stats.show')}
              value={dataType}
              onChange={(event: ChangeEvent<HTMLInputElement & { value: 'routes' | 'stops' }>) => (
                setDataType(event.target.value)
              )}
              variant="outlined"
              size="small"
              className={classes.select}
              SelectProps={{
                // eslint-disable-next-line react/prop-types
                IconComponent: ({ className, ...rest }) => (
                  <div {...rest} className={classNames(className, classes.selectIcon)}>
                    <Chevron />
                  </div>
                ),
              }}
            >
              <MenuItem value="routes">
                {t('stats.selectRoutes')}
              </MenuItem>
              <MenuItem value="stops">
                {t('stats.selectStops')}
              </MenuItem>
            </TextField>
            <IconButton
              onClick={() => setPieChart(true)}
            >
              <ChartPie />
            </IconButton>
            <IconButton
              onClick={() => setPieChart(false)}
            >
              <ChartLine />
            </IconButton>
          </div>
          <div className={classes.chart}>
            <ChartComponent
              height={362}
              plugins={pieChart ? [ChartDataLabels] : []}
              options={{
                maintainAspectRatio: false,
                tooltips: {
                  enabled: false,
                },
                scales: pieChart ? {} : {
                  xAxes: [{
                    gridLines: { display: false },
                    ticks: {
                      fontSize: 14,
                      fontFamily: '"Roboto"',
                      fontColor: '#37001F',
                      beginAtZero: true,
                    },
                  }],
                  yAxes: [{
                    gridLines: { display: false },
                    ticks: {
                      fontSize: 16,
                      fontFamily: '"Roboto"',
                      fontColor: '#37001F',
                    },
                  }],
                },
                plugins: {
                  datalabels: {
                    color: '#ffffff',
                    font: {
                      size: 16,
                      family: '"Roboto"',
                    },
                  },
                },
              }}
              legend={{
                position: 'right',
                display: pieChart,
                labels: {
                  fontSize: 16,
                  fontFamily: '"Roboto"',
                  fontColor: '#37001F',
                  padding: 16,
                  boxWidth: 34,
                },
              }}
              data={{
                labels: chartData.map(R.prop('name')),
                datasets: [{
                  data: chartData.map(R.prop('count')),
                  backgroundColor: [
                    '#217E9A', '#C41515', '#39428B', '#99319D', '#E06307',
                    '#EFD828', '#188D13', '#EB6DD3', '#EBB114', '#9CB436',
                  ],
                }],
              }}
            />
          </div>
        </div>
        <Map
          ref={mapRef}
          className={classes.map}
          minZoom={3}
          maxZoom={18}
          fullscreenControl
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ScaleControl position="topright" imperial={false} />
          <MarkerClusterGroup
            iconCreateFunction={(cluster) => {
              const count = R.sum(cluster.getAllChildMarkers().map((x) => {
                const { html } = x.options.icon!.options as { html: string };
                return Number(html.match(/>(\d+)</)![1]);
              }));
              return leaflet.divIcon({
                className: classes.markerIconContainer,
                html: `<div class="${classes.markerIcon}">${count}</div>`,
              });
            }}
          >
            {providerCounts.map((x) => {
              const count = dataType === 'routes' ? x.routes : x.stops;
              const { lat, lon } = providers.find(R.whereEq({ id: x.id })) as IProvider;
              return (
                <Marker
                  key={`${dataType}-${x.id}`}
                  position={[lat, lon]}
                  icon={leaflet.divIcon({
                    className: classes.markerIconContainer,
                    html: `<div class="${classes.markerIcon}">${count}</div>`,
                  })}
                />
              );
            })}
          </MarkerClusterGroup>
        </Map>
      </div>
      <StatisticsSearch providers={providers} data={data} />
    </div>
  );
}
