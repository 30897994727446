import React from 'react';
import { ReactComponent as Bus } from '../assets/bus.svg';
import { ReactComponent as Train } from '../assets/train.svg';
import { ReactComponent as Tram } from '../assets/tram.svg';
import { ReactComponent as Trolleybus } from '../assets/trolleybus.svg';
import { ReactComponent as Ferry } from '../assets/ferry.svg';

interface Props {
  type: number;
}

export default function VehicleIcon({ type }: Props) {
  switch (type) {
    case 0:
    case 900:
      return <Tram />;
    case 2:
    case 101:
    case 102:
    case 106:
    case 109:
      return <Train />;
    case 800:
      return <Trolleybus />;
    case 1200:
      return <Ferry />;
    case 3:
    case 700:
    case 702:
    default:
      return <Bus />;
  }
}
