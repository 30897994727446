import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import * as R from 'ramda';
import AppContext, { ProviderContext } from '../context';
import CustomAutocomplete from '../common/CustomAutocomplete';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
  },
  info: {
    marginBottom: 24,
  },
  dataSource: {
    width: 300,
  },
});

export default function DataSource() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { providers, user } = useContext(ProviderContext);
  const { updateContext } = useContext(AppContext);
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {t('nav.setDataSource')}
      </Typography>
      <Typography className={classes.info}>
        {t('dataSource.info')}
      </Typography>
      <CustomAutocomplete
        options={providers.filter((x) => x.dataSource === 'manual')}
        value={providers.find(R.propEq('id', user.agency)) || null}
        onChange={async (event, value) => {
          if (value) {
            localStorage.setItem('agency', value.id);
            localStorage.removeItem('version');
            updateContext(R.set(R.lensPath(['user', 'agency']), value.id));
          } else {
            localStorage.removeItem('agency');
            localStorage.removeItem('version');
            updateContext(R.set(R.lensPath(['user', 'agency']), null));
          }
        }}
        label={t('export.dataSource')}
        className={classes.dataSource}
        getOptionLabel={R.prop('name')}
      />
    </div>
  );
}
