import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useGetLink } from '../../utils';
import Routes from './Routes';
import Patterns from './Patterns';
import Stops from './Stops';
import Calendars from './Calendars';
import Trips from './Trips';
import Manage from './Manage';
import Crew from './crew/Crew';
import Reports from './Reports';
import Tickets from './Tickets';
import Timetables from './Timetables';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tabs: {
    margin: '0 -32px',
    backgroundColor: '#F5F5F5',
    paddingTop: 16,
  },
  tab: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 300,
    minWidth: 120,
  },
});

export default function Data() {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const getLink = useGetLink();
  const tab = location.pathname.split('/')[4];
  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={(event, value) => history.push(getLink(`/provider/data/${value}`))}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.tabs}
      >
        <Tab
          className={classes.tab}
          value="routes"
          label={t('data.routes')}
        />
        <Tab
          className={classes.tab}
          value="patterns"
          label={t('data.patterns')}
        />
        <Tab
          className={classes.tab}
          value="stops"
          label={t('data.stops')}
        />
        <Tab
          className={classes.tab}
          value="trips"
          label={t('data.trips')}
          data-testid="data-trips-tab"
        />
        <Tab
          className={classes.tab}
          value="calendars"
          label={t('data.calendars')}
        />
        <Tab
          className={classes.tab}
          value="timetables"
          label={t('data.timetables')}
        />
        <Tab
          className={classes.tab}
          value="crew"
          label={t('data.crew')}
        />
        <Tab
          className={classes.tab}
          value="reports"
          label={t('data.reports')}
        />
        <Tab
          className={classes.tab}
          value="tickets"
          label={t('data.tickets')}
        />
        <Tab
          className={classes.tab}
          value="manage"
          label={t('data.manage')}
        />
      </Tabs>
      {tab === 'routes' && <Routes />}
      {tab === 'patterns' && <Patterns />}
      {tab === 'stops' && <Stops />}
      {tab === 'trips' && <Trips />}
      {tab === 'calendars' && <Calendars />}
      {tab === 'timetables' && <Timetables />}
      {tab === 'crew' && <Crew />}
      {tab === 'reports' && <Reports />}
      {tab === 'tickets' && <Tickets />}
      {tab === 'manage' && <Manage />}
    </div>
  );
}
