import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Redirect, Route, Switch, useHistory, useParams,
} from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import { useGetLink } from '../utils';
import Stationary from './Stationary';
import Mobile from './Mobile';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tabs: {
    margin: '0 -32px',
    backgroundColor: '#F5F5F5',
    paddingTop: 16,
  },
  tab: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 300,
    width: 400,
  },
});

export default function Assignment() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const getLink = useGetLink();
  const { tab } = useParams<{ tab: string; }>();
  if (!tab) {
    return (
      <Redirect from="/:language/beacon/assignment" exact to={getLink('/beacon/assignment/stationary')} />
    );
  }
  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={(event, value) => history.push(getLink(`/beacon/assignment/${value}`))}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.tabs}
      >
        <Tab
          className={classes.tab}
          value="stationary"
          label={t('beacon.stationaryBeacons')}
        />
        <Tab
          className={classes.tab}
          value="mobile"
          label={t('beacon.mobileBeacons')}
        />
      </Tabs>
      <Switch>
        <Route path="/:language/beacon/assignment/stationary" component={Stationary} />
        <Route path="/:language/beacon/assignment/mobile" component={Mobile} />
      </Switch>
    </div>
  );
}
