import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Button, TextField, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import leaflet from 'leaflet';
import { Map, Marker } from 'react-leaflet';
import moment from 'moment';
import polyline from '@mapbox/polyline';
import { getDistance } from 'geolib';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import { IDataShape, IDataStop, IDataTrip } from '../../interfaces';
import VersionInfo from '../VersionInfo';
import { ProviderContext } from '../../context';
import { axios } from '../../utils';
import marker from '../../assets/stop-marker.svg';
import NewHeader from '../NewHeader';
import ButtonList from '../ButtonList';
import DeleteDialog from '../DeleteDialog';
import PathSelect from '../../common/PathSelect';

interface Props {
  shapes: IDataShape[];
  setShapes: (updateShapes: (shapes: IDataShape[]) => IDataShape[]) => void;
  selectedShape: number | null;
  chooseShape: (id: number | null) => void;
  start: IDataStop;
  end: IDataStop;
  goBack: () => void;
  trips: IDataTrip[];
  selectedTrip: number | 'new' | null;
}

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    display: 'flex',
    minHeight: 600,
  },
  left: {
    width: 500,
    flexShrink: 0,
    marginRight: 32,
  },
  goBack: {
    marginBottom: 16,
  },
  start: {
    marginBottom: 8,
    fontSize: 20,
  },
  end: {
    marginBottom: 16,
    fontSize: 20,
  },
  label: {
    fontWeight: 400,
  },
  name: {
    width: 400,
    margin: '32px 0',
  },
  row: {
    display: 'flex',
    marginBottom: 32,
  },
  fileInput: {
    display: 'none',
  },
  import: {
    marginRight: 16,
  },
  distance: {
    marginBottom: 16,
    fontSize: 20,
  },
  discardChanges: {
    marginLeft: 'auto',
    marginRight: 16,
  },
  right: {
    flexGrow: 1,
  },
  map: {
    height: '100%',
  },
});

export default function Shapes(props: Props) {
  const {
    shapes, setShapes, selectedShape: initSelectedShape, chooseShape, start, end, goBack, trips,
    selectedTrip,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: { agency }, version } = useContext(ProviderContext);
  const [selectedShape, setSelectedShape] = useState<number | 'new' | null>(null);
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState(false);
  const [points, setPoints] = useState<{ lat: number; lon: number; }[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const mapRef = useRef<Map>(null);
  useEffect(() => {
    if (mapRef.current) {
      const bounds = points.length > 0
        ? leaflet.polyline(points.map(({ lat, lon }) => [lat, lon])).getBounds()
        : new leaflet.LatLngBounds([
          [start.lat, start.lon],
          [end.lat, end.lon],
        ]);
      mapRef.current.leafletElement.fitBounds(bounds, { padding: [32, 32] });
    }
  }, [points, start, end]);
  const loadData = (shape: IDataShape | null) => {
    if (shape) {
      setName(shape.name);
      setPoints(shape.points);
    } else {
      setName('');
      setPoints([]);
    }
    setNameError(false);
  };
  const initShape = shapes.find(R.propEq('id', initSelectedShape));
  useEffect(() => {
    if (initSelectedShape) {
      setSelectedShape(initSelectedShape);
      loadData(initShape as IDataShape);
    }
  }, [initSelectedShape, initShape]);
  const distance = R.sum(points.map((x, i) => (
    i === points.length - 1 ? 0 : getDistance(x, points[i + 1])
  )));
  const readOnly = !version?.hasUserLock;
  const canDelete = typeof selectedShape !== 'number'
    || !trips.some((x) => (
      x.id !== selectedTrip && x.stops.some((y) => y.shape === selectedShape)
    ));
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <VersionInfo />
        <Button
          color="primary"
          onClick={goBack}
          className={classes.goBack}
        >
          {'< '}
          {t('data.back')}
        </Button>
        <Typography className={classes.start}>
          <span className={classes.label}>
            {t('data.start')}
            {': '}
          </span>
          {start.name}
        </Typography>
        <Typography className={classes.end}>
          <span className={classes.label}>
            {t('data.end')}
            {': '}
          </span>
          {end.name}
        </Typography>
        <NewHeader
          headerText={t('data.shapes')}
          newText={t('data.newShape')}
          onClick={() => {
            setSelectedShape('new');
            loadData(null);
          }}
          disabled={readOnly}
        />
        <ButtonList
          elements={shapes.map((shape) => ({
            id: shape.id,
            name: shape.name,
            onClick: () => {
              if (selectedShape === shape.id) {
                setSelectedShape(null);
                chooseShape(null);
                setPoints([]);
              } else {
                setSelectedShape(shape.id);
                chooseShape(shape.id);
                loadData(shape);
              }
            },
          }))}
          selected={selectedShape}
        />
        {selectedShape !== null && (
          <>
            <TextField
              value={name}
              onChange={({ target }) => {
                setName(target.value);
                setNameError(false);
              }}
              label={t('data.shapeName')}
              className={classes.name}
              variant="outlined"
              size="small"
              error={nameError}
              helperText={nameError ? t('data.shapeExists', { name }) : ''}
            />
            <div className={classes.row}>
              <input
                type="file"
                className={classes.fileInput}
                ref={inputRef}
                onChange={({ target }) => {
                  if (target.files && target.files[0]) {
                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                      if (reader.result) {
                        setPoints(reader.result.toString().split('\n').map((line) => {
                          const [lat, lon] = line.split(',').map(Number);
                          return { lat, lon };
                        }));
                      }
                    });
                    reader.readAsText(target.files[0]);
                  }
                }}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.import}
                onClick={() => inputRef.current && inputRef.current.click()}
              >
                {t('data.importShape')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const { data } = await axios.get('/public/plan-trip', {
                    params: {
                      fromLat: start.lat,
                      fromLon: start.lon,
                      toLat: end.lat,
                      toLon: end.lon,
                      dateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
                      arriveBy: false,
                      car: true,
                    },
                  });
                  setPoints(polyline.decode(data.plan.itineraries[0].legs[0].legGeometry.points)
                    .map(([lat, lon]) => ({ lat, lon })));
                }}
              >
                {t('data.findShape')}
              </Button>
            </div>
            <Typography className={classes.distance}>
              <span className={classes.label}>
                {t('data.distance')}
                {': '}
              </span>
              {distance ? `${distance} m` : '-'}
            </Typography>
            <div className={classes.row}>
              {selectedShape !== 'new' && (
                <Button
                  color="primary"
                  onClick={() => {
                    setDeleteDialog(true);
                    setDeleteName(shapes.find(R.propEq('id', selectedShape))!.name);
                  }}
                  disabled={readOnly}
                >
                  {t('data.deleteShape')}
                </Button>
              )}
              <Button
                color="primary"
                className={classes.discardChanges}
                onClick={() => {
                  loadData(
                    selectedShape === 'new'
                      ? null
                      : shapes.find((x) => x.id === selectedShape) || null,
                  );
                }}
              >
                {t('data.discardChanges')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!name.trim() || points.length === 0 || readOnly}
                onClick={async () => {
                  if (shapes.some((x) => x.name === name && x.id !== selectedShape)) {
                    setNameError(true);
                    return;
                  }
                  const newPoints = getDistance(points[0], end) < getDistance(points[0], start)
                    ? R.reverse(points)
                    : points;
                  const shapeData = {
                    from: start.id,
                    to: end.id,
                    name,
                    points: newPoints,
                  };
                  if (selectedShape === 'new') {
                    const { data } = await axios.post(`/${agency}/data/shape`, shapeData);
                    setShapes(R.append(data));
                    chooseShape(data.id);
                  } else {
                    await axios.put(`/${agency}/data/shape/${selectedShape}`, shapeData);
                    setShapes(R.map((x) => (x.id === selectedShape ? {
                      ...x,
                      ...shapeData,
                    } as IDataShape : x)));
                    chooseShape(selectedShape);
                  }
                  goBack();
                }}
              >
                {t('data.save')}
              </Button>
            </div>
          </>
        )}
      </div>
      <div className={classes.right}>
        <PathSelect
          points={points}
          setPoints={setPoints}
          enabled={selectedShape !== null}
          ref={mapRef}
          className={classes.map}
        >
          <Marker
            position={[start.lat, start.lon]}
            icon={leaflet.icon({
              iconUrl: marker,
              iconSize: [15, 15],
            })}
          />
          <Marker
            position={[end.lat, end.lon]}
            icon={leaflet.icon({
              iconUrl: marker,
              iconSize: [15, 15],
            })}
          />
        </PathSelect>
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={canDelete ? t(
          'data.deleteShapeConfirm',
          { name: deleteName },
        ) : t('data.shapeDeleteError')}
        onDelete={async () => {
          await axios.delete(`/${agency}/data/shape/${selectedShape}`);
          chooseShape(null);
          setSelectedShape(null);
          setShapes(R.filter<IDataShape>((x) => x.id !== selectedShape));
          setDeleteDialog(false);
          setPoints([]);
        }}
        canDelete={canDelete}
      />
    </div>
  );
}
