import React, { Fragment } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import * as R from 'ramda';
import classNames from 'classnames';
import { IDepartureTime, IRoute } from '../../interfaces';

interface Props {
  times: IDepartureTime[];
  from: number;
  to: number;
  routes: IRoute[];
}

const useStyles = makeStyles({
  table: {
    borderCollapse: 'collapse',
    marginTop: 60,
    marginLeft: 20,
  },
  cell: {
    border: '1px solid #adadad',
    width: 22,
    height: 22,
    fontSize: 12,
    fontWeight: 400,
    color: '#37001F',
    position: 'relative',
    '&:first-child': {
      borderLeft: 'none',
      width: 14,
    },
    '&:last-child': {
      borderRight: 'none',
      width: 14,
    },
    '&:nth-child(10n + 1)': {
      borderRightWidth: 2,
    },
  },
  row: {
    '&:last-child $cell': {
      borderBottom: 'none',
    },
  },
  hourLabel: {
    position: 'absolute',
    left: -18,
    top: -1,
    transform: 'translateY(-50%)',
  },
  headerCell: {
    borderTop: 0,
    borderBottomWidth: 2,
    height: 8,
    '&:nth-child(10n + 2)::before': {
      content: '""',
      position: 'absolute',
      left: -2,
      top: -32,
      height: 40,
      width: 2,
      backgroundColor: '#adadad',
    },
  },
  minute: {
    position: 'absolute',
    left: -1,
    bottom: 6,
    transform: 'translateX(-50%)',
  },
  minuteTens: {
    position: 'absolute',
    left: 3,
    top: -35,
    fontSize: 16,
  },
  departure: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  route: {
    position: 'absolute',
    left: 0,
    bottom: 1,
    transform: 'translateX(-50%)',
  },
  threeDots: {
    fontSize: 28,
    lineHeight: '36px',
  },
  rhombuses: {
    position: 'absolute',
    bottom: -4,
    display: 'flex',
  },
  rhombus: {
    position: 'relative',
    marginRight: -4,
    width: 8,
    height: 8,
    border: '1px solid #707070',
    transform: 'rotate(45deg)',
  },
  tooltipAnchor: {
    position: 'absolute',
    left: -10,
    bottom: -5,
    width: 20,
    height: 20,
    zIndex: 1,
  },
  tooltip: {
    backgroundColor: '#A1145C',
    marginBottom: 0,
    fontSize: 12,
  },
  color0: {
    backgroundColor: '#81E03C',
  },
  color1: {
    backgroundColor: '#E3C02F',
  },
  color2: {
    backgroundColor: '#3187D0',
  },
  color3: {
    backgroundColor: '#068B62',
  },
  color4: {
    backgroundColor: '#DE8307',
  },
  color5: {
    backgroundColor: '#FA0000',
  },
  color6: {
    backgroundColor: '#CAD0C6',
  },
  color7: {
    backgroundColor: '#3CE0AE',
  },
  color8: {
    backgroundColor: '#D031BE',
  },
});

export default function SynchronizationTable({
  times, from, to, routes,
}: Props) {
  const classes = useStyles();
  const tableRoutes = R.uniq(times.map(R.prop('route')));
  if (from > to) return null;
  return (
    <table className={classes.table}>
      <thead>
        <tr>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th className={classNames(classes.cell, classes.headerCell)} />
          {R.times(
            (n) => (
              <Fragment key={n}>
                <th className={classNames(classes.cell, classes.headerCell)}>
                  <div className={classes.minuteTens}>
                    {n}
                  </div>
                </th>
                {R.times(
                  (m) => (
                    <th key={m} className={classNames(classes.cell, classes.headerCell)}>
                      <div className={classes.minute}>
                        {m + 1}
                      </div>
                    </th>
                  ),
                  9,
                )}
              </Fragment>
            ),
            6,
          )}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th className={classNames(classes.cell, classes.headerCell)} />
        </tr>
      </thead>
      <tbody>
        {R.times(
          (n) => (
            <tr key={n} className={classes.row}>
              <th className={classes.cell}>
                <div className={classes.hourLabel}>
                  {n !== 0 && String(from + n - 1).padStart(2, '0')}
                </div>
              </th>
              {R.times(
                (m) => {
                  const cellDepartures = times.filter(
                    (x) => (
                      x.departure === `${String(from + n).padStart(2, '0')}:${String(m).padStart(2, '0')}`
                    ),
                  ).map((x) => ({
                    name: routes.find(R.whereEq({ id: x.route }))!.name,
                    id: x.route,
                  }));
                  return (
                    <td key={m} className={classes.cell}>
                      {cellDepartures.length > 0 && (
                        <>
                          <div className={classes.route}>
                            {cellDepartures.length === 1
                              ? cellDepartures[0].name
                              : <span className={classes.threeDots}>…</span>}
                          </div>
                          <div
                            className={classes.rhombuses}
                            style={{ left: -4 - (2 * (cellDepartures.length - 1)) }}
                          >
                            {cellDepartures.map((departure) => (
                              <div
                                className={classNames(
                                  classes.rhombus,
                                  classes[`color${tableRoutes.indexOf(departure.id) % 9}` as keyof typeof classes],
                                )}
                              />
                            ))}
                          </div>
                          {cellDepartures.length > 1 && (
                            <Tooltip
                              placement="top"
                              title={cellDepartures.map(R.prop('name')).join(', ')}
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <div className={classes.tooltipAnchor} />
                            </Tooltip>
                          )}
                        </>
                      )}
                    </td>
                  );
                },
                60,
              )}
              <td className={classes.cell} />
            </tr>
          ),
          to - from + 2,
        )}
      </tbody>
    </table>
  );
}
