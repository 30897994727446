import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Typography, TextField, Button, Dialog, InputAdornment, IconButton,
} from '@material-ui/core';
import moment from 'moment';
import * as R from 'ramda';
import classNames from 'classnames';
import { axios } from '../../utils';
import { ProviderContext } from '../../context';
import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as ChevronPrimary } from '../../assets/chevron-primary.svg';
import { ReactComponent as ChevronWhite } from '../../assets/chevron-white.svg';
import { ReactComponent as DirectionArrow } from '../../assets/direction-arrow.svg';

interface IRelation {
  from: string;
  to: string;
  distanceKm: number;
}

interface ITicket {
  number: string;
  owner: string;
  code: string;
  name: string;
  scope: 'course' | 'relation';
  discount?: string;
  valid: {
    from: string;
    to: string;
  };
  relations: {
    main: string;
    forwards: IRelation[];
    backwards: IRelation[];
  };
}

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    display: 'flex',
  },
  left: {
    flexGrow: 1,
  },
  right: {
    width: 700,
    flexShrink: 0,
    marginLeft: 80,
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 16,
  },
  report: {
    marginBottom: 32,
  },
  importRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  discardChanges: {
    marginRight: 16,
  },
  closeButton: {
    marginLeft: 'auto',
  },
  dialog: {
    padding: '24px 32px',
  },
  importedTickets: {
    color: '#37001F',
    marginBottom: 80,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 24,
  },
  cell: {
    padding: '6px 12px',
    fontSize: 16,
    color: '#37001F',
    fontWeight: 300,
    border: '1px solid #37001F',
    textAlign: 'left',
    position: 'relative',
  },
  headerCell: {
    fontWeight: 400,
    padding: '10px 12px',
  },
  headerCellContent: {
    display: 'flex',
    alignItems: 'center',
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#F5F5F5',
    },
  },
  sortButton: {
    marginRight: -4,
    marginLeft: 4,
  },
  chevronFlipped: {
    transform: 'rotate(180deg)',
  },
  pageButton: {
    minWidth: 20,
    width: 20,
    height: 20,
    borderRadius: '50%',
    padding: 0,
    position: 'absolute',
    zIndex: 2,
    '&$pageButtonDisabled': {
      backgroundColor: '#b9b9b9',
    },
  },
  nextButton: {
    right: 0,
    bottom: 0,
    transform: 'translate(50%, 50%)',
  },
  prevButton: {
    right: 0,
    top: 0,
    transform: 'translate(50%, -50%)',
  },
  pageButtonDisabled: {},
  chevronLeft: {
    transform: 'rotate(180deg)',
  },
  chevronRight: {
    transform: 'rotate(0deg)',
  },
  dialogText: {
    color: '#37001F',
  },
  bold: {
    fontWeight: 700,
  },
  noTickets: {
    marginTop: 24,
  },
  details: {
    marginBottom: 32,
  },
  relation: {
    display: 'flex',
    alignItems: 'center',
  },
  relationName: {
    fontWeight: 400,
    marginLeft: 8,
    marginRight: 'auto',
  },
  relationDistance: {
    fontWeight: 400,
    marginLeft: 24,
  },
  relationMargin: {
    marginLeft: 24,
  },
  backwards: {
    transform: 'rotate(180deg)',
  },
  relationMain: {
    marginTop: 8,
  },
});

const formatDate = (date: string) => moment(date).format('DD.MM.Y');

export default function Tickets() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: { agency } } = useContext(ProviderContext);
  const [report, setReport] = useState('');
  const [result, setResult] = useState<{ success: boolean; total: boolean; } | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tickets, setTickets] = useState<ITicket[] | null>(null);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/${agency}/tickets`);
      setTickets(data);
    })();
  }, [agency]);
  const [search, setSearch] = useState('');
  const [sortColumn, setSortColumn] = useState('passengerName');
  const [sortAsc, setSortAsc] = useState(false);
  const [page, setPage] = useState(0);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [details, setDetails] = useState<ITicket | null>(null);
  if (!tickets) return null;
  const pageSize = 20;
  const fullTableData = tickets.map((ticket) => ({
    passengerName: ticket.owner,
    ticketNumber: ticket.number,
    valid: `${formatDate(ticket.valid.from)} - ${formatDate(ticket.valid.to)}`,
  }))
    .filter((ticket) => Object.values(ticket).some((x) => (
      x.toLowerCase().includes(search.toLowerCase())
    )))
    .sort((sortAsc ? R.ascend : R.descend)(R.prop(sortColumn)));
  const tableData = fullTableData.slice(pageSize * page, pageSize * (page + 1));
  const columns = tableData[0] ? Object.keys(tableData[0]) : null;
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Typography className={classes.header}>
          {t('data.importTickets')}
        </Typography>
        <TextField
          value={report}
          onChange={({ target }) => setReport(target.value)}
          label={t('data.pasteReport')}
          multiline
          rows={20}
          variant="outlined"
          size="small"
          fullWidth
          className={classes.report}
        />
        <div className={classes.importRow}>
          <Button
            color="primary"
            onClick={() => setReport('')}
            className={classes.discardChanges}
          >
            {t('common.discardChanges')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!report}
            onClick={async () => {
              const { data } = await axios.put(`/${agency}/tickets`, report, {
                headers: {
                  'Content-Type': 'text/plain',
                },
              });
              setReport('');
              setResult(data);
              setDialogOpen(true);
            }}
          >
            {t('data.import')}
          </Button>
        </div>
      </div>
      <div className={classes.right}>
        <TextField
          label={t('data.ticketSearch')}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
          fullWidth
          id="search"
        />
        {columns ? (
          <table className={classes.table}>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th
                    key={column}
                    className={classNames(classes.cell, classes.headerCell)}
                  >
                    <div className={classes.headerCellContent}>
                      {t(`data.${column}`)}
                      <IconButton
                        color="primary"
                        size="small"
                        className={classes.sortButton}
                        onClick={() => (
                          sortColumn === column ? setSortAsc(!sortAsc) : setSortColumn(column)
                        )}
                      >
                        <ChevronPrimary
                          className={(sortColumn === column && sortAsc) ? classes.chevronFlipped : ''}
                        />
                      </IconButton>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, i) => (
                <tr
                  className={classes.tableRow}
                  key={row.ticketNumber}
                  onClick={() => {
                    setDetails(tickets.find(R.propEq('number', row.ticketNumber)) as ITicket);
                    setDetailsOpen(true);
                  }}
                >
                  {Object.entries(row).map(([key, cell], j) => (
                    <td className={classes.cell} key={key}>
                      {cell}
                      {i === 0 && j === columns.length - 1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            setPage((x) => x - 1);
                          }}
                          disabled={page === 0}
                          classes={{
                            contained: classNames(classes.pageButton, classes.prevButton),
                            disabled: classes.pageButtonDisabled,
                          }}
                        >
                          <ChevronWhite className={classes.chevronLeft} />
                        </Button>
                      )}
                      {i === tableData.length - 1 && j === columns.length - 1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            setPage(R.add(1));
                          }}
                          disabled={(
                            (page + 1) * pageSize >= fullTableData.length
                          )}
                          classes={{
                            contained: classNames(classes.pageButton, classes.nextButton),
                            disabled: classes.pageButtonDisabled,
                          }}
                        >
                          <ChevronWhite className={classes.chevronRight} />
                        </Button>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Typography className={classes.noTickets}>
            {t('data.noTickets')}
          </Typography>
        )}
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        PaperProps={{ className: classes.dialog }}
      >
        <Typography className={classes.importedTickets}>
          {result && t('data.importedTickets', result)}
        </Typography>
        <Button
          color="primary"
          onClick={() => setDialogOpen(false)}
          className={classes.closeButton}
        >
          {t('data.close')}
        </Button>
      </Dialog>
      <Dialog
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        PaperProps={{ className: classes.dialog }}
      >
        {details && (
          <div className={classes.details}>
            <Typography className={classes.dialogText}>
              {`${details.name} ${t('data.ticketNo')}: ${details.number}`}
            </Typography>
            <Typography className={classes.dialogText}>
              {`${t('data.cipher')}: ${details.code}`}
            </Typography>
            {details.discount && (
              <Typography className={classes.dialogText}>
                {details.discount}
              </Typography>
            )}
            <Typography className={classes.dialogText}>
              {`${t('data.valid')} `}
              <span className={classes.bold}>
                {`${t('data.ticketFrom')} ${formatDate(details.valid.from)} `}
                {`${t('data.ticketTo')} ${formatDate(details.valid.to)}`}
              </span>
            </Typography>
            <Typography className={classes.dialogText}>
              {`${t('data.passenger')}: `}
              <span className={classes.bold}>
                {details.owner}
              </span>
            </Typography>
            <Typography className={classes.dialogText}>
              {`${t('data.relation')}: `}
              <span className={classes.bold}>
                {details.relations.main}
              </span>
            </Typography>
            {details.relations.forwards.map((relation, i) => (
              <div
                className={classNames(classes.relation, {
                  [classes.relationMain]: i === 0,
                })}
                key={relation.from + relation.to}
              >
                {i === 0 && <DirectionArrow />}
                <Typography
                  className={classNames(classes.relationName, {
                    [classes.relationMargin]: i !== 0,
                  })}
                >
                  {`${relation.from} - ${relation.to}`}
                </Typography>
                <Typography className={classes.relationDistance}>
                  {`${relation.distanceKm}km`}
                </Typography>
              </div>
            ))}
            {details.relations.backwards.map((relation, i) => (
              <div
                className={classNames(classes.relation, {
                  [classes.relationMain]: i === 0,
                })}
                key={relation.from + relation.to}
              >
                {i === 0 && <DirectionArrow className={classes.backwards} />}
                <Typography
                  className={classNames(classes.relationName, {
                    [classes.relationMargin]: i !== 0,
                  })}
                >
                  {`${relation.from} - ${relation.to}`}
                </Typography>
                <Typography className={classes.relationDistance}>
                  {`${relation.distanceKm}km`}
                </Typography>
              </div>
            ))}
          </div>
        )}
        <Button
          color="primary"
          onClick={() => setDetailsOpen(false)}
          className={classes.closeButton}
        >
          {t('data.close')}
        </Button>
      </Dialog>
    </div>
  );
}
