import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ICard, IAvailableCard } from './Card';
import AztecCode from '../common/AztecCode';

interface Props {
  card: ICard | IAvailableCard;
  onClick: () => void;
  labels?: boolean;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  index: number;
}

const useStyles = makeStyles({
  card: {
    width: 328,
    height: 211,
    borderRadius: 18,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    position: 'relative',
    userSelect: 'none',
    margin: '-170px auto 0',
    '&:first-child': {
      marginTop: 0,
    },
  },
  cardLabel: {
    marginLeft: 'auto',
    padding: '2px 16px',
    color: 'white',
    borderRadius: 18,
  },
  expired: {
    backgroundColor: '#C41515',
  },
  get: {
    backgroundColor: '#188D15',
  },
  cardTextRow: {
    position: 'absolute',
    top: 147,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 8px',
  },
  cardText: {
    fontSize: 11,
    fontWeight: 700,
  },
  cardAztec: {
    position: 'absolute',
    top: 65,
    left: 243,
  },
});

const splitCardNumber = (id: string) => id.match(/.{4}/g)?.join(' ');

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export default function DraggableCard(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    card, onClick, labels, moveCard, index,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const id = 'id' in card ? card.id : card.packageName;
  const [, drop] = useDrop({
    accept: 'card',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY - 85) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      ref={ref}
      className={classes.card}
      style={{
        backgroundImage: `url("${card.image}")`,
        opacity,
      }}
      onClick={onClick}
    >
      {labels && 'packages' in card && card.packages[0].expired && (
        <Typography
          className={classNames(classes.cardLabel, classes.expired)}
        >
          {t('touristCard.expired')}
        </Typography>
      )}
      {labels && !('id' in card) && (
        <Typography
          className={classNames(classes.cardLabel, classes.get)}
        >
          {t('touristCard.get')}
        </Typography>
      )}
      {'id' in card && (
        <>
          <div className={classes.cardTextRow}>
            <Typography className={classes.cardText}>
              {card.holder}
            </Typography>
            <Typography className={classes.cardText}>
              {splitCardNumber(card.id)}
            </Typography>
          </div>
          <AztecCode
            text={card.id}
            scale={2}
            className={classes.cardAztec}
          />
        </>
      )}
    </div>
  );
}
