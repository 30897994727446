import React, {
  useContext, useEffect, useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Button, Checkbox, FormControlLabel, InputAdornment, TextField, Typography,
} from '@material-ui/core';
import * as R from 'ramda';
import { ProviderContext } from '../../context';
import {
  IDataPattern, IDataRoute, IDataStop, IProvider,
} from '../../interfaces';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import { axios } from '../../utils';
import timeZones from './time-zones';
import { ReactComponent as Search } from '../../assets/search.svg';
import VersionInfo from '../VersionInfo';
import NewHeader from '../NewHeader';
import ButtonList from '../ButtonList';
import SelectLocation, { coordsValid } from '../../common/SelectLocation';
import DeleteDialog from '../DeleteDialog';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    display: 'flex',
  },
  left: {
    flexGrow: 1,
  },
  filtersRow: {
    display: 'flex',
    marginBottom: 16,
  },
  forRoute: {
    width: 184,
    marginRight: 16,
  },
  forPattern: {
    width: 300,
  },
  searchIcon: {
    margin: '0 4px 0 8px',
  },
  search: {
    width: 500,
    marginBottom: 24,
  },
  right: {
    width: 620,
    flexShrink: 0,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  stopName: {
    width: 300,
  },
  row: {
    display: 'flex',
    marginBottom: 24,
  },
  timeZone: {
    width: 300,
    marginBottom: 24,
  },
  accessibilityHeader: {
    fontWeight: 400,
    marginBottom: 8,
  },
  formControlLabel: {
    marginRight: 24,
  },
  saveContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  discard: {
    marginRight: 16,
    marginLeft: 'auto',
  },
});

export default function Stops() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: { agency }, providers, version } = useContext(ProviderContext);
  const {
    timeZone: agencyTimeZone,
    lat: agencyLat,
    lon: agencyLon,
  } = providers.find(R.propEq('id', agency)) as IProvider;
  const [stops, setStops] = useState<IDataStop[]>([]);
  const [routes, setRoutes] = useState<IDataRoute[]>([]);
  const [patterns, setPatterns] = useState<IDataPattern[]>([]);
  const [route, setRoute] = useState<IDataRoute | null>(null);
  const [pattern, setPattern] = useState<IDataPattern | null>(null);
  const [search, setSearch] = useState('');
  const [selectedStop, setSelectedStop] = useState<number | 'new' | null>(null);
  const [stopName, setStopName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');
  const [timeZone, setTimeZone] = useState(agencyTimeZone);
  const [accessible, setAccessible] = useState(false);
  const [elevator, setElevator] = useState(false);
  const [escalator, setEscalator] = useState(false);
  const [technical, setTechnical] = useState(false);
  useEffect(() => {
    (async () => {
      const [
        { data },
        { data: routesData },
        { data: patternsData },
      ] = await Promise.all([
        axios.get(`/${agency}/data/stops`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/routes`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/patterns`, { params: { providerVersion: version!.id } }),
      ]);
      setStops(data);
      setRoutes(routesData);
      setPatterns(patternsData);
    })();
  }, [agency, version]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const loadData = (stop: IDataStop | null) => {
    if (stop) {
      setStopName(stop.name);
      setLat(String(stop.lat));
      setLon(String(stop.lon));
      setTimeZone(stop.timeZone);
      setAccessible(stop.accessible);
      setElevator(stop.elevator);
      setEscalator(stop.escalator);
      setTechnical(stop.technical);
    } else {
      setStopName('');
      setLat('');
      setLon('');
      setTimeZone(agencyTimeZone);
      setAccessible(false);
      setElevator(false);
      setEscalator(false);
      setTechnical(false);
    }
    setNameError(false);
  };
  const readOnly = !version?.hasUserLock;
  const canDelete = typeof selectedStop !== 'number'
    || !patterns.some((x) => x.stops.includes(selectedStop));
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <VersionInfo />
        <NewHeader
          headerText={t('data.stops')}
          newText={t('data.newStop')}
          onClick={() => {
            setSelectedStop('new');
            loadData(null);
          }}
          disabled={readOnly}
        />
        <div className={classes.filtersRow}>
          <CustomAutocomplete
            options={routes}
            value={route}
            onChange={(event, value) => {
              setRoute(value);
              setPattern(null);
            }}
            label={t('data.forRoute')}
            className={classes.forRoute}
            getOptionLabel={R.prop('name')}
          />
          <CustomAutocomplete
            options={patterns.filter(R.propEq('route', route?.id))}
            value={pattern}
            onChange={(event, value) => setPattern(value)}
            label={t('data.forPattern')}
            className={classes.forPattern}
            getOptionLabel={R.prop('name')}
          />
        </div>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          label={t('data.stopName')}
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
          className={classes.search}
          variant="outlined"
          size="small"
        />
        <ButtonList
          elements={(
            stops.filter((stop) => (
              (!route || patterns
                .filter(R.propEq('route', route.id))
                .some((x) => x.stops.includes(stop.id)))
              && (!pattern || pattern.stops.includes(stop.id))
              && stop.name.toLowerCase().includes(search.toLowerCase())
            )).map((stop) => ({
              id: stop.id,
              name: stop.name,
              onClick: () => {
                setSelectedStop(stop.id);
                loadData(stop);
              },
            }))
          )}
          selected={selectedStop}
        />
      </div>
      <div className={classes.right}>
        {selectedStop && (
          <>
            <div className={classes.nameContainer}>
              <TextField
                className={classes.stopName}
                label={t('data.stopName')}
                value={stopName}
                onChange={({ target }) => {
                  setStopName(target.value);
                  setNameError(false);
                }}
                variant="outlined"
                size="small"
                error={nameError}
                helperText={nameError ? t('data.stopExists', { name: stopName }) : ''}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={!technical}
                    onChange={({ target }) => setTechnical(!target.checked)}
                    disabled={!canDelete}
                  />
                )}
                label={t('data.availableToPassengers')}
              />
            </div>
            <SelectLocation
              lat={lat}
              setLat={setLat}
              lon={lon}
              setLon={setLon}
              initialViewport={{
                center: [agencyLat, agencyLon],
                zoom: 13,
              }}
            />
            <CustomAutocomplete
              options={timeZones}
              value={timeZone}
              onChange={(event, value) => value && setTimeZone(value)}
              className={classes.timeZone}
              label={t('data.timeZone')}
            />
            <Typography className={classes.accessibilityHeader}>
              {t('data.accessibility')}
            </Typography>
            <div className={classes.row}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={accessible}
                    onChange={({ target }) => setAccessible(target.checked)}
                    color="primary"
                  />
                )}
                label={t('data.accessible')}
                className={classes.formControlLabel}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={elevator}
                    onChange={({ target }) => setElevator(target.checked)}
                    color="primary"
                  />
                )}
                label={t('data.elevator')}
                className={classes.formControlLabel}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={escalator}
                    onChange={({ target }) => setEscalator(target.checked)}
                    color="primary"
                  />
                )}
                label={t('data.escalator')}
                className={classes.formControlLabel}
              />
            </div>
            <div className={classes.saveContainer}>
              {typeof selectedStop === 'number' && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    setDeleteDialog(true);
                    setDeleteName(stops.find(R.propEq('id', selectedStop))!.name);
                  }}
                  disabled={readOnly}
                >
                  {t('data.deleteStop')}
                </Button>
              )}
              <Button
                variant="text"
                color="primary"
                className={classes.discard}
                onClick={() => {
                  loadData(
                    typeof selectedStop === 'number'
                      ? stops.find(R.propEq('id', selectedStop)) || null
                      : null,
                  );
                }}
              >
                {t('data.discardChanges')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={readOnly || !stopName || !coordsValid(lat, lon)}
                onClick={async () => {
                  if (stops.some((x) => x.name === stopName && x.id !== selectedStop)) {
                    setNameError(true);
                    return;
                  }
                  const stopData = {
                    name: stopName,
                    lat: Number(lat),
                    lon: Number(lon),
                    timeZone,
                    accessible,
                    elevator,
                    escalator,
                    technical,
                  };
                  if (selectedStop === 'new') {
                    const { data } = await axios.post(`/${agency}/data/stop`, stopData);
                    setStops(R.append(data));
                  } else {
                    await axios.put(`/${agency}/data/stop/${selectedStop}`, stopData);
                    setStops(R.map((x) => (x.id === selectedStop ? {
                      id: selectedStop,
                      ...stopData,
                    } : x)));
                  }
                  setSelectedStop(null);
                }}
              >
                {t('data.save')}
              </Button>
            </div>
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        text={canDelete ? t(
          'data.deleteStopConfirm',
          { name: deleteName },
        ) : t('data.stopDeleteError')}
        onDelete={async () => {
          await axios.delete(`/${agency}/data/stop/${selectedStop}`);
          setStops(R.filter<IDataStop>((x) => x.id !== selectedStop));
          setSelectedStop(null);
          setDeleteDialog(false);
        }}
        canDelete={canDelete}
      />
    </div>
  );
}
