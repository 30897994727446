import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  InputAdornment,
  makeStyles, MenuItem, TextField, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import natsort from 'natsort';
import CustomDatePicker from '../../common/CustomDatePicker';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import { axios } from '../../utils';
import {
  IDataVersion, IProvider, IRoute, IStopWithRoutes,
} from '../../interfaces';
import { ProviderContext } from '../../context';
import { ReactComponent as ChevronWhite } from '../../assets/chevron-white.svg';
import VersionInfo from '../VersionInfo';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
  },
  dateAndTime: {
    display: 'flex',
    marginBottom: 24,
  },
  datePicker: {
    width: 240,
    marginRight: 24,
  },
  hourFrom: {
    width: 130,
    marginRight: 24,
  },
  hourTo: {
    width: 130,
  },
  header: {
    fontSize: 18,
    fontWeight: 400,
    color: '#37001F',
    marginBottom: 16,
  },
  from: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0',
    marginBottom: 16,
    backgroundColor: '#F5F5F5',
  },
  to: {
    display: 'flex',
    alignItems: 'center',
  },
  modifyDepartures: {
    display: 'flex',
    paddingTop: 28,
  },
  label: {
    width: 40,
    textAlign: 'right',
    marginRight: 8,
  },
  field: {
    width: 230,
    marginRight: 16,
  },
  route: {
    width: 140,
    marginRight: 16,
  },
  tableLabel: {
    width: 80,
    textAlign: 'right',
    color: '#38001F',
    marginRight: 16,
    marginLeft: 'auto',
  },
  main: {
    display: 'flex',
  },
  table: {
    borderCollapse: 'collapse',
  },
  cell: {
    padding: 12,
    height: 72,
    border: '1px solid #37001F',
    color: '#37001F',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    position: 'relative',
  },
  arrivalCell: {
    backgroundColor: '#F5F5F5',
  },
  shortTransfer: {
    backgroundColor: '#FB4A4A',
    color: 'white',
  },
  minTransferTime: {
    width: 200,
    marginTop: 32,
  },
  resultRow: {
    margin: '8px 0',
    fontSize: 20,
    color: '#37001F',
  },
  resultLabel: {
    display: 'inline-block',
    width: 280,
  },
  resultValue: {
    display: 'inline-block',
    width: 110,
  },
  positive: {
    color: '#C82727',
  },
  negative: {
    color: '#12A72F',
  },
  shortTransfersRow: {
    margin: '4px -8px',
    padding: '4px 8px',
    borderRadius: 4,
    display: 'inline-block',
    backgroundColor: '#FB4A4A',
    color: 'white',
    fontSize: 20,
  },
  firstTrip: {
    width: 140,
    marginRight: 24,
  },
  firstTripInput: {
    paddingLeft: '14px !important',
  },
  firstTripAdornment: {
    marginTop: 2,
    marginRight: 0,
  },
  interval: {
    width: 150,
    marginRight: 16,
  },
  modifyFields: {
    display: 'flex',
  },
  pageButton: {
    minWidth: 20,
    width: 20,
    height: 20,
    borderRadius: '50%',
    padding: 0,
    position: 'absolute',
    top: '50%',
    zIndex: 2,
    '&$pageButtonDisabled': {
      backgroundColor: '#b9b9b9',
    },
  },
  nextButton: {
    right: 0,
    transform: 'translate(50%, -50%)',
  },
  prevButton: {
    left: 0,
    transform: 'translate(-50%, -50%)',
  },
  pageButtonDisabled: {},
  chevronLeft: {
    transform: 'rotate(90deg)',
  },
  chevronRight: {
    transform: 'rotate(-90deg)',
  },
});

const formatDuration = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours > 0 ? `${hours} h ` : ''}${minutes > 0 ? `${minutes} min` : ''}`.trim();
};

export default function StopTransfer() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { providers, version, user } = useContext(ProviderContext);
  const [date, setDate] = useState<Moment>(moment());
  const [from, setFrom] = useState<string | null>('6:00');
  const [to, setTo] = useState<string | null>('18:00');
  const hourOptions = Array(25).fill(null).map((x, i) => (
    `${String(i)}:00`
  ));
  const [stops, setStops] = useState<Record<string, IStopWithRoutes[]>>({});
  const [routes, setRoutes] = useState<Record<string, IRoute[]>>({});
  const [fromProvider, setFromProvider] = useState<IProvider | null>(null);
  const [fromRoute, setFromRoute] = useState<IRoute | null>(null);
  const [fromStop, setFromStop] = useState<IStopWithRoutes | null>(null);
  const [toProvider, setToProvider] = useState<IProvider | null>(null);
  const [toRoute, setToRoute] = useState<IRoute | null>(null);
  const [toStop, setToStop] = useState<IStopWithRoutes | null>(null);
  const [arrivals, setArrivals] = useState<string[] | null>(null);
  const [departures, setDepartures] = useState<string[] | null>(null);
  const [transferTime, setTransferTime] = useState(3);
  const [firstTripMinute, setFirstTripMinute] = useState<number | null>(null);
  const [departureInterval, setDepartureInterval] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const resetChanges = (currentDepartures: string[], currentArrivals: string[]) => {
    const tableDepartures = currentDepartures.filter((x: string) => (
      moment(x, 'H:m').isAfter(moment(currentArrivals[0], 'H:m'))
    ));
    setFirstTripMinute(Number(currentDepartures[0].split(':')[1]));
    if (tableDepartures.length > 1) {
      const diffs = R.tail(tableDepartures as string[]).map((x, i) => (
        moment(x, 'H:m').diff(moment(tableDepartures[i], 'H:m'), 'minutes')
      ));
      setDepartureInterval(diffs.every(R.equals(diffs[0])) ? diffs[0] : null);
    }
  };
  const [prevVersion, setPrevVersion] = useState<IDataVersion | undefined>(version);
  useEffect(() => {
    setStops({});
    setRoutes({});
    setFromProvider(null);
    setFromRoute(null);
    setFromStop(null);
    setToProvider(null);
    setToRoute(null);
    setToStop(null);
    setArrivals(null);
    setDepartures(null);
    setTransferTime(3);
    setFirstTripMinute(null);
    setDepartureInterval(null);
    setPage(0);
    setPrevVersion(version);
  }, [version]);
  useEffect(() => {
    (async () => {
      if (from && to && fromProvider && fromRoute && fromStop && toProvider && toRoute && toStop
        && version === prevVersion) {
        const { data } = await axios.post('/stops/transfers', {
          date: date.format('YYYY-MM-DD'),
          from: moment(from, 'H:m').format('HH:mm'),
          to: moment(to === '24:00' ? '23:59' : to, 'H:m').format('HH:mm'),
          arrivals: {
            provider: fromProvider.id,
            route: fromRoute.id,
            stop: fromStop.id,
          },
          departures: {
            provider: toProvider.id,
            route: toRoute.id,
            stop: toStop.id,
          },
        }, {
          params: (user.agency && version) ? {
            providerName: user.agency,
            providerVersion: version.id,
          } : {},
        });
        setPage(0);
        setArrivals(R.uniq(data.arrivals));
        setDepartures(R.uniq(data.departures));
        if (data.arrivals.length > 0 && data.departures.length > 0) {
          resetChanges(data.departures, data.arrivals);
        }
      }
    })();
  }, [
    date, from, to, fromProvider, fromRoute, fromStop, toProvider, toRoute, toStop, user.agency,
    version, prevVersion,
  ]);
  interface ITableElement {
    arrival: string;
    connectedDepartures: string[];
  }
  interface IResults {
    table: ITableElement[],
    times: number[];
    average: number;
    max: number;
    shortTransfers: number;
  }
  const pageSize = Math.floor((window.innerWidth - 856) / 77);
  const getResults = (currentDepartures: string[]): IResults => {
    let table: ITableElement[] = [];
    if (arrivals && currentDepartures) {
      arrivals.forEach((arrival) => {
        const shortDepartures = currentDepartures.filter((departure) => (
          moment(departure, 'H:m').isBetween(
            moment(arrival, 'H:m'),
            moment(arrival, 'H:m').add(transferTime, 'minutes'),
          )
        ));
        const longDeparture = currentDepartures.find((departure) => (
          moment(departure, 'H:m').isSameOrAfter(
            moment(arrival, 'H:m').add(transferTime, 'minutes'),
          )
        ));
        if (longDeparture) {
          table = R.append({
            arrival,
            connectedDepartures: [...shortDepartures, longDeparture],
          }, table);
        }
      });
    }
    const times = R.chain(
      ({ arrival, connectedDepartures }) => connectedDepartures.map((departure) => (
        moment(departure, 'H:m').diff(moment(arrival, 'H:m'), 'minutes')
      )),
      table,
    );
    const timesWithoutShort = times.filter((x) => x >= transferTime);
    const average = Math.round(R.sum(timesWithoutShort) / timesWithoutShort.length);
    const max = Number(R.reduce(R.max, 0, times));
    const shortTransfers = times.filter((x) => x < transferTime).length;
    return {
      table,
      times,
      average,
      max,
      shortTransfers,
    };
  };
  let modifiedDepartures: string[] = [];
  let results: IResults | null = null;
  let modifiedResults: IResults | null = null;
  let averageDiff: number | null = null;
  let maxDiff: number | null = null;
  let pageTable: ITableElement[] | null = null;
  if (arrivals && departures && arrivals.length > 0 && departures.length > 0 && to) {
    if (firstTripMinute !== null) {
      const tableDepartures = departures.filter((x: string) => (
        moment(x, 'H:m').isAfter(moment(arrivals[0], 'H:m'))
      ));
      const firstTrip = moment(
        tableDepartures[0],
        'H:m',
      );
      if (departureInterval) {
        const currentTime = moment(firstTrip).minute(firstTripMinute);
        modifiedDepartures = [currentTime.format('HH:mm')];
        while (currentTime.isSameOrBefore(moment(to, 'H:m'))) {
          currentTime.add(departureInterval, 'minutes');
          modifiedDepartures.push(currentTime.format('HH:mm'));
        }
      } else {
        const minuteDiff = firstTripMinute - firstTrip.minute();
        modifiedDepartures = tableDepartures.map((x) => (
          moment(x, 'H:m').add(minuteDiff, 'minutes').format('HH:mm')
        ));
      }
    }
    results = getResults(departures);
    modifiedResults = getResults(modifiedDepartures);
    averageDiff = modifiedResults.average - results.average;
    maxDiff = modifiedResults.max - results.max;
    pageTable = [];
    let i = 0;
    modifiedResults.table.forEach((x) => {
      const departuresToAdd: string[] = [];
      x.connectedDepartures.forEach((y) => {
        if (i >= page * pageSize && i < (page + 1) * pageSize) {
          departuresToAdd.push(y);
        }
        i += 1;
      });
      if (pageTable && departuresToAdd.length > 0) {
        pageTable.push({
          arrival: x.arrival,
          connectedDepartures: departuresToAdd,
        });
      }
    });
  }
  const hasResults = Boolean(
    results && modifiedResults && averageDiff !== null && maxDiff !== null,
  );
  return (
    <div className={classes.root}>
      <VersionInfo />
      <div className={classes.dateAndTime}>
        <CustomDatePicker
          value={date}
          onChange={(newDate) => {
            if (newDate) {
              setDate(newDate);
            }
          }}
          className={classes.datePicker}
          label={t('synchronization.date')}
          format="dddd, DD.MM.Y"
        />
        <CustomAutocomplete
          options={hourOptions}
          getOptionLabel={R.identity}
          value={from}
          onChange={(event, value) => {
            setFrom(value);
          }}
          className={classes.hourFrom}
          label={t('synchronization.from')}
          noAdornment
        />
        <CustomAutocomplete
          options={hourOptions}
          getOptionLabel={R.identity}
          value={to}
          onChange={(event, value) => {
            setTo(value);
          }}
          className={classes.hourTo}
          label={t('synchronization.to')}
          noAdornment
        />
      </div>
      <Typography className={classes.header}>
        {t('stopTransfer.transfer')}
      </Typography>
      <div className={classes.main}>
        <div>
          <div className={classes.from} data-testid="from">
            <Typography className={classes.label}>
              {t('stopTransfer.from')}
            </Typography>
            <CustomAutocomplete
              options={providers}
              value={fromProvider}
              onChange={async (event, value) => {
                setFromProvider(value);
                setFromRoute(null);
                setFromStop(null);
                if (value) {
                  const { data }: { data: IRoute[] } = await axios.get(`/${value.id}/routes`, {
                    params: (value.id === user.agency && version)
                      ? { providerVersion: version.id }
                      : {},
                  });
                  const sorter = natsort();
                  setRoutes(R.mergeLeft({
                    [value.id]: data.sort((a, b) => sorter(a.name, b.name)),
                  }));
                }
              }}
              label={t('export.dataSource')}
              className={classes.field}
              getOptionLabel={R.prop('name')}
            />
            <CustomAutocomplete
              options={fromProvider ? routes[fromProvider.id] || [] : []}
              value={fromRoute}
              onChange={async (event, value) => {
                setFromRoute(value);
                setFromStop(null);
                if (fromProvider && value) {
                  const { data } = await axios.get(`/public/${fromProvider.id}/route/${value.id}/trips`, {
                    params: {
                      date: date.format('YYYY-MM-DD'),
                      ...((fromProvider.id === user.agency && version)
                        ? { providerVersion: version.id }
                        : {}),
                    },
                  });
                  const sorter = natsort();
                  setStops(R.mergeLeft({
                    [value.id]: R.uniqBy(
                      R.prop('id'),
                      [...data.stops.default, ...data.stops.opposite]
                        .sort((a, b) => sorter(a.name, b.name)),
                    ),
                  }));
                }
              }}
              label={t('synchronization.route')}
              className={classes.route}
              getOptionLabel={R.prop('name')}
            />
            <CustomAutocomplete
              options={fromRoute ? stops[fromRoute.id] || [] : []}
              value={fromStop}
              onChange={(event, value) => {
                setFromStop(value);
              }}
              label={t('synchronization.stop')}
              className={classes.field}
              getOptionLabel={R.prop('name')}
            />
            {hasResults && (
              <Typography className={classes.tableLabel}>
                {t('stopTransfer.arrival')}
              </Typography>
            )}
          </div>
          <div className={classes.to} data-testid="to">
            <Typography className={classes.label}>
              {t('stopTransfer.to')}
            </Typography>
            <CustomAutocomplete
              options={providers}
              value={toProvider}
              onChange={async (event, value) => {
                setToProvider(value);
                setToRoute(null);
                setToStop(null);
                if (value) {
                  const { data }: { data: IRoute[] } = await axios.get(`/${value.id}/routes`, {
                    params: (value.id === user.agency && version)
                      ? { providerVersion: version.id }
                      : {},
                  });
                  const sorter = natsort();
                  setRoutes(R.mergeLeft({
                    [value.id]: data.sort((a, b) => sorter(a.name, b.name)),
                  }));
                }
              }}
              label={t('export.dataSource')}
              className={classes.field}
              getOptionLabel={R.prop('name')}
            />
            <CustomAutocomplete
              options={toProvider ? routes[toProvider.id] || [] : []}
              value={toRoute}
              onChange={async (event, value) => {
                setToRoute(value);
                setToStop(null);
                if (toProvider && value) {
                  const { data } = await axios.get(`/public/${toProvider.id}/route/${value.id}/trips`, {
                    params: {
                      date: date.format('YYYY-MM-DD'),
                      ...((toProvider.id === user.agency && version)
                        ? { providerVersion: version.id }
                        : {}),
                    },
                  });
                  const sorter = natsort();
                  setStops(R.mergeLeft({
                    [value.id]: R.uniqBy(
                      R.prop('id'),
                      [...data.stops.default, ...data.stops.opposite]
                        .sort((a, b) => sorter(a.name, b.name)),
                    ),
                  }));
                }
              }}
              label={t('synchronization.route')}
              className={classes.route}
              getOptionLabel={R.prop('name')}
            />
            <CustomAutocomplete
              options={toRoute ? stops[toRoute.id] || [] : []}
              value={toStop}
              onChange={(event, value) => {
                setToStop(value);
              }}
              label={t('synchronization.stop')}
              className={classes.field}
              getOptionLabel={R.prop('name')}
            />
            {hasResults && (
              <Typography className={classes.tableLabel}>
                {t('stopTransfer.departure')}
              </Typography>
            )}
          </div>
          {modifiedResults && modifiedResults.table.length > 0 && (
            <div className={classes.modifyDepartures}>
              <div>
                <Typography className={classes.header}>
                  {t('stopTransfer.modifyDepartures')}
                </Typography>
                <div className={classes.modifyFields}>
                  <CustomAutocomplete
                    options={R.times(R.identity, 60)}
                    getOptionLabel={(x) => String(x).padStart(2, '0')}
                    value={firstTripMinute || undefined}
                    onChange={(event, value) => setFirstTripMinute(value)}
                    label={t('stopTransfer.firstTrip')}
                    className={classes.firstTrip}
                    TextFieldProps={{
                      InputProps: {
                        classes: { root: classes.firstTripInput },
                        startAdornment: (
                          <InputAdornment position="start" className={classes.firstTripAdornment}>
                            <Typography>
                              {modifiedResults.table[0].connectedDepartures[0].split(':')[0]}
                              {' '}
                              :
                            </Typography>
                          </InputAdornment>
                        ),
                      },
                    }}
                    disableClearable
                  />
                  <CustomAutocomplete
                    options={R.times((x) => x + 1, 60)}
                    getOptionLabel={(x) => `${x} ${t('synchronization.min')}.`}
                    value={departureInterval}
                    onChange={(event, value) => setDepartureInterval(value)}
                    label={t('stopTransfer.interval')}
                    className={classes.interval}
                    noAdornment
                  />
                  <Button
                    color="primary"
                    onClick={() => departures && arrivals && resetChanges(departures, arrivals)}
                  >
                    {t('stopTransfer.clear')}
                  </Button>
                </div>
              </div>
              <Typography className={classes.tableLabel}>
                {t('stopTransfer.transferTime')}
              </Typography>
            </div>
          )}
        </div>
        {modifiedResults && pageTable && (
          <div>
            <table className={classes.table}>
              <tbody>
                <tr>
                  {pageTable.map(({ arrival, connectedDepartures }, i) => (
                    <td
                      key={arrival}
                      colSpan={connectedDepartures.length}
                      className={classNames(classes.cell, classes.arrivalCell)}
                    >
                      {i === 0 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setPage((x) => x - 1)}
                          disabled={page === 0}
                          classes={{
                            contained: classNames(classes.pageButton, classes.prevButton),
                            disabled: classes.pageButtonDisabled,
                          }}
                        >
                          <ChevronWhite className={classes.chevronLeft} />
                        </Button>
                      )}
                      {arrival}
                      {pageTable && i === pageTable.length - 1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setPage(R.add(1))}
                          disabled={modifiedResults !== null && (
                            (page + 1) * pageSize >= modifiedResults.times.length
                          )}
                          classes={{
                            contained: classNames(classes.pageButton, classes.nextButton),
                            disabled: classes.pageButtonDisabled,
                          }}
                        >
                          <ChevronWhite className={classes.chevronRight} />
                        </Button>
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  {pageTable.map(({ arrival, connectedDepartures }, i) => (
                    <>
                      {connectedDepartures.map((departure, j) => {
                        const colSpan = (pageTable && j === connectedDepartures.length - 1)
                          ? R.takeWhile(
                            R.equals(departure),
                            R.chain(
                              R.prop('connectedDepartures'),
                              R.slice(i + 1, Infinity, pageTable),
                            ),
                          ).length + 1
                          : 1;
                        return (
                          (j === 0 && i !== 0 && pageTable
                            && R.last(pageTable[i - 1].connectedDepartures) === departure)
                            ? null
                            : (
                              <td
                                key={`${arrival}-${departure}`}
                                colSpan={colSpan}
                                style={{ width: 77 * colSpan }}
                                className={classes.cell}
                              >
                                {departure}
                              </td>
                            )
                        );
                      })}
                    </>
                  ))}
                </tr>
                <tr>
                  {R.slice(
                    page * pageSize,
                    ((page + 1) * pageSize),
                    modifiedResults.times,
                  ).map((time, i) => (
                    <td
                      key={
                        // eslint-disable-next-line react/no-array-index-key
                        i
                      }
                      className={classNames(classes.cell, {
                        [classes.shortTransfer]: time < transferTime,
                      })}
                    >
                      {formatDuration(time)}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
            <TextField
              select
              label={t('stopTransfer.minTransferTime')}
              value={String(transferTime)}
              onChange={({ target }) => setTransferTime(Number(target.value))}
              className={classes.minTransferTime}
              variant="outlined"
              size="small"
            >
              {R.times((n) => (
                <MenuItem value={String(n + 1)} key={String(n + 1)}>
                  {n + 1}
                  {' '}
                  {t('synchronization.min')}
                </MenuItem>
              ), 15)}
            </TextField>
            <Typography className={classes.resultRow}>
              <span className={classes.resultLabel}>
                {t('stopTransfer.averageTransfer')}
              </span>
              <span className={classes.resultValue}>
                {formatDuration(modifiedResults.average)}
              </span>
              {averageDiff !== null && averageDiff !== 0 && (
                <span className={averageDiff > 0 ? classes.positive : classes.negative}>
                  [
                  {averageDiff > 0 ? '+' : '-'}
                  {Math.abs(averageDiff)}
                  {' '}
                  {t('synchronization.min')}
                  ]
                </span>
              )}
            </Typography>
            <Typography className={classes.resultRow}>
              <span className={classes.resultLabel}>
                {t('stopTransfer.longestTransfer')}
              </span>
              <span className={classes.resultValue}>
                {formatDuration(modifiedResults.max)}
              </span>
              {maxDiff !== null && maxDiff !== 0 && (
                <span className={maxDiff > 0 ? classes.positive : classes.negative}>
                  [
                  {maxDiff > 0 ? '+' : '-'}
                  {Math.abs(maxDiff)}
                  {' '}
                  {t('synchronization.min')}
                  ]
                </span>
              )}
            </Typography>
            <Typography className={classes.shortTransfersRow}>
              <span className={classes.resultLabel}>
                {t('stopTransfer.shortTransfers', { time: transferTime })}
              </span>
              {modifiedResults.shortTransfers}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
