import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  useHistory, useParams, Switch, Redirect, Route,
} from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import { useGetLink } from '../../../utils';
import Assign from './Assign';
import Manage from './Manage';
import Drivers from './Drivers';
import Vehicles from './Vehicles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tabs: {
    margin: '0 -32px',
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 2,
      backgroundColor: '#F5F5F5',
    },
  },
  tab: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 300,
  },
  indicator: {
    zIndex: 1,
  },
});

export default function SupportContent() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const getLink = useGetLink();
  const { tab } = useParams<{ tab: string; }>();
  if (!tab) {
    return (
      <Redirect from="/:language/provider/data/crew" exact to={getLink('/provider/data/crew/assign')} />
    );
  }
  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={(event, value) => history.push(getLink(`/provider/data/crew/${value}`))}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.tabs}
        classes={{ indicator: classes.indicator }}
      >
        <Tab
          className={classes.tab}
          value="assign"
          label={t('data.assign')}
        />
        <Tab
          className={classes.tab}
          value="manage"
          label={t('data.manage')}
          data-testid="crew-manage"
        />
        <Tab
          className={classes.tab}
          value="drivers"
          label={t('data.drivers')}
        />
        <Tab
          className={classes.tab}
          value="vehicles"
          label={t('data.vehicles')}
        />
      </Tabs>
      <Switch>
        <Route path="/:language/provider/data/crew/assign" component={Assign} />
        <Route path="/:language/provider/data/crew/manage" component={Manage} />
        <Route path="/:language/provider/data/crew/drivers" component={Drivers} />
        <Route path="/:language/provider/data/crew/vehicles" component={Vehicles} />
      </Switch>
    </div>
  );
}
