import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import leaflet from 'leaflet';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import { IStop, IStopSocket } from '../../interfaces';
import stopMarker from '../../assets/stop-marker.svg';

interface Props {
  stop: IStop;
  stopSocket?: IStopSocket;
}

const useStyles = makeStyles({
  root: {
    top: -5,
    left: -5,
    width: 10,
    height: 10,
    transition: '250ms',
    '&:hover': {
      width: 12,
      height: 12,
      top: -6,
      left: -6,
    },
  },
  popup: {
    backgroundColor: 'white',
    borderRadius: 3,
    '& > :first-child': {
      boxShadow: 'none',
      padding: 0,
      '& > div': {
        margin: '16px 24px',
      },
    },
    '& > :last-child': {
      display: 'none',
    },
  },
  name: {
    backgroundColor: '#37001F',
    color: 'white',
    fontSize: 24,
    margin: '0 0 0 -24px !important',
    padding: '4px 16px 4px 24px',
    borderRadius: '0 15px 15px 0',
    display: 'inline-block',
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
    margin: '8px 0 16px',
    width: '100%',
  },
  headerCell: {
    fontSize: 13,
    color: '#666666',
    padding: '4px 8px',
  },
  dataCell: {
    fontSize: 14,
    color: '#37001F',
    padding: '0px 8px',
  },
  numberCell: {
    textAlign: 'right',
  },
  tooltip: {
    boxShadow: 'none',
  },
  tooltipText: {
    fontSize: 24,
    padding: '0 4px',
  },
  message: {
    fontSize: 14,
    color: '#37001F',
  },
});

export default function StopMarker({ stop, stopSocket }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Marker
      position={[stop.lat, stop.lon]}
      icon={leaflet.icon({
        iconUrl: stopMarker,
        className: classes.root,
      })}
    >
      <Popup
        className={classes.popup}
        closeButton={false}
        maxWidth={400}
      >
        <Typography className={classes.name}>
          {stop.name}
        </Typography>
        {stopSocket && (
          <>
            <table className={classes.table}>
              <thead>
                <tr>
                  <Typography component="th" className={classNames(classes.headerCell, classes.numberCell)}>
                    {t('map.route')}
                  </Typography>
                  <Typography component="th" className={classes.headerCell}>
                    {t('map.direction')}
                  </Typography>
                  <Typography component="th" className={classNames(classes.headerCell, classes.numberCell)}>
                    {t('map.departure')}
                  </Typography>
                </tr>
              </thead>
              <tbody>
                {stopSocket.arrivals.map((departure, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={i}>
                    <Typography component="td" className={classNames(classes.dataCell, classes.numberCell)}>
                      {departure.route}
                    </Typography>
                    <Typography component="td" className={classes.dataCell}>
                      {departure.headsign}
                    </Typography>
                    <Typography component="td" className={classNames(classes.dataCell, classes.numberCell)}>
                      {moment(departure.time, 'H:m:s').diff(moment(), 'minutes')}
                      {' '}
                      {t('map.minutes')}
                    </Typography>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={classes.message}>
              {stopSocket.message}
            </div>
          </>
        )}
      </Popup>
      <Tooltip className={classes.tooltip} opacity={1}>
        <Typography className={classes.tooltipText}>
          {stop.name}
        </Typography>
      </Tooltip>
    </Marker>
  );
}
