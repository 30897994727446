import React, {
  useContext, useEffect, useState,
} from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { Moment } from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import { Content } from 'pdfmake/interfaces';
import VersionInfo from '../VersionInfo';
import { IDataRoute, IDataStop } from '../../interfaces';
import { ProviderContext } from '../../context';
import { axios } from '../../utils';
import ButtonList from '../ButtonList';
import VehicleIcon from '../VehicleIcon';
import CustomDatePicker from '../../common/CustomDatePicker';
import robotoRegular from '../../assets/Roboto-Regular.ttf';
import robotoMedium from '../../assets/Roboto-Medium.ttf';
import robotoItalic from '../../assets/Roboto-Italic.ttf';
import robotoMediumItalic from '../../assets/Roboto-MediumItalic.ttf';

pdfMake.fonts = {
  Roboto: {
    normal: window.location.origin + robotoRegular,
    bold: window.location.origin + robotoMedium,
    italics: window.location.origin + robotoItalic,
    bolditalics: window.location.origin + robotoMediumItalic,
  },
};

interface ITimetable {
  route: string;
  stop: string;
  headsign: string;
  description: string;
  timetables: {
    validOn: ('WORKWEEK' | 'SATURDAYS' | 'SUNDAYS')[];
    departures: {
      time: string;
      exception?: string;
    }[];
  }[];
  exceptions: {
    symbol: string;
    description: string;
  }[];
}

const useStyles = makeStyles({
  root: {
    marginTop: 32,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  header: {
    width: 220,
    fontSize: 24,
    fontWeight: 700,
  },
  selectAll: {
    marginRight: 24,
  },
  buttonList: {
    marginBottom: 32,
  },
  routeName: {
    marginLeft: 8,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  datePicker: {
    width: 160,
    marginRight: 16,
  },
});

export default function Timetables() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user: { agency }, version, providers } = useContext(ProviderContext);
  const [stops, setStops] = useState<IDataStop[]>([]);
  const [routes, setRoutes] = useState<IDataRoute[]>([]);
  useEffect(() => {
    (async () => {
      const [
        { data },
        { data: routesData },
      ] = await Promise.all([
        axios.get(`/${agency}/data/stops`, { params: { providerVersion: version!.id } }),
        axios.get(`/${agency}/data/routes`, { params: { providerVersion: version!.id } }),
      ]);
      setStops(data);
      setRoutes(routesData);
    })();
  }, [agency, version]);
  const [selectedStops, setSelectedStops] = useState<number[]>([]);
  const [selectedRoutes, setSelectedRoutes] = useState<number[]>([]);
  const [from, setFrom] = useState<Moment | null>(null);
  const [to, setTo] = useState<Moment | null>(null);
  const openPdf = async () => {
    if (!from || !to) return;
    const { data }: { data: ITimetable[] } = await axios.get(`/${agency}/data/timetables`, {
      params: {
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD'),
        stops: selectedStops.join(','),
        routes: selectedRoutes.join(','),
        providerVersion: version!.id,
      },
    });
    pdfMake.createPdf({
      info: {
        title: 'Stop timetables',
      },
      pageMargins: 8,
      defaultStyle: {
        color: '#37001F',
      },
      content: data.map((timetable, timetableIndex) => ({
        pageBreak: timetableIndex > 0 ? 'before' : undefined,
        table: {
          widths: '*',
          body: [
            [
              {
                stack: [
                  {
                    columnGap: 0,
                    columns: [
                      {
                        text: timetable.route,
                        fontSize: 60,
                        bold: true,
                        width: 110,
                        alignment: 'center',
                      },
                      {
                        table: {
                          body: [
                            [
                              {
                                text: `${t('data.timetableDirection')}:`,
                                fontSize: 8.4,
                              },
                              {
                                text: timetable.headsign,
                                fontSize: 12,
                                bold: true,
                                margin: [0, -3, 0, 0],
                              },
                            ],
                            [
                              {
                                text: `${t('data.timetableStop')}:`,
                                fontSize: 8.4,
                              },
                              {
                                text: timetable.stop,
                                fontSize: 12,
                                bold: true,
                                margin: [0, -3, 0, 0],
                              },
                            ],
                            [
                              {
                                text: `${t('data.timetableDescription')}:`,
                                fontSize: 8.4,
                              },
                              {
                                text: timetable.description,
                                fontSize: 8.4,
                              },
                            ],
                          ],
                        },
                        layout: 'noBorders',
                        width: 'auto',
                        margin: [0, 12, 0, 0],
                      },
                    ],
                  },
                  ...timetable.timetables.map(({ validOn, departures }): Content => {
                    const columns = Array(24).fill('').map((x, i) => (
                      departures.filter(({ time }) => Number(time.substring(0, 2)) === i + 1)
                        .map(({ time, exception }) => (
                          `${time.substring(3)}${exception || ''}`
                        ))
                    ));
                    const rowsCount = columns.map((x) => x.length).reduce(R.max);
                    return ({
                      table: {
                        widths: '*',
                        body: [
                          [
                            {
                              text: validOn.map((x) => t(`data.${x}`)).join(', '),
                              colSpan: 24,
                              fontSize: 9,
                              alignment: 'center',
                            },
                            ...Array(23).fill({}),
                          ],
                          Array(24).fill('').map((x, i) => ({
                            text: i + 1,
                            alignment: 'center',
                            fontSize: 9,
                            bold: true,
                          })),
                          ...Array(rowsCount).fill('').map((x, i) => (
                            Array(24).fill('').map((y, j) => ({
                              text: columns[j][i],
                              alignment: 'center',
                              fontSize: 9,
                            }))
                          )),
                        ],
                      },
                      layout: {
                        hLineWidth: (i) => ([0, 1, 2].includes(i) ? 0.25 : 0),
                        vLineWidth: () => 0.25,
                      },
                    });
                  }),
                  {
                    table: {
                      widths: '*',
                      body: [
                        [
                          {
                            stack: timetable.exceptions.map(({ symbol, description }) => ({
                              text: `${symbol} - ${description}`,
                              fontSize: 9,
                              lineHeight: 1.1,
                            })),
                            margin: 3,
                          },
                        ],
                      ],
                    },
                    layout: {
                      hLineWidth: () => 0.25,
                      vLineWidth: () => 0.25,
                    },
                  },
                  {
                    text: providers.find(R.propEq('id', agency))!.fullName,
                    fontSize: 12,
                    bold: true,
                    alignment: 'right',
                    margin: [10, 5],
                  },
                ],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0.25,
          vLineWidth: () => 0.25,
        },
      })),
    }).open();
  };
  return (
    <div className={classes.root}>
      <VersionInfo />
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>
          {t('data.timetablesForStops')}
        </Typography>
        <Button
          color="primary"
          className={classes.selectAll}
          onClick={() => setSelectedStops(stops.map(R.prop('id')))}
        >
          {t('data.selectAll')}
        </Button>
        <Button
          color="primary"
          onClick={() => setSelectedStops([])}
        >
          {t('data.deselectAll')}
        </Button>
      </div>
      <ButtonList
        className={classes.buttonList}
        elements={(
          stops.map((stop) => ({
            id: stop.id,
            name: stop.name,
            onClick: () => setSelectedStops(
              selectedStops.includes(stop.id)
                ? selectedStops.filter((x) => x !== stop.id)
                : [...selectedStops, stop.id],
            ),
          }))
        )}
        selected={selectedStops}
      />
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>
          {t('data.forRoutes')}
        </Typography>
        <Button
          color="primary"
          className={classes.selectAll}
          onClick={() => setSelectedRoutes(routes.map(R.prop('id')))}
        >
          {t('data.selectAll')}
        </Button>
        <Button
          color="primary"
          onClick={() => setSelectedRoutes([])}
        >
          {t('data.deselectAll')}
        </Button>
      </div>
      <ButtonList
        className={classes.buttonList}
        elements={(
          routes.map((route) => ({
            id: route.id,
            name: (
              <>
                <VehicleIcon type={route.type} />
                <span className={classes.routeName}>
                  {route.name || route.id}
                </span>
              </>
            ),
            onClick: () => setSelectedRoutes(
              selectedRoutes.includes(route.id)
                ? selectedRoutes.filter((x) => x !== route.id)
                : [...selectedRoutes, route.id],
            ),
          }))
        )}
        selected={selectedRoutes}
      />
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>
          {t('data.forPeriod')}
        </Typography>
      </div>
      <div className={classes.row}>
        <CustomDatePicker
          value={from}
          onChange={(newDate) => setFrom(newDate)}
          className={classes.datePicker}
          label={t('data.from')}
          maxDate={to || undefined}
        />
        <CustomDatePicker
          value={to}
          onChange={(newDate) => setTo(newDate)}
          className={classes.datePicker}
          label={t('data.to')}
          minDate={from || undefined}
        />
      </div>
      <div className={classes.row}>
        <Button
          variant="contained"
          color="primary"
          onClick={openPdf}
          disabled={selectedStops.length === 0 || selectedRoutes.length === 0 || !from || !to}
        >
          {t('data.viewAll')}
        </Button>
      </div>
    </div>
  );
}
