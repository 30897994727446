import React, { useContext, useEffect, useState } from 'react';
import { Link as MuiLink, makeStyles, Typography } from '@material-ui/core';
import {
  NavLink, Redirect, Route, Switch, RouteProps,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import classNames from 'classnames';
import * as R from 'ramda';
import logo from '../assets/interconnect-logo.svg';
import { axios, useGetLink } from '../utils';
import Home from './home/Home';
import AppContext, { ProviderContext } from '../context';
import Statistics from './Statistics';
import ImpactArea from './impact-area/ImpactArea';
import Export from './Export';
import Analysis from './analysis/Analysis';
import Import from './Import';
import { IDataVersion, IProvider } from '../interfaces';
import Roles from './Roles';
import Data from './data/Data';
import UserMenu from '../common/UserMenu';
import DataSource from './DataSource';
import Organizations from './Organizations';
import Support from './Support';
import Trips from './trips/Trips';

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    backgroundColor: 'white',
    padding: '0 32px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1000px)': {
      padding: '0 16px',
    },
    '@media (max-width: 1000px), (max-height: 800px)': {
      height: 'auto',
      minHeight: '100vh',
    },
  },
  nav: {
    height: 43,
    flexShrink: 0,
    padding: '0 32px',
    margin: '0 -32px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(232, 232, 232, 0.5)',
  },
  logoLink: {
    height: 41,
    marginRight: 'auto',
  },
  logo: {
    height: '100%',
  },
  navIconLink: {
    display: 'flex',
    justifyContent: 'center',
    width: 100,
    padding: '8px 0',
  },
  navLink: {
    color: '#a1145c',
    fontSize: 20,
    textDecoration: 'none',
    textAlign: 'center',
    width: 200,
    whiteSpace: 'nowrap',
    margin: '0 16px',
  },
  impactLink: {
    width: 250,
  },
  active: {
    fontWeight: 700,
  },
  main: {
    flexGrow: 1,
    minHeight: 400,
    display: 'flex',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 0',
    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  switch: {
    '@media (max-width: 1000px)': {
      marginBottom: 16,
    },
  },
  switchText: {
    fontSize: 12,
  },
  copyright: {
    whiteSpace: 'nowrap',
    fontSize: 12,
    color: '#37001F',
  },
});

export default function Provider() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const getLink = useGetLink();
  const { name, user, interconnectNumber } = useContext(AppContext);
  const [providers, setProviders] = useState<IProvider[] | null>(null);
  const [versions, setVersions] = useState<IDataVersion[] | undefined>();
  const [versionId, setVersionId] = useState<number | undefined>();
  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/providers');
      if (user && user.agency && data.find(R.propEq('id', user.agency))?.dataSource === 'manual') {
        const { data: versionsData } = await axios.get(`/${user.agency}/versions`);
        setVersions(versionsData);
        const versionString = localStorage.getItem('version');
        const newVersion = versionsData.find((x: IDataVersion) => (
          versionString ? (x.id === Number(versionString)) : x.type === 'draft'
        ));
        if (!versionString) {
          localStorage.setItem('version', String(newVersion.id));
        }
        setVersionId(newVersion.id);
      }
      setProviders(R.sort((a, b) => a.name.localeCompare(b.name, 'pl'), data));
    })();
  }, [user]);
  if (!name || !user) return null;
  const isAdmin = user.role === 'ADMIN';
  const isAgencyAdmin = ['ADMIN', 'AGENCY_ADMIN'].includes(user.role || '');
  if (!providers) return null;
  const isManual = providers.find(R.propEq('id', user.agency))?.dataSource === 'manual';
  const dataRoutes = [
    '/:language/provider/data/routes',
    '/:language/provider/data/patterns',
    '/:language/provider/data/stops',
    '/:language/provider/data/trips',
    '/:language/provider/data/calendars',
    '/:language/provider/data/timetables',
    '/:language/provider/data/crew/:tab?',
    '/:language/provider/data/reports',
    '/:language/provider/data/tickets',
    '/:language/provider/data/manage',
  ];
  const version = versions ? versions.find(R.propEq('id', versionId)) : undefined;
  const AdminRoute = (props: RouteProps) => (isAdmin ? <Route {...props} /> : null);
  const AgencyAdminRoute = (props: RouteProps) => (isAgencyAdmin ? <Route {...props} /> : null);
  return (
    <ProviderContext.Provider
      value={{
        name, user, providers, setProviders, version, versions, setVersions,
      }}
    >
      <div className={classes.root}>
        <div className={classes.nav}>
          <NavLink
            to={getLink('/provider')}
            className={classes.logoLink}
          >
            <img
              src={logo}
              alt="Interconnect logo"
              className={classes.logo}
            />
          </NavLink>
          <Typography
            component={NavLink}
            to={getLink('/provider/analysis')}
            className={classes.navLink}
            activeClassName={classes.active}
          >
            {t('nav.analysis')}
          </Typography>
          <Typography
            component={NavLink}
            to={getLink('/provider/statistics')}
            className={classes.navLink}
            activeClassName={classes.active}
          >
            {t('nav.statistics')}
          </Typography>
          {isAdmin && (
            <Typography
              component={NavLink}
              to={getLink('/provider/trips')}
              className={classes.navLink}
              activeClassName={classes.active}
            >
              {t('trips.trips')}
            </Typography>
          )}
          {isAgencyAdmin && (
            <Typography
              component={NavLink}
              to={getLink('/provider/export')}
              className={classes.navLink}
              activeClassName={classes.active}
            >
              {t('nav.export')}
            </Typography>
          )}
          <Typography
            component={NavLink}
            to={getLink('/provider/impact-area')}
            className={classNames(classes.navLink, classes.impactLink)}
            activeClassName={classes.active}
          >
            {t('nav.impactArea')}
          </Typography>
          {isManual ? (
            <Typography
              component={NavLink}
              to={getLink('/provider/data')}
              className={classes.navLink}
              activeClassName={classes.active}
            >
              {t('nav.data')}
            </Typography>
          ) : (
            <Typography
              component={NavLink}
              to={getLink('/provider/import')}
              className={classes.navLink}
              activeClassName={classes.active}
            >
              {t('nav.import')}
            </Typography>
          )}
          {isAdmin && (
            <Typography
              component={NavLink}
              to={getLink('/card')}
              className={classes.navLink}
              activeClassName={classes.active}
              target="_blank"
            >
              {t('nav.cardPortal')}
            </Typography>
          )}
          {isAdmin && (
            <Typography
              component={NavLink}
              to={getLink('/beacon')}
              className={classes.navLink}
              activeClassName={classes.active}
              target="_blank"
            >
              {t('nav.beacons')}
            </Typography>
          )}
          <Typography
            component={NavLink}
            to={getLink('/trip-planning')}
            className={classes.navLink}
            activeClassName={classes.active}
            target="_blank"
          >
            {t('nav.pp')}
          </Typography>
          <UserMenu
            name={name}
            role={user.role}
            agency={user.agency}
            showRoles
            versions={versions || undefined}
            version={version}
            setVersion={setVersionId}
            interconnectNumber={interconnectNumber}
          />
        </div>
        <div className={classes.main}>
          <Switch>
            <Route path="/:language/provider" exact component={Home} />
            <Route path="/:language/provider/analysis" exact component={Analysis} />
            <Route path="/:language/provider/statistics" exact component={Statistics} />
            <AdminRoute path="/:language/provider/trips/:tab?" exact component={Trips} />
            <AgencyAdminRoute path="/:language/provider/import" exact component={Import} />
            <Route path="/:language/provider/export" exact component={Export} />
            <Route path="/:language/provider/impact-area" exact component={ImpactArea} />
            <AgencyAdminRoute path="/:language/provider/roles" exact component={Roles} />
            <AdminRoute path="/:language/provider/organizations" exact component={Organizations} />
            <Redirect from="/:language/provider/data" exact to={getLink('/provider/data/routes')} />
            {isManual ? (
              <Route path={dataRoutes} exact component={Data} />
            ) : (
              <Redirect from="/:language/provider/data" to={getLink('/provider')} />
            )}
            <AdminRoute path="/:language/provider/data-source" exact component={DataSource} />
            <Route path="/:language/provider/support/:tab?" exact component={Support} />
          </Switch>
        </div>
        <div className={classes.footer}>
          <MuiLink
            className={classes.switch}
            component="button"
            onClick={() => {
              i18next.changeLanguage(i18n.language === 'pl' ? 'en' : 'pl');
            }}
          >
            <Typography className={classes.switchText}>
              {t('footer.switchLanguage')}
            </Typography>
          </MuiLink>
          <Typography className={classes.copyright}>
            Copyright © 2020 Innobaltica Sp. z o.o.
          </Typography>
        </div>
      </div>
    </ProviderContext.Provider>
  );
}
