import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as Clear } from '../assets/clear.svg';
import { ReactComponent as ClearWhite } from '../assets/clear-white.svg';

interface Props<T extends number | string> {
  elements: {
    id: T;
    name: React.ReactNode;
    onClick: () => void;
    onDelete?: () => void;
  }[]
  selected: T | T[] | null;
  variant?: 'small' | 'big';
  className?: string;
}

const useStyles = makeStyles({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: -4,
  },
  listButton: {
    backgroundColor: '#F5F5F5',
    minWidth: 'initial',
    margin: '4px',
    borderRadius: 15,
    fontSize: 24,
    fontWeight: 400,
    padding: '1px 18px',
  },
  small: {
    fontSize: 20,
    padding: '4px 17px',
  },
  selectedButton: {
    backgroundColor: '#a1145c',
    color: 'white',
    '&:hover': {
      backgroundColor: '#c62e8a',
    },
  },
  delete: {
    margin: '0 -16px 0 8px',
  },
});

export default function ButtonList<T extends string | number>(props: Props<T>) {
  const {
    elements, selected, variant = 'big', className,
  } = props;
  const classes = useStyles();
  let selectedArray: T[];
  if (Array.isArray(selected)) {
    selectedArray = selected;
  } else {
    selectedArray = selected === null ? [] : [selected];
  }
  return (
    <div className={classNames(classes.list, className)}>
      {elements.map((element) => (
        <Button
          key={element.id}
          variant="contained"
          className={classNames(classes.listButton, {
            [classes.selectedButton]: selectedArray.includes(element.id),
            [classes.small]: variant === 'small',
          })}
          component="div"
          onClick={element.onClick}
        >
          {element.name}
          {element.onDelete && (
            <IconButton
              className={classes.delete}
              onClick={(event) => {
                event.stopPropagation();
                if (element.onDelete) {
                  element.onDelete();
                }
              }}
              data-testid="delete"
            >
              {selected === element.id ? <ClearWhite /> : <Clear />}
            </IconButton>
          )}
        </Button>
      ))}
    </div>
  );
}
