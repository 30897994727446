import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Typography, RadioGroup, FormControlLabel, Radio, TextField, IconButton, Checkbox, Button,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import * as R from 'ramda';
import classNames from 'classnames';
import NewHeader from '../provider/NewHeader';
import CustomDatePicker from '../common/CustomDatePicker';
import { ReactComponent as Clear } from '../assets/clear.svg';
import { ReactComponent as ClearWhite } from '../assets/clear-white.svg';
import { ReactComponent as PackageOnSale } from '../assets/package-on-sale.svg';
import { ReactComponent as PackageOnSaleStarting } from '../assets/package-on-sale-starting.svg';
import { ReactComponent as PackageArchived } from '../assets/package-archived.svg';
import CustomAutocomplete from '../common/CustomAutocomplete';
import {
  ICardTemplate,
  IPackage, IPackageDiscount, ISimpleAttraction, IValidityType,
} from '../interfaces';
import PackageAttraction from './PackageAttraction';
import PackageIcon from './PackageIcon';
import { axios } from '../utils';
import DeleteDialog from '../provider/DeleteDialog';
import NumberField from '../common/NumberField';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    width: '100%',
    display: 'flex',
  },
  left: {
    width: '50%',
    marginRight: 32,
    display: 'flex',
    flexDirection: 'column',
  },
  right: {
    width: '50%',
  },
  subheader: {
    fontWeight: 400,
    marginBottom: 16,
  },
  radioGroup: {
    marginBottom: 24,
  },
  radioRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 32,
  },
  from: {
    width: 160,
    marginRight: 16,
  },
  to: {
    width: 160,
  },
  radioNumber: {
    width: 160,
    marginRight: 16,
  },
  radioText: {
    fontWeight: 400,
    color: '#37001F',
  },
  formControlLabel: {
    marginBottom: 24,
  },
  attractions: {
    marginBottom: 32,
  },
  attraction: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderRadius: 15,
    marginBottom: 8,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  numberOf: {
    width: 40,
    fontSize: 20,
    color: '#37001F',
    marginLeft: 16,
  },
  attractionName: {
    fontSize: 20,
    color: '#37001F',
    marginRight: 'auto',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  price: {
    width: 300,
  },
  currency: {
    width: 40,
    margin: '0 32px 0 8px',
    color: '#37001F',
  },
  limit: {
    width: 300,
    marginRight: 80,
  },
  saleDates: {
    fontWeight: 400,
    color: '#37001F',
    marginRight: 24,
  },
  packageStatus: {
    fontWeight: 400,
    color: '#37001F',
    marginRight: 8,
  },
  onSale: {
    fontWeight: 700,
    color: '#188D13',
  },
  saveRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 24,
  },
  discardChanges: {
    marginRight: 16,
  },
  copyRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  copyPackage: {
    width: 300,
    margin: '0 16px',
  },
  fieldRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 -12px 24px',
  },
  shortField: {
    width: '50%',
    margin: '0 12px',
  },
  package: {
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    marginBottom: 8,
    backgroundColor: '#F5F5F5',
    cursor: 'pointer',
    height: 43,
  },
  packageName: {
    fontSize: 20,
    marginRight: 'auto',
  },
  legend: {
    marginTop: 'auto',
  },
  legendStatus: {
    fontSize: 14,
    color: '#37001F',
    marginBottom: 8,
  },
  legendItem: {
    fontSize: 14,
    color: '#37001F',
    marginRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  packageIcon: {
    marginRight: 16,
  },
  selectedPackage: {
    backgroundColor: '#a1145c',
  },
  selectedPackageText: {
    color: 'white',
  },
  saleStarting: {
    color: '#ebb114',
    fontWeight: 700,
  },
  archived: {
    color: '#9b9b9b',
    fontWeight: 700,
  },
  images: {
    display: 'flex',
    marginBottom: 32,
  },
  image: {
    borderRadius: 5,
    marginRight: 24,
    padding: 0,
  },
  selectedImage: {
    '&::before': {
      content: '""',
      width: '100%',
      height: '100%',
      border: '5px solid #A1145C',
      position: 'absolute',
      borderRadius: 5,
    },
  },
  template: {
    width: 82,
    height: 52,
  },
  limitRow: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 32,
  },
});

const formatISO = (date: Moment) => date.format('YYYY-MM-DD');

export default function Packages() {
  const classes = useStyles();
  const { t, i18n: { language } } = useTranslation();
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [templates, setTemplates] = useState<ICardTemplate[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<number | 'new' | null>(null);
  const [copyPackage, setCopyPackage] = useState<IPackage | null>(null);
  const [namePL, setNamePL] = useState('');
  const [nameEN, setNameEN] = useState('');
  const [template, setTemplate] = useState<ICardTemplate | null>(null);
  const [validType, setValidType] = useState<IValidityType | null>(null);
  const [from, setFrom] = useState<Moment | null>(null);
  const [to, setTo] = useState<Moment | null>(null);
  const [daysFromPurchase, setDaysFromPurchase] = useState('');
  const [daysFromFirstUse, setDaysFromFirstUse] = useState('');
  const [discounts, setDiscounts] = useState<IPackageDiscount[]>([]);
  const [openAttraction, setOpenAttraction] = useState<number | 'new' | null>(null);
  const [price, setPrice] = useState('');
  const [invalidPrice, setInvalidPrice] = useState(false);
  const [free, setFree] = useState(false);
  const [limit, setLimit] = useState('');
  const [limitError, setLimitError] = useState(false);
  const [noLimit, setNoLimit] = useState(false);
  const [saleFrom, setSaleFrom] = useState<Moment | null>(null);
  const [saleTo, setSaleTo] = useState<Moment | null>(null);
  const [attractions, setAttractions] = useState<ISimpleAttraction[]>([]);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [deleteName, setDeleteName] = useState('');
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    (async () => {
      const [{ data }, { data: attractionsData }, { data: templatesData }] = await Promise.all([
        axios.get('/card/packages'),
        axios.get('/card/attractions/simple'),
        axios.get('/card/templates'),
      ]);
      setPackages(data.map((pkg: any) => ({
        ...pkg,
        discounts: pkg.discounts.map((discount: any, i: number) => ({
          id: i,
          ...discount,
        })),
      })));
      setAttractions(attractionsData);
      setTemplates(templatesData);
    })();
  }, []);
  const status = packages.find((x) => x.id === selectedPackage)?.status;
  const onSale = status === 'ON_SALE';
  const archived = status === 'ARCHIVED';
  const soldPackages = packages.find((x) => x.id === selectedPackage)?.sales.sold || 0;
  const loadData = async (pkg: IPackage | null, copy?: boolean) => {
    if (pkg) {
      if (!copy) {
        setSelectedPackage(pkg.id);
      }
      setNamePL(pkg.namePL);
      setNameEN(pkg.nameEN);
      setTemplate(templates.find(R.propEq('file', pkg.image)) || null);
      setValidType(pkg.validity.type);
      setFrom(pkg.validity.from ? moment(pkg.validity.from) : null);
      setTo(pkg.validity.to ? moment(pkg.validity.to) : null);
      if (pkg.validity.type === 'DAYS_SINCE_ASSIGNED') {
        setDaysFromPurchase(String(pkg.validity.days));
        setDaysFromFirstUse('');
      } else if (pkg.validity.type === 'DAYS_SINCE_ACTIVATED') {
        setDaysFromFirstUse(String(pkg.validity.days));
        setDaysFromPurchase('');
      } else {
        setDaysFromFirstUse('');
        setDaysFromPurchase('');
      }
      setDiscounts(pkg.discounts);
      if (!pkg.price) {
        setFree(true);
        setPrice('');
      } else {
        setFree(false);
        setPrice(String(pkg.price));
      }
      if (!pkg.sales.limit) {
        setLimit('');
        setNoLimit(true);
      } else {
        setLimit(String(pkg.sales.limit));
        setNoLimit(false);
      }
      setSaleFrom(moment(pkg.sales.from));
      setSaleTo(moment(pkg.sales.to));
    } else {
      setSelectedPackage('new');
      setNamePL('');
      setNameEN('');
      setTemplate(null);
      setValidType(null);
      setFrom(null);
      setTo(null);
      setDaysFromPurchase('');
      setDaysFromFirstUse('');
      setDiscounts([]);
      setFree(false);
      setPrice('');
      setLimit('');
      setNoLimit(false);
      setSaleFrom(null);
      setSaleTo(null);
    }
    setOpenAttraction(null);
    setInvalidPrice(false);
    setLimitError(false);
  };
  if (openAttraction !== null) {
    return (
      <PackageAttraction
        attractions={attractions}
        packageName={language === 'pl' ? namePL : nameEN}
        discount={openAttraction === 'new'
          ? null
          : discounts.find((x) => x.id === openAttraction) as IPackageDiscount}
        onClose={() => setOpenAttraction(null)}
        onSave={(newDiscountNoId) => {
          const newId = R.last(discounts) ? R.last(discounts)!.id + 1 : 0;
          const newDiscount = {
            ...newDiscountNoId,
            id: openAttraction === 'new' ? newId : openAttraction,
          };
          if (openAttraction === 'new') {
            setDiscounts([...discounts, newDiscount]);
          } else {
            setDiscounts(discounts.map((x) => (x.id === openAttraction ? newDiscount : x)));
          }
          setOpenAttraction(null);
        }}
        disabled={onSale || archived}
      />
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <NewHeader
          headerText={t('card.packages')}
          newText={t('card.newPackage')}
          onClick={() => loadData(null)}
          bigMargin
        />
        {packages.map((pkg) => {
          const isSelected = pkg.id === selectedPackage;
          return (
            <div
              className={classNames(classes.package, {
                [classes.selectedPackage]: isSelected,
              })}
              key={pkg.id}
              onClick={() => loadData(pkg)}
            >
              <PackageIcon status={pkg.status} className={classes.packageIcon} />
              <Typography
                className={classNames(classes.packageName, {
                  [classes.selectedPackageText]: isSelected,
                })}
              >
                {language === 'pl' ? pkg.namePL : pkg.nameEN}
              </Typography>
              {pkg.status === 'DRAFT' && (
                <IconButton
                  color="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    setDeleteName(language === 'pl' ? pkg.namePL : pkg.nameEN);
                    setDeleteId(pkg.id);
                  }}
                  data-testid="delete"
                >
                  {isSelected ? <ClearWhite /> : <Clear />}
                </IconButton>
              )}
            </div>
          );
        })}
        <div className={classes.legend}>
          <Typography className={classes.legendStatus}>
            {t('card.packageStatus')}
            :
          </Typography>
          <div className={classes.row}>
            <Typography className={classes.legendItem}>
              <PackageOnSale className={classes.packageIcon} />
              {t('card.onSale')}
            </Typography>
            <Typography className={classes.legendItem}>
              <PackageOnSaleStarting className={classes.packageIcon} />
              {t('card.saleStarting')}
              …
            </Typography>
            <Typography className={classes.legendItem}>
              <PackageArchived className={classes.packageIcon} />
              {t('card.archived')}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.right}>
        {selectedPackage !== null && (
          <>
            <div className={classes.copyRow}>
              <Typography>
                {t('card.copyPackageFrom')}
              </Typography>
              <CustomAutocomplete
                options={packages}
                value={copyPackage}
                onChange={(event, value) => setCopyPackage(value)}
                label={t('card.packageName')}
                className={classes.copyPackage}
                getOptionLabel={R.prop(language === 'pl' ? 'namePL' : 'nameEN')}
                disabled={onSale || archived}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!copyPackage}
                onClick={() => {
                  if (!copyPackage) return;
                  loadData(copyPackage, true);
                  setCopyPackage(null);
                }}
              >
                {t('data.copy')}
              </Button>
            </div>
            <div className={classes.fieldRow}>
              <TextField
                className={classes.shortField}
                label={t('card.nameInPolish')}
                value={namePL}
                onChange={({ target }) => setNamePL(target.value)}
                variant="outlined"
                size="small"
                disabled={archived}
              />
              <TextField
                className={classes.shortField}
                label={t('card.nameInEnglish')}
                value={nameEN}
                onChange={({ target }) => setNameEN(target.value)}
                variant="outlined"
                size="small"
                disabled={archived}
              />
            </div>
            <Typography className={classes.subheader}>
              {t('card.defaultCardTemplate')}
            </Typography>
            <div className={classes.images}>
              {templates.map((x) => (
                <Button
                  key={x.id}
                  onClick={() => setTemplate(x)}
                  className={classNames(classes.image, {
                    [classes.selectedImage]: template === x,
                  })}
                  disabled={archived}
                >
                  <img
                    src={x.file}
                    alt=""
                    className={classes.template}
                  />
                </Button>
              ))}
            </div>
            <Typography className={classes.subheader}>
              {t('card.valid')}
            </Typography>
            <RadioGroup
              value={validType}
              onChange={({ target }) => setValidType(target.value as IValidityType)}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="FIXED_PERIOD"
                control={<Radio color="primary" />}
                label={(
                  <div className={classes.radioRow}>
                    <CustomDatePicker
                      value={from}
                      onChange={(newFrom) => {
                        setFrom(newFrom);
                        setValidType('FIXED_PERIOD');
                      }}
                      label={t('card.from')}
                      className={classes.from}
                      disabled={onSale || archived}
                    />
                    <CustomDatePicker
                      value={to}
                      onChange={(newTo) => {
                        setTo(newTo);
                        setValidType('FIXED_PERIOD');
                      }}
                      label={t('card.to')}
                      className={classes.to}
                      disabled={onSale || archived}
                    />
                  </div>
                )}
                className={classes.formControlLabel}
                disabled={onSale || archived}
              />
              <FormControlLabel
                value="DAYS_SINCE_ASSIGNED"
                control={<Radio color="primary" />}
                label={(
                  <div className={classes.radioRow}>
                    <NumberField
                      label={t('card.number')}
                      value={daysFromPurchase}
                      onChange={({ target }) => {
                        setDaysFromPurchase(target.value);
                        setValidType('DAYS_SINCE_ASSIGNED');
                      }}
                      variant="outlined"
                      size="small"
                      className={classes.radioNumber}
                      disabled={onSale || archived}
                    />
                    <Typography className={classes.radioText}>
                      {t('card.daysFromPurchase')}
                    </Typography>
                  </div>
                )}
                className={classes.formControlLabel}
                disabled={onSale || archived}
              />
              <FormControlLabel
                value="DAYS_SINCE_ACTIVATED"
                control={<Radio color="primary" />}
                label={(
                  <div className={classes.radioRow}>
                    <NumberField
                      label={t('card.number')}
                      value={daysFromFirstUse}
                      onChange={({ target }) => {
                        setDaysFromFirstUse(target.value);
                        setValidType('DAYS_SINCE_ACTIVATED');
                      }}
                      variant="outlined"
                      size="small"
                      className={classes.radioNumber}
                      disabled={onSale || archived}
                    />
                    <Typography className={classes.radioText}>
                      {t('card.daysFromFirstUse')}
                    </Typography>
                  </div>
                )}
                className={classes.formControlLabel}
                disabled={onSale || archived}
              />
            </RadioGroup>
            <NewHeader
              headerText={t('card.packageContents')}
              newText={t('card.attraction')}
              onClick={() => setOpenAttraction('new')}
              small
              bigMargin
              disabled={onSale || archived}
            />
            <div className={classes.attractions}>
              {discounts.map((discount, i) => (
                <div
                  className={classes.attraction}
                  key={discount.id}
                  onClick={() => setOpenAttraction(discount.id)}
                >
                  <Typography className={classes.numberOf}>
                    {i + 1}
                  </Typography>
                  <Typography className={classes.attractionName}>
                    {attractions.find((x) => x.id === discount.attraction)!.name}
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={(event) => {
                      event.stopPropagation();
                      setDiscounts(discounts.filter((x) => x.attraction !== discount.attraction));
                    }}
                    disabled={onSale || archived}
                  >
                    <Clear />
                  </IconButton>
                </div>
              ))}
            </div>
            <div className={classes.row}>
              <NumberField
                label={t('card.packagePrice')}
                value={price}
                onChange={({ target }) => {
                  setPrice(target.value);
                  setInvalidPrice(false);
                }}
                type="number"
                variant="outlined"
                size="small"
                className={classes.price}
                disabled={free || onSale || archived}
                inputProps={{
                  step: 0.01,
                }}
                error={invalidPrice}
                helperText={invalidPrice ? t('common.invalidValue') : ''}
              />
              <Typography className={classes.currency}>
                PLN
              </Typography>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={free}
                    onChange={({ target }) => {
                      setFree(target.checked);
                      if (target.checked) {
                        setPrice('');
                        setInvalidPrice(false);
                      }
                    }}
                    color="primary"
                    disabled={onSale || archived}
                  />
                )}
                label={t('card.freePackage')}
              />
            </div>
            <div className={classes.limitRow}>
              <NumberField
                label={t('card.packagesForSale')}
                value={limit}
                onChange={({ target }) => {
                  setLimit(target.value);
                  setLimitError(false);
                }}
                variant="outlined"
                size="small"
                className={classes.limit}
                disabled={noLimit || archived}
                error={limitError}
                helperText={limitError ? t('card.salesLimitError') : ''}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={noLimit}
                    onChange={({ target }) => {
                      setNoLimit(target.checked);
                      if (target.checked) {
                        setLimit('');
                        setLimitError(false);
                      }
                    }}
                    color="primary"
                  />
                )}
                label={t('card.noLimit')}
                disabled={archived}
              />
            </div>
            <div className={classes.row}>
              <Typography className={classes.saleDates}>
                {t('card.packageSaleDates')}
                :
              </Typography>
              <CustomDatePicker
                value={saleFrom}
                onChange={setSaleFrom}
                label={t('card.from')}
                className={classes.from}
                maxDate={onSale ? moment() : undefined}
                disabled={archived}
              />
              <CustomDatePicker
                value={saleTo}
                onChange={setSaleTo}
                label={t('card.to')}
                className={classes.to}
                disabled={archived}
              />
            </div>
            <div className={classes.row}>
              <Typography className={classes.packageStatus}>
                {t('card.packageStatus')}
                :
              </Typography>
              {(() => {
                switch (status) {
                  case 'ON_SALE':
                    return (
                      <Typography className={classes.onSale}>
                        {t('card.onSale')}
                      </Typography>
                    );
                  case 'DRAFT':
                    return (
                      <Typography className={classes.saleStarting}>
                        {`${t('card.saleStarting')} ${saleFrom?.format('DD.MM.Y')}`}
                      </Typography>
                    );
                  case 'ARCHIVED':
                    return (
                      <Typography className={classes.archived}>
                        {t('card.archived')}
                      </Typography>
                    );
                  default:
                    return (
                      <Typography>
                        -
                      </Typography>
                    );
                }
              })()}
            </div>
            <div className={classes.saveRow}>
              {selectedPackage !== 'new' && (
                <Button
                  color="primary"
                  className={classes.discardChanges}
                  onClick={() => loadData(
                    packages.find((x) => x.id === selectedPackage) as IPackage,
                  )}
                >
                  {t('card.discardChanges')}
                </Button>
              )}
              <Button
                color="primary"
                variant="contained"
                disabled={(
                  !namePL || !nameEN || !validType || (validType === 'FIXED_PERIOD' && (!from || !to))
                  || (validType === 'DAYS_SINCE_ASSIGNED' && !daysFromPurchase)
                  || (validType === 'DAYS_SINCE_ACTIVATED' && !daysFromFirstUse)
                  || !discounts.length
                  || (!free && !price)
                  || (!noLimit && !limit)
                  || !saleFrom || !saleTo
                  || saving
                )}
                onClick={async () => {
                  if (!saleFrom || !saleTo) {
                    return;
                  }
                  if (!free && (Number(price) * 100) % 1 !== 0) {
                    setInvalidPrice(true);
                    return;
                  }
                  if (onSale && !noLimit && Number(limit) < soldPackages) {
                    setLimitError(true);
                    return;
                  }
                  const requestData: any = {
                    namePL,
                    nameEN,
                    price: Number(price) || undefined,
                    validity: {
                      type: validType,
                      days: Number(daysFromFirstUse || daysFromPurchase) || null,
                      from: from ? formatISO(from) : null,
                      to: to ? formatISO(to) : null,
                    },
                    sales: {
                      limit: noLimit ? null : Number(limit),
                      from: formatISO(saleFrom),
                      to: formatISO(saleTo),
                      sold: soldPackages,
                    },
                    discounts,
                    image: template ? template.id : null,
                  };
                  const newPackage = {
                    ...requestData,
                    image: template ? template.file : null,
                  };
                  try {
                    setSaving(true);
                    if (selectedPackage === 'new') {
                      const { data } = await axios.post('/card/package', requestData);
                      setPackages([...packages, data]);
                    } else {
                      const { data } = await axios.put(`/card/package/${selectedPackage}`, requestData);
                      setPackages(packages.map((x) => (
                        x.id === selectedPackage ? {
                          ...x,
                          ...newPackage,
                          status: data,
                        } : x
                      )));
                    }
                    setSelectedPackage(null);
                  } finally {
                    setSaving(false);
                  }
                }}
              >
                {t('card.save')}
              </Button>
            </div>
          </>
        )}
      </div>
      <DeleteDialog
        open={deleteId !== null}
        onClose={() => setDeleteId(null)}
        text={t('card.packageDeleteConfirm', { name: deleteName })}
        onDelete={async () => {
          await axios.delete(`/card/package/${deleteId}`);
          if (selectedPackage === deleteId) {
            setSelectedPackage(null);
          }
          setPackages(packages.filter((x) => x.id !== deleteId));
          setDeleteId(null);
        }}
        canDelete
      />
    </div>
  );
}
