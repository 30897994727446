import React, {
  FormEvent, useContext, useEffect, useState,
} from 'react';
import {
  Button, Link as MuiLink, makeStyles, TextField, Typography,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { auth0, axios, useGetLink } from '../utils';
import { ReactComponent as GoogleIcon } from '../assets/google.svg';
import { ReactComponent as FacebookIcon } from '../assets/facebook.svg';
import PasswordField from '../common/PasswordField';
import AppContext from '../context';

const useStyles = makeStyles({
  header: {
    fontSize: 35,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 24,
  },
  googleButton: {
    justifyContent: 'flex-start',
    color: '#37001F',
    borderColor: '#37001F',
    padding: '5px 10px',
    marginBottom: 9,
  },
  googleIcon: {
    margin: '0 12px 0 2px',
  },
  facebookButton: {
    justifyContent: 'flex-start',
    color: '#3571B7',
    borderColor: '#3571B7',
    padding: '5px 10px',
    marginBottom: 13,
  },
  facebookIcon: {
    marginRight: 11,
  },
  or: {
    textAlign: 'center',
  },
  forgot: {
    display: 'block',
    marginTop: 8,
  },
  agreement: {
    fontSize: 14,
    margin: '12px 0',
  },
  dontHaveAccount: {
    fontSize: 14,
    marginTop: 8,
  },
  notVerified: {
    color: '#FF0000',
    fontSize: 14,
    marginTop: 8,
  },
  resendButton: {
    font: 'inherit',
  },
});

export default function Login() {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation<{
    accessDenied: boolean;
    notVerified: boolean;
    accessToken: string;
  }>();
  const getLink = useGetLink();
  const { showSnackbar } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    auth0.login({
      realm: 'Username-Password-Authentication',
      email,
      password,
    }, () => {
      setError('auth.wrongEmailOrPassword');
    });
  };
  useEffect(() => {
    if (location.state && location.state.accessDenied) {
      setError('auth.accessDenied');
    }
  }, [location.state]);
  return (
    <>
      <Typography className={classes.header}>
        {t('auth.logIn')}
      </Typography>
      <Button
        variant="outlined"
        fullWidth
        className={classes.googleButton}
        onClick={() => auth0.authorize({ connection: 'google-oauth2' })}
        disabled
      >
        <GoogleIcon className={classes.googleIcon} />
        {t('auth.continueWithGoogle')}
      </Button>
      <Button
        variant="outlined"
        fullWidth
        className={classes.facebookButton}
        onClick={() => auth0.authorize({ connection: 'facebook' })}
        disabled
      >
        <FacebookIcon className={classes.facebookIcon} />
        {t('auth.continueWithFacebook')}
      </Button>
      <Typography className={classes.or}>
        {t('auth.or')}
      </Typography>
      <form
        onSubmit={onSubmit}
      >
        <TextField
          label={t('auth.email')}
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
            // Don't clear error on autofill
            if (event.nativeEvent instanceof InputEvent) {
              setError(null);
            }
          }}
          error={error !== null}
          id="email"
        />
        <PasswordField
          label={t('auth.password')}
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
            if (event.nativeEvent instanceof InputEvent) {
              setError(null);
            }
          }}
          error={error !== null}
          helperText={error ? t(error) : null}
          id="password"
        />
        {location.state && location.state.notVerified && (
          <Typography className={classes.notVerified}>
            {t('auth.notVerified')}
            {' '}
            <MuiLink
              component="button"
              type="button"
              onClick={async () => {
                await axios.post('/users/verification/resend', {}, {
                  headers: {
                    Authorization: `Bearer ${location.state.accessToken}`,
                  },
                });
                showSnackbar(t('auth.linkResent'));
              }}
              className={classes.resendButton}
            >
              {t('auth.resendLink')}
            </MuiLink>
          </Typography>
        )}
        <MuiLink
          className={classes.forgot}
          component={Link}
          to={getLink('/auth/recover-password')}
        >
          {t('auth.forgotLink')}
        </MuiLink>
        <Typography className={classes.agreement}>
          <Trans
            i18nKey="auth.loginAgreement"
            components={[
              <MuiLink component={Link} to={getLink('/terms-and-conditions')} />,
              <MuiLink component={Link} to={getLink('/privacy-policy')} />,
            ]}
          />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          data-testid="submit-button"
        >
          {t('auth.logInButton')}
        </Button>
      </form>
      <Typography className={classes.dontHaveAccount}>
        <Trans
          i18nKey="auth.dontHaveAccount"
          components={[
            <MuiLink
              component={Link}
              to={getLink('/auth/register')}
            />,
          ]}
        />
      </Typography>
    </>
  );
}
