import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Button, Checkbox, FormControlLabel, makeStyles, MenuItem, TextField, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import * as R from 'ramda';
import { std } from 'mathjs';
import {
  IDepartureTime, IProvider, IRoute, IScheduleChange, IStopWithRoutes,
} from '../../interfaces';
import { axios } from '../../utils';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import CustomDatePicker from '../../common/CustomDatePicker';
import { ReactComponent as Clear } from '../../assets/clear.svg';
import SynchronizationTable from './SynchronizationTable';
import { ProviderContext } from '../../context';
import VersionInfo from '../VersionInfo';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
  },
  top: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  left: {
    flexGrow: 1,
  },
  results: {
    width: 550,
    flexShrink: 0,
  },
  header: {
    fontWeight: 400,
    marginBottom: 16,
    color: '#37001F',
  },
  field: {
    width: 300,
    marginRight: 24,
  },
  stop: {
    width: 400,
    marginRight: 24,
  },
  datePicker: {
    width: 240,
    marginRight: 24,
  },
  row: {
    display: 'flex',
    marginBottom: 16,
  },
  hourFrom: {
    width: 130,
    marginRight: 24,
  },
  hourTo: {
    width: 130,
  },
  routes: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 4,
  },
  routesLabel: {
    margin: '14px 24px 14px 0',
    color: '#37001F',
  },
  route: {
    margin: 4,
    fontSize: 24,
    fontWeight: 400,
    padding: '1px 18px',
    borderRadius: 15,
  },
  resultRow: {
    display: 'flex',
    marginBottom: 4,
  },
  resultLabel: {
    width: 260,
    color: '#37001F',
    marginRight: 16,
  },
  bottom: {
    backgroundColor: '#F5F5F5',
    padding: 16,
    margin: '0 -16px',
  },
  table: {
    backgroundColor: 'white',
    borderCollapse: 'collapse',
  },
  headerCell: {
    padding: '8px 12px',
    fontWeight: 400,
    color: '#37001F',
    border: '1px solid #37001F',
    textAlign: 'left',
  },
  cell: {
    padding: '8px 12px',
    color: '#37001F',
    border: '1px solid #37001F',
  },
  routeSelect: {
    width: 80,
  },
  timeSelects: {
    display: 'flex',
    alignItems: 'center',
  },
  tableTimeSelect: {
    width: 70,
  },
  colon: {
    margin: '0 8px',
  },
  intervalMin: {
    marginLeft: 8,
  },
  clear: {
    margin: '0 8px 0 24px',
  },
  delete: {
    padding: 0,
    minWidth: 0,
    borderRadius: '50%',
    marginLeft: 'auto',
    marginRight: 8,
  },
  positive: {
    color: '#C82727',
  },
  negative: {
    color: '#12A72F',
  },
  resultValue: {
    width: 120,
    display: 'inline-block',
  },
});

export default function Synchronization() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { providers, version, user } = useContext(ProviderContext);
  const [selectedProvider, setSelectedProvider] = useState<IProvider | null>(null);
  const [stops, setStops] = useState<IStopWithRoutes[]>([]);
  const [routes, setRoutes] = useState<IRoute[]>([]);
  const [selectedStop, setSelectedStop] = useState<IStopWithRoutes | null>(null);
  const [date, setDate] = useState<Moment>(moment());
  const [from, setFrom] = useState<string | null>('6:00');
  const [to, setTo] = useState<string | null>('18:00');
  const [selectedRoutes, setSelectedRoutes] = useState<string[]>([]);
  const [departureTimes, setDepartureTimes] = useState<IDepartureTime[]>([]);
  const [wheelchair, setWheelchair] = useState<boolean>(false);
  const lastId = useRef(0);
  useEffect(() => {
    setSelectedProvider(null);
    setStops([]);
    setRoutes([]);
    setSelectedStop(null);
    setDate(moment());
    setFrom('6:00');
    setTo('18:00');
    setSelectedRoutes([]);
    setDepartureTimes([]);
    setWheelchair(false);
  }, [version]);
  const newRow = {
    route: '',
    fromHour: null,
    fromMinute: null,
    toHour: null,
    toMinute: null,
    firstTripHour: null,
    firstTripMinute: null,
    interval: null,
    new: true,
  };
  const [scheduleChanges, setScheduleChanges] = useState<IScheduleChange[]>(
    [{ ...newRow, id: lastId.current }],
  );
  const hourOptions = Array(25).fill(null).map((x, i) => (
    `${String(i)}:00`
  ));
  const selectedTimes = departureTimes.filter((x) => (
    selectedRoutes.includes(x.route) && from && to
    && moment(x.departure, 'H:m').isBetween(moment(from, 'H:s'), moment(to, 'H:s'))
    && (!wheelchair || x.wheelchairAccessible)
  ));
  const getAverage = (arr: number[]) => Math.round(R.sum(arr) / arr.length) / 2;
  const getDiffs = (arr: IDepartureTime[]) => R.tail(arr).map((x, i) => (
    moment(x.departure, 'H:m').diff(moment(arr[i].departure, 'H:m'), 'seconds')
  ));
  const getResults = (times: IDepartureTime[]) => {
    const uniqTimes = R.uniqBy(R.prop('departure'), times);
    const averageWait = getAverage(getDiffs(uniqTimes));
    return {
      average: moment.duration(averageWait, 'seconds'),
      std: moment.duration(times.length > 1 ? std(getDiffs(times)) : NaN, 'seconds'),
      overlapping: times.length - uniqTimes.length,
    };
  };

  let changedTimes = R.clone(selectedTimes);
  scheduleChanges.forEach((scheduleChange) => {
    if (!scheduleChange.route || !selectedRoutes.includes(scheduleChange.route)) return;
    const firstTrip = moment({
      hour: Number(scheduleChange.firstTripHour),
      minute: Number(scheduleChange.firstTripMinute),
    });
    if (scheduleChange.interval) {
      changedTimes = changedTimes
        .filter((time) => (
          time.route !== scheduleChange.route
          || !moment(time.departure, 'H:m').isBetween(
            moment({
              hour: Number(scheduleChange.fromHour),
              minute: Number(scheduleChange.fromMinute),
            }),
            moment({
              hour: Number(scheduleChange.toHour),
              minute: Number(scheduleChange.toMinute),
            }),
            undefined,
            '[]',
          )
        ))
        .concat(R.unfold(
          (time) => {
            if (time.isSameOrBefore(moment({
              hour: Number(scheduleChange.toHour),
              minute: Number(scheduleChange.toMinute),
            }))) {
              return [
                {
                  route: scheduleChange.route,
                  departure: time.format('HH:mm'),
                  wheelchairAccessible: true,
                },
                time.clone().add(Number(scheduleChange.interval), 'minutes'),
              ];
            }
            return false;
          },
          firstTrip,
        ));
    } else {
      const originalFirstTrip = changedTimes.find(R.whereEq({ route: scheduleChange.route }));
      if (!originalFirstTrip) return;
      const diff = firstTrip.diff(moment(originalFirstTrip!.departure, 'H:m'), 'minutes');
      changedTimes = changedTimes.map((x) => (
        x.route === scheduleChange.route ? {
          ...x,
          departure: moment(x.departure, 'H:m').add(diff, 'minutes').format('HH:mm'),
        } : x
      ));
    }
  });
  changedTimes = R.sort(R.ascend(R.prop('departure')), changedTimes);

  const results = getResults(selectedTimes);
  const changedResults = getResults(changedTimes);
  const resultsDiff = {
    average: changedResults.average.clone().subtract(results.average),
    std: changedResults.std.clone().subtract(results.std),
    overlapping: changedResults.overlapping - results.overlapping,
  };

  const tableHourOptions = Array(24).fill(null).map((x, i) => String(i));
  const tableMinuteOptions = Array(60).fill(null).map((x, i) => String(i).padStart(2, '0'));
  const intervalOptions = Array(90).fill(null).map((x, i) => String(i + 1));
  const commonSelectProps = {
    getOptionLabel: R.identity,
    className: classes.tableTimeSelect,
    noAdornment: true,
    disableClearable: true,
  };
  const getDepartureTimes = async (
    currentStop: IStopWithRoutes,
    currentDate: Moment,
    currentFrom: string,
    currentTo: string,
  ) => {
    if (!selectedProvider) return;
    const { data } = await axios.get(`/${selectedProvider.id}/stop/${currentStop.id}/departures`, {
      params: {
        date: currentDate.format('YYYY-MM-DD'),
        from: moment(currentFrom, 'H:m').format('HH:mm'),
        to: moment(currentTo, 'H:m').subtract(1, 'minute').format('HH:mm'),
        ...((selectedProvider.id === user.agency && version)
          ? { providerVersion: version.id }
          : {}),
      },
    });
    setDepartureTimes(data);
  };
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <div className={classes.left}>
          <VersionInfo />
          <Typography className={classes.header}>
            {t('synchronization.defineSegment')}
          </Typography>
          <div className={classes.row}>
            <CustomAutocomplete
              options={providers}
              value={selectedProvider}
              onChange={async (event, value) => {
                setSelectedProvider(value);
                setSelectedStop(null);
                setSelectedRoutes([]);
                setScheduleChanges([{ ...newRow, id: lastId.current }]);
                if (value) {
                  const options = (version && value.id === user.agency)
                    ? { params: { providerVersion: version.id } }
                    : {};
                  const [
                    { data },
                    { data: newRoutes },
                  ] = await Promise.all([
                    axios.get(`/${value.id}/stops`, options),
                    axios.get(`/${value.id}/routes`, options),
                  ]);
                  setStops(data);
                  setRoutes(newRoutes);
                }
              }}
              label={t('export.dataSource')}
              className={classes.field}
              getOptionLabel={R.prop('name')}
            />
            <CustomAutocomplete
              options={stops}
              value={selectedStop}
              onChange={(event, value) => {
                setSelectedStop(value);
                setScheduleChanges([{ ...newRow, id: lastId.current }]);
                if (value && selectedProvider && from && to) {
                  setSelectedRoutes(value.routes);
                  getDepartureTimes(value, date, from, to);
                }
              }}
              label={t('synchronization.stop')}
              className={classes.stop}
              getOptionLabel={R.prop('name')}
            />
          </div>
          <div className={classes.routes}>
            <Typography className={classes.routesLabel}>
              {t('synchronization.routes')}
            </Typography>
            {selectedStop && selectedStop.routes.map((id) => (
              <Button
                key={id}
                color="primary"
                variant={selectedRoutes.includes(id) ? 'contained' : 'outlined'}
                onClick={() => setSelectedRoutes(
                  selectedRoutes.includes(id)
                    ? selectedRoutes.filter((x) => x !== id)
                    : [...selectedRoutes, id],
                )}
                className={classes.route}
              >
                {routes.find(R.whereEq({ id }))!.name}
              </Button>
            ))}
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={wheelchair}
                onChange={({ target }) => setWheelchair(target.checked)}
                color="primary"
              />
            )}
            label={t('synchronization.wheelchair')}
          />
        </div>
        <div className={classes.results}>
          <Typography className={classes.header}>
            {t('synchronization.parameters')}
          </Typography>
          <div className={classes.row}>
            <CustomDatePicker
              value={date}
              onChange={(newDate) => {
                if (newDate) {
                  setDate(newDate);
                  if (selectedStop && from && to) {
                    getDepartureTimes(selectedStop, newDate, from, to);
                  }
                }
              }}
              className={classes.datePicker}
              label={t('synchronization.date')}
              format="dddd, DD.MM.Y"
            />
            <CustomAutocomplete
              options={hourOptions}
              getOptionLabel={R.identity}
              value={from}
              onChange={(event, value) => {
                setFrom(value);
                if (selectedStop && value && to) {
                  getDepartureTimes(selectedStop, date, value, to);
                }
              }}
              className={classes.hourFrom}
              label={t('synchronization.from')}
              noAdornment
            />
            <CustomAutocomplete
              options={hourOptions}
              getOptionLabel={R.identity}
              value={to}
              onChange={(event, value) => {
                setTo(value);
                if (selectedStop && from && value) {
                  getDepartureTimes(selectedStop, date, from, value);
                }
              }}
              className={classes.hourTo}
              label={t('synchronization.to')}
              noAdornment
            />
          </div>
          <div className={classes.resultRow}>
            <Typography className={classes.resultLabel}>
              {t('synchronization.average')}
            </Typography>
            <Typography>
              <span className={classes.resultValue} data-testid="average-wait">
                {changedResults.average.minutes() > 0 && (
                  <>
                    {changedResults.average.minutes()}
                    {' '}
                    {t('synchronization.min')}
                    {' '}
                  </>
                )}
                {changedResults.average.seconds() > 0 && (
                  <>
                    {changedResults.average.seconds()}
                    {' '}
                    {t('synchronization.sec')}
                  </>
                )}
              </span>
              {(!Number.isNaN(resultsDiff.average.asSeconds())
                && resultsDiff.average.asSeconds() !== 0) && (
                <span
                  className={
                    resultsDiff.average.asSeconds() > 0 ? classes.positive : classes.negative
                  }
                  data-testid="average-diff"
                >
                  {' '}
                  [
                  {resultsDiff.average.asSeconds() > 0 ? '+' : '-'}
                  {resultsDiff.average.minutes() !== 0 && (
                    <>
                      {Math.abs(resultsDiff.average.minutes())}
                      {' '}
                      {t('synchronization.min')}
                      {' '}
                    </>
                  )}
                  {resultsDiff.average.seconds() !== 0 && (
                    <>
                      {Math.abs(resultsDiff.average.seconds())}
                      {' '}
                      {t('synchronization.sec')}
                    </>
                  )}
                  ]
                </span>
              )}
            </Typography>
          </div>
          <div className={classes.resultRow}>
            <Typography className={classes.resultLabel}>
              {t('synchronization.deviation')}
            </Typography>
            <Typography>
              <span className={classes.resultValue}>
                {changedResults.std.minutes() > 0 && (
                  <>
                    {changedResults.std.minutes()}
                    {' '}
                    {t('synchronization.min')}
                    {' '}
                  </>
                )}
                {!Number.isNaN(changedResults.std.seconds()) && (
                  <>
                    {changedResults.std.seconds()}
                    {' '}
                    {t('synchronization.sec')}
                  </>
                )}
              </span>
              {(!Number.isNaN(resultsDiff.std.asSeconds())
                && resultsDiff.std.asSeconds() !== 0) && (
                <span
                  className={
                    resultsDiff.std.asSeconds() > 0 ? classes.positive : classes.negative
                  }
                >
                  {' '}
                  [
                  {resultsDiff.std.asSeconds() > 0 ? '+' : '-'}
                  {resultsDiff.std.minutes() !== 0 && (
                    <>
                      {Math.abs(resultsDiff.std.minutes())}
                      {' '}
                      {t('synchronization.min')}
                      {' '}
                    </>
                  )}
                  {resultsDiff.std.seconds() !== 0 && (
                    <>
                      {Math.abs(resultsDiff.std.seconds())}
                      {' '}
                      {t('synchronization.sec')}
                    </>
                  )}
                  ]
                </span>
              )}
            </Typography>
          </div>
          <div className={classes.resultRow}>
            <Typography className={classes.resultLabel}>
              {t('synchronization.overlapping')}
            </Typography>
            <Typography>
              <span className={classes.resultValue}>
                {selectedTimes.length > 1 && String(changedResults.overlapping)}
              </span>
              {resultsDiff.overlapping !== 0 && (
                <span
                  className={
                    resultsDiff.overlapping > 0 ? classes.positive : classes.negative
                  }
                >
                  {' '}
                  [
                  {resultsDiff.overlapping > 0 ? '+' : '-'}
                  {Math.abs(resultsDiff.overlapping)}
                  ]
                </span>
              )}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.bottom}>
        <Typography className={classes.header}>
          {t('synchronization.modifySchedule')}
        </Typography>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.headerCell}>
                {t('synchronization.route')}
              </th>
              <th className={classes.headerCell}>
                {t('synchronization.from')}
              </th>
              <th className={classes.headerCell}>
                {t('synchronization.to')}
              </th>
              <th className={classes.headerCell}>
                {t('synchronization.firstTrip')}
              </th>
              <th className={classes.headerCell}>
                {t('synchronization.interval')}
              </th>
            </tr>
          </thead>
          <tbody>
            {scheduleChanges.map((x) => {
              const update = (newChange: Partial<IScheduleChange>) => setScheduleChanges(R.map(
                (y) => (y.id === x.id ? { ...y, ...newChange } : y),
              ));
              return (
                <tr key={x.route || 'new'}>
                  <td className={classes.cell}>
                    <TextField
                      select
                      value={x.route}
                      onChange={({ target: { value } }) => {
                        let fromHour = null;
                        let fromMinute = null;
                        const firstDeparture = R.find((y) => y.route === value, selectedTimes);
                        if (firstDeparture) {
                          const [hour, minute] = firstDeparture.departure.split(':');
                          fromHour = String(Number(hour));
                          fromMinute = minute;
                        }
                        let toHour = null;
                        let toMinute = null;
                        const lastDeparture = R.findLast((y) => y.route === value, selectedTimes);
                        if (lastDeparture) {
                          const [hour, minute] = lastDeparture.departure.split(':');
                          toHour = String(Number(hour));
                          toMinute = minute;
                        }
                        const routeDepartures = R.filter((y) => y.route === value, selectedTimes);
                        const diffs = getDiffs(routeDepartures);
                        let interval = null;
                        if (diffs[0] && diffs.every(R.equals(diffs[0]))) {
                          interval = String(diffs[0] / 60);
                        }
                        update({
                          route: value,
                          fromHour,
                          fromMinute,
                          toHour,
                          toMinute,
                          firstTripHour: fromHour,
                          firstTripMinute: fromMinute,
                          interval,
                        });
                      }}
                      variant="outlined"
                      size="small"
                      className={classes.routeSelect}
                      data-testid="modify-route"
                    >
                      {selectedRoutes.map((id) => (
                        <MenuItem value={id} key={id}>
                          {routes.find(R.whereEq({ id }))!.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </td>
                  <td className={classes.cell}>
                    <div className={classes.timeSelects}>
                      <CustomAutocomplete
                        options={tableHourOptions}
                        value={x.fromHour}
                        onChange={(event, value) => update({ fromHour: value })}
                        TextFieldProps={{ inputProps: { 'data-testid': 'modify-from-hour' } }}
                        {...commonSelectProps}
                      />
                      <Typography className={classes.colon}>
                        :
                      </Typography>
                      <CustomAutocomplete
                        options={tableMinuteOptions}
                        value={x.fromMinute}
                        onChange={(event, value) => update({ fromMinute: value })}
                        TextFieldProps={{ inputProps: { 'data-testid': 'modify-from-minute' } }}
                        {...commonSelectProps}
                      />
                    </div>
                  </td>
                  <td className={classes.cell}>
                    <div className={classes.timeSelects}>
                      <CustomAutocomplete
                        options={tableHourOptions}
                        value={x.toHour}
                        onChange={(event, value) => update({ toHour: value })}
                        TextFieldProps={{ inputProps: { 'data-testid': 'modify-to-hour' } }}
                        {...commonSelectProps}
                      />
                      <Typography className={classes.colon}>
                        :
                      </Typography>
                      <CustomAutocomplete
                        options={tableMinuteOptions}
                        value={x.toMinute}
                        onChange={(event, value) => update({ toMinute: value })}
                        TextFieldProps={{ inputProps: { 'data-testid': 'modify-to-minute' } }}
                        {...commonSelectProps}
                      />
                    </div>
                  </td>
                  <td className={classes.cell}>
                    <div className={classes.timeSelects}>
                      <CustomAutocomplete
                        options={tableHourOptions}
                        value={x.firstTripHour}
                        onChange={(event, value) => update({ firstTripHour: value })}
                        TextFieldProps={{ inputProps: { 'data-testid': 'modify-first-hour' } }}
                        {...commonSelectProps}
                      />
                      <Typography className={classes.colon}>
                        :
                      </Typography>
                      <CustomAutocomplete
                        options={tableMinuteOptions}
                        value={x.firstTripMinute}
                        onChange={(event, value) => update({ firstTripMinute: value })}
                        TextFieldProps={{ inputProps: { 'data-testid': 'modify-first-minute' } }}
                        {...commonSelectProps}
                      />
                    </div>
                  </td>
                  <td className={classes.cell}>
                    <div className={classes.timeSelects}>
                      <CustomAutocomplete
                        options={intervalOptions}
                        value={x.interval}
                        onChange={(event, value) => update({ interval: value })}
                        TextFieldProps={{ inputProps: { 'data-testid': 'modify-interval' } }}
                        {...commonSelectProps}
                      />
                      <Typography className={classes.intervalMin}>
                        {t('synchronization.min')}
                        .
                      </Typography>
                      {x.new ? (
                        <>
                          <Button
                            color="primary"
                            className={classes.clear}
                            onClick={() => update(newRow)}
                          >
                            {t('synchronization.clear')}
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={!x.route}
                            onClick={() => {
                              lastId.current += 1;
                              setScheduleChanges(
                                R.prepend({ ...newRow, id: lastId.current }, R.map(
                                  (y) => (y.id === x.id ? { ...y, new: false } : y),
                                  scheduleChanges,
                                )),
                              );
                            }}
                          >
                            {t('synchronization.add')}
                          </Button>
                        </>
                      ) : (
                        <Button
                          color="primary"
                          className={classes.delete}
                          onClick={() => setScheduleChanges(
                            R.filter<IScheduleChange>((y) => y.id !== x.id),
                          )}
                        >
                          <Clear />
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {from && to && (
          <SynchronizationTable
            times={changedTimes}
            from={Number(from.split(':')[0])}
            to={Number(to.split(':')[0])}
            routes={routes}
          />
        )}
      </div>
    </div>
  );
}
