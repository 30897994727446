import React, { useContext } from 'react';
import { Link as MuiLink, makeStyles, Typography } from '@material-ui/core';
import {
  NavLink, Redirect, Route, Switch,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import logo from '../assets/interconnect-logo.svg';
import { useGetLink } from '../utils';
import AppContext, { CardContext } from '../context';
import Roles from './Roles';
import UserMenu from '../common/UserMenu';
import Site from './Site';
import Attraction from './Attraction';
import Organizations from './Organizations';
import Packages from './Packages';
import Statistics from './Statistics';
import Cards from './Cards';

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    backgroundColor: 'white',
    padding: '0 32px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1000px)': {
      padding: '0 16px',
    },
    '@media (max-width: 1000px), (max-height: 800px)': {
      height: 'auto',
      minHeight: '100vh',
    },
  },
  nav: {
    height: 43,
    flexShrink: 0,
    padding: '0 32px',
    margin: '0 -32px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(232, 232, 232, 0.5)',
  },
  logo: {
    height: 41,
    marginRight: 'auto',
  },
  navIconLink: {
    display: 'flex',
    justifyContent: 'center',
    width: 100,
    padding: '8px 0',
  },
  navLink: {
    color: '#a1145c',
    fontSize: 20,
    textDecoration: 'none',
    textAlign: 'center',
    width: 200,
    whiteSpace: 'nowrap',
    margin: '0 16px',
  },
  active: {
    fontWeight: 700,
  },
  main: {
    flexGrow: 1,
    minHeight: 400,
    display: 'flex',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 0',
    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  switch: {
    '@media (max-width: 1000px)': {
      marginBottom: 16,
    },
  },
  switchText: {
    fontSize: 12,
  },
  copyright: {
    whiteSpace: 'nowrap',
    fontSize: 12,
    color: '#37001F',
  },
});

export default function Card() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const getLink = useGetLink();
  const { name, user, interconnectNumber } = useContext(AppContext);
  if (!name || !user) return null;
  return (
    <CardContext.Provider
      value={{ name, user }}
    >
      <div className={classes.root}>
        <div className={classes.nav}>
          <img
            src={logo}
            alt="Interconnect logo"
            className={classes.logo}
          />
          {user.role === 'ADMIN' && (
            <Typography
              component={NavLink}
              to={getLink('/card/organizations')}
              className={classes.navLink}
              activeClassName={classes.active}
            >
              {t('card.organizations')}
            </Typography>
          )}
          {(user.role === 'ADMIN' || ['CARD_OFFERS_MANAGER', 'SITE_MANAGER'].includes(user.cardRole || '')) && (
            <Typography
              component={NavLink}
              to={getLink('/card/roles')}
              className={classes.navLink}
              activeClassName={classes.active}
            >
              {t('card.roles')}
            </Typography>
          )}
          {user.cardRole === 'CARD_OFFERS_MANAGER' && (
            <Typography
              component={NavLink}
              to={getLink('/card/packages')}
              className={classes.navLink}
              activeClassName={classes.active}
            >
              {t('card.packages')}
            </Typography>
          )}
          {user.cardRole === 'SITE_MANAGER' && (
            <Typography
              component={NavLink}
              to={getLink('/card/site')}
              className={classes.navLink}
              activeClassName={classes.active}
            >
              {t('card.site')}
            </Typography>
          )}
          {user.cardRole === 'CARD_SELLER' && (
            <Typography
              component={NavLink}
              to={getLink('/card/cards')}
              className={classes.navLink}
              activeClassName={classes.active}
            >
              {t('card.cards')}
            </Typography>
          )}
          {user.cardRole === 'CARD_OFFERS_MANAGER' && (
            <Typography
              component={NavLink}
              to={getLink('/card/statistics')}
              className={classes.navLink}
              activeClassName={classes.active}
            >
              {t('card.statistics')}
            </Typography>
          )}
          <UserMenu
            name={name}
            role={user.role}
            agency={user.agency}
            interconnectNumber={interconnectNumber}
          />
        </div>
        <div className={classes.main}>
          <Switch>
            {user.cardRole === 'CARDS_ADMIN' && (
              <Route path="/:language/card/organizations" exact component={Organizations} />
            )}
            {(['CARDS_ADMIN', 'CARD_OFFERS_MANAGER', 'SITE_MANAGER'].includes(user.cardRole || '')) && (
              <Route path="/:language/card/roles" exact component={Roles} />
            )}
            {user.cardRole === 'CARD_OFFERS_MANAGER' && (
              <Route path="/:language/card/packages" exact component={Packages} />
            )}
            {user.cardRole === 'CARD_OFFERS_MANAGER' && (
              <Route path="/:language/card/statistics/:tab?" component={Statistics} />
            )}
            {user.cardRole === 'SITE_MANAGER' && (
              <Route path="/:language/card/site" exact component={Site} />
            )}
            {user.cardRole === 'SITE_MANAGER' && (
              <Route path="/:language/card/site/attraction/:id" exact component={Attraction} />
            )}
            {user.cardRole === 'CARD_SELLER' && (
              <Route path="/:language/card/cards" exact component={Cards} />
            )}
            {user.cardRole === 'CARD_SELLER' && (
              <Redirect from="/:language/card" exact to={getLink('/card/cards')} />
            )}
            {(['CARDS_ADMIN', 'CARD_OFFERS_MANAGER', 'SITE_MANAGER'].includes(user.cardRole || '')) && (
              <Redirect from="/:language/card" exact to={getLink('/card/roles')} />
            )}
          </Switch>
        </div>
        <div className={classes.footer}>
          <MuiLink
            className={classes.switch}
            component="button"
            onClick={() => {
              i18next.changeLanguage(i18n.language === 'pl' ? 'en' : 'pl');
            }}
          >
            <Typography className={classes.switchText}>
              {t('footer.switchLanguage')}
            </Typography>
          </MuiLink>
          <Typography className={classes.copyright}>
            Copyright © 2020 Innobaltica Sp. z o.o.
          </Typography>
        </div>
      </div>
    </CardContext.Provider>
  );
}
