import React, {
  useContext, useEffect, useState, useCallback,
} from 'react';
import {
  Button, IconButton, InputAdornment, makeStyles, MenuItem, TextField, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import * as R from 'ramda';
import { createFilterOptions, FilterOptionsState } from '@material-ui/lab';
import AppContext, { CardContext } from '../context';
import CustomAutocomplete from '../common/CustomAutocomplete';
import {
  ICardRole, ICardUser, IOrganization, IRolesSite, NameOrId,
} from '../interfaces';
import { axios } from '../utils';
import { ReactComponent as ChevronWhite } from '../assets/chevron-white.svg';
import { ReactComponent as ChevronPrimary } from '../assets/chevron-primary.svg';
import { ReactComponent as ChevronBig } from '../assets/chevron-big.svg';
import { ReactComponent as Search } from '../assets/search.svg';

const useStyles = makeStyles({
  root: {
    marginTop: 32,
    width: '100%',
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    color: '#37001F',
    marginBottom: 24,
  },
  search: {
    width: 600,
    backgroundColor: 'white',
  },
  content: {
    backgroundColor: '#F5F5F5',
    padding: 16,
    margin: '16px -16px',
  },
  table: {
    borderCollapse: 'collapse',
    backgroundColor: 'white',
    width: '100%',
  },
  cell: {
    fontSize: 16,
    fontWeight: 300,
    color: '#37001F',
    padding: '16px 20px',
    border: '1px solid #37001F',
    textAlign: 'left',
    position: 'relative',
    '&:nth-child(1)': {
      width: 60,
    },
    '&:nth-child(4)': {
      width: 250,
    },
    '&:nth-child(5)': {
      width: 400,
    },
  },
  headerCell: {
    fontWeight: 400,
  },
  numberCell: {
    textAlign: 'right',
  },
  iconCell: {
    textAlign: 'center',
  },
  roleSelect: {
    width: '100%',
  },
  agencySelect: {
    width: '100%',
  },
  nextPageButton: {
    minWidth: 20,
    width: 20,
    height: 20,
    borderRadius: '50%',
    padding: 0,
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translate(50%, 50%)',
    zIndex: 2,
    '&$pageButtonDisabled': {
      backgroundColor: '#b9b9b9',
    },
  },
  pageButtonDisabled: {},
  chevronFlipped: {
    transform: 'rotate(180deg)',
  },
  legend: {
    marginTop: 32,
  },
  legendElement: {
    display: 'flex',
    marginBottom: 4,
  },
  legendTerm: {
    width: 200,
    flexShrink: 0,
    fontWeight: 400,
    fontSize: 14,
    color: '#37001F',
  },
  legendDescription: {
    fontSize: 14,
    color: '#37001F',
  },
  saveContainer: {
    display: 'flex',
    margin: '8px 0 24px',
  },
  discardButton: {
    marginLeft: 'auto',
    marginRight: 16,
  },
  sortButton: {
    width: 28,
    height: 28,
    padding: 4,
    margin: '-4px -6px -4px 4px',
  },
  addUser: {
    display: 'flex',
    marginBottom: 24,
  },
  addUserEmail: {
    width: 300,
    marginRight: 16,
  },
  addUserName: {
    width: 300,
    marginRight: 16,
  },
  addUserRole: {
    width: 235,
    marginRight: 16,
  },
  addUserAgency: {
    width: 300,
    marginRight: 16,
  },
  smallIcon: {
    width: 16,
    height: 16,
    marginLeft: 8,
  },
  addUserOption: {
    display: 'flex',
    alignItems: 'center',
  },
  selectBorder: {
    border: 'none',
  },
  selectCell: {
    padding: '0 8px',
  },
  selectError: {
    border: '1px solid #f44336',
    margin: -1,
    padding: '1px 0',
    zIndex: 1,
    width: 'calc(100% + 2px)',
  },
  selectIconContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
  },
  selectIcon: {
    transform: 'rotate(90deg)',
  },
  selectInput: {
    fontWeight: 300,
    color: 'inherit',
  },
});

const filter = createFilterOptions<NameOrId>();
const filterOptions = (options: NameOrId[], params: FilterOptionsState<NameOrId>) => {
  const filtered = filter(options, params);
  if (params.inputValue !== '') {
    return [
      ...filtered,
      {
        name: params.inputValue,
      },
    ];
  }
  return filtered;
};

export default function Roles() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppContext);
  const { user: loggedInUser } = useContext(CardContext);
  const [users, setUsers] = useState<ICardUser[] | null>(null);
  const [originalUsers, setOriginalUsers] = useState<ICardUser[] | null>(null);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [sites, setSites] = useState<IRolesSite[]>([]);
  const getData = useCallback(async () => {
    const [
      { data },
      { data: organizationsData },
      { data: sitesData },
    ] = await Promise.all<{ data: any }>([
      axios.get('/card/users'),
      (loggedInUser.cardRole === 'CARDS_ADMIN' ? axios.get('/card/organizations') : { data: [] }),
      (loggedInUser.cardRole === 'CARD_OFFERS_MANAGER' ? axios.get('/card/sites') : { data: [] }),
    ]);
    const newUsers = data.map((user: any) => ({
      ...user,
      organization: user.organization ? { id: user.organization } : null,
      site: user.site ? { id: user.site } : null,
    }));
    setOrganizations(organizationsData);
    setSites(sitesData);
    setUsers(newUsers);
    setOriginalUsers(newUsers);
  }, [loggedInUser.cardRole]);
  useEffect(() => {
    getData();
  }, [getData]);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState<keyof ICardUser>('name');
  const [sortDescending, setSortDescending] = useState(false);
  const [addUserEmail, setAddUserEmail] = useState('');
  const [addUserName, setAddUserName] = useState('');
  const [addUserRole, setAddUserRole] = useState<ICardRole | ''>('');
  const [addUserOrganization, setAddUserOrganization] = useState<NameOrId | null>(null);
  const [addUserSite, setAddUserSite] = useState<NameOrId | null>(null);
  const [agencyErrors, setAgencyErrors] = useState<Record<string, boolean>>({});
  if (!users || !originalUsers) return null;
  const getRoleText = (role: ICardRole) => {
    switch (role) {
      case 'CARD_OFFERS_MANAGER':
        return t('card.offersManager');
      case 'SITE_MANAGER':
        return t('card.siteManager');
      case 'CARD_SELLER':
        return t('card.cardSeller');
      case 'CARD_INSPECTOR':
        return t('card.cardInspector');
      default:
        return '';
    }
  };
  const searchLower = search.toLowerCase();
  const getOrganizationName = (organization?: NameOrId | null) => {
    if (organization) {
      return 'id' in organization
        ? organizations.find(R.whereEq({ id: organization.id }))?.name || ''
        : organization.name;
    }
    return '';
  };
  const getSiteName = (site?: NameOrId | null) => {
    if (site) {
      return 'id' in site
        ? sites.find(R.whereEq({ id: site.id }))?.name || ''
        : site.name;
    }
    return '';
  };
  const renderOption = (getOptionLabel: (option: NameOrId) => string) => (option: NameOrId) => {
    if ('id' in option) {
      return getOptionLabel(option);
    }
    return t('card.addOption', { option: option.name });
  };
  const filteredUsers = R.sort(
    (sortDescending ? R.ascend : R.descend)(R.compose(R.defaultTo(''), (user: ICardUser) => {
      switch (sortColumn) {
        case 'role':
          return getRoleText(user.role);
        case 'organization':
          return getOrganizationName(user.organization);
        case 'site':
          return getSiteName(user.site);
        default:
          return user[sortColumn];
      }
    })),
    users.filter((user) => (
      user.name.toLowerCase().includes(searchLower)
      || user.email.toLowerCase().includes(searchLower)
      || getRoleText(user.role).toLowerCase().includes(searchLower)
      || getOrganizationName(user.organization)?.toLowerCase().includes(searchLower)
      || getSiteName(user.site)?.toLowerCase().includes(searchLower)
    )),
  );
  const pageSize = 10;
  const renderSortButton = (column: keyof ICardUser) => (
    <IconButton
      className={classes.sortButton}
      onClick={() => {
        if (sortColumn === column) {
          setSortDescending(R.not);
        } else {
          setSortColumn(column);
          setSortDescending(false);
        }
      }}
    >
      <ChevronPrimary className={sortColumn === column && sortDescending ? '' : classes.chevronFlipped} />
    </IconButton>
  );
  const roleOptions = [
    (loggedInUser.cardRole === 'CARDS_ADMIN' || loggedInUser.cardRole === 'CARD_OFFERS_MANAGER') ? (
      <MenuItem key={1} value="CARD_OFFERS_MANAGER">
        {t('card.offersManager')}
      </MenuItem>
    ) : null,
    (loggedInUser.cardRole === 'CARD_OFFERS_MANAGER' || loggedInUser.cardRole === 'SITE_MANAGER') ? (
      <MenuItem key={2} value="SITE_MANAGER">
        {t('card.siteManager')}
      </MenuItem>
    ) : null,
    loggedInUser.cardRole === 'CARD_OFFERS_MANAGER' ? (
      <MenuItem key={3} value="CARD_SELLER">
        {t('card.cardSeller')}
      </MenuItem>
    ) : null,
    loggedInUser.cardRole === 'SITE_MANAGER' ? (
      <MenuItem key={4} value="CARD_INSPECTOR">
        {t('card.cardInspector')}
      </MenuItem>
    ) : null,
    <MenuItem key={5} value="">
      {t('roles.none')}
    </MenuItem>,
  ];
  const getChangedUsers = (currentUsers: ICardUser[]) => currentUsers.map((user) => ({
    ...user,
    role: user.role || null,
  })).filter((user) => (
    !R.equals(originalUsers.find(R.whereEq({ email: user.email })), user)
  ));
  const saveUsers = async (currentUsers: ICardUser[]) => {
    const changedUsers = getChangedUsers(currentUsers);
    let valid = true;
    changedUsers.forEach((user) => {
      if ((loggedInUser.cardRole === 'CARDS_ADMIN' && user.role && user.organization === null)
        || ((loggedInUser.cardRole === 'CARD_OFFERS_MANAGER' && user.role === 'SITE_MANAGER') && user.site === null)) {
        setAgencyErrors(R.set(R.lensProp(user.email), true));
        valid = false;
      }
    });
    if (!valid) return;
    setSaveLoading(true);
    try {
      await axios.post('/card/users', changedUsers);
      await getData();
      showSnackbar(t('roles.success'));
    } catch {
      showSnackbar(t('roles.failure'));
    }
    setSaveLoading(false);
  };
  const prevPageButton = (
    <Button
      variant="contained"
      color="primary"
      onClick={() => setPage((x) => x - 1)}
      disabled={page === 0}
      classes={{
        contained: classes.nextPageButton,
        disabled: classes.pageButtonDisabled,
      }}
    >
      <ChevronWhite className={classes.chevronFlipped} />
    </Button>
  );
  const nextPageButton = (
    <Button
      variant="contained"
      color="primary"
      onClick={() => setPage(R.add(1))}
      disabled={(page + 1) * pageSize >= filteredUsers.length}
      classes={{
        contained: classes.nextPageButton,
        disabled: classes.pageButtonDisabled,
      }}
    >
      <ChevronWhite />
    </Button>
  );
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {t('roles.assignRoles')}
      </Typography>
      <div className={classes.addUser}>
        <TextField
          value={addUserName}
          onChange={({ target }) => setAddUserName(target.value)}
          className={classes.addUserName}
          variant="outlined"
          size="small"
          label={t('roles.addName')}
          id="add-name"
        />
        <TextField
          value={addUserEmail}
          onChange={({ target }) => setAddUserEmail(target.value)}
          className={classes.addUserEmail}
          variant="outlined"
          size="small"
          label={t('roles.addEmail')}
          id="add-email"
        />
        <TextField
          select
          value={addUserRole}
          onChange={({ target: { value } }) => {
            setAddUserRole(value as ICardRole);
            if (value === 'CARD_OFFERS_MANAGER' || value === 'CARD_SELLER') {
              setAddUserSite(null);
            }
          }}
          className={classes.addUserRole}
          variant="outlined"
          size="small"
          label={t('roles.addRole')}
          id="add-role"
        >
          {roleOptions}
        </TextField>
        {loggedInUser.cardRole === 'CARDS_ADMIN' && (
          <CustomAutocomplete
            options={organizations}
            value={addUserOrganization}
            onChange={(event, value) => {
              setAddUserOrganization(typeof value === 'string' ? { name: value } : value);
            }}
            className={classes.addUserAgency}
            label={t('card.addOrganization')}
            getOptionLabel={getOrganizationName}
            renderOption={renderOption(getOrganizationName)}
            filterOptions={filterOptions}
            freeSolo
            forcePopupIcon
          />
        )}
        {(loggedInUser.cardRole === 'CARD_OFFERS_MANAGER') && (
          <CustomAutocomplete
            options={sites}
            value={addUserSite}
            onChange={(event, value) => {
              setAddUserSite(typeof value === 'string' ? { name: value } : value);
            }}
            className={classes.addUserAgency}
            label={t('card.addSite')}
            getOptionLabel={getSiteName}
            renderOption={renderOption(getSiteName)}
            filterOptions={filterOptions}
            disabled={addUserRole !== 'SITE_MANAGER'}
            freeSolo
            forcePopupIcon
          />
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={(
            saveLoading || !addUserEmail || !addUserName || !addUserRole
            || (loggedInUser.cardRole === 'CARDS_ADMIN' && !addUserOrganization)
            || ((loggedInUser.cardRole === 'CARD_OFFERS_MANAGER' && addUserRole === 'SITE_MANAGER') && !addUserSite)
          )}
          onClick={async () => {
            if (addUserEmail && addUserName && addUserRole) {
              await saveUsers(R.append(
                {
                  email: addUserEmail,
                  name: addUserName,
                  role: addUserRole,
                  organization: addUserOrganization,
                  site: addUserSite,
                },
                users,
              ));
              setAddUserEmail('');
              setAddUserName('');
              setAddUserRole('');
              setAddUserOrganization(null);
              setAddUserSite(null);
            }
          }}
        >
          {t('roles.add')}
        </Button>
      </div>
      <div className={classes.content}>
        <div className={classes.saveContainer}>
          <TextField
            value={search}
            onChange={({ target }) => {
              setSearch(target.value);
              setPage(0);
            }}
            label={t('roles.search')}
            className={classes.search}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Button
            color="primary"
            className={classes.discardButton}
            onClick={() => {
              setUsers(originalUsers);
              setAgencyErrors({});
            }}
            disabled={getChangedUsers(users).length === 0}
          >
            {t('roles.discardChanges')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={saveLoading || getChangedUsers(users).length === 0}
            onClick={() => saveUsers(users)}
          >
            {t('roles.save')}
          </Button>
        </div>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classNames(classes.cell, classes.headerCell, classes.numberCell)}>
                {t('roles.no')}
              </th>
              <th className={classNames(classes.cell, classes.headerCell)}>
                {t('roles.name')}
                {renderSortButton('name')}
              </th>
              <th className={classNames(classes.cell, classes.headerCell)}>
                {t('roles.email')}
                {renderSortButton('email')}
              </th>
              <th className={classNames(classes.cell, classes.headerCell)}>
                {t('roles.role')}
                {renderSortButton('role')}
                {loggedInUser.cardRole === 'SITE_MANAGER' && prevPageButton}
              </th>
              {loggedInUser.cardRole === 'CARDS_ADMIN' && (
                <th className={classNames(classes.cell, classes.headerCell)}>
                  {t('roles.organization')}
                  {renderSortButton('organization')}
                  {prevPageButton}
                </th>
              )}
              {loggedInUser.cardRole === 'CARD_OFFERS_MANAGER' && (
                <th className={classNames(classes.cell, classes.headerCell)}>
                  {t('card.site')}
                  {renderSortButton('site')}
                  {prevPageButton}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {R.slice(page * pageSize, (page + 1) * pageSize, filteredUsers).map((user, i, arr) => (
              <tr key={user.email}>
                <td className={classNames(classes.cell, classes.numberCell)}>
                  {filteredUsers.indexOf(user) + 1}
                </td>
                <td className={classes.cell}>
                  {user.name}
                </td>
                <td className={classes.cell}>
                  {user.email}
                </td>
                <td className={classNames(classes.cell, classes.selectCell)}>
                  <TextField
                    select
                    value={user.role || ''}
                    onChange={({ target: { value } }) => setUsers(
                      R.map((x) => (
                        x.email === user.email ? {
                          ...x,
                          role: (value || null) as ICardRole,
                          organization: value === '' ? null : x.organization,
                          site: (loggedInUser.cardRole === 'CARD_OFFERS_MANAGER' && value !== 'SITE_MANAGER')
                            ? null
                            : x.site,
                        } : x
                      ), users),
                    )}
                    className={classes.roleSelect}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      className: classes.selectInput,
                      classes: { notchedOutline: classes.selectBorder },
                    }}
                    SelectProps={{
                      // eslint-disable-next-line react/prop-types
                      IconComponent: ({ className, ...rest }) => (
                        <div
                          {...rest}
                          className={classNames(className, classes.selectIconContainer)}
                        >
                          <ChevronBig className={classes.selectIcon} />
                        </div>
                      ),
                    }}
                  >
                    {roleOptions}
                  </TextField>
                  {loggedInUser.cardRole === 'SITE_MANAGER' && i === arr.length - 1 && nextPageButton}
                </td>
                {loggedInUser.cardRole === 'CARDS_ADMIN' && (
                  <td className={classNames(classes.cell, classes.selectCell)}>
                    <CustomAutocomplete
                      options={organizations}
                      value={user.organization || null}
                      onChange={(event, value) => {
                        setUsers(
                          R.map((x) => (
                            x.email === user.email
                              ? { ...x, organization: typeof value === 'string' ? { name: value } : value }
                              : x
                          ), users),
                        );
                        setAgencyErrors(R.set(R.lensProp(user.email), false));
                      }}
                      className={classNames(classes.agencySelect, {
                        [classes.selectError]: agencyErrors[user.email],
                      })}
                      TextFieldProps={{
                        InputProps: {
                          classes: { notchedOutline: classes.selectBorder },
                        },
                      }}
                      getOptionLabel={getOrganizationName}
                      renderOption={renderOption(getOrganizationName)}
                      filterOptions={filterOptions}
                      freeSolo
                      noAdornment
                      popupIcon={(
                        <div className={classes.selectIconContainer}>
                          <ChevronBig className={classes.selectIcon} />
                        </div>
                      )}
                      forcePopupIcon
                      disabled={user.role === null}
                    />
                    {i === arr.length - 1 && nextPageButton}
                  </td>
                )}
                {loggedInUser.cardRole === 'CARD_OFFERS_MANAGER' && (
                  <td className={classNames(classes.cell, classes.selectCell)}>
                    {user.role === 'SITE_MANAGER' && (
                      <CustomAutocomplete
                        options={sites}
                        value={user.site || null}
                        onChange={(event, value) => {
                          setUsers(
                            R.map((x) => (
                              x.email === user.email
                                ? { ...x, site: typeof value === 'string' ? { name: value } : value }
                                : x
                            ), users),
                          );
                          setAgencyErrors(R.set(R.lensProp(user.email), false));
                        }}
                        className={classNames(classes.agencySelect, {
                          [classes.selectError]: agencyErrors[user.email],
                        })}
                        TextFieldProps={{
                          InputProps: {
                            className: classes.selectInput,
                            classes: { notchedOutline: classes.selectBorder },
                          },
                        }}
                        getOptionLabel={getSiteName}
                        renderOption={renderOption(getSiteName)}
                        filterOptions={filterOptions}
                        freeSolo
                        noAdornment
                        popupIcon={(
                          <div className={classes.selectIconContainer}>
                            <ChevronBig className={classes.selectIcon} />
                          </div>
                        )}
                        forcePopupIcon
                        disabled={user.role === null}
                      />
                    )}
                    {i === arr.length - 1 && nextPageButton}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div className={classes.legend}>
          {(loggedInUser.cardRole === 'CARDS_ADMIN' || loggedInUser.cardRole === 'CARD_OFFERS_MANAGER') && (
            <Typography className={classes.legendElement}>
              <span className={classes.legendTerm}>
                {t('card.offersManager')}
              </span>
              <span className={classes.legendDescription}>
                {t('card.offersManagerDescription')}
              </span>
            </Typography>
          )}
          {(loggedInUser.cardRole === 'CARD_OFFERS_MANAGER' || loggedInUser.cardRole === 'SITE_MANAGER') && (
            <Typography className={classes.legendElement}>
              <span className={classes.legendTerm}>
                {t('card.siteManager')}
              </span>
              <span className={classes.legendDescription}>
                {t('card.siteManagerDescription')}
              </span>
            </Typography>
          )}
          {loggedInUser.cardRole === 'CARD_OFFERS_MANAGER' && (
            <Typography className={classes.legendElement}>
              <span className={classes.legendTerm}>
                {t('card.cardSeller')}
              </span>
              <span className={classes.legendDescription}>
                {t('card.cardSellerDescription')}
              </span>
            </Typography>
          )}
          {loggedInUser.cardRole === 'SITE_MANAGER' && (
            <Typography className={classes.legendElement}>
              <span className={classes.legendTerm}>
                {t('card.cardInspector')}
              </span>
              <span className={classes.legendDescription}>
                {t('card.cardInspectorDescription')}
              </span>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
