import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Route } from 'react-router-dom';
import background from '../assets/background.jpg';
import { ReactComponent as Logo } from '../assets/interconnect-logo.svg';
import Register from './Register';
import EmailVerified from './EmailVerified';
import Login from './Login';
import RecoverPassword from './RecoverPassword';
import Footer from '../passenger/Footer';

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    width: 400,
    height: 800,
    backgroundColor: 'white',
    margin: 'auto 0 43px',
    borderRadius: 3,
    padding: '0 52px',
    maxWidth: '100%',
    '@media (max-width: 800px)': {
      padding: '0 24px',
    },
    '@media (max-height: 950px)': {
      marginTop: 43,
    },
  },
  logo: {
    margin: '76px auto 52px',
    display: 'block',
  },
  switch: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    margin: '21px 33px',
    '@media (max-width: 800px)': {
      position: 'static',
      alignSelf: 'flex-start',
    },
  },
  switchText: {
    fontSize: 12,
  },
  copyright: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    margin: '21px 33px',
    color: '#37001F',
    fontSize: 12,
    '@media (max-width: 800px)': {
      position: 'static',
      alignSelf: 'flex-start',
    },
  },
});

export default function Auth() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Logo className={classes.logo} />
        <Route path="/:language/auth/register" component={Register} />
        <Route path="/:language/auth/email-verified" component={EmailVerified} />
        <Route path="/:language/auth/login" component={Login} />
        <Route path="/:language/auth/recover-password" component={RecoverPassword} />
      </div>
      <Footer />
    </div>
  );
}
