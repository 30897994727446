import React, { useContext } from 'react';
import { Link as MuiLink, makeStyles, Typography } from '@material-ui/core';
import {
  NavLink, Route, Switch,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import logo from '../assets/interconnect-logo.svg';
import { useGetLink } from '../utils';
import AppContext, { CardContext } from '../context';
import UserMenu from '../common/UserMenu';
import Dashboard from './Dashboard';
import Logs from './Logs';
import Assignment from './Assignment';

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    backgroundColor: 'white',
    padding: '0 32px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1000px)': {
      padding: '0 16px',
    },
    '@media (max-width: 1000px), (max-height: 800px)': {
      height: 'auto',
      minHeight: '100vh',
    },
  },
  nav: {
    height: 43,
    flexShrink: 0,
    padding: '0 32px',
    margin: '0 -32px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(232, 232, 232, 0.5)',
  },
  logo: {
    height: 41,
    marginRight: 'auto',
  },
  navIconLink: {
    display: 'flex',
    justifyContent: 'center',
    width: 100,
    padding: '8px 0',
  },
  navLink: {
    color: '#a1145c',
    fontSize: 20,
    textDecoration: 'none',
    textAlign: 'center',
    width: 200,
    whiteSpace: 'nowrap',
    margin: '0 16px',
  },
  active: {
    fontWeight: 700,
  },
  main: {
    flexGrow: 1,
    minHeight: 400,
    display: 'flex',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 0',
    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  switch: {
    '@media (max-width: 1000px)': {
      marginBottom: 16,
    },
  },
  switchText: {
    fontSize: 12,
  },
  copyright: {
    whiteSpace: 'nowrap',
    fontSize: 12,
    color: '#37001F',
  },
});

export default function Beacon() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const getLink = useGetLink();
  const { name, user, interconnectNumber } = useContext(AppContext);
  if (!name || !user) return null;
  return (
    <CardContext.Provider
      value={{ name, user }}
    >
      <div className={classes.root}>
        <div className={classes.nav}>
          <img
            src={logo}
            alt="Interconnect logo"
            className={classes.logo}
          />
          <Typography
            component={NavLink}
            to={getLink('/beacon')}
            className={classes.navLink}
            activeClassName={classes.active}
            exact
          >
            {t('beacon.dashboard')}
          </Typography>
          <Typography
            component={NavLink}
            to={getLink('/beacon/assignment')}
            className={classes.navLink}
            activeClassName={classes.active}
          >
            {t('beacon.beaconAssignment')}
          </Typography>
          <Typography
            component={NavLink}
            to={getLink('/beacon/logs')}
            className={classes.navLink}
            activeClassName={classes.active}
          >
            {t('beacon.serviceLogs')}
          </Typography>
          <UserMenu
            name={name}
            role={user.role}
            agency={user.agency}
            interconnectNumber={interconnectNumber}
          />
        </div>
        <div className={classes.main}>
          <Switch>
            <Route path="/:language/beacon" exact component={Dashboard} />
            <Route path="/:language/beacon/assignment/:tab?" exact component={Assignment} />
            <Route path="/:language/beacon/logs" exact component={Logs} />
          </Switch>
        </div>
        <div className={classes.footer}>
          <MuiLink
            className={classes.switch}
            component="button"
            onClick={() => {
              i18next.changeLanguage(i18n.language === 'pl' ? 'en' : 'pl');
            }}
          >
            <Typography className={classes.switchText}>
              {t('footer.switchLanguage')}
            </Typography>
          </MuiLink>
          <Typography className={classes.copyright}>
            Copyright © 2020 Innobaltica Sp. z o.o.
          </Typography>
        </div>
      </div>
    </CardContext.Provider>
  );
}
